import React, {useState} from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {getPaymentList} from "../Components/store/reducers/payment/getDataPaymentList";
import {getStatistics} from "../Components/store/reducers/services/getStatistics";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {getCalcPayment} from "../Components/store/reducers/payment/finalizePayment";

const Statistics = () => {
    const dispatch = useDispatch();
    const [Date1, setDate1] = useState();
    const [Date2, setDate2] = useState();

    const {statisticsData} = useSelector(({service}) => service);
    const {Alive, Removed, Reviews, apiOrdersCount, bonusSum, 'install-IOS': iosInstalls, 'install-android': androidInstalls, invoiceCount, invoiceSum,
    'k.w.Install-IOS': iosKwInstalls, 'k.w.Install-android': androidKwInstalls, ordersCount, 'rate-IOS': iosRate, 'rate-android': androidRate, 'remove_review-android': removeAndroidReview, revenue,
    'review-IOS': iosReview, 'review-android': androidReview, sitetCount, sitetSum, 'upvote-IOS': iosUpvote, 'upvote-android': androidUpvote} = statisticsData;

    const showStat = () => {
        dispatch(getStatistics(Date1, Date2));
    }

    return (
        <div>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <TextField
                        value={Date1}
                        onChange={(e) => setDate1(e.target.value)}
                        type="date"
                        id="date"
                        label='Start Date'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{margin: "10px"}}
                    />
                    <TextField
                        value={Date2}
                        label='End Date'
                        onChange={(e) => setDate2(e.target.value)}
                        type="date"
                        id="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{margin: "10px"}}
                    />
                    <div>
                        <Button
                            onClick={showStat}
                            variant="contained"
                            color="primary"
                            sx={{marginRight: '10px'}}
                        >
                            Show Statistics
                        </Button>
                    </div>
            </Box>
            <Box sx={{width: '100%', display: 'flex', mb: '20px', mt: '20px', justifyContent: 'start'}} className="custom-list">
                <Box sx={{width: '17%', mr: '20px'}}>
                    <Typography variant="h6" component="div" gutterBottom sx={{fontWeight: 'bold'}}>
                        Revenue/Orders
                    </Typography>
                    <Box sx={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}} className='custom-list-col'>
                        <List sx={{width: '100%'}}>
                            <ListItem><label><span>Revenue: </span>{revenue}</label></ListItem>
                            <ListItem><label><span>Service Cost: </span>{}</label></ListItem>
                            <ListItem><label><span>Sites: </span>{sitetSum}</label></ListItem>
                            <ListItem><label><span>Sites q-ty: </span>{sitetCount}</label></ListItem>
                            <ListItem><label><span>Invoices: </span>{invoiceSum}</label></ListItem>
                            <ListItem><label><span>Invoices q-ty: </span>{invoiceCount}</label></ListItem>
                            <ListItem><label><span>Payments: </span>{}</label></ListItem>
                            <ListItem><label><span>Orders: </span>{}</label></ListItem>
                            <ListItem><label><span>Api Orders: </span>{apiOrdersCount}</label></ListItem>
                        </List>
                    </Box>

                </Box>
                <Box sx={{width: '16%', mr: '20px'}}>
                    <Typography variant="h6" component="div" gutterBottom sx={{fontWeight: 'bold'}}>
                        Service Performed q-ty
                    </Typography>
                    <Box sx={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}} className='custom-list-col'>
                        <List sx={{width: '100%'}}>
                            <ListItem><label><span>Android-installs: </span>{androidInstalls}</label></ListItem>
                            <ListItem><label><span>IOS installs: </span>{iosInstalls}</label></ListItem>
                            <ListItem><label><span>IOS k.w.installs: </span>{iosKwInstalls}</label></ListItem>
                            <ListItem><label><span>Android k.w.installs: </span>{androidKwInstalls}</label></ListItem>
                            <ListItem><label><span>IOS reviews: </span>{iosReview}</label></ListItem>
                            <ListItem><label><span>Android reviews: </span>{androidReview}</label></ListItem>
                        </List>
                    </Box>

                </Box>
                <Box sx={{width: '15%', mr: '20px'}}>
                    <Typography variant="h6" component="div" gutterBottom sx={{fontWeight: 'bold'}}>
                        Link Checker Stats:
                    </Typography>
                    <Box sx={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}} className='custom-list-col'>
                        <List sx={{width: '100%'}}>
                            <ListItem><label><span>Reviews: </span>{Reviews}</label></ListItem>
                            <ListItem><label><span>Reviews alive: </span>{Alive}</label></ListItem>
                        </List>
                    </Box>
                </Box>
                <Box sx={{width: '17%'}}>
                    <Typography variant="h6" component="div" gutterBottom sx={{fontWeight: 'bold'}}>
                        Total revenue and bonus:
                    </Typography>
                    <Box sx={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}} className='custom-list-col'>
                        <List sx={{width: '100%'}}>
                            <ListItem><label><span>Android: </span>{}</label></ListItem>
                            <ListItem><label><span>IOS: </span>{}</label></ListItem>
                            <ListItem><label><span>Bonus: </span>{bonusSum}</label></ListItem>
                        </List>
                    </Box>
                </Box>

            </Box>
        </div>
    );
};

export default Statistics;