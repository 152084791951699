import React, { Fragment, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable from '@material-table/core'
import TextField from '@material-ui/core/TextField'
import { Add as AddIcon } from '@material-ui/icons'
import { Button, Paper } from '@material-ui/core'
import 'antd/dist/antd.css'
import { getProxy, resetDataProxy } from '../Components/store/reducers/proxy/actions/getProxy'
import ModalEditReview from '../Components/ModalEditReview'
import { addProxy } from '../Components/store/reducers/proxy/actions/addProxy'
import { updateProxy } from '../Components/store/reducers/proxy/actions/updateProxy'
import { deleteProxy } from '../Components/store/reducers/proxy/actions/deleteProxy'
import Alert from '@mui/material/Alert'
import isEmpty from 'lodash/isEmpty'
import { getStatsProxy } from '../Components/store/reducers/proxy/actions/getStatsProxy'
import Box from '@material-ui/core/Box'

const Proxy = () => {

  const dispatch = useDispatch()
  const { isLoading } = useSelector(({ service }) => service)
  const { proxyData } = useSelector(({ proxy }) => proxy)
  const { newProxy } = useSelector(({ proxy }) => proxy)
  const { deletedDataProxy } = useSelector(({ proxy }) => proxy)
  const { updatedProxy } = useSelector(({ proxy }) => proxy)
  const { statsProxy } = useSelector(({ proxy }) => proxy)
  const { isFetchError } = useSelector(({ error }) => error)
  const { dataError } = useSelector(({ error }) => error)
  const { messageSuccess } = useSelector(({ error }) => error)

  const [data, setData] = useState()
  const [Date1, setDate1] = useState()
  const [Date2, setDate2] = useState()
  const isShowStats = useRef(false)
  const showStat = async () => {
    await dispatch(getStatsProxy(Date1, Date2))
    isShowStats.current = true
  }
  // const getStats = (type, id) => {
  //   console.log('isShowStats', isShowStats)
  //   // if (isShowStats.current && statsProxy) {
  //     console.log('type', type)
  //     console.log('id', id)
  //     console.log('statsProxy', statsProxy)
  //     const statsProxyItem = statsProxy && statsProxy.find(proxyItem => proxyItem.id === id)
  //     console.log('statsProxyItem', statsProxyItem)
  //     // if (type === 'fail') {
  //     //   return statsProxyItem.fail
  //     // }
  //     return statsProxyItem
  //   // }
  // }

  const [columns, setColumns] = useState([
    { field: 'id', title: 'ID', type: 'numeric', width: '30px', editable: 'never', cellStyle: { textAlign: 'center' } },
    { field: 'protocol', title: 'protocol', width: '50px', cellStyle: { textAlign: 'center' } },
    { field: 'address', title: 'address', width: '75px', cellStyle: { textAlign: 'center' } },
    { field: 'port', title: 'port', width: '50px', cellStyle: { textAlign: 'center' } },
    { field: 'username', title: 'username', width: '50px', cellStyle: { textAlign: 'center' } },
    { field: 'password', title: 'password', width: '200px', cellStyle: { textAlign: 'center' } },
    { field: 'country', title: 'country', width: '50px', cellStyle: { textAlign: 'center' } },
    { field: 'city', title: 'city', width: '75px', cellStyle: { textAlign: 'center' } },
    {
      field: 'createdAt',
      title: 'Created',
      editable: 'never',
      width: '100px',
      render: rowData => <span>{ new Date(rowData.createdAt).toLocaleDateString() }</span>,
      cellStyle: { textAlign: 'center' }
    },
    {
      field: 'updatedAt',
      title: 'Updated',
      editable: 'never',
      width: '100px',
      render: rowData => <span>{ new Date(rowData.updatedAt).toLocaleDateString() }</span>,
      cellStyle: { textAlign: 'center' }
    },
    {
      field: 'fail', title: 'fail ios', width: '50px', cellStyle: { textAlign: 'center' },
      // render: rowData => <span>{ getStats('fail', rowData.id) }</span>
      render: rowData => <span>{ rowData.fail?.iOs }</span>
    },
    {
      field: 'fail', title: 'fail android', width: '50px', cellStyle: { textAlign: 'center' },
      // render: rowData => <span>{ getStats('fail', rowData.id) }</span>
      render: rowData => <span>{ rowData.fail?.Android }</span>
    },
    {
      field: 'success', title: 'success ios', width: '50px', cellStyle: { textAlign: 'center' },
      render: rowData => <span>{ rowData.success?.iOs }</span>
    },
    {
      field: 'success', title: 'success android', width: '50px', cellStyle: { textAlign: 'center' },
      render: rowData => <span>{ rowData.success?.Android }</span>
    },
    {
      title: 'Status',
      field: 'status',
      width: '50px',
      render: rowData => (<div>{ rowData.status }</div>)

      // lookup: {
      //   active: 'active', paused: 'paused', acrhived: 'acrhived'
      // }
    }
  ])

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const [dataUpdate, setDataUpdate] = useState({})


  const handleDialogClose = event => {
    setIsDialogOpen(false)
  }

  const handleInputValue = e => {
    if (['port'].includes(e.target.name)) {
      setDataUpdate({ ...dataUpdate, [e.target.name]: parseInt(e.target.value) })
    } else {
      setDataUpdate({ ...dataUpdate, [e.target.name]: e.target.value.trim() })
    }
  }

  useEffect(() => {
    dispatch(getProxy())
  }, [])
  useEffect(() => {
    setData(proxyData)
  }, [proxyData])


  useEffect(() => {
    const resultArray = proxyData.map(obj => {
      const newObj = { ...obj }
      const matchingObj = statsProxy.find(o => o.id === obj.id)
      if (matchingObj) {
        newObj.fail = matchingObj.fail
        newObj.success = matchingObj.success
      } else {
        newObj.fail = null
        newObj.success = null
      }
      return newObj
    })
    setData(resultArray)

  }, [statsProxy])

  const handleAddNewRow = event => {

    const newData = {
      ...dataUpdate
      // order: Number(id)
    }
    setData(
      [newData, ...data]
    )
    dispatch(addProxy(newData))

  }

  useEffect(() => {
    // Closes dialog after saving
    if (isDialogOpen) {
      setIsDialogOpen(false)
    }
  }, [data])

  useEffect(() => {
    // Clears the inputs if `isDialogOpen` equals `false`
    if (!isDialogOpen) {
      setDataUpdate('')
    }
  }, [isDialogOpen])

  useEffect(() => {
    !isEmpty(data) && dispatch(getProxy())
    dispatch(resetDataProxy())
    dispatch(getProxy())
  }, [deletedDataProxy, updatedProxy, newProxy])

  const actions = [
    {
      icon: () => <AddIcon />,
      tooltip: 'Add Proxy',
      isFreeAction: true,
      onClick: (event, rowData) => {
        setIsDialogOpen(true)
      }
    },
    {
      icon: 'delete',
      tooltip: 'Delete proxy',
      onClick: (event, rowData) => {
        let answer
        answer = window.confirm('You want to delete ' + rowData.id)
        if (answer) {
          // const deleteData = {
          //   ids: [rowData.id]
          // }
          // // const newData = {pass: providerPass.toString(), reportId: parseInt(rowData.id)}
          // console.log('deleteData', deleteData)
          dispatch(deleteProxy(rowData.id))
        } else {
          console.log('rowData.id- Check', rowData.id)
        }
      }
    }
    // {
    //   icon: 'delete',
    //   tooltip: 'Delete All Proxies ',
    //   isFreeAction: true,
    //   onClick: (event, rowData) => {
    //     let answer
    //     answer = window.confirm('You want to delete all proxies')
    //     if (answer) {
    //       const deleteData = {
    //         ids: []
    //       }
    //       data.forEach(element => deleteData.ids.push(element.id))
    //       // const newData = {pass: providerPass.toString(), reportId: parseInt(rowData.id)}
    //       console.log('deleteData', deleteData)
    //       // dispatch(deleteReports(deleteData))
    //     } else {
    //       console.log('rowData.id- Check', rowData.id)
    //     }
    //   }
    // }
  ]

  return (
    <>
      { messageSuccess &&
        <div className="errorBlock">
          <Alert severity="success">{ messageSuccess }</Alert>
        </div>
      }
      { isFetchError &&
        <div className="errorBlock">
          <Alert severity="error">{ dataError.message }</Alert>
        </div>
      }
      <Box sx={ { display: 'flex', alignItems: 'center' } }>
        <TextField
          value={ Date1 }
          onChange={ (e) => setDate1(e.target.value) }
          type="date"
          id="date"
          label="Start Date"
          InputLabelProps={ {
            shrink: true
          } }
          style={ { margin: '10px' } }
        />
        <TextField
          value={ Date2 }
          label="End Date"
          onChange={ (e) => setDate2(e.target.value) }
          type="date"
          id="date"
          InputLabelProps={ {
            shrink: true
          } }
          style={ { margin: '10px' } }
        />
        <div>
          <Button
            onClick={ showStat }
            variant="contained"
            color="primary"
            sx={ { marginRight: '10px' } }
          >
            Show Statistics
          </Button>
        </div>
      </Box>
      <Fragment>
        <MaterialTable
          title="Proxy"
          columns={ columns }
          actions={ actions }
          data={ data }
          options={ {
            tableLayout: 'fixed',
            filtering: true,
            columnsButton: true,
            actionsColumnIndex: -1,
            pageSize: 30,
            pageSizeOptions: [30, 60, 90, 120, 150],
            padding: 'dense',
            search: false,
            addRowPosition: 'first',
            headerStyle: {
              backgroundColor: '#DEF3FA',
              color: 'Black'
              // whiteSpace: 'nowrap'
            },
            rowStyle: {
              fontSize: '12px',
              paddingLeft: 5,
              paddingRight: 5
            }
          } }

          cellEditable={ {
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                const newData = {
                  [columnDef.field]: newValue
                }
                const dataUpdate =
                  [...data].map((item, i) => {
                    if (rowData.tableData.id === item.id)
                      rowData[columnDef.field] = newValue
                    return item
                    console.log('item: ', item)
                  })
                setData([...dataUpdate])

                dispatch(updateProxy(rowData.id, newData))
                setTimeout(resolve, 1000)

              })
            }
          } }
        />
        <ModalEditReview sx={ { width: '300px' } } title="Add Proxy" isOpen={ isDialogOpen } onClose={ handleDialogClose }>
          <Paper style={ { padding: '2em' } }>
            <div><TextField onChange={ handleInputValue } name="username" label="Username" /></div>
            <div><TextField onChange={ handleInputValue } name="password" label="Password" /></div>

            <div><TextField onChange={ handleInputValue } name="address" label="Address" /></div>
            <div><TextField onChange={ handleInputValue } name="port" label="Port" type="number" /></div>
            <div><TextField onChange={ handleInputValue } name="country" label="Country" /></div>
            <div><TextField onChange={ handleInputValue } name="city" label="City" /></div>
            <div style={ { marginTop: '3em' } }>
              <Button onClick={ handleAddNewRow }>Save</Button>
              <Button onClick={ handleDialogClose }>Cancel</Button>
            </div>
          </Paper>
        </ModalEditReview>
      </Fragment>
    </>
  )
}

export default Proxy