import authHeader from "../../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../../environment";

export const DOWNLOAD_FILE_HASH_PROVIDER = "DOWNLOAD_FILE_HASH_PROVIDER";
const API_URL = environment.baseUrl;

export const downloadFileHashProvider = (id, providerHash, data) => {

    return (dispatch) => {
        // dispatch(setIsLoading(true));
        axios
            // .get(API_URL + `/orders/order/${providerHash}/provider/report/xlsx`, {
            .get(API_URL + `/providers/orders/download`, {
                headers:
                    {
                        'Content-Disposition': 'attachment; ' ,
                        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'Authorization': authHeader().Authorization,
                    },
                responseType: 'arraybuffer',
                // params: data


            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                // const fileName = res.headers["content-disposition"]
                link.setAttribute('download', `${id}_order_report.xlsx`);
                document.body.appendChild(link);
                link.click();
                // dispatch({ type: DOWNLOAD_FILE_HASH_PROVIDER, payload: res.data });
                // dispatch(setIsLoading(false));
                console.log("data:", res)
                console.log("data2:", res.data)
                console.log("url:", url)
            })
            .catch((err) => {
                console.log("err", err)
                // dispatch(setError(err))
                // dispatch(setIsLoading(false));
            });
    };
};