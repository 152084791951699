import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getOrdersList, resetDataOrdersList } from '../store/reducers/orderReducer/actions/getDataOrdersList'
import TableOrders from './TableOrders'
import isEmpty from 'lodash/isEmpty'
import Button from '@mui/material/Button'
import { downloadOrderFile } from '../store/reducers/orderReducer/actions/downloadXlsxOrders'
import Alert from '@mui/material/Alert'
import { getUser } from '../store/reducers/user/actions/getUserId'
import Box from '@mui/material/Box'
import UploadIcon from '@mui/icons-material/Upload'
import { uploadFileOrderReport } from '../store/reducers/orderReducer/actions/uploadXlsxReport'
import { uploadFileOrders } from '../store/reducers/orderReducer/actions/uploadOrders'
import TableOrdersProvider from './TableOrdersProvider'
import { getUserAll } from '../store/reducers/user/actions/getUserAll'
import Typography from '@mui/material/Typography'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import Modal from '@mui/material/Modal'
import { downloadOrdersByProvider } from '../store/reducers/orderReducer/actions/executorDownloadXlsxOrders'

const Orders = ({ isManager }) => {
  const dispatch = useDispatch()
  const { orderDataList } = useSelector(({ order }) => order)
  const { updateOrder } = useSelector(({ order }) => order)

  const { isFetchError } = useSelector(({ error }) => error)
  const { dataError } = useSelector(({ error }) => error)
  const { messageSuccess } = useSelector(({ error }) => error)

  const [pageSize, setPageSize] = useState(30)
  const [page, setPage] = useState(0)
  const [filterData, setFilterData] = useState({})
  const [filterSave, setFilterSave] = useState({})

  const { userData } = useSelector(({ user }) => user)
  const { userDataOne } = useSelector(({ user }) => user)
  const { user: currentUser } = useSelector((state) => state.auth)
  const [selectedFile, setSelectedFile] = useState(null)

  const checkedFilter = ['draft', 'pending payment', 'active', 'cancelled', 'in work', 'paused', 'add details', 'finished', 'report sent', 'refilling', 'refilled', 'archived']
  const [checkedFilterDownload, setCheckedFilterDownload] = useState(checkedFilter)

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const checkboxCheck = (e) => {
    // setCheckedFilterDownload(e.target.checked);
    console.log('e.target.checked', e.target.checked)
    console.log('e.target.name', e.target.name)
    if (e.target.checked) {
      const checkedResult = checkedFilterDownload.concat(e.target.name)
      setCheckedFilterDownload(checkedResult)
    } else {
      const checkedResult = checkedFilterDownload.filter((current) => current !== e.target.name)
      setCheckedFilterDownload(checkedResult)
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 520,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3
  }


  // const [isExecutor, setIsExecutor] = useState(false);

  // if(isEmpty(userDataOne)){
  //     currentUser && dispatch(getUser(currentUser.id))
  // }

  // const isExecutor = userDataOne.role === "executor"


  if (localStorage.getItem('page') === null && localStorage.getItem('pageSize') === null
    // && localStorage.getItem('columns') === null
  ) {
    window.localStorage.setItem('page', page)
    window.localStorage.setItem('pageSize', pageSize)
    // window.localStorage.setItem('columns', columnsHide);
  }
  useEffect(() => {
    dispatch(getUserAll())
  }, [])


  useEffect(() => {
    setPage(JSON.parse(window.localStorage.getItem('page')))
    setPageSize(JSON.parse(window.localStorage.getItem('pageSize')))
    setFilterData(JSON.parse(window.localStorage.getItem('filterData')))
    setFilterSave(JSON.parse(window.localStorage.getItem('filterValue')))
  }, [])

  useEffect(() => {
    window.localStorage.setItem('page', page)
    window.localStorage.setItem('pageSize', pageSize)
    window.localStorage.setItem('filterData', JSON.stringify(filterData))
    window.localStorage.setItem('filterValue', JSON.stringify(filterSave))
  }, [page, pageSize, filterData, filterSave])

  // useEffect(() => {
  //     dispatch(getUser(currentUser.id));
  // }, []);


  // if(isEmpty(orderDataList)) {
  //     dispatch(getOrdersList(page, pageSize, filterData));
  // }

  useEffect(() => {
    !isEmpty(orderDataList) && dispatch(getOrdersList(page, pageSize, filterData))
    dispatch(resetDataOrdersList())
    dispatch(getOrdersList(page, pageSize, filterData))
  }, [])

  // if (!isManager) {
  //     return <Redirect to={`/orders/provider/${currentUser.id}`} />;
  // }

  // useEffect(() => {
  //     if(isExecutor) {
  //         return <Redirect to={`/orders/provider/${userDataOne.id}`} />;
  //     }
  // }, []);


  const arrServiceProviders = userData.filter(user => user.role === 'executor')
  const downloadFile = () => {
    // dispatch(downloadOrderFile())
    const date = moment().format('DD-MMM-YYYY')
    const filtersData = {
      status: checkedFilterDownload,
      created: {
        from: startDate,
        to: endDate
      }
    }
    handleClose()
    dispatch(getOrdersList(0, 30, filtersData, 'download'))
    dispatch(getOrdersList(page, pageSize, filterData))
  }

  const submitFormUpload = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('file', selectedFile)
    dispatch(uploadFileOrders(formData))
  }

  return (
    <>
      { messageSuccess &&
        <div className="errorBlock">
          <Alert severity="success">{ messageSuccess }</Alert>
        </div>
      }
      { isFetchError &&
        <div className="errorBlock">
          <Alert severity="error">{ dataError.message }</Alert>
        </div>
      }

      { !isEmpty(orderDataList) && (
        <div>
          <div className="column col-100 paymentBtn-block btnXlsReport">
            {/*<Link to="/orders/xlsx" target="_blank" onClick={downloadFile} download >Download xls report</Link>*/ }
            <Button variant="outlined" className="btn-xlsReport" onClick={ handleOpen }>Download xls
              report</Button>

            <form onSubmit={ submitFormUpload } className="uploadOrdersBlock">
              <label className="custom-file-upload">
                <input
                  type="file"
                  // value={selectedFile}
                  onChange={ (e) => setSelectedFile(e.target.files[0]) }
                />
                <div className="uploadIcon">
                  <UploadIcon /><span className="file-preview">{ selectedFile && selectedFile.name }</span>
                </div>
              </label>

              <Button type="submit" sx={ { width: '100%' } } variant="outlined">Upload orders</Button>

            </form>
          </div>

          <Modal
            open={ open }
            onClose={ handleClose }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-filter"
          >
            <Box sx={ style }>
              <Typography variant="h6" component="div" gutterBottom sx={ { fontWeight: 'bold', textAlign: 'center' } }>
                Choose status filter
              </Typography>
              <FormGroup className="list-item">
                {
                  checkedFilter.map(item => (
                    <FormControlLabel
                      control={ <Checkbox checked={ checkedFilterDownload.includes(item) } onChange={ checkboxCheck } /> }
                      label={ item }
                      name={ item }
                    />
                  ))
                }

                <div>
                  <TextField
                    label="Start Date"
                    InputLabelProps={ {
                      shrink: true
                    } }
                    type="date"
                    value={ startDate }
                    onChange={ (e) => setStartDate(e.target.value) }
                    style={ { marginRight: '20px' } }
                  />

                  <TextField
                    label="End Date"
                    InputLabelProps={ {
                      shrink: true
                    } }
                    type="date"
                    value={ endDate }
                    onChange={ (e) => setEndDate(e.target.value) }
                    inputProps={ {
                      max: startDate && moment(startDate).add(2, 'months').format('YYYY-MM-DD'),
                      min: startDate
                    } }
                  />
                </div>
                <Button sx={ { width: '100%', my: '10px' } } variant="outlined" onClick={ downloadFile }>Download xls report</Button>
              </FormGroup>
            </Box>
          </Modal>

          <TableOrders
            page={ page }
            pageSize={ pageSize } setPage={ setPage } setPageSize={ setPageSize } filterData={ filterData } setFilterData={ setFilterData }
            filterSave={ filterSave } setFilterSave={ setFilterSave } arrServiceProviders={ arrServiceProviders }
          />
        </div>
      ) }
    </>
  )
}

export default Orders