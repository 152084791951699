import {addNewOrder} from "../store/reducers/orderReducer/actions/addNewOrderAction";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {addCommentOrder} from "../store/reducers/orderReducer/actions/addOrderComment";
import {Grid, InputLabel, Radio, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {getService} from "../store/reducers/services/getService";
import {getBusiness} from "../store/reducers/services/getBusiness";
import {Link} from "react-router-dom";
import {addNewPromo} from "../store/reducers/cms/actionsCms/addNewPromo";


const NewPromo = ({handleClose, businessData, filterService, serviceData}) => {
    const dispatch = useDispatch()
    // const {id} = useParams();
    // const {paymentData} = useSelector(({payment}) => payment);


    // const checkboxCheck = (event) => {
    //     setChecked(event.target.checked);
    // };
    const [type, setType] = React.useState('page');
    const [promoData, setPromoData] = useState({
        type: type
    })
    const [promoName, setPromoName] = useState()
    const [selectedSite, setSelectedSite] = useState()
    const [selectedService, setSelectedService] = useState()

    useEffect(() => {
        setPromoData({type: type})
    }, [type]);
    console.log('promoData',promoData);
    const updateInputValue = e => {
        console.log('e.target',e.target);
        console.log(e.target.value);
        if (e.target.name === "service") {
            setPromoData({...promoData, [e.target.name]: Number(e.target.value)})
            const findService = serviceData.find(item => item.id === e.target.value)
            setSelectedService(findService)
        } else if(e.target.name === "site") {
            const selectDataSite = businessData.find(item => item.id === e.target.value)
            setSelectedSite(selectDataSite)
        } else {
            setPromoData({...promoData, [e.target.name]: e.target.value})
        }

    };
    const submitAddNewPromo = (e) => {
        e.preventDefault();
        const data = {
            ...promoData
            // stats: [{ }]
        }
        dispatch(addNewPromo(selectedSite.id, data))
        handleClose()
    }

    const addNewCommentOrder = (e) => {
        // e.preventDefault();
        // dispatch(addCommentOrder(comment))
        // console.log("Comment!!!:", comment);
    }


    console.log('type',type);
    const handleChange = (event, newType) => {
        console.log('newType',newType);
        setType(newType);
    };


    const changePromoName = (e) => {
        setPromoName(e.target.value);
    };


    console.log('promoName',promoName);
    console.log('selectedSite',selectedSite);


    return (
        <div className="container">
            <h2>Add Promo</h2>

            <form onSubmit={submitAddNewPromo}>
                <Box sx={{width: '100%', display: 'flex'}}>
                    <Box sx={{width: '50%', display: 'flex', flexDirection: 'column'}}>

                        <ToggleButtonGroup
                            color="primary"
                            value={type}
                            exclusive
                            onChange={handleChange}
                            name="type"
                            size="small"
                        >
                            <ToggleButton value="page">PromoPage</ToggleButton>
                            <ToggleButton value="code">PromoCode</ToggleButton>
                        </ToggleButtonGroup>

                        <Box sx={{display: "flex", flexDirection: "column"}}>
                            <FormControl variant="standard" sx={{m: 1, maxWidth: 150}}>
                                <InputLabel id="select-standard-label">Choose Site:</InputLabel>
                                <Select
                                    labelId="chooseSite"
                                    id="site"
                                    onChange={(e)=>updateInputValue(e)}
                                    label="Choose Site:"
                                    name="site"

                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {businessData.map((item, index) => {
                                        return (
                                            <MenuItem value={item.id} >{item.name}</MenuItem>
                                        )
                                    })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl variant="standard" sx={{m: 1, maxWidth: 150}}>
                                <InputLabel id="label-choosePage">Choose Page:</InputLabel>
                                <Select
                                    labelId="choosePage"
                                    id="servicePage"
                                    onChange={updateInputValue}
                                    // label="Choose Service:"
                                    name="service"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {filterService.map((item, index) => {
                                        return (
                                            <MenuItem value={item.id}>{item.platform} {item.name}</MenuItem>
                                        )
                                    })
                                    }

                                </Select>
                            </FormControl>
                        </Box>


                    </Box>


                    <Box sx={{width: '50%'}}>
                        <Box sx={{display: 'flex', alignItems: 'center'}} className='promoName'>
                            <InputLabel htmlFor="promoName">Promo name</InputLabel>
                            <TextField id="promoName" variant="standard" name="name"
                                       onChange={updateInputValue} onInput={changePromoName}/>
                        </Box>

                    </Box>
                </Box>
                <Box>
                    <div>
                        <p>
                            Hint for the: <a href={`https://${selectedSite && selectedSite.name}.org/`+`${promoName}/`} target='_blank'>
                            {selectedSite && selectedSite.host}{selectedService && (selectedService.platform + '-' + selectedService.name + '/')}{promoName}
                        </a>
                            {/*https://{promoData.site}.org/*/}
                        </p>
                    </div>
                </Box>
                <Box sx={{width: '100%', textAlign: 'center', mt: '30px'}}>
                    <Button type="submit" variant="contained">Create Promo</Button>
                </Box>

            </form>
        </div>
    );
};

export default NewPromo;