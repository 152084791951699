import authHeader from "../../../../../services/auth-header";
import axios from "axios";

export const ADD_COMMENT_ORDER = 'ADD_COMMENT_ORDER';

export const addCommentOrder = (data) => {
    return (dispatch) => {
        axios
            .post('/orders/comment', data, { headers: authHeader() })
            .then((res) => {
                dispatch({ type: ADD_COMMENT_ORDER, payload: data });
            })
            .catch((err) => {
                console.log("err", err)
            });
    };
};