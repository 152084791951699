import authHeader from '../../../../../services/auth-header'
import axios from 'axios'
import environment from '../../../../../environment'
import { DATA_SUCCESS, dataSuccess } from '../../Error/messageSuccess'
import { dataError } from '../../Error/errorData'
import { setFetchError } from '../../Error/errorAction'

export const UPDATE_PROXY = 'UPDATE_PROXY'
const API_URL = environment.baseUrl

export const updateProxy = (id, data) => {
  return (dispatch) => {
    // dispatch(setIsLoading(true));
    axios
      .put(API_URL + `/settings/proxy/${ id }`, data, { headers: authHeader() })
      .then((res) => {
        dispatch({ type: UPDATE_PROXY, payload: res.data })
        dispatch({ type: DATA_SUCCESS, payload: res.data.message })
        setTimeout(() => {
          dispatch(dataSuccess())
        }, 4000)
        // dispatch(setIsLoading(false));
        console.log('res updateProxy:', res.data)
      })
      .catch((err) => {
        console.log('err', err)
        dispatch(dataError(err.res.data))
        setTimeout(() => {
          dispatch(setFetchError(false))
        }, 4000)
        // dispatch(setError(err))
        // dispatch(setIsLoading(false));
      })
  }
}

export const resetDataProxy = () => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PROXY, payload: {} })
  }
}