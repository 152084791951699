import authHeader from "../../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../../environment";

export const ADD_NEW_EXECUTOR = 'ADD_NEW_EXECUTOR';
const API_URL = environment.baseUrl;

export const addNewExecutor = (newProvider) => {
    console.log('newProvider', newProvider)
    return (dispatch) => {
        axios
            .post(API_URL + '/user/registration/executor', newProvider, { headers: authHeader() })
            .then(res => {
                console.log(res)
                dispatch({ type: ADD_NEW_EXECUTOR, payload: res})
            })
            .catch((err) => {
                console.log("err", err)
            })
    }
}