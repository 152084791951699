import authHeader from '../../../../../services/auth-header'
import axios from 'axios'
import environment from '../../../../../environment'
import { DATA_SUCCESS, dataSuccess } from '../../Error/messageSuccess'
import { setFetchError } from '../../Error/errorAction'
import { dataError } from '../../Error/errorData'

export const UPDATE_CHECK_DATE = 'UPDATE_CHECK_DATE'
const API_URL = environment.baseUrl

export const updateCheckDate = (id, date) => {
  console.log('id date', id, date)
  return (dispatch) => {
    // dispatch(setIsLoading(true));
    axios
      .put(API_URL + `/order/${ id }/mass-check-date`, { massCheckEndDate: date }, { headers: authHeader() })
      .then((res) => {
        dispatch({ type: UPDATE_CHECK_DATE, payload: res.data.order })
        dispatch({ type: DATA_SUCCESS, payload: res.data.message })
        setTimeout(() => {
          dispatch(dataSuccess())
        }, 4000)
        // dispatch(setIsLoading(false));
        console.log('UPDATE_CHECK_DATE:', res.data)
      })
      .catch((err) => {
        console.log('err', err)
        dispatch(setFetchError(true))
        dispatch(dataError(err.response.data))
        setTimeout(() => {
          dispatch(setFetchError(false))
        }, 4000)
        // dispatch(setError(err))
        // dispatch(setIsLoading(false));
      })
  }
}

// export const resetDataOrder = () => {
//   return (dispatch) => {
//     dispatch({type: UPDATE_CHECK_DATE, payload: {}});
//   };
// };