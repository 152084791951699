import { ADD_ORDER_REPORT } from './addOrderReport'
import { CHECK_SINGLE_ANDROID_REVIEW } from './checkSingleAndroidReview'
import { CHECK_ANDROID_APP } from './checkAndroidApp'
import { ADD_PROVIDER_REPORT } from './addProviderReport'
import { UPDATE_PROVIDER_REPORT } from './updateProviderReport'
import { DELETE_PROVIDER_REPORT } from './deleteProviderReport'
import { DELETE_REPORTS } from './deleteReports'
import { ALL_REVIEWS_ANDROID_CHECK } from './allReviewsAndroidCheck'
import { CHECK_IOS_APP } from './checkIosApp'
import { ALL_REVIEWS_IOS_CHECK } from './allReviewsIosCheck'
import { CHECK_SINGLE_IOS_REVIEW } from './checkSingleIosReview'

const state = {
  newReport: {},
  newProviderReport: {},
  updateDataProviderReport: {},
  deleteDataProviderReport: {},
  deleteDataReports: {},
  checkSingleAndroidReview: {},
  checkSingleIosReview: {},
  appCheck: {},
  reviewsAndroidCheck: {},
  reviewsIosCheck: {}
}

export const orderReportReducer = (initialState = state, action) => {
  switch (action.type) {
    case ADD_ORDER_REPORT:
      return { ...initialState, newReport: action.payload }
    case ADD_PROVIDER_REPORT:
      return { ...initialState, newProviderReport: action.payload }
    case UPDATE_PROVIDER_REPORT:
      return { ...initialState, updateDataProviderReport: action.payload }
    case DELETE_PROVIDER_REPORT:
      return { ...initialState, deleteDataProviderReport: action.payload }
    case DELETE_REPORTS:
      return { ...initialState, deleteDataReports: action.payload }
    case CHECK_SINGLE_ANDROID_REVIEW:
      return { ...initialState, checkSingleAndroidReview: action.payload }
    case CHECK_SINGLE_IOS_REVIEW:
      return { ...initialState, checkSingleIosReview: action.payload }
    case ALL_REVIEWS_ANDROID_CHECK:
      return { ...initialState, reviewsAndroidCheck: action.payload }
    case ALL_REVIEWS_IOS_CHECK:
      return { ...initialState, reviewsIosCheck: action.payload }
    case CHECK_ANDROID_APP:
      return { ...initialState, appCheck: action.payload }
    case CHECK_IOS_APP:
      return { ...initialState, appCheckIos: action.payload }
    default:
      return initialState
  }
}