import authHeader from "../../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../../environment";

export const UPDATE_MANAGER = 'UPDATE_MANAGER';
const API_URL = environment.baseUrl;

export const updateManager = (data) => {
    console.log('dataExecutor', data)
    return (dispatch) => {
        axios
            .post(API_URL + '/user/update/manager', data, { headers: authHeader() })
            .then(res => {
                console.log(res)
                dispatch({ type: UPDATE_MANAGER, payload: res})
            })
            .catch((err) => {
                console.log("err", err)
            })
    }
}