import authHeader from "../../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../../environment";
import {DATA_SUCCESS, dataSuccess} from "../../Error/messageSuccess";

export const DOWNLOAD_PROVIDER_ORDERS = "DOWNLOAD_PROVIDER_ORDERS";
const API_URL = environment.baseUrl;

export const downloadProviderOrdersFile = (id,date) => {

    const data = {
        providerId: id
    }
    console.log('dataFILE', data)
    return (dispatch) => {
        // dispatch(setIsLoading(true));
        axios
            .post(API_URL + '/orders/xlsx/short', data, {
                headers:
                    {
                        'Content-Disposition': "attachment; filename=Orders.xlsx",
                        'Content-Type': 'application/json',
                        'Authorization': authHeader().Authorization
                    },
                responseType: 'arraybuffer',


            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `order_report_${date}.xlsx`);
                document.body.appendChild(link);
                link.click();
                // dispatch({ type: DOWNLOAD_PROVIDER_ORDERS, payload: res.data });
                // dispatch(setIsLoading(false));

                console.log("data:", res)
            })
            .catch((err) => {
                console.log("err", err)
                // dispatch(setError(err))
                // dispatch(setIsLoading(false));
            });
    };
};