import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";
import {setFetchError} from "../Error/errorAction";
import {dataError} from "../Error/errorData";
import {DATA_SUCCESS, dataSuccess} from "../Error/messageSuccess";

export const DELETE_REPORTS= 'DELETE_REPORTS';
const API_URL = environment.baseUrl;

export const deleteReports = (data) => {
    console.log('data thunk', data)
    // console.log('Report', data)
    return (dispatch) => {
        axios
            .delete( API_URL + `/reviews`, {
                headers:
                    {
                        'Authorization': authHeader().Authorization,
                    },
                data
            })
            .then(res => {
                console.log(res)
                dispatch({ type: DELETE_REPORTS, payload: res})
                dispatch({ type: DATA_SUCCESS, payload: res.message})
                setTimeout(() => {
                    dispatch(dataSuccess())
                }, 4000)
            })
            .catch((error) => {
                dispatch(setFetchError(true))
                dispatch(dataError(error.response))
                setTimeout(() => {
                    dispatch(setFetchError(false))
                }, 4000)
            })
    }
}