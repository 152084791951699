import authHeader from "../../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../../environment";

export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
const API_URL = environment.baseUrl;

export const downloadOrderFile = () => {
    const data = {
    }
    return (dispatch) => {
        // dispatch(setIsLoading(true));
        axios
            .post(API_URL + '/orders/xlsx', data, {
          headers:
            {
                'Content-Disposition': "attachment; filename=Orders.xlsx",
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Authorization': authHeader().Authorization
            },
            responseType: 'arraybuffer',


            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Orders.xlsx');
                document.body.appendChild(link);
                link.click();
                // dispatch({ type: DOWNLOAD_FILE, payload: res.data });
                // dispatch(setIsLoading(false));
                console.log("data:", res)
            })
            .catch((err) => {
                console.log("err", err)
                // dispatch(setError(err))
                // dispatch(setIsLoading(false));
            });
    };
};