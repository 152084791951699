import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";

export const GET_BUSINESS = "GET_BUSINESS";
const API_URL = environment.baseUrl;

export const getBusiness = () => {
    return (dispatch) => {
        // dispatch(setIsLoading(true));
        axios
            .get(API_URL + '/business', { headers: authHeader() })
            .then((res) => {
                dispatch({ type: GET_BUSINESS, payload: res.data });
                // dispatch(setIsLoading(false));
                // console.log("business:", res.data)
            })
            .catch((err) => {
                console.log("err", err)
                // dispatch(setError(err))
                // dispatch(setIsLoading(false));
            });
    };
};

// export const resetDataBusiness = () => {
//     return (dispatch) => {
//         dispatch({type: GET_BUSINESS, payload: {}});
//     };
// };