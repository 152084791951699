import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";
import {setFetchError} from "../Error/errorAction";
import {dataError} from "../Error/errorData";
import {DATA_SUCCESS, dataSuccess} from "../Error/messageSuccess";

export const GET_CALC_PAYMENT = "GET_CALC_PAYMENT";
const API_URL = environment.baseUrl;

export const getCalcPayment = (id) => {

    return (dispatch) => {
        // dispatch(setIsLoading(true));
        axios
            .get(API_URL + `/payments/${id}/calc`, { headers: authHeader() })
            .then((res) => {
                console.log("paymentResDATAinACtion", res)
                dispatch({ type: GET_CALC_PAYMENT, payload: res.data });
                // dispatch(setIsLoading(false));
                dispatch({ type: DATA_SUCCESS, payload: res.data})
                setTimeout(() => {
                    dispatch(dataSuccess())
                }, 4000)
            })
            .catch((error) => {
                dispatch(setFetchError(true))
                dispatch(dataError(error.response.data))
                setTimeout(() => {
                    dispatch(setFetchError(false))
                }, 4000)
                // dispatch(setError(err))
                // dispatch(setIsLoading(false));
            });
    };
};

export const resetDataPayment = () => {
    return (dispatch) => {
        dispatch({type: GET_CALC_PAYMENT, payload: {}});
    };
};