import {GET_SERVICE} from "./getService";
import {ADD_NEW_SERVICE} from "./addNewService";
import {GET_BUSINESS} from "./getBusiness";
import {IS_LOADING} from "./isLoading";
import {GET_STATISTICS} from "./getStatistics";
import { GET_LANGUAGES } from './getLanguages'
import { GET_COUNTRIES } from './getCountries'

const initStore = {
    serviceData: [],
    languagesData: [],
    countriesData: [],
    newService: {},
    businessData: [],
    isLoading: false,
    statisticsData: {},
};

export const serviceReducer = (initialState = initStore, action) => {
    switch (action.type) {
        case GET_SERVICE:
            return {...initialState, serviceData: action.payload};
        case GET_LANGUAGES:
            return {...initialState, languagesData: action.payload};
        case GET_COUNTRIES:
            return {...initialState, countriesData: action.payload};
        case ADD_NEW_SERVICE:
            return {...initialState, newService: action.payload};
        case GET_BUSINESS:
            return {...initialState, businessData: action.payload};
        case IS_LOADING:
            return {...initialState, isLoading: action.payload};
        case GET_STATISTICS:
            return {...initialState, statisticsData: action.payload};
        default:
            return initialState;
    }
};