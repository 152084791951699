import authHeader from '../../../../../services/auth-header'
import axios from 'axios'
import environment from '../../../../../environment'
import { setIsLoading } from '../../services/isLoading'

export const GET_ORDERS_LIST = 'GET_ORDERS_LIST'
const API_URL = environment.baseUrl

export const getOrdersList = (page, pageSize, filters, action) => {
  const data = {
    // filters,
    filters: filters ? filters : {},
    sort: {},
    meta: {
      take: parseInt(pageSize),
      skip: parseInt(page*pageSize)
    }
    // take: parseInt(pageSize),
    // skip: parseInt(page)
  }
  if (action) {
    data['sort'] = {
      created: 'DESC'
    }
  }

  return (dispatch) => {
    dispatch(setIsLoading(true))
    axios
      .post(API_URL + `/order/${ action ? action : 'view' }`, data, {
        headers:
          action
            ? {
              'Content-Disposition': 'attachment; filename=Orders.xlsx',
              'Content-Type': 'application/json',
              'Authorization': authHeader().Authorization
            }
            : authHeader(),
        responseType: action ? 'arraybuffer' : null
      })
      .then((res) => {
        if (action) {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `orders.xlsx`)
          document.body.appendChild(link)
          link.click()
        } else {
          dispatch({ type: GET_ORDERS_LIST, payload: res.data })
          dispatch(setIsLoading(false))
        }

        console.log('data:', res.data)

      })
      .catch((err) => {
        console.log('err', err)
        // dispatch(setError(err))
        dispatch(setIsLoading(false))
      })
  }
}

export const resetDataOrdersList = () => {
  return (dispatch) => {
    dispatch({ type: GET_ORDERS_LIST, payload: {} })
  }
}