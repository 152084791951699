import authHeader from '../../../../../services/auth-header'
import axios from 'axios'
import environment from '../../../../../environment'

export const UPLOAD_FILE_ORDER_REPORT = 'UPLOAD_FILE_ORDER_REPORT'
const API_URL = environment.baseUrl

export const uploadFileOrderReport = (id, formData) => {

  return (dispatch) => {
    // dispatch(setIsLoading(true));
    axios
      .post(API_URL + `/order/${ id }/reviews/upload`, formData, {
        headers:
          {
            'Content-Type': 'multipart/form-data',
            'Authorization': authHeader().Authorization
          }
      })
      .then((res) => {
        dispatch({ type: UPLOAD_FILE_ORDER_REPORT, payload: res.data })
        // dispatch(setIsLoading(false));
      })
      .catch((err) => {
        console.log('err', err)
        // dispatch(setError(err))
        // dispatch(setIsLoading(false));
      })
  }
}