import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";

export const ADD_COMMENT_PAYMENT= 'ADD_COMMENT_PAYMENT';
const API_URL = environment.baseUrl;

export const addCommentPayment = (data) => {
    console.log('dataComment', data)
    return (dispatch) => {
        axios
            .post(API_URL + '/payments/comment', data, { headers: authHeader() })
            .then(res => {
                console.log(res)
                dispatch({ type: ADD_COMMENT_PAYMENT, payload: res})
            })
            .catch((err) => {
                console.log("err", err)
            })
    }
}