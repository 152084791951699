import axios from 'axios'
import environment from '../../../../environment'

export const DOWNLOAD_FILE_SINGLE_ORDER_PROVIDER = 'DOWNLOAD_FILE_SINGLE_ORDER_PROVIDER'
const API_URL = environment.baseUrl

export const downloadFileSingleOrderProvider = (hash, id, pass, data) => {

  const queryParams = encodeURIComponent(JSON.stringify(data))
  return (dispatch) => {
    // dispatch(setIsLoading(true));
    axios
      .get(API_URL + `/providers/${ hash }/reviews/download?status=${ queryParams }`, {
        headers:
          {
            'Content-Disposition': 'attachment; ',
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'provider-pass': pass
          },
        responseType: 'arraybuffer'
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        // const fileName = res.headers["content-disposition"]
        link.setAttribute('download', `${ hash }-${ id }_order_reviews.xlsx`)
        document.body.appendChild(link)
        link.click()
        // dispatch({ type: DOWNLOAD_FILE_SINGLE_ORDER_PROVIDER, payload: res.data });
        // dispatch(setIsLoading(false));

      })
      .catch((err) => {
        console.log('err', err)
        // dispatch(setError(err))
        // dispatch(setIsLoading(false));
      })
  }
}