import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable from '@material-table/core'
import TextField from '@material-ui/core/TextField'
import { addOrderReport } from '../store/reducers/report/addOrderReport'
import { updateOrderReport } from '../store/reducers/orderReducer/actions/updateOrderReport'
import Rating from '@mui/material/Rating'
import { getOrderById, resetDataOrder } from '../store/reducers/orderReducer/actions/getOrderById'
import { Add as AddIcon } from '@material-ui/icons'
import ModalEditReview from '../ModalEditReview'
import { Button, Paper } from '@material-ui/core'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Typography from '@mui/material/Typography'
import CachedIcon from '@mui/icons-material/Cached'
import { deleteReports } from '../store/reducers/report/deleteReports'
import { allReviewsAndroidCheck } from '../store/reducers/report/allReviewsAndroidCheck'
import 'antd/dist/antd.css'
import { allReviewsIosCheck } from '../store/reducers/report/allReviewsIosCheck'
import { checkSingleAndroidReview } from '../store/reducers/report/checkSingleAndroidReview'
import { checkSingleIosReview } from '../store/reducers/report/checkSingleIosReview'
import moment from 'moment'

const TableOrderReport = ({ reports, id, service }) => {

  const dispatch = useDispatch()

  const { orderData } = useSelector(({ order }) => order)
  const { isLoading } = useSelector(({ service }) => service)
  const [data, setData] = useState(reports)
  const [rowId, setRowId] = useState()
  const [newData, setNewData] = useState()

  const status = [
    { name: 'NotChecked' },
    { name: 'Removed' },
    { name: 'Alive' }
  ]

  const onClickEl = (id) => {
    console.log('rowIEEEEEE', id)
    setRowId(id)
  }

  useEffect(() => {
    setData(reports)
    // setIsLoading(false)
  }, [orderData])

  // const linkCheckerFind = orderDataList.find(item => item.id === rowId)

  const onChangeValue = (value, props) => {
    props.onChange(value)
    const newData = ({
      [props.columnDef.field]: value
    })
    dispatch(updateOrderReport(props.rowData.id, newData))
  }

  const onChangeValueInput = (value, props) => {
    props.onChange(value)
    const newValueCheck = props.columnDef.type == 'numeric' ? Number(value) : value
    setNewData({ [props.columnDef.field]: newValueCheck, reportId: rowId })
  }

  const onBlur = () => {

    dispatch(updateOrderReport(id, newData))
    setTimeout(() => {
      dispatch(resetDataOrder())
      dispatch(getOrderById(id))
    }, 1000)
  }

  const [columns, setColumns] = useState([
    { field: 'id', title: '№', type: 'numeric', width: '50px', editable: 'never', cellStyle: { textAlign: 'center' } },
    { field: 'reviewId', title: 'reviewId', width: '150px', cellStyle: { textAlign: 'center' } },
    {
      field: 'text', title: 'Review Text', width: '250px',
      cellStyle: { wordBreak: 'break-all', overflow: 'hidden', width: '100px' },
      emptyValue: <span className="emptyValue">Empty</span>,
      render: rowData => (
        rowData.text === '' ? <span className="emptyValue">Empty</span> : rowData.text
      )
    },
    {
      field: 'title', title: 'Title', width: '100px',
      emptyValue: <span className="emptyValue">Empty</span>,
      render: rowData => (
        rowData.title === '' ? <span className="emptyValue">Empty</span> : rowData.title
      )
    },
    {
      field: 'userName', title: 'Name', width: '140px',
      emptyValue: <span className="emptyValue">Empty</span>,
      render: rowData => (
        rowData.userName === '' ? <span className="emptyValue">Empty</span> : rowData.userName
      )
    },
    {
      field: 'score', title: 'Stars', width: '100px',
      render: rowData => (<Rating name="score" value={ rowData.score } readOnly size="small" />)
    },
    ...(service.id === 12
      ? []
      : [
        {
          field: 'publishedAt',
          title: 'publish',
          editable: 'never',
          width: '100px',
          render: rowData => <span>{ rowData.publishedAt ? moment(rowData.publishedAt).format('DD-MMM-YYYY') : '' }</span>,
          cellStyle: { textAlign: 'center' }
        }]),
    {
      field: 'lastCollectedAt',
      title: 'Last Collected',
      editable: 'never',
      width: '100px',
      render: rowData => <span>{ rowData.lastCollectedAt ? moment(rowData.lastCollectedAt).format('DD-MMM-YYYY') : '' }</span>,
      cellStyle: { textAlign: 'center' }
    },
    {
      title: 'Status',
      field: 'status',
      width: '90px',
      render: rowData => (<div name="status">{ rowData.status }</div>),
      editComponent: props => (
        <select
          name={ props.columnDef.field }
          value={ props.value }
          onChange={ (e) => onChangeValue(e.target.value, props) }

        >
          { status.map((item) => {
            return (
              <option value={ item.name }>{ item.name }</option>
            )
          }) }
        </select>
      )

    }
  ])

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [dataReview, setDataReview] = useState({})

  const handleDialogClose = () => {
    setIsDialogOpen(false)
  }

  const handleInputValue = (e) => {
    setDataReview({ ...dataReview, [e.target.name]: e.target.value.trim() })
  }


  const handleAddNewRow = event => {
    // if (!dataReview.text) return;

    const newData = {
      ...dataReview,
      order: Number(id)
    }
    setData(
      [newData, ...data]
    )
    dispatch(addOrderReport(newData))

  }

  useEffect(() => {
    // Closes dialog after saving
    if (isDialogOpen) {
      setIsDialogOpen(false)
    }
  }, [data])

  useEffect(() => {
    // Clears the inputs if `isDialogOpen` equals `false`
    if (!isDialogOpen) {
      setDataReview('')
    }
  }, [isDialogOpen])

  const actions = [
    {
      icon: () => <AddIcon />,
      tooltip: 'Add Review',
      isFreeAction: true,
      onClick: (event, rowData) => {
        setIsDialogOpen(true)
      }
    },
    {
      icon: CachedIcon,
      iconProps: isLoading ? { sx: { width: '200px', transform: 'rotate(45deg)' } } : '',
      tooltip: 'Link Check',
      onClick: (event, rowData) => {
        // const checkData = {
        //     ids: [rowData.id]
        // }
        service.platform === 'android' ? dispatch(checkSingleAndroidReview(Number(rowData.id))) : dispatch(checkSingleIosReview(Number(rowData.id)))
      }
    },
    {
      icon: 'delete',
      tooltip: 'Delete review',
      onClick: (event, rowData) => {
        let answer
        answer = window.confirm('You want to delete ' + rowData.id)
        if (answer) {
          const deleteData = {
            ids: [rowData.id]
          }
          // const newData = {pass: providerPass.toString(), reportId: parseInt(rowData.id)}
          dispatch(deleteReports(deleteData))
        } else {
          console.log('rowData.id- Check', rowData.id)
        }
      }
    },
    {
      icon: CachedIcon,
      tooltip: 'Link Check All Reviews',
      isFreeAction: true,
      onClick: (event, rowData) => {
        service.platform === 'android' ? dispatch(allReviewsAndroidCheck(id)) : dispatch(allReviewsIosCheck(id))
      }
    },
    {
      icon: 'delete',
      tooltip: 'Delete All Reviews ',
      isFreeAction: true,
      onClick: (event, rowData) => {
        let answer
        answer = window.confirm('You want to delete all reviews')
        if (answer) {
          const deleteData = {
            ids: []
          }
          data.forEach(element => deleteData.ids.push(element.id))
          // const newData = {pass: providerPass.toString(), reportId: parseInt(rowData.id)}

          dispatch(deleteReports(deleteData))
        } else {
          console.log('rowData.id- Check', rowData.id)
        }
      }
    }
  ]

  // useEffect(() => {
  //   setData(reports)
  // }, [updateReport])


  return (
    <>
      {/*{service && (service.id === 20 || service.id === 21 || service.id === 23 || service.id === 26 || service.id === 30) && (*/ }
      {/* // {service && (service.id === 11 || service.id === 12 || service.id === 14 || service.id === 17 || service.id === 21 || service.id === 22) && (*/ }
      <Fragment>
        <MaterialTable
          title="Reviews"
          columns={ columns }
          actions={ actions }
          data={ data }
          isLoading={ isLoading }
          options={ {
            tableLayout: 'fixed',
            filtering: true,
            columnsButton: true,
            actionsColumnIndex: -1,
            pageSize: 30,
            pageSizeOptions: [30, 60, 90, 120, 150],
            padding: 'dense',
            search: false,
            addRowPosition: 'first',
            headerStyle: {
              backgroundColor: '#DEF3FA',
              color: 'Black'
              // whiteSpace: 'nowrap'
            },
            rowStyle: {
              fontSize: '12px',
              paddingLeft: 5,
              paddingRight: 5
            }
          } }

          cellEditable={ {
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                // const dataUpdate = [...data];
                // dataUpdate[rowData.tableData.id][columnDef.field] = newValue
                // const newValueCheck = typeof newValue !== typeof oldValue ? Number(newValue) : newValue
                console.log('newValue', newValue)
                console.log('oldValue', oldValue)
                const newData = {
                  [columnDef.field]: newValue.toString()
                  // reviewId: rowData.id
                }
                const dataUpdate =
                  [...data].map((item, i) => {
                    if (rowData.tableData.id === item.id)
                      rowData[columnDef.field] = newValue
                    return item
                    console.log('item: ', item)
                  })
                setData([...dataUpdate])

                dispatch(updateOrderReport(rowData.id, newData))
                setTimeout(resolve, 1000)

              })
            }
          } }

        />
        <ModalEditReview sx={ { width: '300px' } } title="Add Review" isOpen={ isDialogOpen } onClose={ handleDialogClose }>
          <Paper style={ { padding: '2em' } }>
            <div><Typography variant="h6" component="div" gutterBottom>
              Text
            </Typography>
              <TextareaAutosize
                minRows={ 5 }
                placeholder="write text here"
                style={ { width: 200 } }
                name="text"
                onChange={ handleInputValue }
              />
              {/*<TextField value={dialogText} onInput={handleText} label="Text" />*/ }

            </div>
            <div><TextField onChange={ handleInputValue } name="title" label="Title" /></div>
            <div><TextField onChange={ handleInputValue } name="userName" label="Name" /></div>
            <div><TextField onChange={ handleInputValue } name="score" label="Stars" /></div>
            {/*<div><TextField onChange={ handleInputValue } name="link" label="Link" /></div>*/ }
            <div style={ { marginTop: '3em' } }>
              <Button onClick={ handleAddNewRow }>Save</Button>
              <Button onClick={ handleDialogClose }>Cancel</Button>
            </div>
          </Paper>
        </ModalEditReview>
      </Fragment>
      {/*)}*/ }
    </>
  )
}

export default TableOrderReport