import authHeader from '../../../../../services/auth-header'
import axios from 'axios'
import environment from '../../../../../environment'
import { DATA_SUCCESS, dataSuccess } from '../../Error/messageSuccess'
import { UPDATE_PAYMENT_DATA } from '../../payment/updatePaymentData'
import { setFetchError } from '../../Error/errorAction'
import { dataError } from '../../Error/errorData'
import { setIsLoading } from '../../services/isLoading'

export const GET_PROVIDER_ORDERS = 'GET_PROVIDER_ORDERS'
const API_URL = environment.baseUrl

export const getProviderOrders = (id, page, pageSize, filters) => {

  const data = {
    // filters,
    filters: filters ? filters : {},
    sort: { created: 'DESC' },
    meta: {
      take: parseInt(pageSize),
      skip: parseInt(page*pageSize)
    }
    // take: parseInt(pageSize),
    // skip: parseInt(page)
  }

  return (dispatch) => {
    dispatch(setIsLoading(true))
    axios
      .post(API_URL + `/order/${ id }/provider`, data, { headers: authHeader() })
      .then((res) => {
        dispatch({ type: GET_PROVIDER_ORDERS, payload: res.data })
        dispatch(setIsLoading(false))
        console.log('responsePAGE??', res)
        // dispatch({ type: DATA_SUCCESS, payload: res.data})
        // setTimeout(() => {
        //     dispatch(dataSuccess())
        // }, 4000)
      })
      .catch((error) => {
        dispatch(setFetchError(true))
        console.log('error-res:', error)
        // dispatch(dataError(error.response.data))
        setTimeout(() => {
          dispatch(setFetchError(false))
        }, 4000)
        dispatch(setIsLoading(false))
      })
  }
}

export const resetProviderOrdersList = () => {
  return (dispatch) => {
    dispatch({ type: GET_PROVIDER_ORDERS, payload: {} })
  }
}