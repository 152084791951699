import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable from '@material-table/core'
import { updatePaymentData } from '../store/reducers/payment/updatePaymentData'
import isEmpty from 'lodash/isEmpty'
import { getOrdersList, resetDataOrdersList } from '../store/reducers/orderReducer/actions/getDataOrdersList'
import { Link } from 'react-router-dom'
import { updateOrderData } from '../store/reducers/orderReducer/actions/updateOrderData'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { getPaymentList, resetDataPaymentList } from '../store/reducers/payment/getDataPaymentList'
import { getDataPaymentById, resetDataPayment } from '../store/reducers/payment/paymentAction'
import Select from '@material-ui/core/Select'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import FormControl from '@material-ui/core/FormControl'
import { getService } from '../store/reducers/services/getService'
import Tooltip from '@material-ui/core/Tooltip'
import Autocomplete from '@mui/material/Autocomplete'
import TableCell from '@material-ui/core/TableCell'
// import { alpha } from '@material-ui/core/styles

const TablePayment = ({ orders, id }) => {

  const dispatch = useDispatch()
  const { orderDataList } = useSelector(({ order }) => order)
  const { updateOrder } = useSelector(({ order }) => order)
  const { newDataOrder } = useSelector(({ order }) => order)
  const { userData } = useSelector(({ user }) => user)
  const { serviceData } = useSelector(({ service }) => service)

  const [data, setData] = useState(orders)

  const [serviceProvider, setServiceProvider] = useState({})

  const arrServiceProviders = userData.filter(user => user.role === 'executor')
  useEffect(() => {
    dispatch(getService())
  }, [])

  useEffect(() => {
    arrServiceProviders.map(row => serviceProvider[row.id] = row.login)
    setServiceProvider(serviceProvider)
  }, [arrServiceProviders])

  const status = [
    { id: 1, name: 'pending payment' },
    { id: 2, name: 'active' },
    { id: 3, name: 'add details' },
    { id: 4, name: 'in work' },
    { id: 5, name: 'finished' },
    { id: 6, name: 'report sent' },
    { id: 7, name: 'refilling' },
    { id: 8, name: 'refilled' },
    { id: 9, name: 'cancelled' },
    { id: 10, name: 'archived' },
    { id: 11, name: 'paused' },
    { id: 12, name: 'draft' }
  ]

  const serviceIos = serviceData.filter(item => item.platform === 'IOS')
  const serviceAndroid = serviceData.filter(item => item.platform === 'android')

  const [rowId, setRowId] = useState()
  const [newData, setNewData] = useState()

  const onClickEl = (id) => {
    setRowId(id)
  }

  const onChangeValue = (value, props) => {
    props.onChange(value)
    const newValueCheck = props.columnDef.type === 'numeric' ? parseInt(value) : value
    const newData = ({ [props.columnDef.field]: newValueCheck })

    const dataUpdate =
      [...data].map((item, i) => {
        if (props.rowData.tableData.id === item.id)
          props.rowData[props.columnDef.field] = newValueCheck
        return item
      })
    setData([...dataUpdate])

    dispatch(updateOrderData(props.rowData.id, newData))

    setTimeout(() => {
      // dispatch(resetDataPayment());
      // dispatch(getDataPaymentById(id));
    }, 1000)
  }


  const onChangeValueInput = (value, props) => {
    props.onChange(value)
    const linkCheckerFind = orderDataList.find(item => item.id === props.rowData.id)
    const newValueCheck = props.columnDef.type == 'numeric' ? Number(value) : value
    setNewData({ [props.columnDef.field]: newValueCheck, linkChecker: linkCheckerFind.linkChecker })
  }


  const [created, setCreated] = useState()

  const onChangeDate = (date, props) => {
    props.onChange(date)

    function addDateMin(date, minutes) {
      return new Date(date.getTime() + minutes * 60000)
    }

    // setCreated(date)
    // const newValueCheck = props.columnDef.field == 'price' ? parseInt(value) : value
    const newData = ({ [props.columnDef.field]: addDateMin(date, 180) })
    // dispatch(updatePaymentData(rowId, newData))
    // new KeyboardEvent("keydown", { keyCode: "Tab", which: 9 })
    // new KeyboardEvent("keydown", { keyCode: "Enter", which: 13 })

    const dataUpdate =
      [...data].map((item, i) => {
        if (props.rowData.tableData.id === item.id)
          props.rowData[props.columnDef.field] = props.value
        return item
      })
    setData([...dataUpdate])
    dispatch(updateOrderData(props.rowData.id, newData))
    // setTimeout(() => {
    //     dispatch(resetDataOrdersList());
    //     dispatch(getOrdersList());
    // }, 1000)

    // props.autofocus = false
  }

  const onBlur = () => {
    dispatch(updateOrderData(rowId, newData))
    setTimeout(() => {
      dispatch(resetDataPayment())
      dispatch(getDataPaymentById(id))
    }, 1000)
  }

  const clickOne = (e) => {
    e.target.click()
  }


  useEffect(() => {
  }, [updateOrder, newDataOrder])

  const [columns, setColumns] = useState([
    {
      field: 'created', title: 'Order Date', width: '130px',
      // render: rowData => (<div >{moment(rowData.created).format('DD-MMM-YYYY')}</div> ),
      render: rowData => (<DatePicker value={ moment(rowData.created).format('DD-MMM-YYYY') } />),
      editComponent: props => (
        <div>
          <DatePicker
            value={ moment(props.value).format('DD-MMM-YYYY') }
            // selected={created}
            // onChange={props.onChange}
            onChange={ (date) => onChangeDate(date, props) }
          />
        </div>

      ),
      cellStyle: { whiteSpace: 'nowrap', width: '100%' }
    },
    {
      field: 'id', title: 'Order Number', type: 'numeric', width: '80px', editable: 'never', cellStyle: { whiteSpace: 'nowrap', width: '100%', textAlign: 'center' },
      render: rowData => (<Link to={ { pathname: `/orders/order/${ rowData.id }` } }>{ rowData.id }</Link>)
    },
    {
      field: 'custom_id', title: 'Custom Id', type: 'numeric', width: '80px', editable: 'never',
      cellStyle: { whiteSpace: 'nowrap', width: '100%', textAlign: 'center' },
      render: rowData => (
        rowData.custom_id === '' ? <span className="emptyValue">Empty</span> : rowData.custom_id
      )
    },
    {
      field: 'price', title: 'O.Price', type: 'numeric', width: '80px', cellStyle: { whiteSpace: 'nowrap', width: '100%', textAlign: 'center' },
      render: rowData => (<div>{ rowData.price }</div>)
    },
    {
      field: 'serviceCost', title: 'O.Service Cost', type: 'numeric', width: '80px', cellStyle: { whiteSpace: 'nowrap', width: '100%', textAlign: 'center' },
      render: rowData => (<div>{ rowData.serviceCost }</div>)
    },
    {
      field: 'appId',
      title: 'App Id',
      width: '100px',
      cellStyle: { fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden' },
      render: rowData => (<Tooltip title={ rowData.application?.appId ? rowData.application.appId : 'none' } placement="bottom">
          <div className="cell">{ rowData.application?.appId ? rowData.application.appId : 'none' }</div>
        </Tooltip>
      )
    },

    // { field: 'name', title: 'Client Name',cellStyle: {whiteSpace: 'nowrap'} },
    // { field: 'contactEmail', title: 'Client Email', cellStyle: {whiteSpace: 'nowrap'} },
    {
      field: 'appLink', title: 'App Link', cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' },
      hidden: true, hiddenByColumnsButton: false,
      render: rowData => (<div>{ rowData.appLink }</div>)
    },
    {
      field: 'servicePlatform', title: 'Platform', cellStyle: { whiteSpace: 'nowrap', width: '100%' }, editable: 'never',
      render: rowData => (<div>{ rowData.service && rowData.service.platform }</div>)
    },
    {
      field: 'service', title: 'Service Type',
      render: rowData => (<div>{ rowData.service && rowData.service.name }</div>),
      editComponent: props => (

        <select id="grouped-native-select"
                name={ props.columnDef.field }
                value={ props.value }
                onChange={ (e) => onChangeValue(parseInt(e.target.value), props) }
        >

          <option label="None" value="None" />
          <optgroup label="IOS">
            { serviceIos.map((item, index) => {
              return (

                <option key={ index } value={ item.id }>{ item.name }</option>

              )
            }) }
          </optgroup>
          <optgroup label="Android">
            { serviceAndroid.map((item, index) => {
              return (
                <option key={ index } value={ item.id }>{ item.name }</option>

              )
            }) }
          </optgroup>
        </select>

      )
    },
    {
      field: 'quantity', title: 'Q-ty', type: 'numeric', width: '80px', cellStyle: { whiteSpace: 'nowrap', width: '100%', textAlign: 'center' },
      render: rowData => (<div>{ rowData.quantity }</div>)
    },
    {
      field: 'perDay', title: 'Speed per day', type: 'numeric', width: '80px', cellStyle: { whiteSpace: 'nowrap', width: '100%', textAlign: 'center' },
      render: rowData => (<div>{ rowData.perDay }</div>)
    },
    {
      field: 'GEO', title: 'GEO(old)', width: '80px', editable: 'never', cellStyle: { whiteSpace: 'nowrap', width: '100%', textAlign: 'center' },
      render: rowData => (<div>{ rowData.GEO ? rowData.GEO : 'none' }</div>)
    },
    {
      field: 'location', title: 'Location', width: '80px', cellStyle: { whiteSpace: 'nowrap', width: '100%', textAlign: 'center' },
      render: rowData => (<div>{ rowData.location ? rowData.location : 'none' }</div>)
    },
    {
      title: 'Status',
      field: 'status',

      render: rowData => (
        <Tooltip title={ rowData.status } placement="bottom">
          <div name="status" className={ rowData.status === 'paid' ? 'text-success' : 'text-warning' }>{ rowData.status }</div>
          {/*<select*/ }
          {/*    name='status'*/ }
          {/*    className={rowData.status === "paid" ? "text-success" : "text-warning"}*/ }
          {/*    value={ rowData.status }*/ }
          {/*>*/ }
          {/*    {status.map((item) => {*/ }
          {/*        return (*/ }
          {/*            <option value={item.name}>{item.name}</option>*/ }
          {/*        )*/ }
          {/*    })}*/ }
          {/*</select>*/ }
        </Tooltip>

      ),
      editComponent: props => (
        <Tooltip title={ props.value } placement="bottom">
          <select
            name="status"
            // value={value}
            onChange={ (e) => onChangeValue(e.target.value, props) }
            // onChange={onChange}
            value={ props.value }
            // onChange={(e) => onChangeValue(e.target.value, props)}

          >
            { status.map((item) => {
              return (
                <option value={ item.name }>{ item.name }</option>
              )
            }) }
          </select>
        </Tooltip>
      ),
      cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden' },
      lookup: {
        'pending payment': 'pending payment', active: 'active', 'add details': 'add details', 'in work': 'in work',
        finished: 'finished', 'report sent': 'report sent', refilling: 'refilling', refilled: 'refilled', cancelled: 'cancelled',
        archived: 'archived', removed: 'removed'
      }
    },
    {
      field: 'provider', title: 'Provider', type: 'numeric', cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden' }, width: '190px',
      lookup: serviceProvider,
      // emptyValue: <span className='emptyValue'>Empty</span>,/**/
      render: rowData => (
        <select
          name="provider"
          defaultChecked={ rowData.provider && rowData.provider.login }
          // onChange={(e) => onChangeValue(e.target.value, props)}

        >
          {/*{serviceProvider.find(item =>*/ }
          {/*        item.name*/ }
          {/*)}*/ }
          <option label="None" value="None" />
          { Object.entries(serviceProvider).map(([key, value], i) => (
            // <option value={parseInt(key)}>{value}</option>

            rowData.provider && rowData.provider.login === value ? (
              <option key={ key } value={ key } selected>{ value }</option>
            ) : (
              <option key={ key } value={ key }>{ value }</option>
            )
          )) }
        </select>
      ),
      // render: rowData => (<Tooltip title={rowData.provider && rowData.provider.login} placement="bottom">
      //         <div className='cell' >{rowData.provider && rowData.provider.login}</div>
      //     </Tooltip>
      // ),
      // render: rowData => (<Tooltip title={rowData.provider && rowData.provider.login ? rowData.provider.login : 'None'} placement="bottom">
      //         <div className='cell' onClick={clickOne}>{rowData.provider && rowData.provider.login ? rowData.provider.login : 'None'}</div>
      //
      //         {/*<select*/}
      //         {/*    name='provider'*/}
      //         {/*    // value={!rowData.provider.login }*/}
      //         {/*    value={rowData.provider && rowData.provider.login ? rowData.provider.login : 'None'}*/}
      //         {/*    onChange={(e) => onChangeValueSelect(e, rowData)}*/}
      //
      //         {/*>*/}
      //         {/*    <option label="None" value="None" />*/}
      //         {/*    {Object.entries(serviceProvider).map(([key, value], i) => (*/}
      //
      //         {/*        // <option value={value}*/}
      //         {/*        //         selected={rowData.provider.login !== null && rowData.provider.login === value ? false : true}*/}
      //         {/*        // >{value}</option>*/}
      //         {/*        // !rowData.provider.login == null ? <option label="None" value="" /> :*/}
      //         {/*        rowData.provider && rowData.provider.login == value ? (*/}
      //         {/*        <option key={key} value={value} selected>{value}</option>*/}
      //         {/*        ): (*/}
      //         {/*        <option key={key} value={value == null ? '' : value}>{value == null ? '' : value}</option>*/}
      //         {/*        )*/}
      //
      //
      //         {/*    ))}*/}
      //         {/*</select>*/}
      //     </Tooltip>
      // ),
      // editComponent: props => (
      //     <select
      //         name={props.columnDef.field}
      //         value={props.value == null ? props.value : "None"}
      //         onChange={(e) => onChangeValue(e.target.value, props)}
      //
      //     >
      //         {/*{serviceProvider.find(item =>*/}
      //         {/*        item.name*/}
      //         {/*)}*/}
      //         {props.rowData.provider.login == null ? <option label="None" value="None" /> : <option label="None" value="" />}
      //         {Object.entries(serviceProvider).map(([key, value], i) => (
      //                 props.rowData.provider.login == value ? (
      //                     <option key={key} value={value} selected>{value}</option>
      //                 ): (
      //                     <option key={key} value={value}>{value}</option>
      //                 )
      //
      //
      //         ))}
      //     </select>
      // ),
      editComponent: props => (
        <select
          name={ props.columnDef.field }
          value={ props.value }
          onChange={ (e) => onChangeValue(e.target.value, props) }

        >
          {/*{serviceProvider.find(item =>*/ }
          {/*        item.name*/ }
          {/*)}*/ }
          <option label="None" value="None" />
          { Object.entries(serviceProvider).map(([key, value], i) => (
            // <option value={parseInt(key)}>{value}</option>

            props.rowData.provider && props.rowData.provider.login == value ? (
              <option key={ key } value={ key } selected>{ value }</option>
            ) : (
              <option key={ key } value={ key }>{ value }</option>
            )
          )) }
        </select>
      )

      // emptyValue: '(empty)',
    },
    {
      field: 'progress', title: 'Progress', cellStyle: { whiteSpace: 'nowrap', width: '100%', textAlign: 'center' }, editable: 'never',
      render: rowData => (<div>{ rowData.progress }/{ rowData.quantity }</div>)
      // render: rowData => (<span>{rowData.progress}/{rowData.orders.reports && rowData.orders.reports.length}</span>),
    }
  ])


  return (
    <MaterialTable
      title="Orders"
      columns={ columns }
      data={ data }
      options={ {
        tableLayout: 'fixed',
        filtering: true,
        columnsButton: true,
        pageSize: 30,
        pageSizeOptions: [30, 60, 90, 120, 150],
        padding: 'dense',
        search: false,
        addRowPosition: 'first',
        headerStyle: {
          backgroundColor: '#DEF3FA',
          color: 'Black',
          // whiteSpace: 'nowrap',
          textAlign: 'center'
        },
        rowStyle: {
          // fontSize: '12px',
          paddingLeft: 5,
          paddingRight: 5
        }
      } }
      // components={{
      //     EditField: (props) => {
      //         if (props.columnDef.field === 'provider') {
      //             return  <Select
      //                 name={props.columnDef.field}
      //                 value={props.value}
      //                 onChange={(e) => onChangeValue(e.target.value, props)}
      //
      //             >
      //                 {/*{serviceProvider.find(item =>*/}
      //                 {/*        item.name*/}
      //                 {/*)}*/}
      //                 {Object.entries(serviceProvider).map(([key, value], i) => (
      //                         <MenuItem value={parseInt(key)}>{value}</MenuItem>
      //                 ))}
      //             </Select>
      //         } else if (props.columnDef.field === 'status') {
      //
      //             return  <Select
      //                 name={props.columnDef.field}
      //                 value={props.value}
      //                 onChange={(e) => onChangeValue(e.target.value, props)}
      //
      //             >
      //                 {status.map((item) => {
      //                     return (
      //                         <MenuItem value={item.name}>{item.name}</MenuItem>
      //                     )
      //                 })}
      //             </Select>
      //         } else if (props.columnDef.field === 'service') {
      //
      //             return   <FormControl sx={{ m: 1, minWidth: 120 }}>
      //                 {/*<InputLabel htmlFor="grouped-native-select">Grouping</InputLabel>*/}
      //                 <Select native defaultValue="" id="grouped-native-select" label="Grouping"
      //                         name={props.columnDef.field}
      //                         value={props.value}
      //                         onChange={(e) => onChangeValue(parseInt(e.target.value), props)}
      //                 >
      //                     <option aria-label="None" value="" />
      //                     <optgroup label="IOS">
      //                         {serviceIos.map((item, index) => {
      //                             return (
      //                                 <option value={item.id}>{item.name}</option>
      //                             )
      //                         })}
      //                     </optgroup>
      //                     <optgroup label="Android">
      //                         {serviceAndroid.map((item, index) => {
      //                             return (
      //                                 <option value={item.id}>{item.name}</option>
      //                             )
      //                         })}
      //                     </optgroup>
      //                 </Select>
      //             </FormControl>
      //         } else if (props.columnDef.field === 'created'){
      //             return <DatePicker selected={created} onChange={(date) => onChangeDate(date, props)} />
      //         } else {
      //             return <TextField
      //                 className="editCell"
      //                 style={props.columnDef.type === 'numeric' ? { float: 'right', width: '50px' } : {}}
      //                 type={props.columnDef.type === 'numeric' ? 'number' : 'text'}
      //                 placeholder={props.columnDef.title}
      //                 value={props.value === undefined ? '' : props.value}
      //                 onChange={(e) => onChangeValueInput(e.target.value, props)}
      //                 onBlur={onBlur}
      //                 InputProps={{ autoFocus: true, style: { fontSize: 13, } }}
      //             />
      //         }
      //
      //     }
      // }
      // }

      cellEditable={ {
        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
          return new Promise((resolve, reject) => {
            // const dataUpdate = [...data];
            // dataUpdate[rowData.tableData.id][columnDef.field] = newValue
            const newValueCheck = typeof newValue !== typeof oldValue ? Number(newValue) : newValue
            const newData = {
              [columnDef.field]: newValueCheck
            }
            const dataUpdate =
              [...data].map((item, i) => {
                if (rowData.tableData.id === item.id)
                  rowData[columnDef.field] = newValue
                return item

              })
            setData([...dataUpdate])

            dispatch(updateOrderData(rowData.id, newData))
            setTimeout(resolve, 1000)

          })
        }
      } }

      // editable={{
      //     onRowAdd: newData =>
      //         new Promise((resolve, reject) => {
      //             setTimeout(() => {
      //                 setData([...data, newData]);
      //                 dispatch(updateOrderData(newData))
      //                 resolve();
      //             }, 1000)
      //         }),
      //     // onRowUpdate: (newData, oldData) =>
      //     //     new Promise((resolve, reject) => {
      //     //         setTimeout(() => {
      //     //             const dataUpdate = [...data];
      //     //             const index = oldData.tableData.id;
      //     //             dataUpdate[index] = newData;
      //     //             setData([...dataUpdate]);
      //     //             // setRowId(newData.id);
      //     //             console.log("oldData.tableData", oldData.tableData)
      //     //             dispatch(updateOrderData(newData.id, newData))
      //     //             resolve();
      //     //         }, 1000)
      //     //     }),
      //     // onRowDelete: oldData =>
      //     //     new Promise((resolve, reject) => {
      //     //         setTimeout(() => {
      //     //             const dataDelete = [...data];
      /*    //             const index = oldData.tableData.id;*/
      /*    //             dataDelete.splice(index, 1);*/
      //     //             setData([...dataDelete]);
      //     //
      //     //             resolve();
      //     //         }, 1000)
      //     //     }),
      // }}
    />
  )
}

export default TablePayment