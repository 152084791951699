import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";
import { VENDOR } from '../../../../const/config'

export const GET_COUNTRIES = "GET_COUNTRIES";
const API_URL = environment.baseUrl;

export const getCountries = () => {
  return (dispatch) => {
    // dispatch(setIsLoading(true));
    axios
      .get(API_URL + `/settings/vendor/${VENDOR}/allowed-countries`, { headers: authHeader() })
      .then((res) => {
        dispatch({ type: GET_COUNTRIES, payload: res.data });
        // dispatch(setIsLoading(false));
      })
      .catch((err) => {
        console.log("err", err)
        // dispatch(setError(err))
        // dispatch(setIsLoading(false));
      });
  };
};

// export const resetDataService = () => {
//     return (dispatch) => {
//         dispatch({type: GET_COUNTRIES, payload: {}});
//     };
// };