import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";

export const GET_PAYMENT_TYPE = "GET_PAYMENT_TYPE";
const API_URL = environment.baseUrl;

export const getPaymentType = () => {

    return (dispatch) => {
        // dispatch(setIsLoading(true));
        axios
            .get(API_URL + '/payments/paymentType', { headers: authHeader() })
            .then((res) => {
                // console.log("paymentResDATAinACtion", res)
                dispatch({ type: GET_PAYMENT_TYPE, payload: res.data });
                // dispatch(setIsLoading(false));
            })
            .catch((err) => {
                console.log("err", err)
                // dispatch(setError(err))
                // dispatch(setIsLoading(false));
            });
    };
};

// export const resetPaymentType = () => {
//     return (dispatch) => {
//         dispatch({type: GET_PAYMENT_TYPE, payload: {}});
//     };
// };