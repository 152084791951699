import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";
import {setFetchError} from "../Error/errorAction";
import {dataError} from "../Error/errorData";
import {DATA_SUCCESS, dataSuccess} from "../Error/messageSuccess";
import {setIsLoading} from "../services/isLoading";

export const UPDATE_PAYMENT_DATA= 'UPDATE_PAYMENT_DATA';
const API_URL = environment.baseUrl;

export const updatePaymentData = (rowId, data, paymentType) => {
    console.log('updatePaymentDatarowId', rowId)
    console.log('updatePaymentDatadata', data)
    // const paymentTypeFind = paymentType.find(element => element.name === data.paymentType)
    const id = rowId
    const data3 = {
        created: '',
        price: '',
        clientName: '',
        contactEmail: '',
        paymentEmail: '',
        paymentType: '',
        status: '',

    }
    // const data2 = {
    //     created: data.created,
    //     price: parseInt(data.price),
    //     clientName: data.clientName,
    //     contactEmail: data.contactEmail,
    //     paymentEmail: data.paymentEmail,
    //     // paymentType: typeof data.paymentType == 'string' ? paymentTypeFind.id : data.paymentType,
    //
    //         // payType && payType.id,
    //     status: data.status
    //
    //     // status: data.status,
    //     // bonus: parseInt(data.bonus)
    // }
    // console.log('data.paymentType', data.paymentType)
    // console.log('UPDATE- DATA', data)
    // console.log('UPDATE- id', id)

    return (dispatch) => {
        dispatch(setIsLoading(true));
        axios
            .post(API_URL + `/payments/${id}/update`, data, { headers: authHeader() })
            .then(res => {
                // console.log('dataPayments-res:', data2)
                dispatch({ type: UPDATE_PAYMENT_DATA, payload: res})
                dispatch({ type: DATA_SUCCESS, payload: res.data})
                setTimeout(() => {
                    dispatch(dataSuccess())
                }, 4000)
                dispatch(setIsLoading(false));
            })
            .catch((error) => {
                dispatch(setFetchError(true))
                dispatch(dataError(error.response.data))
                setTimeout(() => {
                    dispatch(setFetchError(false))
                }, 4000)
                dispatch(setIsLoading(false));
            })
    }
}