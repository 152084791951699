import axios from 'axios'
import environment from '../../../../../environment'
import { setFetchError } from '../../Error/errorAction'
import { dataError } from '../../Error/errorData'
import { DATA_SUCCESS, dataSuccess } from '../../Error/messageSuccess'

export const UPLOAD_FILE_PROVIDER_REPORT = 'UPLOAD_FILE_PROVIDER_REPORT'
const API_URL = environment.baseUrl

export const uploadFileProviderReport = (providerHash, formData, pass) => {

  return (dispatch) => {
    // dispatch(setIsLoading(true));
    axios
      .post(API_URL + `/providers/${ providerHash }/reviews/upload`, formData, {
        headers:
          {
            'Content-Type': 'multipart/form-data',
            'provider-pass': pass
          }
      })
      .then((res) => {
        dispatch({ type: UPLOAD_FILE_PROVIDER_REPORT, payload: res.data })
        // dispatch(setIsLoading(false));
        const messageSuccess = 'Upload reviews succesfully'
        dispatch({ type: DATA_SUCCESS, payload: messageSuccess })
        setTimeout(() => {
          dispatch(dataSuccess())
        }, 4000)
      })
      .catch((error) => {
        dispatch(setFetchError(true))
        dispatch(dataError(error.response.data))
        setTimeout(() => {
          dispatch(setFetchError(false))
        }, 4000)
      })
  }
}