import React, {Fragment, useEffect, useState} from 'react';
import {useParams, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import isEmpty from "lodash/isEmpty";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {updateExecutor} from "../Components/store/reducers/user/actions/updateExecutor";
import TextField from "@mui/material/TextField";
import {addNewExecutor} from "../Components/store/reducers/user/actions/addNewExecutor";
import {Input} from "@mui/material";
import ReadOnlyRow from "../Components/Executor/ReadOnlyRow";
import EditableRow from "../Components/Executor/EditableRow";
import FormControlLabel from "@mui/material/FormControlLabel";
import {getService} from "../Components/store/reducers/services/getService";
import {getPaymentType} from "../Components/store/reducers/payment/getPaymentType";
import {addNewManager} from "../Components/store/reducers/user/actions/addNewManager";
import {getUserAll} from "../Components/store/reducers/user/actions/getUserAll";
import {addNewService} from "../Components/store/reducers/services/addNewService";
import {addNewPaymentType} from "../Components/store/reducers/payment/addPaymentType";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {updateManager} from "../Components/store/reducers/user/actions/updateManager";

const Admin = () => {
    const dispatch = useDispatch();

    const {userData} = useSelector(({user}) => user);
    const {newExecutor} = useSelector(({user}) => user);
    const {executorData} = useSelector(({user}) => user);
    const {managerData} = useSelector(({user}) => user);
    const {serviceData} = useSelector(({service}) => service);
    const {paymentType} = useSelector(({payment}) => payment);
    const { user: currentUser } = useSelector((state) => state.auth);

    const roleManager = [
        'ExecutorEdit',
        'ManagerEdit',
        'PayTypeEdit',
        'PromoEdit',
        'BonusEdit',
    ]

    // async function asyncDispatch () {
    //     await dispatch(getUserAll());
    //     await  dispatch(getService());
    //     await  dispatch(getPaymentType());
    // }
    // useEffect(() => {
    //     asyncDispatch();
    // }, [executorData]);

    useEffect(() => {
        dispatch(getUserAll());
        dispatch(getService());
        dispatch(getPaymentType());
    }, [executorData]);
    console.log("1userData", userData)

    useEffect(() => {
        dispatch(getUserAll());
    }, [managerData]);


    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const [newDataExecutor, setNewDataExecutor] = useState({
        login: "",
    })
    const [newDataManager, setNewDataManager] = useState({
        login: "",
    })
    const [newDataService, setNewDataService] = useState({
        name: "",
        platform: "",
    })
    const [newDataPaymentType, setNewDataPaymentType] = useState({
        name: "",
        isManual: true,
    })

    const [editExecutorId, setEditExecutorId] = useState(null)
    const [providerData, setProviderData] = useState({
        login: "",
        password: ""
        // managerRole: [""]
    })
    const managersData = userData && userData.filter(user => user.role === "manager");
    const providersData = userData && userData.filter(user => user.role === "executor");

    // const {managerRole} = managersData
    const [providers, setProviders] = useState(providersData)
    const [managers, setManagers] = useState([managersData])
    const [role, setRole] = useState([])

    const [service, setService] = useState(serviceData)
    console.log("2managersData", managersData)
    console.log("role", role)


    const handleCheck2 = (e, id) => {
        const roleFind = role.find(item => item.id === id)
        if (e.target.checked) {

            const updateRole = {managerRole: roleFind.managerRole.concat(e.target.name), id: id}
            // const updateRole = ({managerRole: (user.managerRole).concat(e.target.name), id: user.id})
            dispatch(updateManager(updateRole));
        } else {
            const updateRole = {managerRole: roleFind.managerRole.filter((current) => current !== e.target.name), id: id}
            dispatch(updateManager(updateRole));
        }
    };

    // useEffect(() => {
    //     dispatch(getUserAll());
    // }, [managerData]);


    useEffect(() => {
        setManagers(managersData)
    }, [managerData]);
    useEffect(() => {
        // setRole({managerRole: managersData.map(item => item.managerRole), id: managersData.map(item => item.id)})
        setRole(managersData.map(item => {
            return {
                managerRole: item.managerRole,
                id: item.id
            }
        }))
    }, [managerData]);


    // console.log("managerRole", PayTypeEditCheck)

    const PayTypeEdit = (user) => {
        console.log("USER", user)
        return user.managerRole.includes("PayTypeEdit") ? true : false

    }
    // const ExecutorEdit = () =>managersData && managersData.map(user => user.managerRole.includes("ExecutorEdit"))
    // const PromoEdit = () =>managersData && managersData.map(user => user.managerRole.includes("PromoEdit"))
    // const ManagerEdit = () =>managersData && managersData.map(user => user.managerRole.includes("ManagerEdit"))
    // const BonusEdit = () =>managersData && managersData.map(user => user.managerRole.includes("BonusEdit"))




    const [checked, setChecked] = useState(true)
    const ManagarRoleList = managersData && managersData.map(user => user && user.managerRole)


    // {Object.entries(business).map(([key, value], i) => (
    //     <MenuItem value={parseInt(key)}>{value}</MenuItem>
    // ))}
    console.log('ManagarRoleList', ManagarRoleList)
    useEffect(() => {
        setChecked(ManagarRoleList)
    }, [userData]);





    // add event to the button for the demo
// document.getElementById("getChecked").onclick = getChecked;

    // CHECKED PUSH !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // const getChecked = () => {
    //     // get all inputs of the page
    //     // use node.getElementsByTagName("input") to get only child of node
    //     inputs = document.getElementsByTagName("input")
    //
    //     checked = []
    //     for (let input of inputs) {
    //         // for each element look if it is checked
    //         if (input.checked) checked.push(input.name)
    //     }
    //
    //     // here I log because can't return from event but you could return it
    //     console.log(checked)
    // }

    // const [checkboxValue, setCheckboxValue] = useState({
    //     // ExecutorEdit: ExecutorEdit(),
    //     PayTypeEdit,
    //     // PromoEdit: PromoEdit(),
    //     // ManagerEdit: ManagerEdit(),
    //     // BonusEdit: BonusEdit()
    // })
    // const handleCheck = (e) => {
    //     const { value } = e.target;
    //     setChecked(checked.includes(value) ? checked.filter(c => c !== value) : [...checked, value]);
    //     console.log("target", value)
    // };


    const [checkRole, setCheckRole] = useState([
        'ExecutorEdit',
        'ManagerEdit',
        'PayTypeEdit',
        'PromoEdit',
        'BonusEdit',
    ])
    const [roleManagers, setRoleManagers] = useState(ManagarRoleList)
    console.log('roleManagers', roleManagers)
    // useEffect(() => {
    //     setUpdateRole(ManagarRoleList)
    // }, []);

    const handleCheck = (e, user) => {
        // if (e.target.checked) {
        //     setChecked((prevState => prevState.filter((current) => current !== e.target.name)))
        //
        // } else {
        //     setChecked((prevState) => prevState.map((userRole) => userRole.concat(e.target.name)))


        if (e.target.checked) {
            const updateRole = ({managerRole: (user.managerRole).concat(e.target.name), id: user.id})
            dispatch(updateManager(updateRole));
        } else {
            const updateRole = ({managerRole:
                    // (prevState) =>
                    // (console.log('PREVSTATE', prevState),
                        (user.managerRole).filter((current) => current !== e.target.name), id: user.id
            })
            dispatch(updateManager(updateRole));
            // checks.forEach(function(check){
            //     check.addEventListener('click', checkIndex);
            // })
            //
            // function checkIndex(event){
            //     console.log( Array.from(checks).indexOf(event.target) );
            // }
        }

        console.log("target", e.target.name)
        console.log("currentUser", currentUser)
    };

    console.log("ManagarRoleList checked", checked)
    // console.log("ManagarRoleList updateRole", updateRole)
    // console.log("ManagarRoleList", value)

    // const handleChange = (event) => {
    //     setManagers({
    //         ...userData,
    //         [event.target.name]: event.target.checked,
    //     });
    // };
    // const { ExecutorEdit, PayTypeEdit, PromoEdit, ManagerEdit } = managersData.managerRole;

    /*ADD new Provider*/
    const changeNewExecutor = e => {
        e.preventDefault();
        console.log(e.target.value);
        setNewDataExecutor({...newDataExecutor, [e.target.name]: e.target.value})
    };
    const onClickAddExecutor = () => {
        dispatch(addNewExecutor(newDataExecutor))
    }

    const handleEditExecutorData = (e) => {
        e.preventDefault();
        console.log(e.target.value);
        setProviderData({...providerData, [e.target.name]: e.target.value})
    };

    /*ADD new Manager*/
    const changeNewManager = e => {
        e.preventDefault();
        console.log(e.target.value);
        setNewDataManager({...newDataManager, [e.target.name]: e.target.value})
    };
    const onClickAddManager = () => {
        dispatch(addNewManager(newDataManager))
    }

    /*ADD new Service*/
    const changeNewService = e => {
        e.preventDefault();
        console.log(e.target.value);
        setNewDataService({...newDataService, [e.target.name]: e.target.value})
    };
    const onClickAddService = () => {
        dispatch(addNewService(newDataService))
    }
    /*ADD new PaymentType*/
    const changeNewPaymentType = e => {
        e.preventDefault();
        console.log(e.target.value);
        setNewDataPaymentType({...newDataPaymentType, [e.target.name]: e.target.value})
    };
    const onClickAddPaymentType = () => {
        dispatch(addNewPaymentType(newDataPaymentType))
    }


    const handleEditFormSubmitExecutor = (e) => {
        e.preventDefault();

        const editedProvider = {
            id: editExecutorId,
            login: providerData.login,
        }

        const newProviders = [...providers]

        const index = providers.findIndex((provider) => provider.id === editExecutorId)
        newProviders[index] = editedProvider;

        setProviders(newProviders);
        dispatch(updateExecutor(providerData));
        setEditExecutorId(null);
    }

    const handleEditClickExecutor = (e, provider) => {
        e.preventDefault();
        setEditExecutorId(provider.id)

        const formValues = {
            id: provider.id,
            login: provider.login,
        }

        setProviderData(formValues)
    }

    const handleCancelClick = () => {
        setEditExecutorId(null);
    }

    const handleDeleteClick = (providerId) => {
        const newProviders = [...providersData];

        const index = providersData.findIndex((provider) => provider.id === providerId);

        newProviders.splice(index, 1);

        setProviders(newProviders);
        console.log("providerId", providerId)
        console.log("newProviders", newProviders)
        console.log("providers", providers)
        console.log("managersData", providersData)
        console.log("index", index)
        dispatch(updateExecutor(newProviders));
    };
    // const [checked, setChecked] = useState([]);
    return (
        <>
            <Box sx={{width: '100%'}}>
                <Typography variant="h5" component="div" gutterBottom sx={{textAlign: 'center'}}>
                    Users
                </Typography>
                <Box>
                    <Typography variant="h6" component="div" gutterBottom>
                        Managers
                    </Typography>
                </Box>
                <div>
                    <table name="managers">
                        <thead>
                        <TableRow>
                            <TableCell size='small' sx={{fontWeight: 'bold'}}>Login</TableCell>
                            <TableCell size='small' sx={{fontWeight: 'bold'}}>Password</TableCell>
                            <TableCell size='small' sx={{fontWeight: 'bold'}}>Providers</TableCell>
                            <TableCell size='small' sx={{fontWeight: 'bold'}}>Payment Method</TableCell>
                            <TableCell size='small' sx={{fontWeight: 'bold'}}>Promo</TableCell>
                            <TableCell size='small' sx={{fontWeight: 'bold'}}>Managers</TableCell>
                            <TableCell size='small' sx={{fontWeight: 'bold'}}>Bonuses</TableCell>
                        </TableRow>
                        </thead>
                        <tbody>
                        {managers.map((user) => (
                            <TableRow>
                                <TableCell size='small'>{user.login}</TableCell>
                                <TableCell size='small'>
                                    <TextField variant="standard"
                                               type="text"
                                               value={user.password}
                                    />
                                </TableCell>
                                {/*{user.managerRole && user.managerRole.map(({ value }) => (*/}
                                {/*    <TableCell size='small'>*/}
                                {/*        <Checkbox onChange={e => handleCheck(e)} checked={checked.includes(value)} name={value}/>*/}
                                {/*    </TableCell>*/}
                                {/*))}*/}
                                {/*{checked.map((item) => (*/}


                                <TableCell size='small'>
                                    <Checkbox
                                        checked={user.managerRole && (user.managerRole).includes("ExecutorEdit") ? checked : false}
                                        // checked={role.map((item, index) => item.includes("ExecutorEdit")) ? checked : false}
                                        onChange={(e) => handleCheck(e, user)}
                                        // onChange={setChecked(true)}
                                        name="ExecutorEdit"
                                        // value="ExecutorEdit"
                                    />
                                    {/*{ console.log(user.managerRole.includes("PayTypeEdit")) }*/}
                                    {/*<Checkbox checked={user.managerRole} name="ExecutorEdit"/>*/}
                                </TableCell>

                                <TableCell size='small'>
                                    <Checkbox
                                        // checked={checkRole.some(item=> user.managerRole && (user.managerRole).includes(item))}
                                        checked={user.managerRole && (user.managerRole).includes("PayTypeEdit") ? checked : false}
                                        // checked={(role.map(item => item.id === user.id && item.managerRole.includes("PayTypeEdit"))) ? checked : false}
                                        onChange={(e) => handleCheck(e, user)}
                                        // onChange={() => setCheckboxValue(!checkboxValue.PayTypeEdit)}

                                        name="PayTypeEdit"
                                        value="PayTypeEdit"
                                    />
                                    {/*<Checkbox checked={user.managerRole} name="PayTypeEdit"/>*/}
                                </TableCell>
                                <TableCell size='small'>
                                    <Checkbox
                                           // checked={checkRole.some(item=> user.managerRole && (user.managerRole).includes(item))}
                                           checked={user.managerRole && (user.managerRole).includes("PromoEdit") ? checked : false}
                                              onChange={(e) => handleCheck(e, user)}
                                              name="PromoEdit"
                                              value="PromoEdit"
                                    />
                                </TableCell>
                                <TableCell size='small'>
                                    <Checkbox
                                        // checked={checkRole.some(item=> user.managerRole && (user.managerRole).includes(item))}
                                              checked={user.managerRole && (user.managerRole).includes("ManagerEdit") ? checked : false}
                                              onChange={(e) => handleCheck(e, user)}
                                              name="ManagerEdit"
                                              value="ManagerEdit"
                                    />
                                </TableCell>
                                <TableCell size='small'>
                                    <Checkbox
                                        // checked={checkRole.some(item=> user.managerRole && (user.managerRole).includes(item))}
                                    checked={user.managerRole && (user.managerRole).includes("BonusEdit") ? checked : false}
                                              onChange={(e) => handleCheck(e, user)}
                                              name="BonusEdit"
                                              value="BonusEdit"
                                              // onChange={() => setCheckboxValue(!checkboxValue.BonusEdit)}
                                    />
                                </TableCell>


                            </TableRow>
                        ))}
                        <tr>
                            <TableCell>
                                <Input
                                    type="text" id="login" name="login"
                                    placeholder="Enter login"
                                    onChange={changeNewManager}
                                />
                            </TableCell>

                            <TableCell>
                                <button onClick={onClickAddManager}>add</button>
                            </TableCell>
                        </tr>

                        </tbody>
                    </table>
                </div>
                <Box sx={{mt: '50px'}}>
                    <Box>
                        <Typography variant="h6" component="div" gutterBottom>
                            Providers
                        </Typography>
                    </Box>
                    <Box>
                        <form onSubmit={handleEditFormSubmitExecutor}>
                            <table name="providers">
                                <thead>
                                <TableRow>
                                    <TableCell size='small' sx={{fontWeight: 'bold'}}>Login</TableCell>
                                    <TableCell size='small' sx={{fontWeight: 'bold'}}>Password</TableCell>
                                    <TableCell size='small' sx={{fontWeight: 'bold'}}>Order Pass</TableCell>
                                </TableRow>
                                </thead>
                                <tbody>
                                {providersData.map((provider) => (
                                    <Fragment>
                                        {editExecutorId === provider.id ? (
                                            <EditableRow providerData={providerData}
                                                         handleEditExecutorData={handleEditExecutorData}
                                                         handleCancelClick={handleCancelClick}/>
                                        ) : (
                                            <ReadOnlyRow
                                                provider={provider}
                                                setProviderData={setProviderData}
                                                handleDeleteClick={handleDeleteClick}
                                                handleEditClick={handleEditClickExecutor}/>
                                        )}
                                    </Fragment>

                                ))}
                                <tr>
                                    {/*<TableCell>*/}
                                    {/*    <TextField*/}
                                    {/*        disabled*/}
                                    {/*        id="standard-disabled"*/}
                                    {/*        defaultValue="Name"*/}
                                    {/*        variant="standard"*/}
                                    {/*    />*/}
                                    {/*</TableCell>*/}
                                    <TableCell>
                                        <Input
                                            type="text" id="login" name="login"
                                            placeholder="Enter login"
                                            onChange={changeNewExecutor}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            disabled
                                            id="standard-disabled"
                                            defaultValue="password"
                                            variant="standard"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            disabled
                                            id="standard-disabled"
                                            defaultValue="providerPass"
                                            variant="standard"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <button onClick={onClickAddExecutor}>add</button>
                                    </TableCell>
                                </tr>

                                </tbody>
                            </table>
                        </form>
                    </Box>
                </Box>
                <Box sx={{width: "100%", display: "flex", mt: "50px"}}>
                    <Box sx={{width: "50%"}}>
                        <Box>
                            <Typography variant="h6" component="div" gutterBottom>
                                Services
                            </Typography>
                        </Box>
                        <Box>
                            <form>
                                <table name="services">
                                    <thead>
                                    <TableRow>
                                        <TableCell size='small' sx={{fontWeight: 'bold'}}>Platform</TableCell>
                                        <TableCell size='small' sx={{fontWeight: 'bold'}}>Service Name</TableCell>
                                    </TableRow>
                                    </thead>
                                    <tbody>
                                    {serviceData.map((service) => (
                                        <TableRow>
                                            <TableCell size='small'>{service.platform}</TableCell>
                                            <TableCell size='small'>{service.name}</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell>
                                            <Input
                                                type="text" id="platform" name="platform"
                                                placeholder="Enter platform"
                                                onChange={changeNewService}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Input
                                                type="text" id="name" name="name"
                                                placeholder="Enter name"
                                                onChange={changeNewService}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <button onClick={onClickAddService}>add</button>
                                        </TableCell>
                                    </TableRow>

                                    </tbody>
                                </table>
                            </form>
                        </Box>
                    </Box>
                    <Box sx={{width: "50%"}}>
                        <Box>
                            <Typography variant="h6" component="div" gutterBottom>
                                Payment Method
                            </Typography>
                        </Box>
                        <Box>
                            <form>
                                <table name="paymentType">
                                    <thead>
                                    <TableRow>
                                        <TableCell size='small' sx={{fontWeight: 'bold'}}>Payment Method</TableCell>
                                    </TableRow>
                                    </thead>
                                    <tbody>
                                    {paymentType.map((type) => (
                                        <TableRow>
                                            <TableCell size='small'>{type.name}</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell>
                                            <Input
                                                type="text" id="name" name="name"
                                                placeholder="Enter name"
                                                onChange={changeNewPaymentType}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <button onClick={onClickAddPaymentType}>add</button>
                                        </TableCell>
                                    </TableRow>
                                    </tbody>
                                </table>
                            </form>
                        </Box>
                    </Box>
                </Box>

            </Box>
        </>
    );
}

export default Admin;