export const statusOrder = [
  'pending payment',
  'active',
  'add details',
  'in work',
  'finished',
  'report sent',
  'refilling',
  'refilled',
  'cancelled',
  'archived',
  'paused',
];