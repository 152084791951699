import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";
import {setFetchError} from "../Error/errorAction";
import {dataError} from "../Error/errorData";
import {DATA_SUCCESS, dataSuccess} from "../Error/messageSuccess";

export const PROVIDER_CHECK_SINGLE_ANDROID_REVIEW= 'PROVIDER_CHECK_SINGLE_ANDROID_REVIEW';
const API_URL = environment.baseUrl;

export const providerCheckSingleAndroidReview = (id, providerPass) => {
  console.log('idReview', id)
  // console.log('Report', data)
  return (dispatch) => {
    axios
      .get(API_URL + `/providers/scraper/android/${id}/review`,{
        headers: {
            'provider-pass': providerPass
          }
      })
      .then(res => {
        console.log(res)
        dispatch({ type: PROVIDER_CHECK_SINGLE_ANDROID_REVIEW, payload: res.data})
        dispatch({ type: DATA_SUCCESS, payload: res.data.message})
        setTimeout(() => {
          dispatch(dataSuccess())
        }, 4000)
      })
      .catch((error) => {
        dispatch(setFetchError(true))
        dispatch(dataError(error.response.data))
        setTimeout(() => {
          dispatch(setFetchError(false))
        }, 4000)
      })
  }
}