import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable, { MTableEditCell } from '@material-table/core'
import { updatePaymentData } from '../store/reducers/payment/updatePaymentData'
import isEmpty from 'lodash/isEmpty'
import { getOrdersList, resetDataOrdersList } from '../store/reducers/orderReducer/actions/getDataOrdersList'
import Select from '@mui/material/Select'
import { Link } from 'react-router-dom'
import { updateOrderData } from '../store/reducers/orderReducer/actions/updateOrderData'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { addOrderReport } from '../store/reducers/report/addOrderReport'
import { updateOrderReport } from '../store/reducers/orderReducer/actions/updateOrderReport'
import Rating from '@mui/material/Rating'
import ModalEditReview from '../ModalEditReview'
import { Button, Paper } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { Add as AddIcon } from '@material-ui/icons'
import CachedIcon from '@mui/icons-material/Cached'
import Box from '@mui/material/Box'
import { addProviderReport } from '../store/reducers/report/addProviderReport'
import { updateProviderReport } from '../store/reducers/report/updateProviderReport'
import { deleteProviderReport } from '../store/reducers/report/deleteProviderReport'
import { hashCheckLink } from '../store/reducers/providerHash/providerLinkCheck'
import Tooltip from '@material-ui/core/Tooltip'
import { checkSingleAndroidReview } from '../store/reducers/report/checkSingleAndroidReview'
import { checkSingleIosReview } from '../store/reducers/report/checkSingleIosReview'
import { allReviewsAndroidCheck } from '../store/reducers/report/allReviewsAndroidCheck'
import { allReviewsIosCheck } from '../store/reducers/report/allReviewsIosCheck'
import moment from 'moment'
import { providerAllReviewsAndroidCheck } from '../store/reducers/providerHash/prAllReviewsAndroidCheck'
import { providerAllReviewsIosCheck } from '../store/reducers/providerHash/prAllReviewsIosCheck'
import { providerCheckSingleAndroidReview } from '../store/reducers/providerHash/prCheckSingleAndroisReview'
import { providerCheckSingleIosReview } from '../store/reducers/providerHash/prCheckSingleIosReview'
// import { alpha } from '@material-ui/core/styles

const TableSingleOrderProvider = ({ reviews, providerPass, hash, service, id, provider }) => {

  const dispatch = useDispatch()
  const { newReport } = useSelector(({ report }) => report)
  const { newProviderReport } = useSelector(({ report }) => report)
  const { updateReport } = useSelector(({ report }) => report)
  const { providerOrderData } = useSelector(({ order }) => order)
  const { isLoading } = useSelector(({ service }) => service)

  const { updateDataProviderReport } = useSelector(({ report }) => report)
  const { dataError } = useSelector(({ error }) => error)

  const [data, setData] = useState(reviews)

  const status = [
    { name: 'NotChecked' },
    { name: 'Removed' },
    { name: 'Alive' }
  ]

  const statusSpecial = [
    'done',
    'progress',
    'pending',
    'upcoming'
  ]

  const onChangeValue = (value, props) => {
    props.onChange(value)
    console.log('value for change', value)

    // const newData = {[props.columnDef.field]: value, pass: providerPass, reportId: props.rowData.id}
    const newData = { [props.columnDef.field]: value }
    dispatch(updateProviderReport(id, props.rowData.id, newData, providerPass))

    const dataUpdate =
      [...data].map((item, i) => {
        if (props.rowData.id === item.id)
          props.rowData[props.columnDef.field] = value
        return item
      })
    setData([...dataUpdate])
  }

  const [columns, setColumns] = useState([
    { field: 'id', title: '№', type: 'numeric', editable: 'never', cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' }, width: '60px' },
    { field: 'reviewId', title: 'reviewId', type: 'numeric', editable: 'never', cellStyle: { whiteSpace: 'pre-wrap', textAlign: 'center' }, width: '60px' },
    {
      field: 'text', title: 'Review Text', width: '230px',
      emptyValue: <span className="emptyValue">Empty</span>,
      render: rowData => (
        rowData.text === '' ? <span className="emptyValue">Empty</span> : rowData.text
      )
      // cellStyle: {whiteSpace: 'nowrap'}
    },
    {
      field: 'title', title: 'Title', cellStyle: { whiteSpace: 'nowrap' }, width: '90px',
      emptyValue: <span className="emptyValue">Empty</span>,
      render: rowData => (
        rowData.title === '' ? <span className="emptyValue">Empty</span> : rowData.title
      )
      // editable: 'onUpdate'
    },
    // service && provider && service.id === 21 && provider === 20 ? { field: 'name', title: 'Name', cellStyle: {whiteSpace: 'nowrap'},  width: '70px',
    //     emptyValue: <span className='emptyValue'>Empty</span>,
    //     render: rowData => (
    //         rowData.name === '' ? <span className='emptyValue' name="name">Empty</span> : rowData.name
    //     ),
    //
    //     editComponent: props => (<select
    //         name='name'
    //         defaultValue={props.rowData.name}
    //         onChange={(e) => onChangeValue(e.target.value, props)}
    //
    //     >
    //         <option value={props.rowData.name} hidden>{props.rowData.name}</option>
    //         {statusSpecial.map((item) => (
    //             item === 'done' || item === 'progress' || item === 'pending' || item === 'upcoming' ? (
    //                 <option value={item}>{item}</option>
    //             ) : (
    //                 <option value={item} hidden>{item}</option>
    //             )
    //         ))}
    //     </select>),
    // } : { field: 'name', title: 'Name', cellStyle: {whiteSpace: 'nowrap'},  width: '70px' },
    {
      field: 'userName', title: 'Name', cellStyle: { whiteSpace: 'nowrap' }, width: '70px',
      emptyValue: <span className="emptyValue">Empty</span>,
      render: rowData => (
        rowData.name === '' ? <span className="emptyValue" name="userName">Empty</span> : rowData.userName
      )
    },
    {
      field: 'score', title: 'Stars', type: 'numeric', cellStyle: { whiteSpace: 'nowrap', textAlign: 'center' }, width: '100px',
      render: rowData => (<Rating name="score" value={ rowData.score } readOnly size="small" />)
    },
    ...(service.id === 12
      ? []
      : [{
        field: 'publishedAt', title: 'Publish', width: '80px', cellStyle: { width: '80px', textAlign: 'center', margin: '0 auto' },
        editable: 'never',
        render: rowData => (<div>{ rowData.publishedAt ? moment(rowData.publishedAt).format('DD-MMM-YYYY') : '' }</div>)
      }]),
    {
      field: 'lastCollectedAt', title: 'LastCollected', width: '80px', cellStyle: { width: '80px', textAlign: 'center', margin: '0 auto' },
      editable: 'never',
      render: rowData => (<div>{ rowData.lastCollectedAt ? moment(rowData.lastCollectedAt).format('DD-MMM-YYYY') : '' }</div>)
    },
    {
      title: 'Status',
      field: 'status',
      width: '90px',
      render: rowData => (<div name="status">{ rowData.status }</div>),
      editComponent: props => (
        <select
          name={ props.columnDef.field }
          value={ props.value }
          onChange={ (e) => onChangeValue(e.target.value, props) }

        >
          { status.map((item) => {
            return (
              <option value={ item.name }>{ item.name }</option>
            )
          }) }
        </select>
      )
    }
  ])

  useEffect(() => {

  }, [newReport, updateReport])

  useEffect(() => {
    setData(reviews)
    // setIsLoading(false)
  }, [providerOrderData])


  const [isDialogOpen, setIsDialogOpen] = useState(false)
  // const [newData, setNewData] = useState({
  //
  //     pass: providerPass
  // })

  // const [dialogText, setDialogText] = useState('');
  // const [dialogTitle, setDialogTitle] = useState('');
  // const [dialogStars, setDialogStars] = useState('');
  // const [dialogLink, setDialogLink] = useState('');
  // const [dialogName, setDialogName] = useState('');

  const [dataReview, setDataReview] = useState({})


  const handleDialogClose = event => {
    setIsDialogOpen(false)
  }

  // const handleText = event => {
  //     setDialogText(event.target.value);
  // }
  // const handleTitle = event => {
  //     setDialogTitle(event.target.value);
  // }
  // const handleStars = event => {
  //     setDialogStars(event.target.value);
  // }
  // const handleLink = event => {
  //     setDialogLink(event.target.value);
  // }
  // const handleName = event => {
  //     setDialogName(event.target.value);
  // }

  const handleInputValue = (e) => {
    setDataReview({ ...dataReview, [e.target.name]: e.target.value.trim() })
  }


  const handleAddNewRow = event => {
    // if (!dataReview.text) return;
    console.log('datadatadata', data)
    const newData = {
      ...dataReview, order: id
    }
    setData(
      [newData, ...data]
    )
    dispatch(addProviderReport(newData, providerPass))

  }

  useEffect(() => {
    // Closes dialog after saving
    if (isDialogOpen) {
      setIsDialogOpen(false)
    }
  }, [data])

  useEffect(() => {
    // Clears the inputs if `isDialogOpen` equals `false`
    if (!isDialogOpen) {
      setDataReview('')
      // setDialogText('');
      // setDialogTitle('');
      // setDialogName('');
      // setDialogStars('');
      // setDialogLink('');
    }
  }, [isDialogOpen])


  const [dataCheck, setDataCheck] = useState({})
  console.log('dataCHeck', dataCheck)
  const actions = [
    {
      icon: () => <AddIcon />,
      tooltip: 'Add Review',
      isFreeAction: true,
      onClick: (event, rowData) => {
        setIsDialogOpen(true)
      }
    },
    // {
    //     tooltip: 'Link Check All Selected reviews',
    //     icon: 'check',
    //     onClick: (event, rowData) => (
    //         console.log('rowData', rowData),
    //         dispatch(checkLinkPost(id, {reportId: Number(rowData.id)}))
    //     )
    // },
    {
      icon: CachedIcon,
      iconProps: { sx: { width: '200px' } },
      tooltip: 'Link Check',
      onClick: (event, rowData) => {
        // const checkData = {
        //   ids: [rowData.id],
        //   pass: providerPass
        // }
        service.platform === 'android' ? dispatch(providerCheckSingleAndroidReview(Number(rowData.id), providerPass)) : dispatch(providerCheckSingleIosReview(Number(rowData.id), providerPass))
        // dispatch(hashCheckLink(hash, checkData))
      }
    },
    {
      icon: 'delete',
      tooltip: 'Delete review',
      onClick: (event, rowData) => {
        let answer
        answer = window.confirm('You want to delete ' + rowData.id)
        if (answer) {
          const deleteData = {
            ids: [rowData.id]
          }
          // const newData = {pass: providerPass.toString(), reportId: parseInt(rowData.id)}
          console.log('deleteData', deleteData)
          dispatch(deleteProviderReport(id, deleteData, providerPass))
        } else {
          console.log('rowData.id- Check', rowData.id)
        }
      }
    },
    {
      icon: CachedIcon,
      tooltip: 'Link Check All Reviews',
      isFreeAction: true,
      onClick: (event, rowData) => {
        // // setCheckData({reportId: rowData.id}),
        // const allDataId = data.forEach(element => console.log(element.id))
        // console.log('allDataId Check', [allDataId])
        // // dispatch(checkLinkPost(id, {reportId: rowData.id}))
        // // alert("You check report" + rowData.id)
        //
        // const checkData = {
        //   ids: [],
        //   pass: providerPass
        // }
        // data.forEach(element => checkData.ids.push(element.id))
        // // const newData = {pass: providerPass.toString(), reportId: parseInt(rowData.id)}
        // console.log('deleteData', checkData)
        // dispatch(hashCheckLink(hash, checkData))
        service.platform === 'android' ? dispatch(providerAllReviewsAndroidCheck(id, providerPass)) : dispatch(providerAllReviewsIosCheck(id, providerPass))
      }
    },
    {
      icon: 'delete',
      tooltip: 'Delete All Reviews ',
      isFreeAction: true,
      onClick: (event, rowData) => {
        let answer
        answer = window.confirm('You want to delete all reviews')
        if (answer) {
          const deleteData = {
            ids: []
          }
          data.forEach(element => deleteData.ids.push(element.id))
          // const newData = {pass: providerPass.toString(), reportId: parseInt(rowData.id)}
          console.log('deleteData', deleteData)
          dispatch(deleteProviderReport(id, deleteData, providerPass))
        } else {
          console.log('rowData.id- Check', rowData.id)
        }
      }
    }
  ]

  return (
    <>
      {/*{service && (service.id === 11 || service.id === 12 || service.id === 14 || service.id === 17 || service.id === 21 || service.id === 22) && (*/ }
      { service && [11, 12, 17, 21, 22, 18, 20, 14].includes(service.id) && (
        <Fragment>
          <MaterialTable
            title="Reviews"
            columns={ columns }
            actions={ actions }
            data={ data }
            isLoading={ isLoading }
            options={ {
              tableLayout: 'fixed',
              filtering: true,
              columnsButton: true,
              actionsColumnIndex: -1,
              actionsCellStyle: {
                textAlign: 'center'
              },
              pageSize: 30,
              pageSizeOptions: [30, 60, 90, 120, 150],
              padding: 'dense',
              search: false,
              // selection: true,
              // selectionProps: rowData => (
              //     setDataCheck(...dataCheck, rowData.id)
              // ),
              addRowPosition: 'first',
              headerStyle: {
                backgroundColor: '#DEF3FA',
                color: 'Black',
                whiteSpace: 'nowrap'
              },
              rowStyle: {
                fontSize: '12px'
                // paddingLeft: 5,
                // paddingRight: 5,
              }
            } }

            cellEditable={ {
              onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                return new Promise((resolve, reject) => {
                  // const dataUpdate = [...data];
                  // dataUpdate[rowData.tableData.id][columnDef.field] = newValue
                  // const newData = {[columnDef.field]: newValue}
                  // const newData = {[columnDef.field]: newValue, pass: providerPass, reportId: rowData.id}
                  const newData = { [columnDef.field]: newValue.toString() }
                  const dataUpdate =
                    [...data].map((item, i) => {
                      if (rowData.id === item.id)
                        rowData[columnDef.field] = newValue
                      return item
                      console.log('item.id: ', item.id)
                    })
                  setData([...dataUpdate])

                  console.log('rowData.tableData.id: ' + rowData.tableData.id)
                  console.log('newValue: ' + newValue)
                  console.log('rowData: ' + rowData.id)
                  // console.log('columnDef: '+ columnDef.field);
                  dispatch(updateProviderReport(id, rowData.id, newData, providerPass))
                  setTimeout(resolve, 1000)

                })
              }
            } }
          />
          <ModalEditReview sx={ { width: '300px' } } title="Add Review" isOpen={ isDialogOpen } onClose={ handleDialogClose }>
            <Paper style={ { padding: '2em' } }>
              <div><Typography variant="h6" component="div" gutterBottom>
                Text
              </Typography>
                <TextareaAutosize
                  minRows={ 5 }
                  placeholder="write text here"
                  style={ { width: 200 } }
                  name="text"
                  onChange={ handleInputValue }
                />
                {/*<TextField value={dialogText} onInput={handleText} label="Text" />*/ }

              </div>
              <div><TextField onChange={ handleInputValue } name="title" label="Title" /></div>
              <div><TextField onChange={ handleInputValue } name="userName" label="Name" /></div>
              <div><TextField onChange={ handleInputValue } name="score" label="Stars" /></div>
              {/*<div><TextField onChange={ handleInputValue } name="link" label="Link" /></div>*/ }
              <div style={ { marginTop: '3em' } }>
                <Button onClick={ handleAddNewRow }>Save</Button>
                <Button onClick={ handleDialogClose }>Cancel</Button>
              </div>
            </Paper>
          </ModalEditReview>
        </Fragment>
      ) }
    </>
  )
}

export default TableSingleOrderProvider