import authHeader from "../../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../../environment";
import {setFetchError} from "../../Error/errorAction";
import {dataError} from "../../Error/errorData";
import {DATA_SUCCESS, dataSuccess} from "../../Error/messageSuccess";

export const ADD_NEW_PROMO = "ADD_NEW_PROMO";
const API_URL = environment.baseUrl;

export const addNewPromo = (site, data) => {
    console.log('siteUpd -thunk', site)
    console.log('dataUpdate -thunk', data)
    return (dispatch) => {
        // dispatch(setIsLoading(true));
        axios
            .post(API_URL + '/cms', data, {
                headers:
                    {
                        'Authorization': authHeader().Authorization
                    },
                params: {
                    site: site
                }
            })
            .then((res) => {
                dispatch({ type: ADD_NEW_PROMO, payload: res.data });
                // dispatch(setIsLoading(false));
                const messageSuccess = 'New promo add succesfully'
                dispatch({ type: DATA_SUCCESS, payload: messageSuccess})
                setTimeout(() => {
                    dispatch(dataSuccess())
                }, 4000)
            })
            .catch((error) => {
                // dispatch(setFetchError(true))
                // dispatch(dataError(error.response.data))
                // setTimeout(() => {
                //     dispatch(setFetchError(false))
                // }, 4000)
                // dispatch(setIsLoading(false));
            });
    };
};
//
// export const resetDataUser = () => {
//     return (dispatch) => {
//         dispatch({type: GET_USERS, payload: {}});
//     };
// };