import authHeader from "../../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../../environment";

export const GET_USERS = "GET_USERS";
const API_URL = environment.baseUrl;

export const getUserAll = () => {
    return (dispatch) => {
        // dispatch(setIsLoading(true));
        axios
            .get(API_URL + '/user/get/all', { headers: authHeader() })
            .then((res) => {
                dispatch({ type: GET_USERS, payload: res.data });
                // dispatch(setIsLoading(false));
                console.log("dataUSER:", res.data)
            })
            .catch((err) => {
                console.log("err", err)
                // dispatch(setError(err))
                // dispatch(setIsLoading(false));
            });
    };
};
//
// export const resetDataUser = () => {
//     return (dispatch) => {
//         dispatch({type: GET_USERS, payload: {}});
//     };
// };