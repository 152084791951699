import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunk from "redux-thunk";
import {orderReducer} from "./reducers/orderReducer/orderReducer";
import {paymentReducer} from "./reducers/payment/paymentReducer";
import auth from "../../reducers/auth";
import message from "../../reducers/message";

import { reducer as form } from "redux-form";
import {userReducer} from "./reducers/user/userReducer";
import {serviceReducer} from "./reducers/services/servicesReducer";
import {orderReportReducer} from "./reducers/report/reportReducer";
import {errorReducer} from "./reducers/Error/errorReducer";
import {commentReducer} from "./reducers/comment/commentReducer";
import {providerHashReducer} from "./reducers/providerHash/providerHashReducer";
import {cmsReducer} from "./reducers/cms/cmsReducer";
import { proxyReducer } from './reducers/proxy/proxyReducer'

const reducers = combineReducers({
    form,
    auth,
    message,
    order: orderReducer,
    payment: paymentReducer,
    comment: commentReducer,
    user: userReducer,
    service: serviceReducer,
    report: orderReportReducer,
    error: errorReducer,
    hash: providerHashReducer,
    cms: cmsReducer,
    proxy: proxyReducer,
})

const enhanceMiddleware = compose(applyMiddleware(thunk))
const store = createStore(reducers, enhanceMiddleware)

export default store;