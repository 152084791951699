import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";
import {setFetchError} from "../Error/errorAction";
import {dataError} from "../Error/errorData";
import {DATA_SUCCESS, dataSuccess} from "../Error/messageSuccess";

export const CHECK_SINGLE_ANDROID_REVIEW= 'CHECK_SINGLE_ANDROID_REVIEW';
const API_URL = environment.baseUrl;

export const checkSingleAndroidReview = (id) => {
    console.log('idReview', id)
    // console.log('Report', data)
    return (dispatch) => {
        axios
            .get(API_URL + `/reviews/scraper/android/${id}/review`,{ headers: authHeader() })
            .then(res => {
                console.log(res)
                dispatch({ type: CHECK_SINGLE_ANDROID_REVIEW, payload: res.data})
                dispatch({ type: DATA_SUCCESS, payload: res.data.message})
                setTimeout(() => {
                    dispatch(dataSuccess())
                }, 4000)
            })
            .catch((error) => {
                dispatch(setFetchError(true))
                dispatch(dataError(error.response.data))
                setTimeout(() => {
                    dispatch(setFetchError(false))
                }, 4000)
            })
    }
}