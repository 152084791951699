import authHeader from '../../../../../services/auth-header'
import axios from 'axios'
import environment from '../../../../../environment'

export const GET_STATS_PROXY = 'GET_STATS_PROXY'
const API_URL = environment.baseUrl

export const getStatsProxy = (Date1, Date2) => {
  return (dispatch) => {
    // dispatch(setIsLoading(true));
    axios
      .get(API_URL + '/statistics/proxy', {
        headers:
          {
            'Authorization': authHeader().Authorization
          },
        params: {
          from: Date1,
          to: Date2
        }
      })
      .then((res) => {
        dispatch({ type: GET_STATS_PROXY, payload: res.data })
        // dispatch(setIsLoading(false));
        console.log('statistics:', res.data)
      })
      .catch((err) => {
        console.log('err', err)
        // dispatch(setError(err))
        // dispatch(setIsLoading(false));
      })
  }
}

// export const resetDataStatistics = () => {
//     return (dispatch) => {
//         dispatch({type: GET_STATISTICS, payload: {}});
//     };
// };