import authHeader from '../../../../services/auth-header'
import axios from 'axios'
import environment from '../../../../environment'
import { setFetchError } from '../Error/errorAction'
import { dataError } from '../Error/errorData'
import { DATA_SUCCESS, dataSuccess } from '../Error/messageSuccess'

export const UPDATE_PROVIDER_HASH_STATUS = 'UPDATE_PROVIDER_HASH_STATUS'
const API_URL = environment.baseUrl

export const updateProviderStatus = (providerHash, data, providerPass) => {

  console.log('hash -thunk', providerHash)
  console.log('data thunk', data)

  return (dispatch) => {
    // dispatch(setIsLoading(true));
    axios
      .post(API_URL + `/providers/orders/${ providerHash }`, data, {
        headers:
          {
            'provider-pass': providerPass
          }
      })
      .then((res) => {
        dispatch({ type: UPDATE_PROVIDER_HASH_STATUS, payload: res.data })
        // dispatch(setIsLoading(false));
        dispatch({ type: DATA_SUCCESS, payload: res.data.message })
        setTimeout(() => {
          dispatch(dataSuccess())
        }, 4000)
      })
      .catch((error) => {
        dispatch(setFetchError(true))
        dispatch(dataError(error.response))

        setTimeout(() => {
          dispatch(setFetchError(false))
        }, 4000)
      })
  }
}

export const resetDataOrderProvider = () => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PROVIDER_HASH_STATUS, payload: {} })
  }
}