import authHeader from "../../../../../services/auth-header";
import axios from "axios";

export const ADD_COMMENT_PAYMENT = 'ADD_COMMENT_PAYMENT';

export const addCommentPayment = (data) => {
    return (dispatch) => {
        axios
            .post('/payments/comment', data, { headers: authHeader() })
            .then((res) => {
                dispatch({ type: ADD_COMMENT_PAYMENT, payload: data });
            })
            .catch((err) => {
                console.log("err", err)
            });
    };
};