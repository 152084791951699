import authHeader from "../../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../../environment";

export const UPLOAD_FILE_ORDER_CLIENT = "UPLOAD_FILE_ORDER_CLIENT";
const API_URL = environment.baseUrl;

export const uploadFileOrderClient = (id, formData) => {
    console.log("formData", id, formData)
    return (dispatch) => {
        // dispatch(setIsLoading(true));
        axios
            .post(API_URL + `/orders/order/${id}/xlsxClient`, formData, {
                headers:
                    {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': authHeader().Authorization
                    },
            })
            .then((res) => {
                dispatch({ type: UPLOAD_FILE_ORDER_CLIENT, payload: res.data });
                // dispatch(setIsLoading(false));
            })
            .catch((err) => {
                console.log("err", err)
                // dispatch(setError(err))
                // dispatch(setIsLoading(false));
            });
    };
};