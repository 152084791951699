import {SET_FETCH_ERROR} from "./errorAction";
import {DATA_ERROR} from "./errorData";
import {DATA_SUCCESS} from "./messageSuccess";

const initStore = {
    isFetchError: false,
    dataError: {},
    messageSuccess: '',
};

export const errorReducer = (initialState = initStore, action) => {
    switch (action.type) {
        case SET_FETCH_ERROR:
            return {...initialState, isFetchError: action.payload};
        case DATA_ERROR:
            return {...initialState, dataError: action.payload};
        case DATA_SUCCESS:
            return {...initialState, messageSuccess: action.payload};
        default:
            return initialState;
    }
};
