import React, { useEffect, useState } from 'react'
import './Payment.css'
import TablePayment from './TablePayment'
import { useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getDataPaymentById, resetDataPayment } from '../store/reducers/payment/paymentAction'
import isEmpty from 'lodash/isEmpty'
import { addCommentPayment } from '../store/reducers/payment/addPaymentComment'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import NewOrder from '../NewOrder/NewOrder'
import TextField from '@material-ui/core/TextField'
import { updatePaymentData } from '../store/reducers/payment/updatePaymentData'
import { getCalcPayment } from '../store/reducers/payment/finalizePayment'
import Alert from '@mui/material/Alert'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { getService } from '../store/reducers/services/getService'
import { getOrdersList } from '../store/reducers/orderReducer/actions/getDataOrdersList'

const Payment = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { paymentData, commentPayment } = useSelector(({ payment }) => payment)
  const { calcPayment } = useSelector(({ payment }) => payment)
  const { newDataOrder } = useSelector(({ order }) => order)
  const { updateOrder } = useSelector(({ order }) => order)

  const { serviceData } = useSelector(({ service }) => service)

  const { isFetchError } = useSelector(({ error }) => error)
  const { dataError } = useSelector(({ error }) => error)
  const { messageSuccess } = useSelector(({ error }) => error)

  const [open, setOpen] = React.useState(false)
  const [bonusUpdate, setBonusUpdate] = React.useState({})
  const [commentSend, setCommentSend] = useState({
    paymentId: parseInt(id),
    text: ''
  })
  const {
    // id,
    business,
    created,
    price,
    client,
    source,
    paymentType,
    status,
    margin,
    ROI,
    serviceCost,
    bonus,
    comment
  } = paymentData
  // const paymentId = paymentId
  // const id = paymentDataList.id

  async function asyncDispatch() {

    // dispatch(getOrdersList());
    await !isEmpty(paymentData) && dispatch(getDataPaymentById(id))
    await dispatch(resetDataPayment())
    await dispatch(getDataPaymentById(id))
  }

  useEffect(() => {
    asyncDispatch()
  }, [])

  useEffect(() => {
    !isEmpty(paymentData) && dispatch(getDataPaymentById(id))
    dispatch(resetDataPayment())
    dispatch(getDataPaymentById(id))
  }, [newDataOrder, commentPayment, calcPayment])

  const updateCommentValue = e => {
    console.log(e.target.value)
    setCommentSend({ ...commentSend, [e.target.name]: e.target.value.toString() })
  }

  const AddNewCommentPayment = (e) => {
    e.preventDefault()
    dispatch(addCommentPayment(commentSend))
  }
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 6
  }

  const changeBonus = (e) => {
    e.preventDefault()
    dispatch(updatePaymentData(id, bonusUpdate))
    console.log('bonus:', bonusUpdate)
  }
  const updateInputValue = e => {
    console.log(e.target.value)
    setBonusUpdate({ [e.target.name]: parseInt(e.target.value) })
  }

  return (
    <>
      { messageSuccess &&
        <div className="errorBlock">
          <Alert severity="success">{ messageSuccess }</Alert>
        </div>
      }
      { isFetchError &&
        <div className="errorBlock">
          <Alert severity="error">{ dataError.message }</Alert>
        </div>
      }
      { !isEmpty(paymentData) && (
        <Box>
          <div>

            <Button variant="contained" onClick={ handleOpen }>Add new order</Button>

            <Modal
              open={ open }
              onClose={ handleClose }
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={ style }>
                <NewOrder handleClose={ handleClose } />
              </Box>
            </Modal>
          </div>
          <Typography variant="h5" component="div" gutterBottom sx={ { textAlign: 'center' } }>
            Payment № { id }
          </Typography>

          <Box sx={ { width: '100%', display: 'flex', mb: '20px', justifyContent: 'space-between' } } className="custom-list">
            <Box sx={ { width: '40%' } }>
              <Typography variant="h6" component="div" gutterBottom sx={ { fontWeight: 'bold' } }>
                Payment detail
              </Typography>
              <Box sx={ { width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' } } className="custom-list-col">
                <List sx={ { width: '45%' } }>
                  <ListItem><label><span>Site: </span>{ business.name }</label></ListItem>
                  <ListItem><label><span>PaymentDate: </span>{ created.slice(0, 10) }</label></ListItem>
                  <ListItem><label><span>Status: </span>{ status }</label></ListItem>
                  <ListItem><label><span>PaymentType: </span>{ paymentType && paymentType.name }</label></ListItem>
                  <ListItem><label><span>Source: </span>{ source }</label></ListItem>
                </List>
                <List sx={ { width: '45%' } }>
                  <ListItem><label><span>Client Name: </span>{ client.name }</label></ListItem>
                  <ListItem><label><span>Email: </span>{ client.contactEmail }</label></ListItem>
                  <ListItem><label><span>P.Email: </span>{ client.paymentEmail }</label></ListItem>

                </List>

                <Box sx={ { width: '100%' } }>Payment link: { }</Box>
              </Box>

            </Box>
            <Box sx={ { width: '16%' } }>
              <Typography variant="h6" component="div" gutterBottom sx={ { fontWeight: 'bold' } }>
                Comment
              </Typography>
              <Box sx={ { display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' } }>

                <Box sx={ { width: '100%' } } className="custom-list-col">
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={ 5 }
                    placeholder="Comment for payment"
                    style={ { width: 200 } }
                    name="text"
                    onChange={ updateCommentValue }
                  >
                    { comment }
                  </TextareaAutosize>

                  <div>
                    <Button onClick={ AddNewCommentPayment }>Apply</Button>
                  </div>

                </Box>
              </Box>

            </Box>
            <Box sx={ { width: '17%' } }>
              <Typography variant="h6" component="div" gutterBottom sx={ { fontWeight: 'bold' } }>
                Costs
              </Typography>
              <Box sx={ { width: '100%' } }>
                <Box className="custom-list-col">
                  <List>
                    <form onSubmit={ changeBonus }>
                      <ListItem><label><span>Payment Price: </span>{ price }</label></ListItem>
                      <ListItem><label><span>ServiceCost: </span>{ Number.isInteger(serviceCost) ? serviceCost : serviceCost.toFixed(2) }</label></ListItem>
                      <div className="bonusBlock">

                        <TextField
                          id="standard-number"
                          label="Bonus"
                          type="number"
                          name="bonus"
                          defaultValue={ bonus }
                          InputLabelProps={ {
                            shrink: true
                          } }
                          variant="standard"
                          onChange={ updateInputValue }
                        />

                        <Button type="submit" variant="contained">save bonus</Button>
                      </div>


                    </form>
                  </List>
                </Box>


              </Box>
            </Box>
            <Box sx={ { width: '17%', height: '100%' } }>
              <Typography variant="h6" component="div" gutterBottom sx={ { fontWeight: 'bold' } }>
                Profit
              </Typography>
              <Box className="custom-list-col">

                <List>
                  <ListItem><label><span>Margin: </span>{ margin }</label></ListItem>
                  <ListItem><label><span>ROI: </span>{ ROI }</label></ListItem>
                  <div>
                    <Button variant="contained" onClick={ getCalcPayment(id) }>Finalize payment</Button>
                  </div>
                </List>
              </Box>
            </Box>

          </Box>
          <TablePayment id={ id } orders={ paymentData.orders }
                        paymentType={ paymentType }
                        name={ client.name }
                        contactEmail={ client.contactEmail }
            // orderDataList={orderDataList}
                        serviceData={ serviceData }
          />


        </Box>
      ) }
    </>
  )
}

export default Payment