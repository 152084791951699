import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import TableOrdersProvider from './TableOrdersProvider'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'
import { getProviderOrders, resetProviderOrdersList } from '../store/reducers/orderReducer/actions/getProviderOrders'
import isEmpty from 'lodash/isEmpty'
import { addCommentProvider } from '../store/reducers/comment/actions/commentProvider'
import { getUser } from '../store/reducers/user/actions/getUserId'
import Typography from '@mui/material/Typography'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Alert from '@mui/material/Alert'
import { getService } from '../store/reducers/services/getService'
import { updateExecutor } from '../store/reducers/user/actions/updateExecutor'
import TextField from '@material-ui/core/TextField'
import { downloadProviderOrdersFile } from '../store/reducers/orderReducer/actions/downloadProviderXlsxOrders'
import moment from 'moment'
import { DATA_SUCCESS, dataSuccess } from '../store/reducers/Error/messageSuccess'
import { getOrdersByProvider, resetOrdersByProviderList } from '../store/reducers/orderReducer/actions/getOrdersByProvider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'
import DatePicker from 'react-datepicker'
import Modal from '@mui/material/Modal'
import { downloadFileSingleOrder } from '../store/reducers/orderReducer/actions/downloadXlsxSingleOrder'
import { downloadOrdersByProvider } from '../store/reducers/orderReducer/actions/executorDownloadXlsxOrders'

const OrdersProvider = ({ isManager }) => {

  const { id } = useParams()
  const dispatch = useDispatch()
  const { userDataOne } = useSelector(({ user }) => user)
  const { executorData } = useSelector(({ user }) => user)

  const { providerOrdersData } = useSelector(({ order }) => order)
  const { ordersByProviderData } = useSelector(({ order }) => order)
  const { finished, placed } = isManager ? providerOrdersData : ordersByProviderData
  const { orderData } = useSelector(({ order }) => order)

  const { userData } = useSelector(({ user }) => user)


  const { serviceData } = useSelector(({ service }) => service)

  const { isFetchError } = useSelector(({ error }) => error)
  const { dataError } = useSelector(({ error }) => error)
  const { messageSuccess } = useSelector(({ error }) => error)

  const [pageSize, setPageSize] = useState(30)
  const [page, setPage] = useState(0)
  const [filterData, setFilterData] = useState({})
  const [filterSave, setFilterSave] = useState({})

  const [columnsHide, setColumnsHide] = useState({})
  const checkedFilter = ['active', 'in work', 'paused', 'add details', 'finished', 'refilling', 'refilled', 'archived']
  const [checkedFilterDownload, setCheckedFilterDownload] = useState(checkedFilter)

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const checkboxCheck = (e) => {
    // setCheckedFilterDownload(e.target.checked);
    console.log('e.target.checked', e.target.checked)
    console.log('e.target.name', e.target.name)
    if (e.target.checked) {
      const checkedResult = checkedFilterDownload.concat(e.target.name)
      setCheckedFilterDownload(checkedResult)
    } else {
      const checkedResult = checkedFilterDownload.filter((current) => current !== e.target.name)
      setCheckedFilterDownload(checkedResult)
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3
  }

  // useEffect(() => {
  //
  //     dispatch(resetProviderOrdersList());
  //     // localStorage.removeItem('filterData')
  //     // localStorage.removeItem('filterValue')
  //     setPage(JSON.parse(window.localStorage.getItem('page')));
  //     setFilterData(JSON.parse(window.localStorage.getItem('filterData')));
  //     setFilterSave(JSON.parse(window.localStorage.getItem('filterValue')));
  // }, [id]);


  // if(isEmpty(filterData) && filterData !== null){
  //     setFilterData(JSON.parse(window.localStorage.getItem('filterData')));
  // }

  // useEffect(() => {
  //     window.localStorage.setItem('page', page);
  // }, [page]);
  // useEffect(() => {
  //     window.localStorage.setItem('pageSize', pageSize);
  // }, [pageSize]);
  // useEffect(() => {
  //     window.localStorage.setItem('filterData', JSON.stringify(filterData));
  // }, [filterData]);
  // useEffect(() => {
  //     window.localStorage.setItem('filterValue', JSON.stringify(filterSave));
  // }, [filterSave]);


  if (localStorage.getItem('page') === null && localStorage.getItem('pageSize') === null
    // && localStorage.getItem('columns') === null
  ) {
    window.localStorage.setItem('page', page)
    window.localStorage.setItem('pageSize', pageSize)
  }


  useEffect(() => {
    setPage(JSON.parse(window.localStorage.getItem('page')))
    setPageSize(JSON.parse(window.localStorage.getItem('pageSize')))
    setFilterData(JSON.parse(window.localStorage.getItem('filterData')))
    setFilterSave(JSON.parse(window.localStorage.getItem('filterValue')))
  }, [])

  useEffect(() => {
    window.localStorage.setItem('page', page)
    window.localStorage.setItem('pageSize', pageSize)
    window.localStorage.setItem('filterData', JSON.stringify(filterData))
    window.localStorage.setItem('filterValue', JSON.stringify(filterSave))
  }, [page, pageSize, filterData, filterSave])

  // useEffect(() => {
  //     setColumnsHide(JSON.parse(window.localStorage.getItem('columns')));
  // }, []);
  // useEffect(() => {
  //     window.localStorage.setItem('columns', columnsHide);
  // }, [columnsHide]);


  useEffect(() => {
    // dispatch(getUserAll());
    // window.localStorage.setItem('page', page);
    // window.localStorage.setItem('pageSize', pageSize);
    isManager ? dispatch(resetProviderOrdersList()) : dispatch(resetOrdersByProviderList())
    isManager ? dispatch(getProviderOrders(id, page, pageSize, filterData)) : dispatch(getOrdersByProvider(page, pageSize, filterData))
    // setProviderId(id)
  }, [id])


  // useEffect(() => {
  //     setFilterSave(JSON.parse(window.localStorage.getItem('filterValue')));
  //
  // }, []);
  //
  // useEffect(() => {
  //     filterSave && window.localStorage.setItem('filterValue', JSON.stringify(filterSave));
  //
  // }, [filterSave]);


  const [commentSend, setCommentSend] = useState({
    userId: parseInt(id),
    text: ''
  })

  // const isManager = userDataOne && userDataOne.role === "manager"
  const [inputPaid, setInputPaid] = useState({
    id: Number(id)
  })
  const [commentUser, setCommentUser] = useState()
  useEffect(() => {
    // dispatch(resetUser())
    dispatch(getUser(parseInt(id)))
    userDataOne.comment && setCommentUser(userDataOne.comment)
  }, [executorData])

  useEffect(() => {
    isManager && dispatch(getService())
  }, [])
  // useEffect(() => {
  //     dispatch(getUser(id));
  // }, [executorData]);
  // useEffect(() => {
  //
  //     console.log('userDataOne2IN', userDataOne)
  //     setProviderId(id)
  //     dispatch(getUser(providerId));
  //     console.log('providerIdIN', providerId)
  //
  // }, []);
  const [providerId, setProviderId] = useState()

  const updateCommentValue = e => {
    console.log(e.target.value)
    setCommentSend({ ...commentSend, [e.target.name]: e.target.value.toString() })
  }

  const addNewCommentProvider = (e) => {
    e.preventDefault()
    dispatch(addCommentProvider(commentSend))
  }

  const updateInputPaid = e => {
    setInputPaid({ ...inputPaid, [e.target.name]: Number(e.target.value) })
  }
  const editPaid = (e) => {
    dispatch(updateExecutor(inputPaid))
  }
  const downloadFile = () => {

    const date = moment().format('DD-MMM-YYYY')
    const filtersData = {
      status: checkedFilterDownload,
      created: {
        from: startDate,
        to: endDate
      }
    }
    dispatch(downloadOrdersByProvider(filtersData, date))
    handleClose()
  }

  return (

    <>
      { messageSuccess &&
        <div className="errorBlock">
          <Alert severity="success">{ messageSuccess }</Alert>
        </div>
      }
      { isFetchError &&
        <div className="errorBlock">
          <Alert severity="error">{ dataError.message }</Alert>
        </div>
      }
      { !isEmpty(isManager ? providerOrdersData : ordersByProviderData) && userDataOne && (
        <Box>
          <Typography variant="h5" component="div" gutterBottom sx={ { textAlign: 'center' } }>
            Orders - Provider { id }
          </Typography>
          <Box sx={ { width: '100%', display: 'flex', justifyContent: 'space-between', mb: '20px' } }>
            <Box sx={ { width: '25%' } } className="custom-list-col">
              <List
                // sx={{width: '30%'}}
              >
                <ListItem><label><span>Orders placed: </span>{ placed }</label></ListItem>
                <ListItem><label><span>Orders Finished: </span>{ finished }</label></ListItem>
                <ListItem><label><span>Paid: </span>
                  { isManager ? <TextField className="paidInput" name="payoff" onChange={ updateInputPaid }
                                           defaultValue={ userDataOne.payoff } variant="standard"
                                           size="small" type="number" />
                    : userDataOne.payoff
                  }
                </label></ListItem>
                { isManager && <Box sx={ { textAlign: 'right' } }>
                  <Button variant="contained" onClick={ editPaid } sx={ { width: '25%', mr: '16px' } }>edit</Button>
                </Box> }
                <ListItem><label><span>Pending: </span>{ (finished - userDataOne.payoff).toFixed(2) }</label></ListItem>
              </List>
            </Box>
            <Box sx={ { width: '30%' } }>


            </Box>
            <Box sx={ { width: '30%' } } className="custom-list-col">
              <Typography variant="h6" component="div" gutterBottom sx={ { fontWeight: 'bold' } }>
                Comment for service provider
              </Typography>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={ 5 }
                placeholder={ isManager ? 'Write your comment for provider here' : '' }
                style={ { width: 400 } }
                disabled={ isManager ? false : true }
                name="text"
                onChange={ updateCommentValue }
              >
                { userDataOne.comment }
              </TextareaAutosize>

              <div>
                { isManager && <Button onClick={ addNewCommentProvider }>Apply</Button> }
              </div>

            </Box>
          </Box>
          <Box sx={ { width: '100%', display: 'flex', justifyContent: 'flex-end', mb: '20px' } }>
            <Button variant="outlined" className="btn-xlsReport" onClick={ handleOpen }>Download xls
              orders</Button>
          </Box>
          <Modal
            open={ open }
            onClose={ handleClose }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={ style }>
              <Typography variant="h6" component="div" gutterBottom sx={ { fontWeight: 'bold', textAlign: 'center' } }>
                Choose status filter
              </Typography>
              <FormGroup className="list-item">
                {
                  checkedFilter.map(item => (
                    <FormControlLabel
                      control={ <Checkbox checked={ checkedFilterDownload.includes(item) } onChange={ checkboxCheck } /> }
                      label={ item }
                      name={ item }
                    />
                  ))
                }

                <div>
                  <TextField
                    label="Start Date"
                    InputLabelProps={ {
                      shrink: true
                    } }
                    type="date"
                    value={ startDate }
                    onChange={ (e) => setStartDate(e.target.value) }
                    style={ { marginRight: '20px' } }
                  />

                  <TextField
                    label="End Date"
                    InputLabelProps={ {
                      shrink: true
                    } }
                    type="date"
                    value={ endDate }
                    onChange={ (e) => setEndDate(e.target.value) }
                    inputProps={ {
                      max: startDate && moment(startDate).add(2, 'months').format('YYYY-MM-DD'),
                      min: startDate
                    } }
                  />
                </div>
                <Button sx={ { width: '100%', my: '10px' } } variant="outlined" onClick={ downloadFile }>Download xls report</Button>
              </FormGroup>
            </Box>
          </Modal>
          <TableOrdersProvider
            // orders={orders}
            id={ id } serviceData={ serviceData } isManager={ isManager } page={ page }
            pageSize={ pageSize } setPage={ setPage } setPageSize={ setPageSize } filterData={ filterData } setFilterData={ setFilterData }
            filterSave={ filterSave } setFilterSave={ setFilterSave } columnsHide={ columnsHide } setColumnsHide={ setColumnsHide }
            // totalItem={totalItem} totalPage={totalPage} currentPage={currentPage}
          />
        </Box>
      ) }
    </>

  )
}

export default OrdersProvider