import authHeader from '../../../../services/auth-header'
import axios from 'axios'
import environment from '../../../../environment'
import { setFetchError } from '../Error/errorAction'
import { dataError } from '../Error/errorData'
import { DATA_SUCCESS, dataSuccess } from '../Error/messageSuccess'

export const UPDATE_PROVIDER_REPORT = 'UPDATE_PROVIDER_REPORT'
const API_URL = environment.baseUrl

export const updateProviderReport = (orderId, reviewId, data, providerPass) => {

  return (dispatch) => {
    axios
      // .post(API_URL + `/orders/order/${providerHash}/provider/report/update`, data, { headers: authHeader() })
      .put(API_URL + `/providers/${ orderId }/reviews/${ reviewId }`, data, {
        headers:
          {
            'provider-pass': providerPass,
            'Authorization': authHeader().Authorization
          }
      })
      .then(res => {
        console.log(res)
        dispatch({ type: UPDATE_PROVIDER_REPORT, payload: res })
        dispatch({ type: DATA_SUCCESS, payload: res.data.message })
        setTimeout(() => {
          dispatch(dataSuccess())
        }, 4000)
      })
      .catch((error) => {
        dispatch(setFetchError(true))
        dispatch(dataError(error.response.data))
        setTimeout(() => {
          dispatch(setFetchError(false))
        }, 4000)
      })
  }
}