import {ADD_NEW_PAYMENT} from "./addNewPaymentAction";
import {GET_PAYMENT} from "./paymentAction";
import {GET_PAYMENT_LIST} from "./getDataPaymentList";
import {ADD_COMMENT_PAYMENT} from "./addPaymentComment";
import {UPDATE_PAYMENT_DATA} from "./updatePaymentData";
import {GET_PAYMENT_TYPE} from "./getPaymentType";
import {DOWNLOAD_FILE_PAYMENTS} from "./downloadXlsxPayments";
import {ADD_NEW_PAYMENT_TYPE} from "./addPaymentType";
import {GET_CALC_PAYMENT} from "./finalizePayment";

const initStore = {
    newDataPayment: {},
    paymentData: {},
    paymentDataList: [],
    commentPayment: {},
    updatePayment: {},
    paymentType: [],
    downloadFilePayments: {},
    newPaymentType: {},
    calcPayment: {},
};

export const paymentReducer = (initialState = initStore, action) => {
    switch (action.type) {
        case ADD_NEW_PAYMENT:
            return {...initialState, newDataPayment: action.payload};
        case GET_PAYMENT:
            return {...initialState, paymentData: action.payload};
        case GET_PAYMENT_LIST:
            return {...initialState, paymentDataList: action.payload};
        case ADD_COMMENT_PAYMENT:
            return {...initialState, commentPayment: action.payload};
        case UPDATE_PAYMENT_DATA:
            return {...initialState, updatePayment: action.payload};
        case GET_PAYMENT_TYPE:
            return {...initialState, paymentType: action.payload};
        case DOWNLOAD_FILE_PAYMENTS:
            return {...initialState, downloadFilePayments: action.payload};
        case ADD_NEW_PAYMENT_TYPE:
            return {...initialState, newPaymentType: action.payload};
        case GET_CALC_PAYMENT:
            return {...initialState, calcPayment: action.payload};
        default:
            return initialState;
    }
};