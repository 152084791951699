// import {httpClient} from "../../../../services/services";
import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";

export const GET_PAYMENT = "GET_PAYMENT";
const API_URL = environment.baseUrl;

export const getDataPaymentById = (id) => {

    return (dispatch) => {
        // dispatch(setIsLoading(true));
        axios
            .get(API_URL + `/payments/${id}`, { headers: authHeader() })
            .then((res) => {
                console.log("paymentResDATAinACtion", res)
                dispatch({ type: GET_PAYMENT, payload: res.data });
                // dispatch(setIsLoading(false));
            })
            .catch((err) => {
                console.log("err", err)
                // dispatch(setError(err))
                // dispatch(setIsLoading(false));
            });
    };
};

export const resetDataPayment = () => {
    return (dispatch) => {
        dispatch({type: GET_PAYMENT, payload: {}});
    };
};