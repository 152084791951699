import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";
import {setFetchError} from "../Error/errorAction";
import {dataError} from "../Error/errorData";
import {DATA_SUCCESS, dataSuccess} from "../Error/messageSuccess";

export const HASH_LINK_CHECK= 'HASH_LINK_CHECK';
const API_URL = environment.baseUrl;

export const hashCheckLink = (providerHash, data) => {
    console.log('OrderProviderHash', providerHash)
    console.log('Report', data)
    return (dispatch) => {
        axios
            .post(API_URL + `/orders/order/${providerHash}/provider/report/check`, data, { headers: authHeader() })
            .then(res => {
                console.log(res)
                dispatch({ type: HASH_LINK_CHECK, payload: res})
                dispatch({ type: DATA_SUCCESS, payload: res.data})
                setTimeout(() => {
                    dispatch(dataSuccess())
                }, 4000)
            })
            .catch((error) => {
                dispatch(setFetchError(true))
                dispatch(dataError(error.response.data))
                setTimeout(() => {
                    dispatch(setFetchError(false))
                }, 4000)
            })
    }
}