export const countries_android = [
  {
    code: 'US',
    name: 'United States',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'BR',
    name: 'Brazil',
    lang_code: 'pt',
    lang_name: 'Portuguese'
  },
  {
    code: 'RU',
    name: 'Russian Federation',
    lang_code: 'ru',
    lang_name: 'Russian'
  },
  {
    code: 'TR',
    name: 'Turkey',
    lang_code: 'tr',
    lang_name: 'Turkish'
  },
  {
    code: 'DE',
    name: 'Germany',
    lang_code: 'de',
    lang_name: 'German'
  },
  {
    code: 'PL',
    name: 'Poland',
    lang_code: 'pl',
    lang_name: 'Polish'
  },
  {
    code: 'FR',
    name: 'France',
    lang_code: 'fr',
    lang_name: 'French'
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'AR',
    name: 'Argentina',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'AT',
    name: 'Austria',
    lang_code: 'de',
    lang_name: 'German'
  },
  {
    code: 'AU',
    name: 'Australia',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
    lang_code: 'az',
    lang_name: 'Azerbaijani'
  },
  {
    code: 'BD',
    name: 'Bangladesh',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'BE',
    name: 'Belgium',
    lang_code: 'nl',
    lang_name: 'Dutch'
  },
  {
    code: 'BH',
    name: 'Bahrain',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'BJ',
    name: 'Benin',
    lang_code: 'fr',
    lang_name: 'French'
  },
  {
    code: 'BY',
    name: 'Belarus',
    lang_code: 'be',
    lang_name: 'Belarusian'
  },
  {
    code: 'CA',
    name: 'Canada',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'CH',
    name: 'Switzerland',
    lang_code: 'de',
    lang_name: 'German'
  },
  {
    code: 'CL',
    name: 'Chile',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'CN',
    name: 'China',
    lang_code: 'zh',
    lang_name: 'Chinese'
  },
  {
    code: 'CO',
    name: 'Colombia',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'CR',
    name: 'Costa Rica',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
    lang_code: 'cs',
    lang_name: 'Czech'
  },
  {
    code: 'DK',
    name: 'Denmark',
    lang_code: 'da',
    lang_name: 'Danish'
  },
  {
    code: 'DZ',
    name: 'Algeria',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'EC',
    name: 'Ecuador',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'EG',
    name: 'Egypt',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'ES',
    name: 'Spain',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'FI',
    name: 'Finland',
    lang_code: 'fi',
    lang_name: 'Finnish'
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'GR',
    name: 'Greece',
    lang_code: 'el',
    lang_name: 'Greek'
  },
  {
    code: 'GT',
    name: 'Guatemala',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'HK',
    name: 'Hong Kong',
    lang_code: 'zh',
    lang_name: 'Chinese'
  },
  {
    code: 'HR',
    name: 'Croatia',
    lang_code: 'hr',
    lang_name: 'Croatian'
  },
  {
    code: 'HU',
    name: 'Hungary',
    lang_code: 'hu',
    lang_name: 'Hungarian'
  },
  {
    code: 'ID',
    name: 'Indonesia',
    lang_code: 'id',
    lang_name: 'Indonesian'
  },
  {
    code: 'IE',
    name: 'Ireland',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'IL',
    name: 'Israel',
    lang_code: 'he',
    lang_name: 'Hebrew'
  },
  {
    code: 'IN',
    name: 'India',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'IT',
    name: 'Italy',
    lang_code: 'it',
    lang_name: 'Italian'
  },
  {
    code: 'JO',
    name: 'Jordan',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'JP',
    name: 'Japan',
    lang_code: 'ja',
    lang_name: 'Japanese'
  },
  {
    code: 'KE',
    name: 'Kenya',
    lang_code: 'sw',
    lang_name: 'Swahili'
  },
  {
    code: 'KH',
    name: 'Cambodia',
    lang_code: 'km',
    lang_name: 'Central Khmer'
  },
  {
    code: 'KR',
    name: 'South Korea',
    lang_code: 'ko',
    lang_name: 'Korean'
  },
  {
    code: 'KW',
    name: 'Kuwait',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
    lang_code: 'kk',
    lang_name: 'Kazakh'
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
    lang_code: 'si',
    lang_name: 'Sinhalese'
  },
  {
    code: 'MO',
    name: 'Macao',
    lang_code: 'zh',
    lang_name: 'Chinese'
  },
  {
    code: 'MX',
    name: 'Mexico',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'MY',
    name: 'Malaysia',
    lang_code: 'ms',
    lang_name: 'Malay'
  },
  {
    code: 'NG',
    name: 'Nigeria',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'NL',
    name: 'Netherlands',
    lang_code: 'nl',
    lang_name: 'Dutch'
  },
  {
    code: 'NO',
    name: 'Norway',
    lang_code: 'nb',
    lang_name: 'Norwegian'
  },
  {
    code: 'NZ',
    name: 'New Zealand',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'OM',
    name: 'Oman',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'PA',
    name: 'Panama',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'PE',
    name: 'Peru',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'PH',
    name: 'Philippines',
    lang_code: 'fil',
    lang_name: 'Filipino'
  },
  {
    code: 'PK',
    name: 'Pakistan',
    lang_code: 'ur',
    lang_name: 'Urdu'
  },
  {
    code: 'PT',
    name: 'Portugal',
    lang_code: 'pt',
    lang_name: 'Portuguese'
  },
  {
    code: 'PY',
    name: 'Paraguay',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'QA',
    name: 'Qatar',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'RO',
    name: 'Romania',
    lang_code: 'ro',
    lang_name: 'Romanian'
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'SE',
    name: 'Sweden',
    lang_code: 'sv',
    lang_name: 'Swedish'
  },
  {
    code: 'SG',
    name: 'Singapore',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'SI',
    name: 'Slovenia',
    lang_code: 'sl',
    lang_name: 'Slovenian'
  },
  {
    code: 'TH',
    name: 'Thailand',
    lang_code: 'th',
    lang_name: 'Thai'
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
    lang_code: 'tg',
    lang_name: 'Tajik'
  },
  {
    code: 'TN',
    name: 'Tunisia',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'TW',
    name: 'Taiwan',
    lang_code: 'zh',
    lang_name: 'Chinese'
  },
  {
    code: 'UA',
    name: 'Ukraine',
    lang_code: 'uk',
    lang_name: 'Ukrainian'
  },
  {
    code: 'UY',
    name: 'Uruguay',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
    lang_code: 'uz',
    lang_name: 'Uzbek'
  },
  {
    code: 'VN',
    name: 'Vietnam',
    lang_code: 'vi',
    lang_name: 'Vietnamese'
  },
  {
    code: 'YE',
    name: 'Yemen',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'ZA',
    name: 'South Africa',
    lang_code: 'en',
    lang_name: 'English'
  }
]