import authHeader from '../../../../../services/auth-header'
import axios from 'axios'
import environment from '../../../../../environment'
import { setFetchError } from '../../Error/errorAction'
import { dataError } from '../../Error/errorData'
import { DATA_SUCCESS, dataSuccess } from '../../Error/messageSuccess'

export const DELETE_PROXY = 'DELETE_PROXY'
const API_URL = environment.baseUrl

export const deleteProxy = (id) => {
  return (dispatch) => {
    axios
      .delete(API_URL + `/settings/proxy/${ id }`, { headers: authHeader() })
      .then(res => {
        console.log(res)
        dispatch({ type: DELETE_PROXY, payload: res.data })
        dispatch({ type: DATA_SUCCESS, payload: res.data.message })
        setTimeout(() => {
          dispatch(dataSuccess())
        }, 4000)
      })
      .catch((error) => {
        dispatch(setFetchError(true))
        dispatch(dataError(error.response))
        setTimeout(() => {
          dispatch(setFetchError(false))
        }, 4000)
      })
  }
}