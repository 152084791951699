import './App.css'
import Sidebar from './Components/Sidebar/Sidebar'
import { Router, Link, Redirect, Route, Switch } from 'react-router-dom'
import NewOrder from './Components/NewOrder/NewOrder'
import NewPayment from './Components/NewPayment/NewPayment'
import React, { useCallback, useEffect } from 'react'
import OrdersProvider from './Components/Orders/OrdersProvider'
import Payments from './Components/Payments/Payments'
import SingleOrder from './Components/SingleOrder/SingleOrder'
import { useDispatch, useSelector } from 'react-redux'
import { clearMessage } from './actions/message'
import { history } from './helpers/history'
import { logout } from './actions/auth'
import Login from './Components/Login'
import Payment from './Components/Payment/Payment'
import Orders from './Components/Orders/Orders'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
// import IconButton from "@mui/material/IconButton";
// import Toolbar from "@mui/material/Toolbar";
// import AppBar from "@mui/material/AppBar";
import { useTheme } from '@mui/material/styles'
// import Box from "@mui/material/Box";
import MenuIcon from '@mui/icons-material/Menu'
import ListItem from '@mui/material/ListItem'
import SingleOrderProvider from './Components/SingleOrder/SingleOrderProvider'
import Admin from './Pages/Admin'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import EventBus from './common/EventBus'

import Cookies from 'universal-cookie'
import HashPage from './Pages/HashPage'
import Container from '@material-ui/core/Container'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { getUser } from './Components/store/reducers/user/actions/getUserId'
import Site from './Pages/Site'
import Proxy from './Pages/Proxy'
import isEmpty from 'lodash/isEmpty'
import Statistics from './Pages/Statistics'
import MainMenu from './Components/Sidebar/Menu'
import { getBusiness } from './Components/store/reducers/services/getBusiness'

function App() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const [showSidebar, setShowSidebar] = React.useState(false)
  // const [manager, setManager] = React.useState(false);
  const { user: currentUser } = useSelector((state) => state.auth)
  const { isLoggedIn } = useSelector(state => state.auth)
  const { userDataOne } = useSelector(({ user }) => user)
  useEffect(() => {
    currentUser && dispatch(getUser(currentUser.id))
  }, [])

  // if(isEmpty(userDataOne)){
  //     currentUser && dispatch(getUser(currentUser.id))
  // }

  // useEffect(() => {
  //     if (currentUser && currentUser.id === userDataOne.id && userDataOne.role === "manager") {
  //         setManager(true)
  //     }
  // }, [userDataOne]);

  // useEffect(() => {
  //     if (userDataOne && userDataOne.role === "manager") {
  //         setManager(true)
  //         console.log('setManager true111', manager)
  //     }
  // }, [userDataOne]);

  const manager = currentUser && currentUser.role === 'manager' ? true : false

  // if(userDataOne){
  //     setManager(userDataOne.role === "manager")
  //     console.log('setManager ---22', manager)
  // }

  // const isManager = currentUser.id === userDataOne.id && userDataOne.role === "manager" ? setManager(true) : null
  // const isManager = () => {
  //     if (currentUser.id === userDataOne.id) {
  //         return userDataOne.role
  //     }
  // }


  // const cookies = new Cookies()
  //
  // cookies.set('myCat', 'Pacman', { path: '/' })
  // console.log(cookies.get('myCat')) // Pacman

  // console.log("currentUser:", currentUser)
  // const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  // const [showAdminBoard, setShowAdminBoard] = useState(false);


  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()) // clear message when changing location
    })
  }, [dispatch])

  const logOut = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  // useEffect(() => {
  //     if (currentUser) {
  //         setShowSidebar(currentUser !== null);
  //     } else {
  //         setShowSidebar(false);
  //     }
  //     console.log("showSidebarBEF:", showSidebar)
  // }, [currentUser]);
  // console.log("showSidebarAFTE:", showSidebar)
  // console.log("currentUser.length:", currentUser.length)

  useEffect(() => {
    if (currentUser
      // && currentUser.role === "manager"
    ) {
      setShowSidebar(true)
    } else {
      setShowSidebar(false)
    }
    // if (currentUser && currentUser.role === "executor") {
    //
    // } else {
    // }
    EventBus.on('logout', () => {
      logOut()
    })

    return () => {
      EventBus.remove('logout')
    }
  }, [currentUser, logOut])

  return (
    <Box sx={ { display: 'flex' } }>
      {/*<div className="app">*/ }
      <Router history={ history }>
        <React.Fragment>
          {/*<CssBaseline />*/ }
          { currentUser &&
            <AppBar position="fixed" open={ open }>
              <Toolbar>
                { manager && (<MainMenu />) }

                {/*<Typography variant="h6" noWrap component="div">*/ }
                {/*    Persistent drawer*/ }
                {/*</Typography>*/ }
                { currentUser && (
                  <Link to={ '/login' } className="nav-link" onClick={ logOut }>
                    LogOut
                  </Link>
                ) }
              </Toolbar>

            </AppBar>
          }

          {/*{showSidebar && (*/ }
          {/*    <Sidebar handleDrawerClose={handleDrawerClose} theme={theme} open={open} drawerWidth={drawerWidth} DrawerHeader={DrawerHeader}/>*/ }
          {/*)}*/ }
          <Container maxWidth="false">
            <Box className="main" sx={ { my: 8 } }>
              <Switch>
                {/*<Route exact path={["/", "/home"]} component={Home} />*/ }
                {/*{currentUser ? <Redirect to="/orders" /> : <Login />}*/ }
                {/*<Route path={["/", "/login"]} exact component={Login}/>*/ }
                <Route path={ ['/', '/login'] } exact render={ () => <Login isManager={ manager } /> } />
                {/*<Route path='/login' exact component={Login}/>*/ }
                <Route path="/payments" exact component={ Payments } />
                <Route path="/payments/create" exact component={ NewPayment } />
                {/*<Route path='/orders' exact component={Orders}/>*/ }
                <Route path="/orders" exact render={ () => <Orders isManager={ manager } /> } />
                {/*<Route path='/orders/provider/:id' exact component={OrdersProvider}/>*/ }
                <Route path="/orders/provider/:id" exact render={ () => <OrdersProvider isManager={ manager } /> } />
                <Route path="/orders/create" exact component={ NewOrder } />
                <Route path="/payments/:id" component={ Payment } />
                <Route path="/orders/order/:id(\d+)" exact component={ SingleOrder } />
                {/*<Route path='/orders/order/:hash' exact component={SingleOrderProvider}/>*/ }
                <Route path="/orders/order/:hash" exact render={ () => <SingleOrderProvider isManager={ manager } /> } />
                <Route path="/user" exact component={ Admin } />
                <Route path="/hashIn" exact component={ HashPage } />
                <Route path="/sites/site/:id" exact component={ Site } />
                <Route path="/proxy" exact component={ Proxy } />
                <Route path="/statistics" exact component={ Statistics } />
              </Switch>
            </Box>
          </Container>
        </React.Fragment>
      </Router>
    </Box>
  )
}

export default App
