import React, { useEffect, useState } from 'react'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { useDispatch, useSelector } from 'react-redux'
import { getCmsData } from '../Components/store/reducers/cms/actionsCms/getCms'
import { getService } from '../Components/store/reducers/services/getService'
import TextField from '@material-ui/core/TextField'
import { updateCmsData } from '../Components/store/reducers/cms/actionsCms/updateCms'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import NewPayment from '../Components/NewPayment/NewPayment'
import NewPromo from '../Components/NewPromo/NewPromo'
import { getBusiness } from '../Components/store/reducers/services/getBusiness'
import { updateCmsStats } from '../Components/store/reducers/cms/actionsCmsStats/updateCmsStats'
import Alert from '@mui/material/Alert'
import isEmpty from 'lodash/isEmpty'
import { deleteCmsData } from '../Components/store/reducers/cms/actionsCms/deleteCms'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import { useParams } from 'react-router-dom'

const Site = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const { serviceData } = useSelector(({ service }) => service)
  const { businessData } = useSelector(({ service }) => service)
  const { cmsData } = useSelector(({ cms }) => cms)
  const { deleteCmsAnswer } = useSelector(({ cms }) => cms)
  const { newPromoData } = useSelector(({ cms }) => cms)

  const { main, pages, promo } = cmsData

  const { isFetchError } = useSelector(({ error }) => error)
  const { dataError } = useSelector(({ error }) => error)
  const { messageSuccess } = useSelector(({ error }) => error)

  const [serviceTab, setServiceTab] = React.useState(11)
  const [dataUpdate, setDataUpdate] = useState()

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 6
  }

  useEffect(() => {
    dispatch(getService())
  }, [])


  useEffect(() => {
    dispatch(getCmsData(id, serviceTab))
  }, [serviceTab, deleteCmsAnswer, newPromoData])


  const filterService = serviceData && serviceData.filter(service => [11,12,13,15,16,19].includes(service.id))
  console.log('filterService', filterService)
  console.log('serviceData', serviceData)
  // filterService.pop()

  const handleChange = (event, anotherService) => {
    setServiceTab(anotherService)
  }

  const onDataUpdate = (e, columnId) => {
    setDataUpdate({ ...dataUpdate, [e.target.name]: Number(e.target.value), id: Number(columnId) })
  }
  const onBlurUpdate = (e) => {
    if (dataUpdate) {
      dispatch(updateCmsStats(1, dataUpdate))
      setDataUpdate('')
    }
  }
  const onFocusSelect = (e) => {
    e.target.select()
  }
  const deletePromo = (e) => {
    dispatch(deleteCmsData(1, e.target.id))
  }


  const text = `http://dev.app-reviews.org/android review`
  var result = text.split(' ').join('-')

  return (
    <>
      { messageSuccess &&
        <div className="errorBlock">
          <Alert severity="success">{ messageSuccess }</Alert>
        </div>
      }
      { isFetchError &&
        <div className="errorBlock">
          <Alert severity="error">{ dataError.message }</Alert>
        </div>
      }
      { !isEmpty(cmsData) && (
        <Box>
          <Box sx={ { textAlign: 'center', mb: '20px' } }>
            <div className="column col-100 paymentBtn-block">

              <Button variant="contained" onClick={ handleOpen }>Add new Promo</Button>
              <Modal
                open={ open }
                onClose={ handleClose }
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={ style }>
                  <NewPromo handleClose={ handleClose } businessData={ businessData } filterService={ filterService } serviceData={ serviceData } />
                </Box>
              </Modal>
            </div>
            <ToggleButtonGroup
              color="primary"
              value={ serviceTab }
              exclusive
              onChange={ handleChange }
              name="serviceTab"
            >
              { filterService && filterService.map(service => (
                  <ToggleButton value={ service.id }>{ service.platform } { service.name }</ToggleButton>
                )
              ) }
            </ToggleButtonGroup>
          </Box>
          { !isEmpty(main) && (
            <Box>
              <h3>
                Link:
                <a href={ `http://dev.app-reviews.org/${ main && main.service.split(' ').join('-') }` }>
                  http://dev.app-reviews.org/{ main && main.service.split(' ').join('-') }
                </a>
              </h3>
              <TableContainer component={ Paper } elevation={ 2 }>
                <Table className="cmsTable">
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">Type</TableCell>
                      { main.stats && main.stats.map((item, index) => {
                        return (
                          <TableCell className="headerTable">{ main.service } №{ item.id }</TableCell>
                        )
                      }) }

                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Q-ty</TableCell>
                      { main.stats && main.stats.map((item, index) => {
                        return (
                          index <= 3
                            ? <TableCell>
                              <input
                                name="quantityInt"
                                className="inputTable"
                                onChange={ (e) => onDataUpdate(e, item.id) }
                                onBlur={ onBlurUpdate }
                                onFocus={ onFocusSelect }
                                defaultValue={ item.quantityInt }
                              />
                            </TableCell>
                            : <TableCell>
                              <input
                                name="quantityFrom"
                                className="inputTable inputRangeFrom"
                                onChange={ (e) => onDataUpdate(e, item.id) }
                                onBlur={ onBlurUpdate }
                                onFocus={ onFocusSelect }
                                defaultValue={ item.quantityFrom !== null ? item.quantityFrom : '' }
                              /> - { ' ' }
                              <input
                                name="quantityTo"
                                className="inputTable inputRangeTo"
                                onChange={ (e) => onDataUpdate(e, item.id) }
                                onBlur={ onBlurUpdate }
                                onFocus={ onFocusSelect }
                                defaultValue={ item.quantityTo !== null ? item.quantityTo : '' }
                              />
                            </TableCell>
                        )
                      }) }
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Price</TableCell>
                      { main.stats && main.stats.map((item, index) => {
                        return (
                          <TableCell>
                            <input
                              name="price"
                              className="inputTable"
                              onChange={ (e) => onDataUpdate(e, item.id) }
                              onBlur={ onBlurUpdate }
                              onFocus={ onFocusSelect }
                              defaultValue={ item.price }
                            />
                          </TableCell>
                        )
                      }) }
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">P.Q-ty</TableCell>
                      { main.stats && main.stats.map((item, index) => {
                        return (
                          // <TableCell className="notEditCell">n/a</TableCell>
                          index <= 3
                            ? <TableCell>
                              <input
                                name="pQuantityInt"
                                className="inputTable"
                                onChange={ (e) => onDataUpdate(e, item.id) }
                                onBlur={ onBlurUpdate }
                                onFocus={ onFocusSelect }
                                defaultValue={ item.pQuantityInt }
                              />
                            </TableCell>
                            : <TableCell>
                              <input
                                name="pQuantityFrom"
                                className="inputTable inputRangeFrom"
                                onChange={ (e) => onDataUpdate(e, item.id) }
                                onBlur={ onBlurUpdate }
                                onFocus={ onFocusSelect }
                                defaultValue={ item.pQuantityFrom !== null ? item.pQuantityFrom : '' }
                              /> - { ' ' }
                              <input
                                name="pQuantityTo"
                                className="inputTable inputRangeTo"
                                onChange={ (e) => onDataUpdate(e, item.id) }
                                onBlur={ onBlurUpdate }
                                onFocus={ onFocusSelect }
                                defaultValue={ item.pQuantityTo !== null ? item.pQuantityTo : '' }
                              />
                            </TableCell>
                        )
                      }) }
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">P.Price</TableCell>
                      { main.stats && main.stats.map((item, index) => {
                        return (
                          // <TableCell className="notEditCell">n/a</TableCell>
                          <TableCell>
                            <input
                              name="pPrice"
                              className="inputTable"
                              onChange={ (e) => onDataUpdate(e, item.id) }
                              onBlur={ onBlurUpdate }
                              onFocus={ onFocusSelect }
                              defaultValue={ item.pPrice }
                            />
                          </TableCell>
                        )
                      }) }
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Discount</TableCell>
                      { main.stats && main.stats.map((item, index) => {
                        return (
                          // <TableCell className="notEditCell">n/a</TableCell>
                          <TableCell>
                            <input
                              name="discount"
                              className="inputTable"
                              onChange={ (e) => onDataUpdate(e, item.id) }
                              onBlur={ onBlurUpdate }
                              onFocus={ onFocusSelect }
                              defaultValue={ item.discount }
                            />
                          </TableCell>
                        )
                      }) }
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

            </Box>
          ) }
          { pages && pages.map((page, index) => {
            return <Box sx={ { marginTop: '20px' } }>
              <Box className="promoHeader">
                <h3><b>Type:</b> PromoPage - <a href={ `http://dev.app-reviews.org/${ page.service.split(' ').join('-') }/${ page.name }` }>
                  { page.service.split(' ').join('-') }/{ page.name }
                </a></h3>
                <Button onClick={ deletePromo } id={ page.id } variant="contained" size="small" className="removeBtn">remove promo</Button>
              </Box>

              <TableContainer component={ Paper } elevation={ 2 }>
                <Table className="cmsTable">
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">Type</TableCell>
                      { page.stats.map((item, index) => {
                        return (
                          <TableCell className="headerTable">{ page.service } №{ item.id }</TableCell>
                        )
                      }) }

                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Q-ty</TableCell>
                      { page.stats.map((item, index) => {
                        return (
                          // <TableCell>
                          //     <input name="quantityInt"
                          //            className='inputTable'
                          //            onChange={(e) => onDataUpdate(e, item.id)}
                          //            onBlur={onBlurUpdate}
                          //            defaultValue={item.quantityInt ? item.quantityInt
                          //                : item.quantityFrom + '-' + (item.quantityTo !== null ? item.quantityTo : '')}
                          //     />
                          // </TableCell>
                          index <= 3
                            ? <TableCell>
                              <input
                                name="quantityInt"
                                className="inputTable"
                                onChange={ (e) => onDataUpdate(e, item.id) }
                                onBlur={ onBlurUpdate }
                                onFocus={ onFocusSelect }
                                defaultValue={ item.quantityInt }
                              />
                            </TableCell>
                            : <TableCell>
                              <input
                                name="quantityFrom"
                                className="inputTable inputRangeFrom"
                                onChange={ (e) => onDataUpdate(e, item.id) }
                                onBlur={ onBlurUpdate }
                                onFocus={ onFocusSelect }
                                defaultValue={ item.quantityFrom !== null ? item.quantityFrom : '' }
                              /> - { ' ' }
                              <input
                                name="quantityTo"
                                className="inputTable inputRangeTo"
                                onChange={ (e) => onDataUpdate(e, item.id) }
                                onBlur={ onBlurUpdate }
                                onFocus={ onFocusSelect }
                                defaultValue={ item.quantityTo !== null ? item.quantityTo : '' }
                              />
                            </TableCell>
                        )
                      }) }
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Price</TableCell>
                      { page.stats.map((item, index) => {
                        return (
                          <TableCell>
                            <input
                              name="price"
                              className="inputTable"
                              onChange={ (e) => onDataUpdate(e, item.id) }
                              onBlur={ onBlurUpdate }
                              onFocus={ onFocusSelect }
                              defaultValue={ item.price }
                            />
                          </TableCell>
                        )
                      }) }
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">P.Q-ty</TableCell>
                      { page.stats.map((item, index) => {
                        return (
                          <TableCell>
                            <input
                              name="pQuantityInt"
                              className="inputTable"
                              onChange={ (e) => onDataUpdate(e, item.id) }
                              onBlur={ onBlurUpdate }
                              onFocus={ onFocusSelect }
                              defaultValue={ item.pQuantityInt ? item.pQuantityInt
                                : item.pQuantityFrom && item.pQuantityFrom + '-' + (item.pQuantityTo !== null ? item.pQuantityTo : '')
                              }
                            />
                          </TableCell>
                        )
                      }) }
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">P.Price</TableCell>
                      { page.stats.map((item, index) => {
                        return (
                          <TableCell>
                            <input
                              name="pPrice"
                              className="inputTable"
                              onChange={ (e) => onDataUpdate(e, item.id) }
                              onBlur={ onBlurUpdate }
                              onFocus={ onFocusSelect }
                              defaultValue={ item.pPrice }
                            />
                          </TableCell>
                        )
                      }) }
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Discount</TableCell>
                      { page.stats.map((item, index) => {
                        return (
                          <TableCell>
                            <input
                              name="discount"
                              className="inputTable"
                              onChange={ (e) => onDataUpdate(e, item.id) }
                              onBlur={ onBlurUpdate }
                              onFocus={ onFocusSelect }
                              defaultValue={ item.discount }
                            />
                          </TableCell>
                        )
                      }) }
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

            </Box>
          }) }

          { promo && promo.map((page, index) => {
            return <Box sx={ { marginTop: '20px' } }>
              <Box className="promoHeader">
                <h3><b>Type:</b> PromoCode
                  <b> Code:</b> { page.name }
                </h3>
                <Button onClick={ deletePromo } id={ page.id } variant="contained" size="small" className="removeBtn">remove promo</Button>
              </Box>

              <TableContainer component={ Paper } elevation={ 2 }>
                <Table className="cmsTable">
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">Type</TableCell>
                      { page.stats.map((item, index) => {
                        return (
                          <TableCell className="headerTable">{ page.service } №{ item.id }</TableCell>
                        )
                      }) }

                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Q-ty</TableCell>
                      { page.stats.map((item, index) => {
                        return (
                          <TableCell className="notEditCell">n/a</TableCell>
                        )
                      }) }
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Price</TableCell>
                      { page.stats.map((item, index) => {
                        return (
                          <TableCell className="notEditCell">n/a</TableCell>
                        )
                      }) }
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">P.Q-ty</TableCell>
                      { page.stats.map((item, index) => {
                        return (
                          index <= 3 ?
                            <TableCell>
                              <input
                                name="pQuantityInt"
                                className="inputTable"
                                onChange={ (e) => onDataUpdate(e, item.id) }
                                onBlur={ onBlurUpdate }
                                onFocus={ onFocusSelect }
                                defaultValue={ item.pQuantityInt }
                              />
                            </TableCell>
                            : <TableCell className="notEditCell">n/a</TableCell>
                        )
                      }) }
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">P.Price</TableCell>
                      { page.stats.map((item, index) => {
                        return (
                          index <= 3 ?
                            <TableCell>
                              <input
                                name="pPrice"
                                className="inputTable"
                                onChange={ (e) => onDataUpdate(e, item.id) }
                                onBlur={ onBlurUpdate }
                                onFocus={ onFocusSelect }
                                defaultValue={ item.pPrice }
                              />
                            </TableCell>
                            : <TableCell className="notEditCell">n/a</TableCell>
                        )
                      }) }
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Discount</TableCell>
                      { page.stats.map((item, index) => {
                        return (
                          <TableCell className="notEditCell">n/a</TableCell>
                        )
                      }) }
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

            </Box>
          }) }
        </Box>
      ) }
    </>
  )
}

export default Site