import React, {useEffect, useState} from 'react';
import './NewPayment.css';
import {useDispatch, useSelector} from "react-redux";
import {addNewPayment} from "../store/reducers/payment/addNewPaymentAction";
import DatePicker from "react-datepicker";
import {InputLabel} from '@mui/material';
import {Radio} from '@mui/material';
import {Select} from '@mui/material';
import "react-datepicker/dist/react-datepicker.css";
import {Grid} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {getPaymentType} from "../store/reducers/payment/getPaymentType";
import {getBusiness} from "../store/reducers/services/getBusiness";

const NewPayment = ({handleClose}) => {
    const dispatch = useDispatch();
    const {paymentType} = useSelector(({payment}) => payment);
    const {businessData} = useSelector(({service}) => service);
    const [created, setCreated] = useState(new Date());
    const [payData, setPayData] = useState({
        source: "site",

    })

    console.log('created', created)
    console.log('payDataCreated', payData.created)
    useEffect(() => {
        dispatch(getPaymentType());
        dispatch(getBusiness());
    }, []);

    const createNewPayment = (e) => {
        e.preventDefault();
        dispatch(addNewPayment(payData))
        handleClose()
    }
    const updateInputValue = e => {
        console.log(e.target.value);
        if (e.target.name === "source" || e.target.name === "clientName" || e.target.name === "clientEmail") {
            setPayData({...payData, [e.target.name]: e.target.value.toString(), created: created})
        } else {
            setPayData({...payData, [e.target.name]: Number(e.target.value), created: created})
        }
    };

    const validate = values => {
        const errors = {}
        const requiredFields = [ 'clientEmail' ]
        requiredFields.forEach(field => {
            if (!values[ field ]) {
                errors[ field ] = 'Required'
            }
        })
        if (values.clientEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.clientEmail)) {
            errors.clientEmail = 'Invalid email address'
        }
        return errors
    }

    return (
        <div className="container">
            <h1>Add new Payment</h1>

            <form onSubmit={createNewPayment} className="columns">
                <Grid className="content" container spacing={3}>
                    <Grid item xs className="listPayment column col-30">
                        <FormControl variant="standard" required sx={{m: 1, minWidth: 120, mb: '50px'}}>
                            <InputLabel id="demo-simple-select-standard-label">Choose Site:</InputLabel>
                            <Select
                                labelId="chooseSite"
                                id="business"
                                value={payData.business}
                                onChange={updateInputValue}
                                label="Choose Site:"
                                name="business"
                            >

                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {businessData.map((item) => {
                                    return (
                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <FormControl required>
                            <DatePicker selected={created} onChange={(date) => setCreated(date)}/>
                            Only for manual payment method
                        </FormControl>

                    </Grid>

                    <Grid item xs className="col-30">
                        <div className="paymentInfo">
                            <TextField id="standard-basic" label="Client Name" variant="standard" name="clientName" required
                                       onChange={updateInputValue}/>
                        </div>
                        <div className="paymentInfo">
                            <TextField id="standard-basic" label="Email" variant="standard" name="clientEmail" required
                                       onChange={updateInputValue}/>
                        </div>
                        <div className="paymentInfo">
                            <TextField id="standard-basic" label="Price" variant="standard" type="text" name="price" required
                                       onChange={updateInputValue}/>
                        </div>


                    </Grid>
                    <Grid item xs className="col-30">
                        <FormControl component="fieldset" required>
                            <FormLabel component="legend">Choose payment method</FormLabel>
                            <RadioGroup
                                aria-label="payMethod"
                                defaultValue="Paypal"
                                name="paymentType"
                                onChange={updateInputValue}
                            >
                                {paymentType.map((item, index) => {
                                    return (
                                        <FormControlLabel value={item.id} control={<Radio required/>} label={item.name}/>
                                    )
                                })}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Box sx={{width: '100%', textAlign: 'center', mt: '40px'}}>
                    <Button type="submit" variant="contained">Create New payment</Button>
                </Box>

            </form>

        </div>
    );
};

export default NewPayment;