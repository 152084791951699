import authHeader from "../../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../../environment";

export const GET_CMS = "GET_CMS";
const API_URL = environment.baseUrl;

export const getCmsData = (site, service) => {
    console.log('site -thunk', site)
    console.log('service -thunk', service)
    return (dispatch) => {
        // dispatch(setIsLoading(true));
        axios
            .get(API_URL + '/cms', {
                headers:
                    {
                        'Authorization': authHeader().Authorization
                    },
                params: {
                    site: site,
                    service: service
                }
            })
            .then((res) => {
                dispatch({ type: GET_CMS, payload: res.data });
                // dispatch(setIsLoading(false));
                console.log("dataCMS:", res.data)
            })
            .catch((err) => {
                console.log("err", err)
                // dispatch(setError(err))
                // dispatch(setIsLoading(false));
            });
    };
};
//
// export const resetDataUser = () => {
//     return (dispatch) => {
//         dispatch({type: GET_USERS, payload: {}});
//     };
// };