import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";

export const GET_SERVICE = "GET_SERVICE";
const API_URL = environment.baseUrl;

export const getService = () => {
    return (dispatch) => {
        // dispatch(setIsLoading(true));
        axios
            .get(API_URL + '/services', { headers: authHeader() })
            .then((res) => {
                dispatch({ type: GET_SERVICE, payload: res.data });
                // dispatch(setIsLoading(false));
                console.log("service:", res.data)
            })
            .catch((err) => {
                console.log("err", err)
                // dispatch(setError(err))
                // dispatch(setIsLoading(false));
            });
    };
};

// export const resetDataService = () => {
//     return (dispatch) => {
//         dispatch({type: GET_SERVICE, payload: {}});
//     };
// };