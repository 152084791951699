import authHeader from '../../../../../services/auth-header'
import axios from 'axios'
import environment from '../../../../../environment'
import { setIsLoading } from '../../services/isLoading'

export const GET_PROXY = 'GET_PROXY'
const API_URL = environment.baseUrl

export const getProxy = () => {
  return (dispatch) => {
    dispatch(setIsLoading(true))
    axios
      .get(API_URL + '/settings/proxy/all', { headers: authHeader() })
      .then((res) => {
        dispatch({ type: GET_PROXY, payload: res.data })
        dispatch(setIsLoading(false))
        // console.log("data:", res.data)
      })
      .catch((err) => {
        console.log('err', err)
        // dispatch(setError(err))
        dispatch(setIsLoading(false))
      })
  }
}

export const resetDataProxy = () => {
  return (dispatch) => {
    dispatch({ type: GET_PROXY, payload: [] })
  }
}