import authHeader from '../../../../../services/auth-header'
import axios from 'axios'
import environment from '../../../../../environment'
import { DATA_SUCCESS, dataSuccess } from '../../Error/messageSuccess'

export const ADD_PROXY = 'ADD_PROXY'
const API_URL = environment.baseUrl

export const addProxy = (data) => {
  return (dispatch) => {
    axios
      .post(API_URL + '/settings/proxy', data, { headers: authHeader() })
      .then(res => {
        console.log(res)
        dispatch({ type: ADD_PROXY, payload: res.data })
        const messageSuccess = 'New proxy add succesfully'
        dispatch({ type: DATA_SUCCESS, payload: messageSuccess })
        setTimeout(() => {
          dispatch(dataSuccess())
        }, 4000)
        console.log('addProxy', res)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }
}