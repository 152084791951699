import axiosInstance from "./api";
import TokenService from "./token.service";
import { refreshToken } from "../actions/auth";
import environment from "../environment";
const API_URL = environment.baseUrl;

const setup = (store) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            const token = TokenService.getLocalAccessToken();
            if (token) {
                config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
                // config.headers["x-access-token"] = token; // for Node.js Express back-end
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    const { dispatch } = store;
    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;

            if (originalConfig.url !== "/auth/login" && err.response) {
                // Access Token was expired
                if (err.response.status !== 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        const rs = await axiosInstance.post("/auth/refreshToken", {
                            // access_token: TokenService.getLocalAccessToken(),
                            refreshToken: TokenService.getLocalRefreshToken()
                        });

                        const { access_token } = rs.data;

                        dispatch(refreshToken(access_token));
                        TokenService.updateLocalAccessToken(access_token);

                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            }

            return Promise.reject(err);
        }
    );
};

export default setup;