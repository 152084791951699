import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";
import {DATA_SUCCESS, dataSuccess} from "../Error/messageSuccess";
import {setFetchError} from "../Error/errorAction";
import {dataError} from "../Error/errorData";

export const ADD_ORDER_REPORT= 'ADD_ORDER_REPORT';
const API_URL = environment.baseUrl;

export const addOrderReport = (data) => {
    // console.log('OrderId', id)
    console.log('Report', data)
    return (dispatch) => {
        axios
            .post(API_URL + `/reviews`, data, { headers: authHeader() })
            .then(res => {
                console.log(res)
                dispatch({ type: ADD_ORDER_REPORT, payload: res.data})
                const messageSuccess = 'Review added succesfully'
                dispatch({ type: DATA_SUCCESS, payload: messageSuccess})
                setTimeout(() => {
                    dispatch(dataSuccess())
                }, 4000)
            })
            .catch((error) => {
                dispatch(setFetchError(true))
                dispatch(dataError(error.response.data))

                setTimeout(() => {
                    dispatch(setFetchError(false))
                }, 4000)
            })
    }
}