import {httpClient} from "../../../../../services/services";
import authHeader from "../../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../../environment";
import { setFetchError } from '../../Error/errorAction'
import { dataError } from '../../Error/errorData'

export const ADD_NEW_ORDER = 'ADD_NEW_ORDER';
const API_URL = environment.baseUrl;

export const addNewOrder = (data) => {
    return (dispatch) => {
        axios
            .post(API_URL + '/order/create/single', data, { headers: authHeader() })
            .then(res => {
                console.log(res)
                dispatch({ type: ADD_NEW_ORDER, payload: res.data})
            })
            .catch((err) => {
              console.log("err", err)
              dispatch(setFetchError(true))
              dispatch(dataError(err.response.data))
              setTimeout(() => {
                dispatch(setFetchError(false))
              }, 4000)
        })
    }
}