import authHeader from '../../../../../services/auth-header'
import axios from 'axios'
import environment from '../../../../../environment'
import { setFetchError } from '../../Error/errorAction'
import { dataError } from '../../Error/errorData'
import { setIsLoading } from '../../services/isLoading'

export const GET_PROVIDER_ORDER = 'GET_PROVIDER_ORDER'
const API_URL = environment.baseUrl

export const getProviderOrder = (hash, providerPass) => {

  console.log('hash -thunk', hash)
  console.log('providerPass thunk', providerPass)
  const data = {
    pass: providerPass
  }
  return (dispatch) => {
    dispatch(setIsLoading(true));
    axios
      .get(API_URL + `/providers/orders/${ hash }`, {
        headers:
          {
            'Provider-pass': providerPass
          }
        // authHeader(),
      })
      .then((res) => {
        dispatch({ type: GET_PROVIDER_ORDER, payload: res.data })
        dispatch(setIsLoading(false));
      })
      .catch((error) => {
        dispatch(setFetchError(true))
        dispatch(dataError(error.response))
        dispatch(setIsLoading(false))
        setTimeout(() => {
          dispatch(setFetchError(false))
        }, 4000)
      })
  }
}

export const resetDataOrderProvider = () => {
  return (dispatch) => {
    dispatch({ type: GET_PROVIDER_ORDER, payload: {} })
  }
}