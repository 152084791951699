import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useLocation, Redirect, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { addCommentOrder } from '../store/reducers/orderReducer/actions/addOrderComment'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Button from '@mui/material/Button'
import TableSingleOrderProvider from './TableSingleOrderProvider'
import { getProviderOrder, resetDataOrderProvider } from '../store/reducers/orderReducer/actions/getProviderOrderHash'
import { downloadFileSingleOrder } from '../store/reducers/orderReducer/actions/downloadXlsxSingleOrder'
import Alert from '@mui/material/Alert'
import { getUser } from '../store/reducers/user/actions/getUserId'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Cookies from 'universal-cookie'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Checkbox from '@mui/material/Checkbox'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import UploadIcon from '@mui/icons-material/Upload'
import { uploadFileOrderReport } from '../store/reducers/orderReducer/actions/uploadXlsxReport'
import { getService } from '../store/reducers/services/getService'
import { uploadFileProviderReport } from '../store/reducers/orderReducer/actions/uploadProviderXlsReport'
import { downloadFileHashProvider } from '../store/reducers/orderReducer/actions/downloadXlsxFileProvider'
import { updateOrderData } from '../store/reducers/orderReducer/actions/updateOrderData'
import { getOrdersList, resetDataOrdersList } from '../store/reducers/orderReducer/actions/getDataOrdersList'
import { getOrderById, resetDataOrder } from '../store/reducers/orderReducer/actions/getOrderById'
import { InputLabel, Select } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { updateProviderStatus } from '../store/reducers/providerHash/updateStatus'
import { updateOrderExecutor } from '../store/reducers/orderReducer/actions/updateOrderExecutor'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Modal from '@mui/material/Modal'
import { downloadFileSingleOrderProvider } from '../store/reducers/providerHash/downloadReviewsProvider'

const SingleOrderProvider = ({ isManager }) => {


  const dispatch = useDispatch()

  const { hash } = useParams()
  const { providerOrdersData } = useSelector(({ order }) => order)
  const { providerOrderData } = useSelector(({ order }) => order)
  const { commentOrder } = useSelector(({ order }) => order)
  const { fileProviderReport } = useSelector(({ order }) => order)

  const { orderData } = useSelector(({ order }) => order)
  const { userData } = useSelector(({ user }) => user)
  const { userDataOne } = useSelector(({ user }) => user)
  const { user: currentUser } = useSelector((state) => state.auth)
  const { isLoggedIn } = useSelector(state => state.auth)

  const { updateDataProviderReport } = useSelector(({ report }) => report)
  const { newProviderReport } = useSelector(({ report }) => report)

  const { deleteDataProviderReport } = useSelector(({ report }) => report)

  const { isFetchError } = useSelector(({ error }) => error)
  const { dataError } = useSelector(({ error }) => error)
  const { messageSuccess } = useSelector(({ error }) => error)

  const statusRead = [
    'pending payment',
    'active',
    'add details',
    'in work',
    'finished',
    'report sent',
    'refilling',
    'refilled',
    'cancelled',
    'archived',
    'paused'
  ]
  const statusSpecial = [
    'done',
    'progress',
    'pending',
    'upcoming'
  ]

  const statusProvider = [
    1, 2, 3, 4, 5
  ]


  // useEffect(() => {
  //     currentUser && dispatch(getUser(currentUser.id));
  // }, [hash]);
  console.log('USERDATAONE', userDataOne)


  const arrServiceProviders = userData.filter(user => user.role === 'executor')

  const [serviceProvider, setServiceProvider] = useState({})
  const [selectedFile, setSelectedFile] = useState(null)

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const checkedFilter = ['Alive', 'Empty', 'NotFound', 'NotChecked', 'Removed']
  const [checkedFilterDownload, setCheckedFilterDownload] = useState(checkedFilter)

  const checkboxCheck = (e) => {
    // setCheckedFilterDownload(e.target.checked);
    console.log('e.target.checked', e.target.checked)
    console.log('e.target.name', e.target.name)
    if (e.target.checked) {
      const checkedResult = checkedFilterDownload.concat(e.target.name)
      setCheckedFilterDownload(checkedResult)
    } else {
      const checkedResult = checkedFilterDownload.filter((current) => current !== e.target.name)
      setCheckedFilterDownload(checkedResult)
    }
  }
  console.log('checkedFilterDownload22', checkedFilterDownload)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 250,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3
  }

  const cookiesProvider = new Cookies()

  const passProvider = cookiesProvider.get('pass')
  const passCheckLog = isLoggedIn ? userDataOne.providerPass : cookiesProvider.get('pass')

  const downloadFile = () => {
    const data = {
      in: checkedFilterDownload
    }
    dispatch(downloadFileSingleOrderProvider(hash, id, passCheckLog, data))
    handleClose()
  }

  useEffect(() => {
    arrServiceProviders.map(row => serviceProvider[row.id] = row.login)
    setServiceProvider(serviceProvider)
  }, [])

  // const checkPass = () => {
  //     const result = window.prompt('Enter pass')
  //     console.log('result', result)
  //     dispatch(getProviderOrder(hash,result))
  // }

  // const [pass, setPass] = useState();

  // const promptCall = () => {
  // do {

  // cookiesProvider.set("pass", pass)
  // } while (pass !== userDataOne.providerPass);
  // }

  // async function asyncDispatch (providerOrderData) {
  //     try {
  //         if(!isLoggedIn && !passProvider) {
  //             let passEnt
  //             passEnt = prompt("Enter pass")
  //             console.log('passEnt', passEnt)
  //             setPass(passEnt)
  //
  //             !cookiesProvider.get('pass') && cookiesProvider.set('pass', passEnt)
  //
  //             console.log('cookiesProvider.get', cookiesProvider.get('pass'))
  //             console.log('cookiesProvider111', cookiesProvider)
  //             const result = await dispatch(getProviderOrder(hash,passEnt))
  //             // The checkClient call is now done!
  //             console.log(`success: ${result}`)
  //             console.log('dataERROR', dataError)
  //             return result
  //                 if(result !== undefined) {
  //                     console.log('delete cookie')
  //                     isEmpty(providerOrderData) && cookiesProvider.remove("pass")
  //                     // cookiesProvider.set('pass', pass)
  //                     cookiesProvider.set("pass", prompt("Enter pass"))
  //                     // dispatch(getProviderOrder(hash,cookiesProvider.get('pass')))
  //                 }
  //         }
  //         else {
  //             !isEmpty(userDataOne) && dispatch(getProviderOrder(hash,userDataOne.providerPass))
  //         }
  //
  //         // Do something
  //     } catch (err) {
  //         console.log(`err:`, err)
  //     }
  // }
  // useEffect(() => {
  //     console.log("propsUSEEFFECT", providerOrderData)
  //     return asyncDispatch(providerOrderData);
  //
  // }, [userDataOne]);

  const promptCall = () => {
    let passEnt
    passEnt = prompt('Enter pass')
    if (passEnt === null) {
      return prompt('Enter pass')
    }
    return passEnt
  }


  useEffect(() => {
    // while(!answer){
    //     let answer =
    // };

    // let pass
    if (!isLoggedIn && !passProvider) {
      // let passEnt
      // passEnt = prompt("Enter pass")
      const pass = promptCall()
      // console.log('passEnt', passEnt)
      // setPass(passEnt)
      // cookiesProvider.set('pass', passEnt)

      // pass = prompt("Enter pass")
      // setPass(prompt("Enter pass"))

      // const promptCall = () => {
      //     // do {
      //         console.log('pass', pass)
      //         console.log('userDataOne.providerPass1111', userDataOne.providerPass);
      //         pass = prompt("Enter pass")
      //         dispatch(getProviderOrder(hash,pass))
      //         dispatch(getUser(providerOrderData.provider));
      //     // } while (pass !== userDataOne.providerPass);
      // }
      // console.log('pass222', pass)
      //
      // !isLoggedIn && !passProvider && !userDataOne && promptCall()

      !cookiesProvider.get('pass') && cookiesProvider.set('pass', pass)

      // (window.prompt('Enter pass'))
      // console.log('cookiesProvider.get', cookiesProvider.get('pass'))
      // console.log('cookiesProvider111', cookiesProvider)
      // currentUser ? dispatch(getProviderOrder(hash,userDataOne.providerPass)) : dispatch(getProviderOrder(hash,cookiesProvider.get('pass')))
      dispatch(getProviderOrder(hash, pass))

    } else {
      !isEmpty(userDataOne) && dispatch(getProviderOrder(hash, userDataOne.providerPass))
    }
    // if(!providerOrderData) {
    //     cookiesProvider.remove("pass")
    // }

  }, [userDataOne])


  useEffect(() => {
    if (isEmpty(providerOrderData) && dataError.statusCode === 403) {
      console.log('delete cookie')
      cookiesProvider.remove('pass')
      const pass = promptCall()

      // cookiesProvider.set('pass', pass)
      cookiesProvider.set('pass', pass)
      dispatch(getProviderOrder(hash, pass))
    }
  }, [dataError])

  useEffect(() => {
    if (passProvider) {
      dispatch(getProviderOrder(hash, cookiesProvider.get('pass')))
    }
  }, [])

  // useEffect(() => {
  // if(!isLoggedIn && !passProvider && isEmpty(providerOrderData)) {
  //     console.log('delete cookie')
  //     isEmpty(providerOrderData) && cookiesProvider.remove("pass")
  //     // cookiesProvider.set('pass', pass)
  //     cookiesProvider.set("pass", prompt("Enter pass"))
  //     // dispatch(getProviderOrder(hash,cookiesProvider.get('pass')))
  // }
  // }, [providerOrderData]);


  // const providerDataCallback = useCallback(
  //     () => dispatch(getProviderOrder(hash, passProvider)),
  //     [dispatch]
  // )
  // const doSomething = () => {
  //     return new Promise((resolve, reject) => {
  //         console.log("Начало Промис.");
  //
  //             let passEnt
  //             passEnt = prompt("Enter pass")
  //             console.log('passEnt', passEnt)
  //             console.log('providerDataCallback', providerDataCallback)
  //             setPass(passEnt)
  //             !cookiesProvider.get('pass') && cookiesProvider.set('pass', passEnt)
  //         dispatch(getProviderOrder(hash, passEnt))
  //
  //
  //
  //         setTimeout(() => {
  //             if(providerDataCallback){
  //                 resolve("Успех")
  //             } else {
  //                 console.log('Ошибка', reject)
  //                 console.log('delete cookie')
  //                 cookiesProvider.remove("pass")
  //                 doSomething()
  //                 reject("Ошибка")
  //             }
  //         }, 1500)
  //
  //     })
  // }
  //
  //
  // useEffect(() => {
  // // let promise = new Promise((resolve, reject) => {
  //     console.log('Начало useeffect');
  //     if (!isLoggedIn && !passProvider) {
  //         doSomething().then((message) => {
  //             console.log('providerOrderData', providerOrderData)
  //             console.log('message', message)
  //             if (isEmpty(providerOrderData)) {
  //                 console.log('delete cookie')
  //                 cookiesProvider.remove("pass")
  //
  //                 // cookiesProvider.set("pass", prompt("Enter pass"))
  //                 // dispatch(getProviderOrder(hash,cookiesProvider.get('pass')))
  //             }
  //             doSomething()
  //         }).catch((message) => {
  //             console.log('messageErr', message)
  //             console.log('delete cookie')
  //             cookiesProvider.remove("pass")
  //             doSomething()
  //         })
  //     }
  //
  //     //     .then((result) => {
  //     //     if(providerOrderData && isEmpty(providerOrderData)) {
  //     //                 console.log('delete cookie')
  //     //                 cookiesProvider.remove("pass")
  //     //
  //     //                 // cookiesProvider.set("pass", prompt("Enter pass"))
  //     //                 // dispatch(getProviderOrder(hash,cookiesProvider.get('pass')))
  //     //             }
  //     //         doSomething()
  //     // })
  //     console.log('PASS tyt', pass)
  //
  //     currentUser ? dispatch(getProviderOrder(hash,userDataOne.providerPass)) : dispatch(getProviderOrder(hash,cookiesProvider.get('pass')))
  //
  //     // resolve();
  // // })
  //     // .then(() => {
  //     //     console.log('Выведи это isFetchError', isFetchError);
  //     //
  //     //     if(isFetchError) {
  //     //         console.log('Выведи это');
  //     //         // return Promise.reject(new Error("Empty list!"));
  //     //         console.log('delete cookie')
  //     //         cookiesProvider.remove("pass")
  //     //
  //     //         // cookiesProvider.set("pass", prompt("Enter pass"))
  //     //         // dispatch(getProviderOrder(hash,cookiesProvider.get('pass')))
  //     //     }
  //     //     let passEnt
  //     //     passEnt = prompt("Enter pass")
  //     //
  //     //     cookiesProvider.set('pass', passEnt)
  //     //     dispatch(getProviderOrder(hash,passEnt))
  //     //     setPass (passEnt)
  //     //
  //     // })
  //     // .catch((err) => {
  //     //     console.log('error', err);
  //     //     console.log('delete cookie')
  //     //     cookiesProvider.remove("pass")
  //     //     prompt("Enter pass")
  //     // })
  //
  // }, [userDataOne]);


  // useEffect(() => {
  //     // while(!answer){
  //     //     let answer =
  //     // };
  //
  //     // let pass
  //     if(!isLoggedIn && !passProvider) {
  //         let passEnt
  //         passEnt = prompt("Enter pass")
  //         console.log('passEnt', passEnt)
  //     setPass(passEnt)
  //       // pass = prompt("Enter pass")
  //         // setPass(prompt("Enter pass"))
  //
  //     // const promptCall = () => {
  //     //     // do {
  //     //         console.log('pass', pass)
  //     //         console.log('userDataOne.providerPass1111', userDataOne.providerPass);
  //     //         pass = prompt("Enter pass")
  //     //         dispatch(getProviderOrder(hash,pass))
  //     //         dispatch(getUser(providerOrderData.provider));
  //     //     // } while (pass !== userDataOne.providerPass);
  //     // }
  //     // console.log('pass222', pass)
  //     //
  //     // !isLoggedIn && !passProvider && !userDataOne && promptCall()
  //
  //     !cookiesProvider.get('pass') && cookiesProvider.set('pass', passEnt)
  //
  //     // (window.prompt('Enter pass'))
  //     console.log('cookiesProvider.get', cookiesProvider.get('pass'))
  //     console.log('cookiesProvider111', cookiesProvider)
  //     currentUser ? dispatch(getProviderOrder(hash,userDataOne.providerPass)) : dispatch(getProviderOrder(hash,cookiesProvider.get('pass')))
  //
  //     // if(!providerOrderData) {
  //     //     cookiesProvider.remove("pass")
  //     // }
  //     }
  // }, [userDataOne, pass]);
  //
  // // useEffect(() => {
  //     if(isEmpty(providerOrderData)) {
  //         console.log('delete cookie')
  //         cookiesProvider.remove("pass")
  //         // cookiesProvider.set("pass", prompt("Enter pass"))
  //         // dispatch(getProviderOrder(hash,cookiesProvider.get('pass')))
  //     }
  // }, [pass]);
  // console.log("pass", pass)

  useEffect(() => {
    console.log('GETPROVIDER')
    if (!isEmpty(userDataOne)) {
      dispatch(resetDataOrderProvider())
      dispatch(getProviderOrder(hash, userDataOne.providerPass))
    } else if (!isEmpty(cookiesProvider.get('pass'))) {
      dispatch(resetDataOrderProvider())
      dispatch(getProviderOrder(hash, cookiesProvider.get('pass')))
    }
    // currentUser && !userDataOne && dispatch(getProviderOrder(hash,userDataOne.providerPass))
    // if (currentUser) { return dispatch(getProviderOrder(hash,userDataOne.providerPass))} else { return undefined}
  }, [userDataOne, fileProviderReport, deleteDataProviderReport, newProviderReport])

  useEffect(() => {
    if (!isEmpty(userDataOne)) {
      dispatch(getProviderOrder(hash, userDataOne.providerPass))
    } else if (!isEmpty(cookiesProvider.get('pass'))) {
      dispatch(getProviderOrder(hash, cookiesProvider.get('pass')))
    }
  }, [updateDataProviderReport])

  // useEffect(() => {
  //     dispatch(getOrderById(providerOrdersData.orders[0].id));
  // }, [providerOrdersData]);

  // console.log("providerOrdersData@@@@@@@@@@@!!!!!!!:", providerOrdersData)
  // console.log("providerOrdersData0000000000:", providerOrdersData.orders[0].id)
  // console.log("orderData@@@@@@@@@@@!!!!!!!:", orderData)
  // const userPass = () => {
  //     if (providerOrdersData[0].serviceProvider === userData.login) {
  //         return userData.providerPass
  //     }
  // }


  // const serviceProv = !isEmpty(providerOrdersData) && (providerOrdersData.orders[0].provider)

  const findUser = (userData, serviceProv) => {
    for (var i = 0; i < userData.length; i++) {
      if (userData[i].login === serviceProv) {
        return userData[i].providerPass
      }
    }
    return false
  }
  // const providerPass = findUser(userData, serviceProv)

  //
  // const findUser = userData.find(element => element.id === serviceProv.id);
  // const providerPass = findUser.providerPass


  // console.log("providerOrderData:", providerOrdersData.orders[0].serviceProvider)
  // console.log("SingleOrderProvider - userData:", userData[1].login)
  // console.log("SingleOrderProvider - userPass:", userPass)
  // useEffect(() => {
  //     console.log("IN USEEFFECT", providerOrderData)
  //     !isEmpty(providerOrderData) && dispatch(getProviderOrder(hash,providerPass));
  //     dispatch(resetDataOrderProvider());
  //     console.log("IN USEEFFECT2", providerOrderData)
  //     dispatch(getProviderOrder(hash,providerPass));
  //     console.log("IN USEEFFECT3", providerOrderData)
  // }, [hash, providerPass,commentOrder]);

  const {
    id,
    appLink,
    created,
    updated,
    client,
    source,
    paymentType,
    comment,
    status,
    serviceProviderStatus,
    quantity,
    perDay,
    GEO,
    location,
    language,
    progress,
    serviceCost,
    service,
    provider,
    linkChecker,
    application,
    reviews
  } = providerOrderData

  const managerBread = () => {
    if (currentUser) {
      return <Breadcrumbs aria-label="breadcrumb">
        <Link hover="underline" color="inherit" to={ `/orders/provider/${ currentUser.id }` }>
          Orders
        </Link>
        <Typography color="text.primary">Order</Typography>
      </Breadcrumbs>
    } else {
      return undefined
    }
  }
  const handleClick = (e) => {
    e.preventDefault()
  }
  const reportAlive = reviews && reviews.filter(item => item.status === 'alive' || item.status === 'alive_ok' || item.status === 'removed')

  const [commentSend, setCommentSend] = useState({
    orderId: parseInt(id),
    comment: ''
  })
  // if (isLoggedIn === false) {
  //     return alert("Enter pass")
  // }
  // console.log("ALERt")

  const updateCommentValue = e => {
    console.log(e.target.name, e.target.value)
    setCommentSend({ ...commentSend, [e.target.name]: e.target.value.toString() })
  }
  const AddNewCommentOrder = (e) => {
    e.preventDefault()
    dispatch(addCommentOrder(commentSend))
    console.log('Comment!!!:', commentSend)
  }

  const submitFormUpload = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('file', selectedFile)
    dispatch(uploadFileProviderReport(hash, formData, passCheckLog))
  }

  const [checked, setChecked] = React.useState(linkChecker)

  const handleChange = (event) => {
    setChecked(event.target.checked)
  }

  const updateStatusValue = e => {
    console.log(e.target.value)
    const value = e.target.name === 'status' ? e.target.value : parseInt(e.target.value)

    // const newData = {[e.target.name]: value, pass: passCheckLog}
    const newData = { [e.target.name]: value }
    console.log('newData&&&', newData)
    dispatch(isLoggedIn ? updateOrderExecutor(id, newData) : updateProviderStatus(hash, newData, passCheckLog))
  }

  const clipboardClick = () => {
    navigator.clipboard.writeText(appLink)
  }

  const arrCount = reviews && reviews.length !== 0 ? reviews.map(item => {
    return item.count
  }) : 0
  const reducer = (prev, cur) => prev + cur

  // const reviewShow = service && (service.id === 20 || service.id === 25);
  // console.log('reviewShow',reviewShow )
  // const reviewId =[20, 25];
  //
  // const reviewShowArray = serviceData && serviceData.filter(({id}) => reviewId.includes(id));
  // const reviewShow = () => {
  //
  //     reviewShowArray.map((item, i) => {
  //         if (service.id === item.id)
  //         return true;
  //     })
  // }


  // const reviewShow = serviceData.includes(service.id) && service.id === 20

  // console.log('reviewShowArray',reviewShowArray )
  // console.log('reviewShow',reviewShow )

  return (
    <>
      { messageSuccess &&
        <div className="errorBlock">
          <Alert severity="success">{ messageSuccess }</Alert>
        </div>
      }
      { isFetchError &&
        <div className="errorBlock">
          <Alert severity="error">{ dataError.message }</Alert>
        </div>
      }
      { !isEmpty(providerOrderData) && (
        <Box>
          <div role="presentation" onClick={ handleClick }>
            { managerBread() }
          </div>
          <Typography variant="h5" component="div" gutterBottom sx={ { textAlign: 'center' } }>
            Order № { id }
          </Typography>
          <Box sx={ { width: '100%', display: 'flex', justifyContent: 'space-between', mb: '20px' } }>
            <Box sx={ { width: '40%', display: 'flex' } }>
              <Box sx={ { width: '100%', maxWidth: 600 } } className="custom-list-col">
                <Box sx={ { width: '100%' } }>
                  <label htmlFor="appLink">App link: <span className="spanCopy" onClick={ clipboardClick }>{ appLink } <ContentCopyIcon fontSize="small" className="copyLink" /></span> </label>
                </Box>
                <Box sx={ { width: '100%', display: 'flex' } }>
                  <List sx={ { width: '50%' } }>
                    <ListItem className="list-item">
                      <label htmlFor="created"><span>Order Date: </span>{ created.slice(0, 10) }</label>
                    </ListItem>
                    <ListItem className="list-item">
                      <label htmlFor="update"><span>Update Date: </span>{ updated && updated.slice(0, 10) }</label>
                    </ListItem>
                    <ListItem className="list-item">
                      <label htmlFor="serviceCost"><span>Quantity </span>{ quantity }</label>
                    </ListItem>
                    <ListItem className="list-item">
                      <label htmlFor="perDay"><span>Speed per day: </span>{ perDay }</label>
                    </ListItem>
                    <ListItem className="list-item">
                      <label htmlFor="appId"><span>AppId </span>{ application ? application?.appId : 'none' }</label>
                    </ListItem>
                    <ListItem className="list-item">
                      <label htmlFor="trackId"><span>TrackId </span>{ application?.trackId ? application?.trackId : 'none' }</label>
                    </ListItem>
                  </List>
                  <List sx={ { width: '50%' } }>
                    <ListItem className="list-item">
                      <label htmlFor="service"><span>Service: </span>{ service.name }</label>
                    </ListItem>
                    <ListItem className="list-item">
                      <label htmlFor="service"><span>Platform: </span>{ service.platform }</label>
                    </ListItem>
                    <ListItem className="list-item">
                      <label htmlFor="GEO"><span>Geo(old): </span>{ GEO }</label>
                    </ListItem>
                    <ListItem className="list-item">
                      <label htmlFor="location"><span>Location: </span>{ location }</label>
                    </ListItem>
                    <ListItem className="list-item">
                      <label htmlFor="language"><span>Language: </span>{ language }</label>
                    </ListItem>
                    <ListItem className="list-item">
                      <label htmlFor="Progress"><span>Progress: </span>{ progress + '/' + quantity }</label>
                    </ListItem>
                    {/*<ListItem className="list-item">*/ }
                    {/*    <label htmlFor="Progress"><span>Progress: </span>{reportAlive.length}/{quantity}</label>*/ }
                    {/*</ListItem>*/ }
                    {/*<ListItem className="list-item">*/ }
                    {/*  <label htmlFor="count"><span>Total reviews posted: </span>{ arrCount ? arrCount.reduce(reducer) : 0 }</label>*/ }
                    {/*</ListItem>*/ }
                  </List>
                </Box>

              </Box>
            </Box>

            <Box sx={ { width: '55%', display: 'flex', justifyContent: 'space-between' } }>
              <Box sx={ { width: '32%' } } className="custom-list-col">
                <List sx={ { width: '100%' } }>
                  <ListItem className="list-item">
                    <label htmlFor="status"><span>Status: </span>
                      <FormControl variant="standard" component="fieldset" sx={ { maxWidth: 100 } }>
                        {/*<InputLabel id="provider">Status</InputLabel>*/ }
                        <select
                          labelId="status"
                          onChange={ updateStatusValue }
                          defaultValue={ status }
                          label="status"
                          name="status"
                        >
                          { statusRead.map((item) => (
                            item === 'add details' || item === 'in work' || item === 'finished' || item === 'refilling' || item === 'refilled' || item === 'paused' ? (
                              <option value={ item }>{ item }</option>
                            ) : (
                              <option value={ item } hidden>{ item }</option>
                            )
                          )) }
                        </select>
                      </FormControl>
                    </label>
                  </ListItem>
                  <ListItem className="list-item">
                    <label htmlFor="serviceProviderStatus"><span>Provider Status: </span>
                      <FormControl variant="standard" component="fieldset" sx={ { minWidth: 30 } }>
                        {/*<InputLabel id="provider">Status</InputLabel>*/ }
                        <select
                          labelId="serviceProviderStatus"
                          onChange={ updateStatusValue }
                          defaultValue={ serviceProviderStatus }
                          label="serviceProviderStatus"
                          name="serviceProviderStatus"
                        >
                          { statusProvider.map((item) => {
                            return (
                              <option value={ item }>{ item }</option>
                            )
                          }) }
                        </select>
                      </FormControl></label>
                  </ListItem>


                </List>
                { isManager &&
                  <div>
                    <label htmlFor="linkChecker" className="linkCheckerLabel">
                      <Checkbox
                        checked={ checked }
                        onChange={ handleChange }
                        inputProps={ { 'aria-label': 'linkChecker' } }
                      />
                      <span>Link Checker (24h)</span></label>
                  </div>
                }
              </Box>
              <Box sx={ { width: '30%' } } className="custom-list-col">
                {/*<div className="column col-30">*/ }
                {/*    <div className="notes">*/ }
                {/*    <textarea name="text" id="" cols="27" rows="3"*/ }
                {/*              onChange={updateCommentValue}>{comment}</textarea>*/ }
                {/*    </div>*/ }
                {/*    <Button onClick={AddNewCommentOrder}>Apply</Button>*/ }
                {/*</div>*/ }

                <Typography variant="h6" component="div" gutterBottom sx={ { fontWeight: 'bold' } }>
                  Comment
                </Typography>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={ 5 }
                  placeholder="Comment for this order"
                  disabled
                  style={ { width: 200 } }
                  name="comment"
                  onChange={ updateCommentValue }
                >
                  { comment }
                </TextareaAutosize>
                { isManager &&
                  <div>
                    <Button onClick={ AddNewCommentOrder }>Apply</Button>
                  </div>
                }

              </Box>
              <Box sx={ { width: '35%' } } className="custom-list-col">

                <Box sx={ { mb: '20px', borderBottom: '1px solid grey', pb: '30px' } }>
                  <form onSubmit={ submitFormUpload }>
                    <label className="custom-file-upload">
                      <input
                        type="file"
                        // value={selectedFile}
                        onChange={ (e) => setSelectedFile(e.target.files[0]) }
                      />
                      <div className="uploadIcon">
                        <UploadIcon /><span className="file-preview">{ selectedFile && selectedFile.name }</span>
                      </div>
                    </label>

                    <Button type="submit" sx={ { width: '100%' } } variant="outlined">Upload texts</Button>

                  </form>
                </Box>

                <Button sx={ { width: '100%', my: '10px' } } variant="outlined" onClick={ handleOpen }>Download xls report</Button>
                <Modal
                  open={ open }
                  onClose={ handleClose }
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={ style }>
                    <Typography variant="h6" component="div" gutterBottom sx={ { fontWeight: 'bold', textAlign: 'center' } }>
                      Choose status filter
                    </Typography>
                    <FormGroup className="list-item">
                      {
                        checkedFilter.map(item => (
                          <FormControlLabel
                            control={ <Checkbox checked={ checkedFilterDownload.includes(item) } onChange={ checkboxCheck } /> }
                            label={ item }
                            name={ item }
                          />
                        ))
                      }
                      <Button sx={ { width: '100%', my: '10px' } } variant="outlined" onClick={ downloadFile }>Download xls report</Button>
                    </FormGroup>
                  </Box>
                </Modal>


                { isManager && <Button sx={ { width: '100%' } } variant="outlined">Send report to client</Button> }


              </Box>
            </Box>
          </Box>

          <TableSingleOrderProvider reviews={ reviews } hash={ hash } providerPass={ passCheckLog }
                                    service={ service } id={ id } provider={ provider } />

        </Box>

      ) }
    </>
  )
}

export default SingleOrderProvider