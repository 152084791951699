import authHeader from '../../../../../services/auth-header'
import axios from 'axios'
import environment from '../../../../../environment'
import { setFetchError } from '../../Error/errorAction'
import { dataError } from '../../Error/errorData'
import { DATA_SUCCESS, dataSuccess } from '../../Error/messageSuccess'
import { setIsLoading } from '../../services/isLoading'

export const UPDATE_ORDER_EXECUTOR = 'UPDATE_ORDER_EXECUTOR'
const API_URL = environment.baseUrl

export const updateOrderExecutor = (rowId, data, providerFind) => {
  const id = rowId

  return (dispatch) => {
    dispatch(setIsLoading(true))
    axios
      .put(API_URL + `/providers/orders/${ id }`, data, { headers: authHeader() })
      .then(res => {
        console.log(res)
        dispatch({ type: UPDATE_ORDER_EXECUTOR, payload: res })
        dispatch({ type: DATA_SUCCESS, payload: res.data.message })
        setTimeout(() => {
          dispatch(dataSuccess())
        }, 4000)
        dispatch(setIsLoading(false))
      })
      .catch((error) => {
        dispatch(setFetchError(true))
        dispatch(dataError(error.response.data))
        setTimeout(() => {
          dispatch(setFetchError(false))
        }, 4000)
        dispatch(setIsLoading(false))
      })
  }
}