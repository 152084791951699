import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable, { MTableEditCell } from '@material-table/core'
import { updatePaymentData } from '../store/reducers/payment/updatePaymentData'
import isEmpty from 'lodash/isEmpty'
import { Link } from 'react-router-dom'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { getPaymentList, resetDataPaymentList } from '../store/reducers/payment/getDataPaymentList'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import Tooltip from '@material-ui/core/Tooltip'
import { getService } from '../store/reducers/services/getService'
import { getBusiness } from '../store/reducers/services/getBusiness'
import { updateOrderData } from '../store/reducers/orderReducer/actions/updateOrderData'
import { getOrdersList } from '../store/reducers/orderReducer/actions/getDataOrdersList'
import TableCell from '@material-ui/core/TableCell'
import ModalBasic from '../ModalBasic'
import { setIsLoading } from '../store/reducers/services/isLoading'
// const tableRef = React.createRef();
const TablePayments = ({
  paymentType, page, pageSize, setPage, setPageSize, filterData, setFilterData,
  filterSave, setFilterSave
}) => {
  const tableRef = React.createRef()
  const dispatch = useDispatch()
  const { paymentDataList } = useSelector(({ payment }) => payment)
  const { payments, totalItem, totalPage, currentPage } = paymentDataList
  const { updatePayment } = useSelector(({ payment }) => payment)
  const { businessData } = useSelector(({ service }) => service)
  const { isLoading } = useSelector(({ service }) => service)

  const { newDataPayment } = useSelector(({ payment }) => payment)
  const { dataError } = useSelector(({ error }) => error)


  const [data, setData] = useState(payments)
  const [payType, setPayType] = useState({})
  const [business, setBusiness] = useState({})

  const [activeFilter, isActiveFilter] = useState(false)
  // const business = {
  //     1: 'app-reviews',
  //     2: 'reviews-up',
  //     3:'mobirink',
  //     4: 'applatide',
  //     5: 'app-promotion',
  //     6: 'appreviewsubmit'
  // }

  const status = [
    { id: 1, name: 'not paid' },
    { id: 2, name: 'paid' },
    { id: 3, name: 'refunded' },
    { id: 4, name: 'archived' },
    { id: 5, name: 'removed' },
    { id: 6, name: 'draft' }
  ]

  const [created, setCreated] = useState(
    new Date()
  )

  useEffect(() => {
    // dispatch(resetDataPaymentList())
    // dispatch(setIsLoading(true));
    dispatch(getPaymentList(page, pageSize, filterData))
  }, [page, pageSize, filterData, updatePayment])

  useEffect(() => {
    if (!isEmpty(filterData)) {
      isActiveFilter(true)
    }
  }, [filterData])


  useEffect(() => {
    setData(payments)
    // setIsLoading(false)
  }, [paymentDataList])


  useEffect(() => {
    paymentType.map(row => payType[row.id] = row.name)
    setPayType(payType)
  }, [])

  const [rowId, setRowId] = useState()
  const [newData, setNewData] = useState()
  const [columnName, setColumnName] = useState()
  const [value, setNewValue] = useState()
  const [focusChange, setFocusChange] = useState(true)


  const onClickEl = (id) => {
    // console.log("rowIEEEEEE", id)
    setRowId(id)
  }

  const onChangeValue = (value, props) => {
    props.onChange(value)
    const newValueCheck = props.columnDef.type === 'numeric' ? parseInt(value) : value
    const newData = ({ [props.columnDef.field]: newValueCheck })


    const dataUpdate =
      [...data].map((item, i) => {
        if (props.rowData.tableData.id === item.id)
          props.rowData[props.columnDef.field] = newValueCheck
        return item
      })
    setData([...dataUpdate])
    // dispatch(updatePaymentData(rowId, newData))
    // new KeyboardEvent("keydown", { keyCode: "Tab", which: 9 })
    // new KeyboardEvent("keydown", { keyCode: "Enter", which: 13 })
    dispatch(updatePaymentData(props.rowData.id, newData))
    // setTimeout(() => {
    //     dispatch(resetDataPaymentList())
    //     dispatch(getPaymentList());
    // }, 1000)

    // props.autofocus = false
  }


  const onChangeValueInput = (value, props) => {
    props.onChange(value)

    const newValueCheck = props.columnDef.field == 'price' ? Number(value) : value
    setNewData({ [props.columnDef.field]: newValueCheck })

    return new Promise((resolve, reject) => {

      // const dataUpdate = [...data];
      // let index=dataUpdate.findIndex(item=>{
      //
      //
      //     return item[props.columnDef.field]===props.value
      //
      // })
      // dataUpdate[index][props.columnDef.field]=value;
      // console.log('dataUpdate', dataUpdate)
      // setData(dataUpdate)

      const dataUpdate =
        [...data].map((item, i) => {
          if (props.rowData.tableData.id === item.id)
            props.rowData[props.columnDef.field] = props.value
          return item
          console.log('item: ', item)
        })
      setData([...dataUpdate])


      setTimeout(resolve, 1000)
    })


    // const dataUpdate = [...data];
    // dataUpdate[props.rowData.id].props.columnDef.tableData.columnOrder = value
    // setData(...dataUpdate)


    // setData(prevData => [
    //     {
    //         ...prevData[props.rowData.id],
    //         newData
    //     }
    // ]);


    // const dataUpdate = [...data];
    // console.log('dataUpdateprops', props)
    //
    // // props.onChange(value)
    // const index = props.rowData.tableData.id;
    // const column = props.columnDef.field;
    // console.log('index', index)
    // console.log('column', column)
    // console.log('value', value)
    // console.log('dataUpdate[index][column]1', dataUpdate[index][column])
    // dataUpdate[index][column] = props.value;
    // console.log('dataUpdate[index][column]2', dataUpdate[index][column])
    // setData(...dataUpdate)
    // console.log('data!!!!!!!', data)
    // const newValueCheck = props.columnDef.field == 'price' ? parseInt(value) : value
    // setNewData({[props.columnDef.field]: newValueCheck})
    //
    // console.log('newData', newData)
    // dispatch(updatePaymentData(rowId, newData))
    // new KeyboardEvent("keydown", { keyCode: "Tab", which: 9 })
    // new KeyboardEvent("keydown", { keyCode: "Enter", which: 13 })

    // setTimeout(() => {
    //     dispatch(resetDataPaymentList())
    //     dispatch(getPaymentList());
    // }, 1000)

    // props.autofocus = false
  }

  const onChangeDate = (date, props) => {
    props.onChange(date)

    function addDateMin(date, minutes) {
      return new Date(date.getTime() + minutes * 60000)
    }

    // console.log('addMinutes', addDateMin(date, 180))
    // setCreated(date)
    // const newValueCheck = props.columnDef.field == 'price' ? parseInt(value) : value
    const newData = ({ [props.columnDef.field]: addDateMin(date, 180) })

    const dataUpdate =
      [...data].map((item, i) => {
        if (props.rowData.tableData.id === item.id)
          props.rowData[props.columnDef.field] = props.value
        return item
      })
    setData([...dataUpdate])

    // dispatch(updatePaymentData(rowId, newData))
    // new KeyboardEvent("keydown", { keyCode: "Tab", which: 9 })
    // new KeyboardEvent("keydown", { keyCode: "Enter", which: 13 })
    dispatch(updatePaymentData(props.rowData.id, newData))
    // setTimeout(() => {
    //     dispatch(resetDataPaymentList())
    //     dispatch(getPaymentList());
    // }, 1000)

    // props.autofocus = false
  }
  //
  // const onBlur = (props) => {
  //     console.log("IDIDID", rowId)
  //     console.log("IDIDIDnewData", newData)
  //
  //             return new Promise((resolve, reject) => {
  //                 dispatch(updatePaymentData(rowId, newData))
  //                 setTimeout(resolve, 1000);
  //             });
  //
  //
  //
  //     // props.autoFocus = false
  //
  //     // setTimeout(() => {
  //     //     // dispatch(resetDataPaymentList())
  //     //     dispatch(getPaymentList());
  //     // }, 1000)
  // }
  // const onBlurDate = () => {
  //     console.log("IDIDID", rowId)
  //     console.log("created", created)
  //     dispatch(updatePaymentData(rowId, created))
  //     setTimeout(() => {
  //         dispatch(resetDataPaymentList())
  //         dispatch(getPaymentList());
  //     }, 1000)
  // }


  useEffect(() => {
    businessData.map(row => business[row.id] = row.name)
    setBusiness(business)
  }, [businessData])

  // useEffect(() => {
  // }, [dataError.message]);
  // useEffect(() => {
  // }, [newDataPayment]);
  // console.log('valueSource', valueSource)

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      console.log('enter press here! ')
    }
  }
  // const refresh = () => {
  //     dispatch(resetDataPaymentList())
  //     dispatch(getPaymentList())
  // }

  const f = (props) => {


    // props.columnDef.render(props.rowData)
    // tableRef.current.autofocus()

    const dataUpdate =
      [...data].map((item, i) => {
        if (props.rowData.tableData.id === item.id)
          props.rowData[props.columnDef.field] = props.value
        return item
      })
    setData([...dataUpdate])

    dispatch(updatePaymentData(props.rowData.id, newData))
    //
    // let promise = new Promise((resolve, reject) => {
    //     // const dataUpdate =
    //     //     [...data].map((item, i) => {
    //     //         if (props.rowData.tableData.id === item.id)
    //     //             item[props.columnDef.field] = props.value;
    //     //         return item;
    //     //         console.log('item: ', item);
    //     //     })
    //     // setData([...dataUpdate]);
    //
    //     setTimeout(() => resolve("готово!"), 500)
    // });

    // let result = await promise; // будет ждать, пока промис не выполнится (*)
    // await new Promise((resolve, reject) => setTimeout(resolve, ));
    // dispatch(resetDataPaymentList())
    //    dispatch(getPaymentList())
  }

  const valueFilter = (props) => {
    if (props.columnDef.tableData.filterValue !== undefined) {
      // filterData &&
      return props.columnDef.tableData.filterValue
    } else if (filterSave && filterSave[props.columnDef.field] !== null) {
      return filterSave[props.columnDef.field]
    } else {
      return ''
      // filterSave['GEO'] !== null
    }
  }

  const onChangeFilter = (e, props) => {
    // setIsLoading(true)
    props.onFilterChanged(props.columnDef.tableData.id, e.target.value)
    const filterSelect = {
      [e.target.id]: {
        in: [e.target.value]
      }
    }
    const filterToSave = {
      [e.target.id]: e.target.value
    }

    // if(e.target.value === '') {
    //     setFilterData('')
    //     setFilterSave('')
    //     localStorage.removeItem('filterData')
    //     localStorage.removeItem('filterValue')
    //     dispatch(getProviderOrders(id, page, pageSize, ''))
    // } else {

    // const setFilterValue = () => {
    setFilterSave(prevState => ({
      ...prevState, ...filterToSave
    }))
    setFilterData(prevState => ({
      ...prevState, ...filterSelect
    }))
    // }

    // }

  }


  const [columns, setColumns] = useState([
    // { title: 'Name', field: 'name', editable: 'onUpdate' },
    // { title: 'Surname', field: 'surname', editable: 'never' },

    {
      field: 'id',
      title: 'Payment Number',
      type: 'numeric',
      width: '100px',
      editable: 'never',
      whiteSpace: 'nowrap',
      cellStyle: { textAlign: 'center', width: '140px' },
      render: rowData => (<Link to={ { pathname: `/payments/${ rowData.id }` } }>{ rowData.id }</Link>),
      filterComponent: (props) => {
        return (
          <TableCell className={ props.columnDef.tableData.filterValue ? 'withFilter' : '' }>
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'created', title: 'Payment Date', width: '115px',
      // render: rowData => (<div >{moment(rowData.created).format('DD-MMM-YYYY')}</div> )
      render: rowData => (<DatePicker value={ moment(rowData.created).format('DD-MMM-YYYY') } />),
      editComponent: props => (
        <div>
          <DatePicker
            value={ moment(props.value).format('DD-MMM-YYYY') }
            // selected={created}
            // onChange={props.onChange}
            onChange={ (date) => onChangeDate(date, props) }
          />
        </div>
      ),
      filterComponent: (props) =>
        <TableCell
          className={ isActiveFilter && (props.columnDef.tableData.filterValue || (filterData && filterData[props.columnDef.field])) ? 'withFilter' : '' }
        >
          <ModalBasic
            setFilterData={ setFilterData } filterData={ filterData } isActiveFilter={ isActiveFilter }
            filterSave={ filterSave } setFilterSave={ setFilterSave }
          />
        </TableCell>
    },
    {
      field: 'business', name: 'business', type: 'numeric', title: 'Website', width: '155px', lookup: business,
      render: rowData => (<div>{ rowData.business }</div>),

      // render: rowData => ( <select
      //     name='business'
      //     value={rowData.business}
      //     // onChange={(e) => onChangeValue(e.target.value, props)}
      //
      // >
      //     {businessData.map((item) => {
      //         return (
      //             <option value={item.name}>{item.name}</option>
      //         )
      //     })}
      // </select>
      // ),
      editComponent: props => (
        <select
          name={ props.columnDef.field }
          value={ props.value }
          onChange={ (e) => onChangeValue(e.target.value, props) }

        >
          {/*{businessData.map((item) => {*/ }
          {/*    return (*/ }
          {/*        <option value={item.name}>{item.name}</option>*/ }
          {/*    )*/ }
          {/*})}*/ }

          { Object.entries(business).map(([key, value], i) => (

            props.rowData.business === value ? (
              <option key={ key } value={ key } selected>{ value }</option>
            ) : (
              <option key={ key } value={ key }>{ value }</option>
            )
            // <option value={parseInt(key)}>{value}</option>
          )) }
        </select>
      ),
      filterComponent: (props) => {
        return (
          <TableCell className={ props.columnDef.tableData.filterValue ? 'withFilter' : '' }>
            <select
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            >
              <option value={ '' } hidden></option>
              { Object.entries(business).map(([key, value], i) => (
                <option value={ key }>{ value }</option>
              )) }
            </select>
          </TableCell>
        )
      }
    },
    {
      field: 'price', title: 'P.Price', type: 'numeric', width: '110px', cellStyle: { textAlign: 'center' },
      render: rowData => (<div className="editCell">{ rowData.price }</div>),
      filterComponent: (props) => {
        return (
          <TableCell className={ props.columnDef.tableData.filterValue ? 'withFilter' : '' }>
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
      // editComponent: props => {
      //
      //     return (
      //         <TextField
      //             style={props.columnDef.type === 'numeric' ? { float: 'right' } : {}}
      //             type={props.columnDef.type === 'numeric' ? 'number' : 'text'}
      //             placeholder={props.columnDef.title}
      //             value={props.value === undefined ? '' : props.value}
      //             onChange={event => props.onChange(event.target.value)}
      //             InputProps={{ autoFocus: true, style: { fontSize: 13, } }}
      //             onKeyPress={event => {
      //                 if (event.key === "Enter") {
      //                     event.preventDefault();
      //                     tableRef.current.onEditingApproved(props.rowData.tableData.editing, props.rowData, tableRef.current.props.data[props.rowData.tableData.id]);
      //                 }
      //             }}
      //             onKeyUp={event => {
      //                 if (event.key === "Escape") {
      //                     tableRef.current.onEditingCanceled(props.rowData.tableData.editing, props.rowData);
      //                 }
      //             }}
      //         />
      //     );
      // }
    },
    {
      field: 'serviceCost', title: 'P.Service Cost', type: 'numeric', editable: 'never', width: '80px', cellStyle: { textAlign: 'center' },
      render: rowData => (
        <div>{ Number.isInteger(rowData.serviceCost) ? rowData.serviceCost : rowData.serviceCost.toFixed(2) }</div>
      ),
      filterComponent: (props) => {
        return (
          <TableCell className={ props.columnDef.tableData.filterValue ? 'withFilter' : '' }>
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }

    },
    {
      field: 'margin', title: 'P.Margin', type: 'numeric', editable: 'never',
      cellStyle: { textAlign: 'center' },
      width: '100px', hidden: true, hiddenByColumnsButton: false,
      filterComponent: (props) => {
        return (
          <TableCell className={ props.columnDef.tableData.filterValue ? 'withFilter' : '' }>
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'ROI', title: 'P.ROI', type: 'numeric', editable: 'never',
      cellStyle: { textAlign: 'center' },
      width: '100px', hidden: true, hiddenByColumnsButton: false,
      render: rowData => (<div>{ rowData.ROI }%</div>),
      filterComponent: (props) => {
        return (
          <TableCell className={ props.columnDef.tableData.filterValue ? 'withFilter' : '' }>
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'clientName', title: 'Client Name', width: '155px', cellStyle: { whiteSpace: 'nowrap', width: '50px', overflow: 'hidden' },
      render: rowData => (<div>
          {/*<Tooltip title={rowData.clientName} placement="bottom">*/ }
          <div>{ rowData.clientName }</div>

          <Tooltip title={ rowData.contactEmail } placement="bottom">
            <div>{ rowData.contactEmail }</div>
          </Tooltip>
        </div>
      ),
      filterComponent: (props) => {
        return (
          <TableCell className={ props.columnDef.tableData.filterValue ? 'withFilter' : '' }>
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    // {field: 'contactEmail', title: 'Client Email', width:'155px', cellStyle: {fontSize:'12px', whiteSpace: 'nowrap',width:'50px', overflow:'hidden'},
    //     render: rowData => (<Tooltip title={rowData.contactEmail} placement="bottom">
    //             <div >{rowData.contactEmail}</div>
    //         </Tooltip>
    //     ),
    //
    // },
    {
      field: 'source', title: 'Source', width: '80px', render: rowData => (<div>{ rowData.source }</div>),
      filterComponent: (props) => {
        return (
          <TableCell className={ props.columnDef.tableData.filterValue ? 'withFilter' : '' }>
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
      //
      // editComponent: props => {
      //     console.log('props!@#$', props)
      //     return (
      //         <TextField
      //             style={props.columnDef.type === 'numeric' ? { float: 'right' } : {}}
      //             type={props.columnDef.type === 'numeric' ? 'number' : 'text'}
      //             placeholder={props.columnDef.title}
      //             value={props.value === undefined ? '' : props.value}
      //             onChange={event => props.onChange(event.target.value)}
      //             InputProps={{ autoFocus: true, style: { fontSize: 13, } }}
      //             onKeyPress={event => {
      //                 if (event.key === "Enter") {
      //                     event.preventDefault();
      //                     tableRef.current.onEditingApproved(props.rowData.tableData.editing, props.rowData, tableRef.current.props.data[props.rowData.tableData.id]);
      //                 }
      //             }}
      //
      //             onKeyUp={event => {
      //                 if (event.key === "Escape") {
      //                     tableRef.current.onEditingCanceled(props.rowData.tableData.editing, props.rowData);
      //                 }
      //             }}
      //
      //         />
      //
      //     );
      // }
      // editComponent: (value, onChange, rowData) =>
      //     <DropDownMenu
      //         value={this.state.selection}
      //         onChange={this.handleChange}
      //     >
      //         <MenuItem value={1} primaryText="English"  />
      //         <MenuItem value={2} primaryText="Spanish" />
      //         <MenuItem value={3} primaryText="French" />
      //
      //     </DropDownMenu>
      // <select className='qwert!!!' value={value}/>,

    },
    {
      field: 'paymentType', name: 'paymentType', type: 'numeric', title: 'Payment Type', width: '120px',
      render: rowData => (<div>{ rowData.paymentType }</div>),
      // render: rowData => (<select
      //     name='paymentType'
      //     value={rowData.paymentType}
      //     // onChange={(e) => onChangeValue(e.target.value, props)}
      //
      // >
      //     {paymentType.map((item) => {
      //         return (
      //             <option value={item.name}>{item.name}</option>
      //         )
      //     })}
      // </select> ),
      // lookup: paymentType,
      editComponent: props => (
        <select
          name={ props.columnDef.field }
          value={ props.value }
          onChange={ (e) => onChangeValue(e.target.value, props) }

        >
          {/*{paymentType.map((item) => {*/ }
          {/*    return (*/ }
          {/*        props.rowData.paymentType == props.value ? (*/ }
          {/*            <option key={item.id} value={item.id} selected>{item.name}</option>*/ }
          {/*        ): (*/ }
          {/*            <option key={item.id} value={item.id}>{item.name}</option>*/ }
          {/*        )*/ }
          {/*    )}*/ }
          {/*        // <option value={item.id}>{item.name}</option>*/ }
          {/*    )*/ }
          {/*})}*/ }

          { Object.entries(payType).map(([key, value], i) => (
            props.rowData.paymentType === value ? (
              <option key={ key } value={ key } selected>{ value }</option>
            ) : (
              <option key={ key } value={ key }>{ value }</option>
            )
            // <option value={parseInt(key)}>{value}</option>
          )) }
        </select>
      ),
      filterComponent: (props) => {
        return (
          <TableCell className={ props.columnDef.tableData.filterValue ? 'withFilter' : '' }>
            <select
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            >
              <option value={ '' } hidden></option>
              { Object.entries(payType).map(([key, value], i) => (
                <option value={ key }>{ value }</option>
              )) }
            </select>
          </TableCell>
        )
      }
    },
    {
      title: 'Payment Status',
      field: 'status', width: '110px',
      render: rowData => (<div name="status">{ rowData.status }</div>),
      // render: rowData => ( <select
      //     name='status'
      //     value={rowData.status}
      //     // onChange={(e) => onChangeValue(e.target.value, props)}
      //
      // >
      //     {status.map((item) => {
      //         return (
      //             <option value={item.name}>{item.name}</option>
      //         )
      //     })}
      // </select> ),
      editComponent: props => (
        <select
          name={ props.columnDef.field }
          value={ props.value }
          onChange={ (e) => onChangeValue(e.target.value, props) }

        >
          { status.map((item) => {
            return (
              <option value={ item.name }>{ item.name }</option>
            )
          }) }
        </select>
      ),
      filterComponent: (props) => {
        return (
          <TableCell className={ props.columnDef.tableData.filterValue ? 'withFilter' : '' }>
            <select
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            >
              <option value={ '' } hidden></option>
              { status.map((item) => (
                <option value={ item.name }>{ item.name }</option>
              )) }
            </select>
          </TableCell>
        )
      }
      // emptyValue: rowData => (<div onClick={(e)=>onClickEl(rowData.id)}>выбрать</div> ),
      // lookup: {
      //     'not paid': 'not paid', paid: 'paid', refunded: 'refunded', archived: 'archived', removed: 'removed'
      // },
    }
  ])


  const actions = [
    {
      icon: () => <span className={ activeFilter ? 'btnFilterActive' : 'btnFilter' }>Clear Filters</span>,
      tooltip: activeFilter ? 'Clear all filters' : '',
      isFreeAction: true,
      onClick: (event) => {
        isActiveFilter(false)
        tableRef.current.dataManager.columns.map(item => {
          tableRef.current.onFilterChange(item.tableData.id, '')
        })

        setFilterData(null)
        setFilterSave(null)
        // localStorage.removeItem('filterData')
        // localStorage.removeItem('filterValue')
        dispatch(getPaymentList(page, pageSize, ''))
      }
    }
  ]


  return (
    <MaterialTable
      tableRef={ tableRef }
      title="Payments"
      columns={ columns }
      actions={ actions }
      data={ data }
      page={ page }
      isLoading={ isLoading }
      totalCount={ totalItem }
      options={ {
        tableLayout: 'fixed',
        filtering: true,
        columnsButton: true,
        pageSize: pageSize,
        paginationPosition: 'both',
        pageSizeOptions: [30, 60, 90, totalItem],
        padding: 'dense',
        search: false,
        headerStyle: {
          backgroundColor: '#DEF3FA',
          color: 'Black'
          // whiteSpace: 'nowrap',
          // wordBreak: 'break-all',

        },
        rowStyle: {
          fontSize: '14px',
          paddingLeft: 5,
          paddingRight: 5
        }
      } }

      //  components={{
      //     EditField: (props) => {
      //         console.log("propsEDIT", props)
      //         // console.log("tableRef", tableRef.current.props.data[props.rowData.tableData.id])
      //         console.log("tableRef", tableRef)
      //
      //         if (props.columnDef.field === 'paymentType') {
      //
      //             return  <Select
      //                 name={props.columnDef.field}
      //                 value={props.value}
      //                 onChange={(e) => onChangeValue(e.target.value, props)}
      //
      //             >
      //                 {paymentType.map((item) => {
      //                     return (
      //                         <MenuItem value={item.id}>{item.name}</MenuItem>
      //                     )
      //                 })}
      //             </Select>
      //         } else if (props.columnDef.field === 'business') {
      //
      //             return  <Select
      //                 name={props.columnDef.field}
      //                 value={props.value}
      //                 onChange={(e) => onChangeValue(e.target.value, props)}
      //
      //             >
      //                 {/*{business.map((item) => {*/}
      //                 {/*    return (*/}
      //                 {/*        <MenuItem value={item.id}>{item.name}</MenuItem>*/}
      //                 {/*    )*/}
      //                 {/*})}*/}
      //                 {/*{Object.entries(business).map(([key, value], i) => (*/}
      //                 {/*    <MenuItem value={parseInt(key)}>{value}</MenuItem>*/}
      //                 {/*))}*/}
      //                 {businessData.map((item) => {
      //                     return (
      //                         <MenuItem value={item.id}>{item.name}</MenuItem>
      //                     )
      //                 })}
      //             </Select>
      //         } else if (props.columnDef.field === 'status') {
      //
      //             return  <Select
      //                 name={props.columnDef.field}
      //                 value={props.value}
      //                 onChange={(e) => onChangeValue(e.target.value, props)}
      //
      //             >
      //                 {status.map((item) => {
      //                     return (
      //                         <MenuItem value={item.name}>{item.name}</MenuItem>
      //                     )
      //                 })}
      //             </Select>
      //         } else if (props.columnDef.field === 'created'){
      //             return <DatePicker value={moment(props.value).format('DD-MMM-YYYY')} onChange={(date) => onChangeDate(date, props)} />
      //         } else if (props.columnDef.field === 'price') {
      //
      //             return <TextField
      //                 className="editCell"
      //                 style={props.columnDef.type === 'numeric' ? { width: '50px' } : {}}
      //                 type={props.columnDef.type === 'numeric' ? 'text' : 'text'}
      //                 placeholder={props.columnDef.title}
      //                 value={props.value}
      //                 onChange={(e) => onChangeValueInput(e.target.value, props)}
      //                 // onBlur={onBlur}
      //                 InputProps={{ autoFocus: true, style: { fontSize: 13, } }}
      //                 onKeyDown={(e) => {
      //                     if (e.key === "Enter") {
      //                         f(props)
      //                     }
      //                 }}
      //             />
      //         } else {
      //             return <TextField
      //                 className="editCell"
      //                 style={props.columnDef.type === 'numeric' ? { float: 'right', width: '50px' } : {}}
      //                 type={props.columnDef.type === 'numeric' ? 'number' : 'text'}
      //                 // pattern={props.columnDef.type === 'numeric' ? '^\d*(\.\d{0,2})?$' : ''}
      //                 placeholder={props.columnDef.title}
      //                 value={props.value === undefined ? '' : props.value}
      //                 onChange={(e) => onChangeValueInput(e.target.value, props)}
      //                 // onChange={event => props.onChange(event.target.value)}
      //                 // onBlur={()=> onBlur(props)}
      //                 // autoFocus={focusChange}
      //                 InputProps={{ autoFocus: true, style: { fontSize: 13, } }}
      //                 onKeyDown={(e) => {
      //                     if (e.key === "Enter") {
      //                         f(props)
      //                         // dispatch(updatePaymentData(rowId, newData))
      //                         // // props.columnDef.render(props.rowData)
      //                         // // tableRef.current.autofocus()
      //                         // setTimeout(
      //                         //     (dispatch(resetDataPaymentList()),
      //                         //     dispatch(getPaymentList())), 1000);
      //                         // // refresh()
      //                         // console.log('eeeeee', e)
      //
      //
      //                         // e.preventDefault();
      //                         // tableRef.current.onCellEditFinished(rowId, props.columnDef)
      //                     }
      //                 }}
      //
      //                 // onKeyPress={event => {
      //                 //     if (event.key === "Enter") {
      //                 //         event.preventDefault();
      //                 //         tableRef.current.onEditingApproved('update', rowData1, tableRef.current.props.data[rowData1.tableData.id]);
      //                 //     }
      //                 // }}
      //                 // onKeyUp={event => {
      //                 //     if (event.key === "Escape") {
      //                 //         tableRef.current.onEditingCanceled(rowData1.tableData.editing, rowData1);
      //                 //     }
      //                 // }}
      //             />
      //         }
      //         console.log("rowId", rowId)
      //         console.log("props!!!", props)
      //         console.log("columnDef!!!", props.columnDef.field)
      //
      //             // return <span>por</span>
      //             // <select value='site1'>
      //             //     <MenuItem value='site'>site</MenuItem>
      //             //     <MenuItem value='invoice'>invoice</MenuItem>
      //             //     <MenuItem value='invoice'>invoice2</MenuItem>
      //             // </select>
      //
      //     }
      // }
      // }

      // components={{
      //     EditField: props => {
      //         if (props.columnDef.field === 'source') {
      //             return <span>por</span>
      //         } else {
      //             return <input value={props.value}/>
      //         }
      //         console.log("props!!!", props)
      //         console.log("columnDef!!!", props.columnDef.field)
      //
      //             // return <span>por</span>
      //             // <select value='site1'>
      //             //     <MenuItem value='site'>site</MenuItem>
      //             //     <MenuItem value='invoice'>invoice</MenuItem>
      //             //     <MenuItem value='invoice'>invoice2</MenuItem>
      //             // </select>
      //
      //     }
      // }
      // }
      onRowsPerPageChange={ pageSize => {
        setPageSize(pageSize)
      } }
      onPageChange={ page => {
        setPage(page)
      } }
      cellEditable={ {
        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
          return new Promise((resolve, reject) => {


            // setData((prev) =>
            //     prev.map((item, i) => {
            //         if (rowData.tableData.id === i)
            //             item[columnDef.field] = newValue;
            //         return item;
            //         console.log('item: ', item);
            //     })
            // );
            //
            const newData = { [columnDef.field]: newValue }
            const dataUpdate =
              [...data].map((item, i) => {
                if (rowData.tableData.id === item.id)
                  rowData[columnDef.field] = newValue
                return item
              })
            // setData([...dataUpdate, dataUpdate]);


            // console.log('dataUpdate[data.indexOf(rowData)]', index)
            // console.log('dataUpdate[data.indexOf(rowData)][columnDef.field]', dataUpdate[data.indexOf(rowData)][columnDef.field])
            // dataUpdate[data.indexOf(rowData)][columnDef.field] = newValue;

            // setData(dataUpdate, newData)
            // if (columnDef.type === 'numeric') {
            //     parseInt(newData)
            // }
            // dataUpdate[rowData.tableData.id] = newData;
            dispatch(updatePaymentData(rowData.id, newData, paymentType))

            // const data = [...data];
            // rowData[columnDef.field] = newValue
            // // return { data };
            // console.log('dataUpdate: ', dataUpdate);


            // console.log('columnDef: '+ columnDef.field);
            // setData(prevData => [
            //     {
            //         ...prevData[0],
            //         newData
            //     }
            // ])
            setTimeout(resolve, 1000)

          })
        }
      } }

      // editable={{
      //     onRowAdd: newData =>
      //         new Promise((resolve, reject) => {
      //             setTimeout(() => {
      //                 setData([...data, newData]);
      //
      //                 resolve();
      //             }, 1000)
      //         }),
      //     // onRowUpdate: (newData, oldData) =>
      //     //     new Promise((resolve, reject) => {
      //     //         setTimeout(() => {
      //     //             const dataUpdate = [...data];
      //     //             const index = oldData.tableData.id;
      //     //             console.log('index: ', index);
      //     //             dataUpdate[index] = newData;
      //     //             setData([...dataUpdate]);
      //     //
      //     //             const paymentTypeFind = () => {
      //     //                 if(typeof newData.paymentType == 'string') {
      //     //                     paymentType.find(element => element.name === newData.paymentType)
      //     //                 }
      //     //             }
      //     //             // const paymentTypeFind = paymentType.find(element => element.name === newData.paymentType)
      //     //
      //     //
      //     //             // console.log('newData.paymentType', newData.paymentType)
      //     //             // console.log('paymentTypeFind', paymentTypeFind)
      //     //
      //     //             dispatch(updatePaymentData(newData.id, newData, paymentType))
      //     //             resolve();
      //     //         }, 1000)
      //     //     }),
      //     // onRowDelete: oldData =>
      //     //     new Promise((resolve, reject) => {
      //     //         setTimeout(() => {
      //     //             const dataDelete = [...data];
      //     //             const index = oldData.tableData.id;
      //     //             dataDelete.splice(index, 1);
      //     //             setData([...dataDelete]);
      //     //
      //     //             resolve();
      /*    //         }, 1000)*/
      /*    //     }),*/
      //
      // }}
    />
  )
}

export default TablePayments