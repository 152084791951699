import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";

export const GET_STATISTICS = "GET_STATISTICS";
const API_URL = environment.baseUrl;

export const getStatistics = (Date1, Date2) => {
    return (dispatch) => {
        // dispatch(setIsLoading(true));
        axios
            .get(API_URL + '/statistics', {
                headers:
                    {
                        'Authorization': authHeader().Authorization
                    },
                params: {
                    startDate: Date1,
                    endDate: Date2,
                }
            })
            .then((res) => {
                dispatch({ type: GET_STATISTICS, payload: res.data });
                // dispatch(setIsLoading(false));
                console.log("statistics:", res.data)
            })
            .catch((err) => {
                console.log("err", err)
                // dispatch(setError(err))
                // dispatch(setIsLoading(false));
            });
    };
};

// export const resetDataStatistics = () => {
//     return (dispatch) => {
//         dispatch({type: GET_STATISTICS, payload: {}});
//     };
// };