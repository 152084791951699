import {GET_USERS} from "./actions/getUserAll";
import {UPDATE_EXECUTOR} from "./actions/updateExecutor";
import {ADD_NEW_EXECUTOR} from "./actions/addNewExecutor";
import {ADD_NEW_MANAGER} from "./actions/addNewManager";
import {GET_USER} from "./actions/getUserId";
import {SET_PROVIDER_PASS} from "./actions/saveProviderPass";
import {UPDATE_MANAGER} from "./actions/updateManager";

const initStore = {
    userData: [],
    userDataOne: {},
    executorData: {},
    managerData: {},
    newExecutor: {},
    newManager: {},
    providerPass: {},
};

export const userReducer = (initialState = initStore, action) => {
    switch (action.type) {
        case GET_USERS:
            return {...initialState, userData: action.payload};
        case GET_USER:
            return {...initialState, userDataOne: action.payload};
        case UPDATE_EXECUTOR:
            return {...initialState, executorData: action.payload};
        case UPDATE_MANAGER:
            return {...initialState, managerData: action.payload};
        case ADD_NEW_EXECUTOR:
            return {...initialState, newExecutor: action.payload};
        case ADD_NEW_MANAGER:
            return {...initialState, newManager: action.payload};
        case SET_PROVIDER_PASS:
            return {...initialState, providerPass: action.payload};
        default:
            return initialState;
    }
};
