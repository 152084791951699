import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";

export const DOWNLOAD_FILE_PAYMENTS = "DOWNLOAD_FILE_PAYMENTS";
const API_URL = environment.baseUrl;

export const downloadXlsxPayments = () => {
    const data = {
    }
    return (dispatch) => {
        // dispatch(setIsLoading(true));
        axios
            .post(API_URL + '/payments/xlsx', data, {
                headers:
                    {
                        'Content-Disposition': "attachment; filename=Payments.xlsx",
                        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'Authorization': authHeader().Authorization
                    },
                responseType: 'arraybuffer',


            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Payments.xlsx');
                document.body.appendChild(link);
                link.click();
                // dispatch({ type: DOWNLOAD_FILE_PAYMENT, payload: res.data });
                // dispatch(setIsLoading(false));
                console.log("data:", res)
            })
            .catch((err) => {
                console.log("err", err)
                // dispatch(setError(err))
                // dispatch(setIsLoading(false));
            });
    };
};