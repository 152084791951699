import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable from '@material-table/core'
import { Link } from 'react-router-dom'
import { updateOrderData } from '../store/reducers/orderReducer/actions/updateOrderData'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Tooltip from '@material-ui/core/Tooltip'
import moment from 'moment'
import { getProviderOrders, resetProviderOrdersList } from '../store/reducers/orderReducer/actions/getProviderOrders'
import isEmpty from 'lodash/isEmpty'
import DatePicker from 'react-datepicker'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import ModalBasic from '../ModalBasic'
import TableCell from '@material-ui/core/TableCell'
import { updateOrderExecutor } from '../store/reducers/orderReducer/actions/updateOrderExecutor'
import { getOrdersByProvider } from '../store/reducers/orderReducer/actions/getOrdersByProvider'

const API_URL = window.location.origin

const TableOrdersProvider = ({
                               id, serviceData, isManager, page, pageSize, setPage, setPageSize, filterData, setFilterData,
                               filterSave, setFilterSave, columnsHide, setColumnsHide
                             }) => {
  const tableRef = React.createRef()

  const dispatch = useDispatch()

  const { providerOrdersData } = useSelector(({ order }) => order)
  const { ordersByProviderData } = useSelector(({ order }) => order)
  const { isLoading } = useSelector(({ service }) => service)

  let
    totalProvider,
    ordersProvider

  if (isManager) {
    const { total, provider } = providerOrdersData
    totalProvider = total
    ordersProvider = provider.orders
  } else {
    const { total, orders } = ordersByProviderData
    totalProvider = total
    ordersProvider = orders
  }

  console.log('ordersProvider',ordersProvider)
  // if(page !== 0 && currentPage > totalItem) {
  //     setPage(totalPage - 1)
  // }
  console.log('orders', ordersProvider)
  const [data, setData] = useState(ordersProvider)


  const { updateOrderExec } = useSelector(({ order }) => order)
  const statusRead = [
    'draft',
    'pending payment',
    'active',
    'add details',
    'in work',
    'finished',
    'report sent',
    'refilling',
    'refilled',
    'cancelled',
    'archived',
    'paused'
  ]
  // const statusFilter = [
  //     'pending payment',
  //     'active',
  //     'add details',
  //     'in work',
  //     'finished',
  //     'report sent',
  //     'refilling',
  //     'refilled',
  //     'cancelled',
  //     'archived',
  //     'paused',
  // ];
  const status = [
    'active',
    'add details',
    'in work',
    'finished',
    'refilling',
    'refilled',
    'report sent',
    'paused',
    'archived'
  ]


  const statusProvider = [
    1, 2, 3, 4, 5
  ]
  const [value, setValue] = React.useState(null)

  const [rowId, setRowId] = useState()

  const [activeFilter, isActiveFilter] = useState(false)

  const { orderDataList } = useSelector(({ order }) => order)
  const { userData } = useSelector(({ user }) => user)

  const [created, setCreated] = useState()

  const allItemLabel = { All: totalProvider }


  const clipboardClick = (rowData) => {
    navigator.clipboard.writeText(API_URL + `/orders/order/${ rowData.hash }`)
  }
  const clipboardClickLink = (rowData) => {
    navigator.clipboard.writeText(`${ rowData.appLink }`)
  }

  const arrServiceProviders = userData.filter(user => user.role === 'executor')

  const [serviceProvider, setServiceProvider] = useState({})

  const serviceIos = serviceData.filter(item => item.platform === 'IOS')
  const serviceAndroid = serviceData.filter(item => item.platform === 'android')

  useEffect(() => {
    arrServiceProviders.map(row => serviceProvider[row.id] = row.login)
    setServiceProvider(serviceProvider)

  }, [])

  const onChangeDate = (date, props) => {
    setRowId(props.rowData.id)

    // const linkCheckerFind = orderDataList.find(item => item.id === props.rowData.id)

    function addDateMin(date, minutes) {
      return new Date(date.getTime() + minutes * 60000)
    }

    // setCreated(date)
    // const newValueCheck = props.columnDef.field == 'price' ? parseInt(value) : value
    const newData = ({ [props.columnDef.field]: addDateMin(date, 180) })

    // dispatch(updatePaymentData(rowId, newData))
    // new KeyboardEvent("keydown", { keyCode: "Tab", which: 9 })
    // new KeyboardEvent("keydown", { keyCode: "Enter", which: 13 })
    dispatch(updateOrderData(props.rowData.id, newData))
    // setTimeout(() => {
    //     // dispatch(resetProviderOrdersList());
    //     // dispatch(getProviderOrders());
    // }, 1000)

    // props.autofocus = false
  }

  const onChangeValue = (value, props) => {
    // setRowId(props.rowData.id)
    props.onChange(value)
    console.log('value for change', value)
    const newValueCheck = props.columnDef.type === 'numeric' ? parseInt(value) : value
    const newData = ({ [props.columnDef.field]: newValueCheck })

    isManager ? dispatch(updateOrderData(props.rowData.id, newData)) : dispatch(updateOrderExecutor(props.rowData.id, newData))
    const dataUpdate =
      [...data].map((item, i) => {
        if (props.rowData.tableData.id === item.id)
          props.rowData[props.columnDef.field] = newValueCheck
        return item
      })
    // setData([...dataUpdate]);

    // dispatch(updateOrderData(props.rowData.id, newData))
    // setTimeout(() => {
    //     // dispatch(resetDataOrdersList());
    //     // dispatch(getOrdersList());
    // }, 1000)
  }
  const valueFilter = (props) => {
    if (props.columnDef.tableData.filterValue !== undefined) {
      // filterData &&
      return props.columnDef.tableData.filterValue
      // return props.onFilterChanged(props.columnDef.tableData.id, props.columnDef.tableData.filterValue)
    } else if (filterSave && filterSave[props.columnDef.field] && filterSave[props.columnDef.field] !== null) {
      // return filterSave[props.columnDef.field]
      return props.onFilterChanged(props.columnDef.tableData.id, filterSave[props.columnDef.field])
    } else {
      return undefined
      // filterSave['GEO'] !== null
    }
  }

  const onChangeFilter = (e, props) => {
    // setIsLoading(true)
    props.onFilterChanged(props.columnDef.tableData.id, e.target.value)
    const filterSelect = {
      [e.target.id]: [e.target.value]
    }
    const filterToSave = {
      [e.target.id]: e.target.value
    }

    // if(e.target.value === '') {
    //     setFilterData('')
    //     setFilterSave('')
    //     localStorage.removeItem('filterData')
    //     localStorage.removeItem('filterValue')
    //     dispatch(getProviderOrders(id, page, pageSize, ''))
    // } else {

    // const setFilterValue = () => {
    setFilterSave(prevState => ({
      ...prevState, ...filterToSave
    }))
    setFilterData(prevState => ({
      ...prevState, ...filterSelect
    }))
    // }

    // }

  }

  // const activeClassFilter = (props) => {
  //     if(isActiveFilter === true && props.columnDef.tableData.filterValue !== ''){
  //         return 'withFilter'
  //     } else if(isActiveFilter === true && filterData && filterSave[props.columnDef.field]){
  //         return 'withFilter'
  //     } else if(props.columnDef.tableData.filterValue === '') {
  //         return ''
  //     }
  //
  // }
  // const [filterToogle, setFilterToogle] = useState(false)
  //
  // useEffect(() => {
  //     if(!isEmpty(filterData)) {
  //         isActiveFilter(true)
  //     }
  // }, [filterData]);
  //
  // useEffect(() => {
  //     const filterActiveChange = () => {
  //         setFilterToogle(false)
  //     }
  //
  // }, [filterToogle]);


  const [columns, setColumns] = useState([
    {
      field: 'created', title: 'Date', width: '130px', cellStyle: { width: '130px', textAlign: 'center', margin: '0 auto' },
      editable: isManager ? 'always' : 'never',
      render: rowData => (<div>{ moment(rowData.created).format('DD-MMM-YYYY') }</div>),
      editComponent: props => (
        <DatePicker selected={ created } onChange={ (date) => onChangeDate(date, props) } />
      ),
      filterComponent: (props) => {
        return (
          <TableCell className={ isActiveFilter && (filterData && filterData[props.columnDef.field]) ? 'withFilter' : '' }>
            <ModalBasic setFilterData={ setFilterData } filterData={ filterData } isActiveFilter={ isActiveFilter }
                        filterSave={ filterSave } setFilterSave={ setFilterSave } props={ props } />
          </TableCell>)
      }
    },
    {
      field: 'id',
      title: 'Order Number',
      type: 'numeric',
      editable: 'never',
      width: '130px',
      cellStyle: { width: '130px' },
      render: rowData => (
        <div className="hashCell"><Link to={ { pathname: `/orders/order/${ isManager ? rowData.id : rowData.hash }` } }>{ rowData.id }</Link>
          <span className="spanCopy" onClick={ (e) => clipboardClick(rowData) }><ContentCopyIcon
            fontSize="small" className="copyLink" /></span></div>),
      filterComponent: (props) => {
        return (
          <TableCell className={ props.columnDef.tableData.filterValue ? 'withFilter' : '' }>
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'custom_id', title: 'Custom Id', type: 'numeric', width: '80px', editable: 'never',
      hidden: true,
      hiddenByColumnsButton: false,
      cellStyle: { whiteSpace: 'nowrap', width: '100%', textAlign: 'center' },
      emptyValue: <span className="emptyValue">Empty</span>,
      render: rowData => (
        rowData.custom_id === '' ? <span className="emptyValue">Empty</span> : rowData.custom_id
      ),
      filterComponent: (props) => {
        return (
          <TableCell className={ props.columnDef.tableData.filterValue ? 'withFilter' : '' }>
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    // {   field: 'hash', title: 'Link to order', editable: 'never',
    //     render: rowData => (<span className='spanCopy' onClick={clipboardClick(rowData)} ><ContentCopyIcon fontSize="small" className='copyLink' /></span>)
    // },
    {
      field: 'serviceCost', title: 'O.Service Cost', type: 'numeric', editable: 'never', width: '90px',
      render: rowData => (<div className="cell">{ rowData.serviceCost }</div>),
      cellStyle: { textAlign: 'center', width: '90px' },
      filterComponent: (props) => {
        return (
          <TableCell className={ props.columnDef.tableData.filterValue ? 'withFilter' : '' }>
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'servicePlatform',
      title: 'Platform',
      width: '70px',
      editable: 'never',
      cellStyle: { overflow: 'hidden' },
      filtering: false,
      render: rowData => (<div>{ rowData.service.platform }</div>)
    },
    {
      field: 'service',
      title: 'Service Type',
      width: '70px',
      filtering: false,
      editable: isManager ? 'always' : 'never',
      cellStyle: { overflow: 'hidden' },
      render: rowData => (<div>{ rowData.service.name }</div>),
      // render: rowData => (
      //     <FormControl sx={{ m: 1, width: '100%' }}>
      //     {/*<label htmlFor="grouped-select">{rowData.service.name}</label>*/}
      //     <select
      //             name='service'
      //             id='grouped-select'
      //             value={rowData.service.name}
      //             // label={rowData.service.name}
      //     >
      //
      //         <optgroup label="IOS">
      //             {serviceIos.map((item, index) => {
      //                 return (
      //                     <option value={item.name} selected={rowData.service.id === item.id}>{item.name}</option>
      //                 )
      //             })}
      //         </optgroup>
      //         <optgroup label="Android">
      //             {serviceAndroid.map((item, index) => {
      //                 return (
      //                     <option value={item.name} selected={rowData.service.id === item.id }>{item.name}</option>
      //                 )
      //             })}
      //         </optgroup>
      //     </select>
      //     </FormControl>
      // ),
      editComponent: props => (
        <FormControl sx={ { m: 1, width: '100%' } }>
          <select defaultValue="" id="grouped-native-select"
                  name={ props.columnDef.field }
                  value={ props.value }
                  onChange={ (e) => onChangeValue(parseInt(e.target.value), props) }
          >
            <option label="None" value="None" />
            <optgroup label="IOS">
              { serviceIos.map((item, index) => {
                return (
                  <option value={ item.id } key={ item.id }>{ item.name }</option>
                )
              }) }
            </optgroup>
            <optgroup label="Android">
              { serviceAndroid.map((item, index) => {
                return (
                  <option value={ item.id } key={ item.id }>{ item.name }</option>
                )
              }) }
            </optgroup>
          </select>
        </FormControl>
      )
    },
    {
      field: 'appLink', title: 'App Link', width: isManager ? '170px' : '50px', editable: isManager ? 'always' : 'never',
      filtering: isManager ? true : false,
      render: rowData => (<div className="tooltipCell">

        { isManager
          ? <Tooltip title={ rowData.appLink } placement="bottom">
            <div className="appLinkCell">{ rowData.appLink }</div>
          </Tooltip>
          : <span className="spanCopy" name={ rowData.appLink } onClick={ (e) => clipboardClickLink(rowData) }><ContentCopyIcon
            fontSize="small" className="copyLink" /></span> }


      </div>),
      cellStyle: { whiteSpace: isManager ? 'nowrap' : 'inherit', overflow: isManager ? 'hidden' : 'inherit', textAlign: isManager ? 'left' : 'center' }
    },
    {
      field: 'appId',
      title: 'App Id',
      width: '100px',
      cellStyle: { fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden' },
      hidden: true,
      hiddenByColumnsButton: false,
      render: rowData => (<Tooltip title={ rowData.application?.appId ? rowData.application.appId : 'none' } placement="bottom">
          <div className="cell">{ rowData.application?.appId ? rowData.application.appId : 'none' }</div>
        </Tooltip>
      ),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && props.columnDef.tableData.filterValue || (filterSave && filterSave[props.columnDef.field]) ? 'withFilter' : '' }
          >
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'trackId', title: 'TrackId', width: '60px',
      hidden: true,
      hiddenByColumnsButton: false,
      render: rowData => (
        rowData.application.trackId === '' ? <span className="emptyValue">Empty</span> : rowData.application.trackId
      )
    },
    // { field: 'servicePlatform', title: 'Platform', width: '80px',
    //     render: rowData => ( <div >{rowData.service.platform && rowData.service.platform}</div> ),
    // },
    // { field: 'serviceType', title: 'Service Type', width: '80px', cellStyle: {overflow: 'hidden'},
    //     render: rowData => ( <div >{rowData.service.name && rowData.service.name}</div> ),
    // },
    {
      field: 'quantity',
      title: 'Q-ty',
      type: 'numeric',
      width: '100px',
      cellStyle: { textAlign: 'center', width: '90px' },
      editable: isManager ? 'always' : 'never',
      render: rowData => (<div>{ rowData.quantity }</div>),
      filterComponent: (props) => {
        return (
          <TableCell className={ props.columnDef.tableData.filterValue ? 'withFilter' : '' }>
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'perDay',
      title: 'Speed per day',
      type: 'numeric',
      width: '100px',
      cellStyle: { textAlign: 'center', width: '100px' },
      editable: isManager ? 'always' : 'never',
      // defaultFilter: filterSave && filterSave['perDay'],
      render: rowData => (<div>{ rowData.perDay }</div>),
      filterComponent: (props) => {
        return (
          <TableCell className={ props.columnDef.tableData.filterValue ? 'withFilter' : '' }>
            <TextField
              type={ 'number' }
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'GEO', title: 'GEO(old)', width: '60px', editable: 'never', cellStyle: { textAlign: 'center' },
      emptyValue: <span className="emptyValue">Empty</span>,
      render: rowData => (
        rowData.GEO === null ? 'none' : rowData.GEO
      ),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && props.columnDef.tableData.filterValue || (filterSave && filterSave[props.columnDef.field]) ? 'withFilter' : '' }
          >
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'location', title: 'Location', width: '80px', cellStyle: { textAlign: 'center', width: '80px' }, editable: 'never',
      // emptyValue: <span className="emptyValue">Empty</span>,
      render: rowData => (
        rowData.location === null ? 'none' : rowData.location
      ),
      // render: rowData => (<div className='cell' >{rowData.GEO}</div> ),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && props.columnDef.tableData.filterValue || (filterSave && filterSave[props.columnDef.field]) ? 'withFilter' : '' }
          >
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'language', title: 'Lang', width: '60px', editable: 'never',
      emptyValue: <span className="emptyValue">Empty</span>,
      hidden: true,
      hiddenByColumnsButton: false,
      render: rowData => (
        rowData.language === '' ? <span className="emptyValue">Empty</span> : rowData.language
      ),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && props.columnDef.tableData.filterValue || (filterSave && filterSave[props.columnDef.field]) ? 'withFilter' : '' }
          >
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      title: 'Status',
      field: 'status', width: '140px',
      render: rowData => (<Tooltip title={ rowData.status } placement="bottom">
          <select
            name="status"
            defaultValue={ rowData.status }
            // onChange={(e) => onChangeValue(e.target.value, props)}

          >
            { statusRead.map((item, index) => (
              item === 'add details' || item === 'in work' || item === 'finished' || item === 'refilling' || item === 'refilled' || item === 'paused' ? (
                <option value={ item } key={ index }>{ item }</option>
              ) : (
                <option value={ item } hidden>{ item }</option>
              )
            )) }
          </select>
        </Tooltip>
      ),
      editComponent: props => (

        <select
          name="status"
          value={ props.value }
          onChange={ (e) => onChangeValue(e.target.value, props) }

        >

          { statusRead.map((item, index) => (
            item === 'add details' || item === 'in work' || item === 'finished' || item === 'refilling' || item === 'refilled' || item === 'paused' ? (
              <option value={ item } key={ index }>{ item }</option>
            ) : (
              <option value={ item } hidden>{ item }</option>
            )
          )) }
        </select>
        // <Autocomplete
        /*{...defaultProps}*/
        // name={props.columnDef.field}
        // value={props.value}
        // onChange={(e) => onChangeValue(e.target.value, props)}
        // id="blur-on-select"
        // blurOnSelect
        // renderInput={(params) => (
        //     <TextField {...params} variant="standard" />
        // )}
        // />
      ),
      cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden' },
      filterComponent: (props) => {
        return (
          <TableCell className={ props.columnDef.tableData.filterValue ? 'withFilter' : '' }>
            <select
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
              // multiple='multiple'
              // size={1}

            >
              <option value={ '' } hidden></option>
              { status.map((item, index) => (
                <option value={ item } key={ index }>{ item }</option>
              )) }
            </select>

          </TableCell>
        )
        // filterSave && filterSave[columnDef.field] ? filterSave[columnDef.field] : ''
      }
      // lookup: statusRead
      // lookup: { 'pending payment': 'pending payment', active: 'active', 'add details': 'add details', 'in work': 'in work',
      //     finished:'finished', 'report sent': 'report sent', refilling: 'refilling', refilled:'refilled', cancelled:'cancelled',
      //     archived: 'archived', paused: 'paused'
      // },
    },

    {
      field: 'serviceProviderStatus',
      title: 'Provider Status',
      type: 'numeric',
      cellStyle: { textAlign: 'center', width: '100px' },
      width: '100px',
      render: rowData => (<select
          name="status"
          defaultValue={ rowData.serviceProviderStatus }
          // onChange={(e) => onChangeValue(e.target.value, props)}
        >
          { statusProvider.map((item, index) => {
            return (
              <option value={ item } key={ index }>{ item }</option>
            )
          }) }
        </select>
      ),
      editComponent: props => (
        <select
          name="status"
          value={ props.value }
          onChange={ (e) => onChangeValue(e.target.value, props) }
        >
          { statusProvider.map((item, index) => {
            return (
              <option value={ item } key={ index }>{ item }</option>
            )
          }) }
        </select>
      ),
      filterComponent: (props) => {
        return (
          <TableCell className={ props.columnDef.tableData.filterValue ? 'withFilter' : '' }>
            <select
              name="status"
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            >
              <option value={ '' } hidden></option>
              { statusProvider.map((item, index) => {
                return (
                  <option value={ item } key={ index }>{ item }</option>
                )
              }) }
            </select>
          </TableCell>
        )
      }
    },
    {
      field: 'progress',
      title: 'Progress',
      editable: 'never',
      cellStyle: { textAlign: 'center', width: '100px' },
      width: '100px',
      render: rowData => (<div>{ rowData.progress }/{ rowData.quantity }</div>),
      filtering: false
    },
    {
      field: 'appLinkFull', title: 'App Link', editable: 'never',
      hidden: true,
      hiddenByColumnsButton: false,
      render: rowData => (
        <Tooltip title={ rowData.appLink } placement="bottom">
          <div className="appLinkCell">{ rowData.appLink }</div>
        </Tooltip>),
      cellStyle: { overflow: 'hidden' },
      filtering: false
      // filterComponent: () => <input placeholder="use filter with All Rows" />,

    }
    // { field: 'provider', title: 'Provider', type:'numeric', cellStyle: {width: '100px'},
    //     render: rowData => (<Tooltip title={rowData.provider} placement="bottom">
    //             {/*<div className='cell' >{rowData.provider}</div>*/}
    //             <select
    //                 name='provider'
    //                 value={rowData.provider}
    //                 // onChange={(e) => onChangeValue(e.target.value, props)}
    //
    //             >
    //                 {/*{serviceProvider.find(item =>*/}
    //                 {/*        item.name*/}
    //                 {/*)}*/}
    //                 {Object.entries(serviceProvider).map(([key, value], i) => (
    //                     <option value={key}>{value}</option>
    //                 ))}
    //             </select>
    //         </Tooltip>
    //     ),
    //     editComponent: props => (
    //         <select
    //             name={props.columnDef.field}
    //             value={props.value}
    //             onChange={(e) => onChangeValue(e.target.value, props)}
    //
    //         >
    //             {/*{serviceProvider.find(item =>*/}
    //             {/*        item.name*/}
    //             {/*)}*/}
    //             {Object.entries(serviceProvider).map(([key, value], i) => (
    //                 <option value={parseInt(key)}>{value}</option>
    //             ))}
    //         </select>
    //     )
    // },
  ])

  // useEffect(() => {
  //     console.log("reload")
  // }, [data]);
  // useEffect(() => {
  //     console.log("reload")
  // }, [orders]);

  useEffect(() => {
    if (!isEmpty(filterData)) {
      isActiveFilter(true)
    }
  }, [filterData])


  useEffect(() => {
    // dispatch(setIsLoading(true));
    isManager ? dispatch(getProviderOrders(id, page, pageSize, filterData)) : dispatch(getOrdersByProvider(page, pageSize, filterData))
  }, [page, pageSize, filterData, updateOrderExec])


  useEffect(() => {
    setData(ordersProvider)
    // setIsLoading(false)
  }, [providerOrdersData, ordersByProviderData])

  // const filterChange = (filters) => {
  //     filters.map(item => filterData[item.column.field] = {in: item.value})
  //     setFilterData(filterData)
  // }

  const actions = [
    {
      icon: () => <span className={ activeFilter ? 'btnFilterActive' : 'btnFilter' }>Clear Filters</span>,
      tooltip: activeFilter ? 'Clear all filters' : '',
      isFreeAction: true,
      // iconProps: {  fontSize: "small", color: "primary"  },
      // onClick: (event) => {
      //     setFilterData('')
      //     dispatch(resetProviderOrdersList())
      //     // localStorage.removeItem("filters");
      //     console.log('event', event)// set new key causing remount
      // }
      onClick: (event) => {
        isActiveFilter(false)
        tableRef.current.dataManager.columns.map(item => {
          console.log('tableReff', tableRef.current)
          tableRef.current.onFilterChange(item.tableData.id, '')
        })

        setFilterData(null)
        setFilterSave(null)
        // localStorage.removeItem('filterData')
        // localStorage.removeItem('filterValue')
        // isActiveFilter(false)
        isManager ? dispatch(getProviderOrders(id, page, pageSize, '')) : dispatch(getOrdersByProvider(page, pageSize, ''))
        // dispatch(getProviderOrders(id, page, pageSize, ''))
        // setColumns(columns)
      }
    }
  ]


  // const FilterRow = ({onChangeFilter})=> (
  //     <TableRow>
  //         {columns && columns.map((col) => {
  //             // console.log("col", col)
  //             if (col.field !== 'progress' && col.field !== 'servicePlatform' && col.field !== 'service') {
  //                 return (
  //                     <TableCell>
  //                         <TextField
  //                             // placeholder="filter"
  //                             id={col.field}
  //                             value={filterSave && filterSave[col.field] ? filterSave[col.field] : ''}
  //                             onChange={onChangeFilter}
  //                             // onBlur={onKeyEnter}
  //                         />
  //                     </TableCell>
  //                 );
  //             } else {
  //                 return (<TableCell>
  //
  //                 </TableCell>)
  //             }
  //         })}
  //     </TableRow>
  // )
  return (
    <MaterialTable
      tableRef={ tableRef }
      title="Orders"
      columns={ columns }
      actions={ actions }
      data={ data }
      page={ page }
      isLoading={ isLoading }
      totalCount={ totalProvider }
      // onFilterChange={(filters, event) => {
      //     isActiveFilter(true)
      //     const filterSelect = {}
      //     const filterToSave = {}
      //     console.log('onFilterChangeEVENT1111', event);
      //     filters.map((item , index) => {
      //         console.log('onFilterChangeIndex', index);
      //         console.log('onFilterChangeITEM', item);
      //         console.log('onFilterChangeEVENT222', event);
      //
      //         index = item.column.field
      //         // if(item.value){
      //         const filterValue = {in: [item.value]}
      //             filterSelect[index] = filterValue
      //
      //         // if(filters.some((element) => console.log("OBJECt VALUE", filters))) {
      //             // Object.values(element.column) !== item.column.field)) {
      //             // filterToSave[item.column.field] = item.value
      //             filterToSave[item.column.field] = item.value
      //
      // })
      //         setFilterData(prevState => ({
      //             ...prevState, ...filterSelect
      //         }))
      //     setFilterSave(prevState => ({
      //         ...prevState, ...filterToSave
      //     }))
      //
      //     console.log('onFilterChange', filters);
      //
      // }}
      options={ {
        tableLayout: 'fixed',
        filtering: true,
        columnsButton: true,
        pageSize: pageSize,

        paginationPosition: 'both',
        pageSizeOptions: [30, 60, 90, totalProvider],
        padding: 'dense',
        search: false,
        addRowPosition: 'first',
        headerStyle: {
          backgroundColor: '#DEF3FA',
          color: 'Black'
        },
        rowStyle: {
          fontSize: '12px',
          paddingLeft: 5,
          paddingRight: 5
        }
      } }
      onRowsPerPageChange={ pageSize => {
        setPageSize(pageSize)
      } }
      onPageChange={ page => {
        setPage(page)
      } }
      // onChangeColumnHidden={(column, hidden) =>{
      //     const hideCol = [
      //         {
      //             [column]: hidden
      //         },
      //     ]
      //     console.log('column', column)
      //     console.log('hidden', hidden)
      //     console.log('hidden', hideCol)
      //     setColumnsHide(hideCol)
      // }
      //
      //     // setColumnsHide(column)
      // }

      // components={{
      //     EditField: fieldProps => {<MTableEditField { [...fieldProps], value: fieldProps.value || '' } />}
      //     // {
      //     //     const {
      //     //         columnDef: { lookup },
      //     //     } = fieldProps
      //     //     if (lookup) {
      //     //         console.info(fieldProps)
      //     //         return <Autocomplete {...fieldProps} />
      //     //     } else {
      //     //         return <MTableEditField {...{ ...fieldProps, value: fieldProps.value || '' }} />
      //     //     }
      //     // },
      // }}

      cellEditable={ {
        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
          return new Promise((resolve, reject) => {
            // const dataUpdate = [...data];
            // dataUpdate[rowData.tableData.id][columnDef.field] = newValue
            const newData = { [columnDef.field]: newValue }

            const dataUpdate =
              [...data].map((item, i) => {
                if (rowData.tableData.id === item.id)
                  rowData[columnDef.field] = Number(newValue)
                return item
              })
            // setData([...dataUpdate]);
            // const dataCopy = [...data];
            // console.log('dataCopy2', dataCopy)
            // const dataUpdate = () => {
            //     dataCopy.map((item, i) => {
            //         if (rowData.tableData.id === item.id)
            //             item[columnDef.field] = newValue;
            //         return item;
            //         console.log('item: ', item);
            //     })
            // }
            // console.log('dataUpdate2', dataUpdate)
            // setData(dataUpdate(rowData, columnDef, newValue));

            // setData((prev) =>
            //     prev.map((item, i) => {
            //         if (rowData.tableData.id === i)
            //             item[columnDef.field] = newValue;
            //         return item;
            //         console.log('item', item)
            //     })
            // );
            dispatch(updateOrderData(rowData.id, newData))
            // updateParentState = (data, element) => {
            //     this.setState({
            //         ...this.state,
            //         [element]: [...this.state[element], data]
            //     })
            // }

            // console.log('dataUpdate: ', dataUpdate);
            // setData([...dataUpdate]);

            // console.log('columnDef: '+ columnDef.field);

            setTimeout(resolve, 1000)

          })
        }
      } }
    />
  )
}

export default TableOrdersProvider