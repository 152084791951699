import authHeader from "../../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../../environment";

export const GET_USER = "GET_USER";
const API_URL = environment.baseUrl;

export const getUser = (id) => {
    console.log('getUserID22222', id)
    return (dispatch) => {
        // dispatch(setIsLoading(true));
        axios
            .get(API_URL + `/user/get/${id}`, { headers: authHeader() })
            .then((res) => {
                dispatch({ type: GET_USER, payload: res.data });
                // dispatch(setIsLoading(false));
                console.log("dataUSER:", res.data)
            })
            .catch((err) => {
                console.log("err", err)
                // dispatch(setError(err))
                // dispatch(setIsLoading(false));
            });
    };
};

export const resetUser = () => {
    return (dispatch) => {
        dispatch({type: GET_USER, payload: {}});
    };
};