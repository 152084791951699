import { UPDATE_PROVIDER_HASH_STATUS } from './updateStatus'
import { HASH_LINK_CHECK } from './providerLinkCheck'
import { PROVIDER_CHECK_ANDROID_APP } from './providerCheckAndroidApp'
import { PROVIDER_CHECK_IOS_APP } from './providerCheckIosApp'
import { PROVIDER_CHECK_SINGLE_ANDROID_REVIEW } from './prCheckSingleAndroisReview'
import { PROVIDER_CHECK_SINGLE_IOS_REVIEW } from './prCheckSingleIosReview'
import { PROVIDER_ALL_REVIEWS_IOS_CHECK } from './prAllReviewsIosCheck'
import { PROVIDER_ALL_REVIEWS_ANDROID_CHECK } from './prAllReviewsAndroidCheck'
import { DOWNLOAD_FILE_SINGLE_ORDER_PROVIDER } from './downloadReviewsProvider'


const state = {
  changeStatusData: {},
  checkLinkData: {},
  prAndroidAppCheck: {},
  prIosAppCheck: {},
  prCheckSingleAndroidReview: {},
  prCheckSingleIosReview: {},
  prReviewsAndroidCheck: {},
  prReviewsIosCheck: {},
  downloadFileSingleOrderProv: {}
}

export const providerHashReducer = (initialState = state, action) => {
  switch (action.type) {
    case UPDATE_PROVIDER_HASH_STATUS:
      return { ...initialState, changeStatusData: action.payload }
    case PROVIDER_CHECK_ANDROID_APP:
      return { ...initialState, prAndroidAppCheck: action.payload }
    case PROVIDER_CHECK_IOS_APP:
      return { ...initialState, prIosAppCheck: action.payload }
    case PROVIDER_CHECK_SINGLE_ANDROID_REVIEW:
      return { ...initialState, prCheckSingleAndroidReview: action.payload }
    case PROVIDER_CHECK_SINGLE_IOS_REVIEW:
      return { ...initialState, prCheckSingleIosReview: action.payload }
    case PROVIDER_ALL_REVIEWS_ANDROID_CHECK:
      return { ...initialState, prReviewsAndroidCheck: action.payload }
    case PROVIDER_ALL_REVIEWS_IOS_CHECK:
      return { ...initialState, prReviewsIosCheck: action.payload }
    case HASH_LINK_CHECK:
      return { ...initialState, checkLinkData: action.payload }
    case DOWNLOAD_FILE_SINGLE_ORDER_PROVIDER:
      return { ...initialState, downloadFileSingleOrderProv: action.payload }
    default:
      return initialState
  }
}