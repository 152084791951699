import {ADD_COMMENT_ORDER} from "./actions/actionsCommentOrder";
import {ADD_COMMENT_PAYMENT} from "./actions/actionsCommentPay";
import {ADD_COMMENT_PROVIDER} from "./actions/commentProvider";

const state = {
    commentDataOrder: [],
    commentDataPayment: {},
    commentDataProvider: {}
};

export const commentReducer = (initialState = state, action) => {
    switch (action.type) {
        case ADD_COMMENT_ORDER:
            return {...initialState, commentDataOrder: action.payload};
        case ADD_COMMENT_PAYMENT:
            return {...initialState, commentDataPayment: action.payload};
        case ADD_COMMENT_PROVIDER:
            return {...initialState, commentDataProvider: action.payload};
        default:
            return initialState;
    }
};