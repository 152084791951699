import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";

export const ADD_NEW_PAYMENT = 'ADD_NEW_PAYMENT';
const API_URL = environment.baseUrl;

export const addNewPayment = (data) => {

    return (dispatch) => {
        axios
            .post(API_URL + '/payments/create', data, { headers: authHeader() })
            .then((res) => {
                console.log("res", res)
                dispatch({ type: ADD_NEW_PAYMENT, payload: res });
            })
            .catch((err) => {
                console.log("err", err)
            });
    };
};