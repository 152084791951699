export const VENDOR = 'google'

export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 12;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 260,
    },
  },
};