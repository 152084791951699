import { GET_PROXY } from './actions/getProxy'
import { ADD_PROXY } from './actions/addProxy'
import { UPDATE_PROXY } from './actions/updateProxy'
import { DELETE_PROXY } from './actions/deleteProxy'
import { GET_STATS_PROXY } from './actions/getStatsProxy'

const initStore = {
  proxyData: [],
  newProxy: {},
  updatedProxy: {},
  deletedDataProxy: {},
  statsProxy: []
}

export const proxyReducer = (initialState = initStore, action) => {
  switch (action.type) {
    case GET_PROXY:
      return { ...initialState, proxyData: action.payload }
    case ADD_PROXY:
      return { ...initialState, newProxy: action.payload }
    case UPDATE_PROXY:
      return { ...initialState, updatedProxy: action.payload }
    case DELETE_PROXY:
      return { ...initialState, deletedDataProxy: action.payload }
    case GET_STATS_PROXY:
      return { ...initialState, statsProxy: action.payload }

    default:
      return initialState
  }
}
