import React, {useState} from 'react';
import TableCell from "@mui/material/TableCell";
import {updateExecutor} from "../store/reducers/user/actions/updateExecutor";

import IconButton from '@material-ui/core/IconButton';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import TextField from "@material-ui/core/TextField";

import InputAdornment from '@material-ui/core/InputAdornment';

const ReadOnlyRow = ({ provider, handleEditClick, handleDeleteClick, setProviderData }) => {

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const handleChange = prop => event => {
        setProviderData({ [prop]: event.target.value });
    };

    return (
        <tr>
            <TableCell>
                {provider.login}
            </TableCell>
            <TableCell>
                {/*<TextField*/}
                {/*    variant="standard"*/}
                {/*    value={provider.password}*/}
                {/*    onChange={handleChange('password')}*/}
                {/*    type={showPassword ? "text" : "password"} // <-- This is where the magic happens*/}

                {/*        endAdornment = {*/}
                {/*        <InputAdornment position="end">*/}
                {/*        <IconButton*/}
                {/*        aria-label="toggle password visibility"*/}
                {/*        onClick={handleClickShowPassword}*/}
                {/*        onMouseDown={handleMouseDownPassword}*/}
                {/*        >*/}
                {/*        {showPassword ? <Visibility/> : <VisibilityOff/>}*/}
                {/*        </IconButton>*/}
                {/*        </InputAdornment>*/}
                {/*    }*/}
                {/*/>*/}






                <TextField variant="standard"
                           type="text"
                           value={provider.password}
                />
            </TableCell>
            <TableCell>
                <TextField variant="standard"
                           type="text"
                           value={provider.providerPass}
                />
            </TableCell>
            <TableCell>
                <button type="button" onClick={(e) => handleEditClick(e, provider)}>Edit</button>
                <button type="button" onClick={() => handleDeleteClick(provider.id)}>Delete</button>
            </TableCell>
        </tr>
    );
};

export default ReadOnlyRow;