import {httpClient} from "../../../../services/services";
import authHeader from "../../../../services/auth-header";
import axios from "axios";
import {GET_PAYMENT} from "./paymentAction";
import environment from "../../../../environment";
import {setIsLoading} from "../services/isLoading";

export const GET_PAYMENT_LIST = "GET_PAYMENT_LIST";

const API_URL = environment.baseUrl;

export const getPaymentList = (page, pageSize, filters) => {
    const data = {
        take: parseInt(pageSize),
        skip: parseInt(page)
    }
    if (filters) {
        data['where'] = filters
    }
        // "where": {
        //     "status": {
        //         "in": [
        //             "paid",
        //             "archived"
        //         ]
        //     },
        //     "created": {
        //         "from": "2021-09-01T08:48:41.672Z",
        //         "to": "2021-09-19T08:48:41.672Z"
        //     },
        //     "appLink": "https://cutt.ly/dWT93jw"
        // }
    return (dispatch) => {
        dispatch(setIsLoading(true));
        axios
            .post(API_URL + '/payments', data, { headers: authHeader() })
            .then((res) => {
                dispatch({ type: GET_PAYMENT_LIST, payload: res.data });
                dispatch(setIsLoading(false));
                // console.log("data:", res.data)
            })
            .catch((err) => {
                console.log("err", err)
                // dispatch(setError(err))
                dispatch(setIsLoading(false));
            });
    };
};

export const resetDataPaymentList = () => {
    return (dispatch) => {
        dispatch({type: GET_PAYMENT_LIST, payload: {}});
    };
};