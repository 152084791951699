import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {getProviderOrder} from "../Components/store/reducers/orderReducer/actions/getProviderOrderHash";
import {useDispatch} from "react-redux";
import Box from "@material-ui/core/Box";

const HashPage = () => {
    const [hash, setHash] = useState("")
    const [providerPass, setProviderPass] = useState("");
    const dispatch = useDispatch();

    const onChangeHash = (e) => {
        const hash = e.target.value;
        setHash(hash);
    };

    const onChangePass = (e) => {
        const providerPass = e.target.value;
        setProviderPass(providerPass);
    };

    const handleClick = () => {
        dispatch(getProviderOrder(hash,providerPass));
    }
    return (
        <Box sx={{ display: 'flex', width: '250px', margin: '0 auto', flexDirection: 'column' }}>
            <form >
                <Box >
                    hash
                    <input type="text" name="hash" onChange={onChangeHash}/>
                </Box>
               <Box sx={{margin: '20px 0'}}>
                   pass
                   <input type="text" name="pass" onChange={onChangePass}/>
               </Box>

                <div>
                    <Link to={`/orders/order/${hash}`} onClick={handleClick}>Войти</Link>
                </div>

            </form>
            <Box sx={{margin: '10px 0', color: 'red'}}>
                <Link to='/login'>Вернуться</Link>
            </Box>

        </Box>
    );
};

export default HashPage;