import {GET_CMS} from "./actionsCms/getCms";
import {UPDATE_CMS} from "./actionsCms/updateCms";
import {ADD_NEW_PROMO} from "./actionsCms/addNewPromo";
import {UPDATE_CMS_STATS} from "./actionsCmsStats/updateCmsStats";
import {DELETE_CMS} from "./actionsCms/deleteCms";

const initStore = {
    cmsData: {},
    updateData: {},
    newPromoData: {},
    updateCmsStats: {},
    deleteCmsAnswer: {},
};

export const cmsReducer = (initialState = initStore, action) => {
    switch (action.type) {
        case GET_CMS:
            return {...initialState, cmsData: action.payload};
        case UPDATE_CMS:
            return {...initialState, updateData: action.payload};
        case ADD_NEW_PROMO:
            return {...initialState, newPromoData: action.payload};
        case UPDATE_CMS_STATS:
            return {...initialState, updateCmsStats: action.payload};
        case DELETE_CMS:
            return {...initialState, deleteCmsAnswer: action.payload};
        default:
            return initialState;
    }
};
