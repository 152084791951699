import React, {useCallback, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import {SidebarData} from "./SidebarData";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {history} from "../../helpers/history";
import {clearMessage} from "../../actions/message";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {logout} from "../../actions/auth";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
// import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import isEmpty from "lodash/isEmpty";
import {getOrdersList, resetDataOrdersList} from "../store/reducers/orderReducer/actions/getDataOrdersList";
import {getUserAll} from "../store/reducers/user/actions/getUserAll";
// import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

function Sidebar(props) {
    const dispatch = useDispatch()
    const {userData} = useSelector(({user}) => user);

    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        dispatch(getUserAll());
    }, []);


    const serviceProviders = userData.filter(user => user.role === "executor");

    return (
        <Drawer
            sx={{
                width: props.drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: props.drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="left"
            open={props.open}
        >
        <props.DrawerHeader>
            <IconButton onClick={props.handleDrawerClose}>
                {props.theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
            </IconButton>
        </props.DrawerHeader>
    <Divider/>
    <div className="sidebar">
        <List className="sidebarList">
            {SidebarData.map((item, index) => {
                return (
                    <Link to={item.path}>
                    <ListItem button key={index} className="row">
                            <ListItemText primary={item.title}/>
                    </ListItem>
                    </Link>
                )
            })}


            <ListItemButton onClick={handleClick}>
                <ListItemText primary="Provider" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {serviceProviders.map((item, index) => {
                    return (
                        <Link to={`/orders/provider/${item.id}`}>
                            <ListItem button key={index} className="row">
                                <ListItemText > Provider {item.id} </ListItemText>
                            </ListItem>
                        </Link>
                    )
                })}
            </Collapse>

            <Link to="/user">
            <ListItem button>
                <ListItemText primary="AdminCRM" />
            </ListItem>
            </Link>
        </List>

        {/*<ul className="sidebarList">*/}
        {/*    <li className="row">Payments</li>*/}
        {/*    <li className="row">Orders</li>*/}
        {/*    <li className="row">Admin</li>*/}
        {/*    <li className="row">Service Provider</li>*/}
        {/*    <li className="row">Button</li>*/}
        {/*    <li className="row">Button</li>*/}
        {/*</ul>*/}
    </div>
        </Drawer>
)
    ;
}

export default Sidebar;