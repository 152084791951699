import authHeader from '../../../../services/auth-header'
import axios from 'axios'
import environment from '../../../../environment'
import { DATA_SUCCESS, dataSuccess } from '../Error/messageSuccess'
import { setFetchError } from '../Error/errorAction'
import { dataError } from '../Error/errorData'

export const ADD_PROVIDER_REPORT = 'ADD_PROVIDER_REPORT'
const API_URL = environment.baseUrl

export const addProviderReport = (data, providerPass) => {

  return (dispatch) => {
    axios
      // .post(API_URL + `/orders/order/${providerHash}/provider/report`, data, { headers: authHeader() })
      .post(API_URL + `/providers/reviews`, data, {
        headers:
          {
            'provider-pass': providerPass,
            'Authorization': authHeader().Authorization
          }
      })
        .then(res => {
          console.log(res)
          dispatch({ type: ADD_PROVIDER_REPORT, payload: res })
          const messageSuccess = 'Review added succesfully'
          dispatch({ type: DATA_SUCCESS, payload: messageSuccess })
          setTimeout(() => {
            dispatch(dataSuccess())
          }, 4000)
        })
        .catch((error) => {
          dispatch(setFetchError(true))
          dispatch(dataError(error.response.data))

          setTimeout(() => {
            dispatch(setFetchError(false))
          }, 4000)
        })
  }
}