import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import MaterialTable from "material-table";
import MaterialTable, { MTableEditCell } from '@material-table/core'
// import MaterialTable, { MTableEditCell } from "material-table";
import NumberFormat from 'react-number-format'
import { updatePaymentData } from '../store/reducers/payment/updatePaymentData'
import isEmpty from 'lodash/isEmpty'
import { getOrdersList, resetDataOrdersList } from '../store/reducers/orderReducer/actions/getDataOrdersList'

import { Link } from 'react-router-dom'
import { updateOrderData } from '../store/reducers/orderReducer/actions/updateOrderData'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Numeral from 'react-numeral'
import { createMuiTheme, darken } from '@material-ui/core'
// import { alpha } from '@material-ui/core/styles
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { getDataPaymentById, resetDataPayment } from '../store/reducers/payment/paymentAction'
import Select from '@material-ui/core/Select'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { getPaymentList, resetDataPaymentList } from '../store/reducers/payment/getDataPaymentList'
import Tooltip from '@material-ui/core/Tooltip'
import FormControl from '@material-ui/core/FormControl'
import { getService } from '../store/reducers/services/getService'
import InputLabel from '@material-ui/core/InputLabel'
import TableCell from '@material-ui/core/TableCell'
import ModalBasic from '../ModalBasic'
import { setIsLoading } from '../store/reducers/services/isLoading'
import { getProviderOrders } from '../store/reducers/orderReducer/actions/getProviderOrders'
import { deleteProviderReport } from '../store/reducers/report/deleteProviderReport'


const tableRef = React.createRef()

const TableOrders = ({
                       page, pageSize, setPage, setPageSize, filterData, setFilterData,
                       filterSave, setFilterSave, arrServiceProviders
                     }) => {
  // const theme = createMuiTheme({
  //     overrides: {
  //         MuiTypography: {
  //             h6: {
  //                 fontWeight: 'bolder !important',
  //                 color: darken('#5CDB94', 0.15),
  //                 fontSize: '12px'
  //             },
  //         },
  //     },
  // });

  const dispatch = useDispatch()
  const { orderDataList } = useSelector(({ order }) => order)
  // const { orders, totalItem, totalPage, currentPage } = orderDataList
  const { orders, total } = orderDataList

  // if (page !== 0 && currentPage > totalItem) {
  //   setPage(totalPage - 1)
  // }
  const { updateOrder } = useSelector(({ order }) => order)

  const { serviceData } = useSelector(({ service }) => service)
  const { isLoading } = useSelector(({ service }) => service)

  const [activeFilter, isActiveFilter] = useState(false)
  const [created, setCreated] = useState(new Date())
  const [data, setData] = useState(orders)

  const status = [
    { id: 1, name: 'pending payment' },
    { id: 2, name: 'active' },
    { id: 3, name: 'add details' },
    { id: 4, name: 'in work' },
    { id: 5, name: 'finished' },
    { id: 6, name: 'report sent' },
    { id: 7, name: 'refilling' },
    { id: 8, name: 'refilled' },
    { id: 9, name: 'cancelled' },
    { id: 10, name: 'archived' },
    { id: 11, name: 'paused' },
    { id: 12, name: 'draft' }
  ]

  useEffect(() => {
    dispatch(getService())
  }, [])
  const serviceIos = serviceData.filter(item => item.platform === 'IOS')
  const serviceAndroid = serviceData.filter(item => item.platform === 'android')


  const [serviceProvider, setServiceProvider] = useState({})

  useEffect(() => {
    arrServiceProviders.map(row => serviceProvider[row.id] = row.login)
    setServiceProvider(serviceProvider)
  }, [])

  const [rowId, setRowId] = useState()
  const [newData, setNewData] = useState()

  const onClickEl = (id) => {
    setRowId(id)
  }

  const onChangeValue = (value, props) => {
    props.onChange(value)
    const newValueCheck = props.columnDef.type === 'numeric' ? parseInt(value) : value
    const newData = ({ [props.columnDef.field]: newValueCheck })
    dispatch(updateOrderData(props.rowData.id, newData))
    const dataUpdate =
      [...data].map((item, i) => {
        if (props.rowData.tableData.id === item.id)
          props.rowData[props.columnDef.field] = newValueCheck
        return item
      })
    // setData([...dataUpdate]);

    // setData((prev) =>
    //     prev.map((item, i) => {
    //         if (props.rowData.tableData.id === i)
    //             props.rowData[props.columnDef.field] = value;
    //         return item;
    //     })
    // );
    // dispatch(updateOrderData(props.rowData.id, newData))

    // dispatch(updateOrderData(props.rowData.id, newData))
    // setTimeout(() => {
    //     // dispatch(resetDataOrdersList());
    //     // dispatch(getOrdersList());
    // }, 1000)
  }

  const onChangeValueInput = (value, props) => {
    setRowId(props.rowData.id)
    const linkCheckerFind = orderDataList.find(item => item.id === props.rowData.id)
    props.onChange(value)
    const newValueCheck = props.columnDef.type == 'numeric' ? Number(value) : value
    setNewData({ [props.columnDef.field]: newValueCheck, linkChecker: linkCheckerFind.linkChecker })
  }
  const onChangeDate = (date, props) => {
    setRowId(props.rowData.id)
    props.onChange(date)
    const linkCheckerFind = orderDataList.find(item => item.id === props.rowData.id)

    function addDateMin(date, minutes) {
      return new Date(date.getTime() + minutes * 60000)
    }

    setCreated(date)
    // const newValueCheck = props.columnDef.field == 'price' ? parseInt(value) : value
    const newData = ({ [props.columnDef.field]: addDateMin(date, 180), linkChecker: linkCheckerFind.linkChecker })

    const dataUpdate =
      [...data].map((item, i) => {
        if (props.rowData.tableData.id === item.id)
          props.rowData[props.columnDef.field] = props.value
        return item
      })
    // setData([...dataUpdate]);
    // dispatch(updatePaymentData(rowId, newData))
    // new KeyboardEvent("keydown", { keyCode: "Tab", which: 9 })
    // new KeyboardEvent("keydown", { keyCode: "Enter", which: 13 })
    dispatch(updateOrderData(props.rowData.id, newData))
    // setTimeout(() => {
    //     dispatch(resetDataOrdersList());
    //     dispatch(getOrdersList());
    // }, 1000)

    // props.autofocus = false
  }

  // const onBlur = () => {
  //     console.log("IDIDID", rowId)
  //     console.log("IDIDIDnewData", newData)
  //     dispatch(updateOrderData(props.rowData.id, newData))
  //     setTimeout(() => {
  //         dispatch(resetDataOrdersList());
  //         dispatch(getOrdersList());
  //     }, 1000)
  // }

  const valueFilter = (props) => {
    if (props.columnDef.tableData.filterValue !== undefined) {
      // filterData &&
      return props.columnDef.tableData.filterValue
    } else if (filterSave && filterSave[props.columnDef.field] !== null) {
      return filterSave[props.columnDef.field]
    } else {
      return ''
      // filterSave['GEO'] !== null
    }
  }

  const onChangeFilter = (e, props) => {
    // setIsLoading(true)
    props.onFilterChanged(props.columnDef.tableData.id, e.target.value)
    const filterSelect = {
      [e.target.id]: [e.target.value]
    }
    const filterToSave = {
      [e.target.id]: e.target.value
    }
    // if(e.target.value === '') {
    //     setFilterData('')
    //     setFilterSave('')
    //     localStorage.removeItem('filterData')
    //     localStorage.removeItem('filterValue')
    //     dispatch(getProviderOrders(id, page, pageSize, ''))
    // } else {

    // const setFilterValue = () => {
    setFilterSave(prevState => ({
      ...prevState, ...filterToSave
    }))
    setFilterData(prevState => ({
      ...prevState, ...filterSelect
    }))
    // }

    // }

  }

  const [columns, setColumns] = useState([
    {
      field: 'id', title: 'Order Number', type: 'numeric', width: '60px', editable: 'never', cellStyle: { textAlign: 'center' },
      render: rowData => (<Link to={ { pathname: `/orders/order/${ rowData.id }` } }>{ rowData.id }</Link>),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && ((props.columnDef.tableData.filterValue !== undefined || props.columnDef.tableData.filterValue !== '') && (filterSave && filterSave[props.columnDef.field])) ? 'withFilter' : '' }
          >
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'custom_id', title: 'Custom Id', type: 'numeric', width: '80px', editable: 'never',
      hidden: false,
      hiddenByColumnsButton: false,
      cellStyle: { whiteSpace: 'nowrap', width: '100%', textAlign: 'center' },
      emptyValue: <span className="emptyValue">Empty</span>,
      render: rowData => (
        rowData.custom_id === '' ? <span className="emptyValue">Empty</span> : rowData.custom_id
      ),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && ((props.columnDef.tableData.filterValue !== undefined || props.columnDef.tableData.filterValue !== '') && (filterSave && filterSave[props.columnDef.field])) ? 'withFilter' : '' }
          >
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'created', title: 'Order Date', width: '90px', cellStyle: { whiteSpace: 'nowrap' },
      render: rowData => (<DatePicker value={ moment(rowData.created).format('DD-MMM-YYYY') } />),
      editComponent: props => (
        <div>
          <DatePicker
            value={ moment(props.value).format('DD-MMM-YYYY') }
            // selected={created}
            // onChange={props.onChange}
            onChange={ (date) => onChangeDate(date, props) }
          />
        </div>

      ),
      filterComponent: (props) =>
        <TableCell
          className={ isActiveFilter && (props.columnDef.tableData.filterValue || (filterData && filterData[props.columnDef.field])) ? 'withFilter' : '' }
        >
          <ModalBasic
            setFilterData={ setFilterData } filterData={ filterData } isActiveFilter={ isActiveFilter }
            filterSave={ filterSave } setFilterSave={ setFilterSave }
          />
        </TableCell>
      // cellStyle: {whiteSpace: 'nowrap'},
    },
    {
      field: 'price', title: 'O.Price', type: 'numeric', width: '70px', cellStyle: { textAlign: 'center' },
      render: rowData => (<div className="cell">{ rowData.price }</div>),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && ((props.columnDef.tableData.filterValue !== undefined || props.columnDef.tableData.filterValue !== '') && (filterSave && filterSave[props.columnDef.field])) ? 'withFilter' : '' }
          >
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'serviceCost', title: 'O.Service Cost', type: 'numeric', width: '70px', cellStyle: { textAlign: 'center' },
      render: rowData => (<div className="cell">{ rowData.serviceCost }</div>),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && props.columnDef.tableData.filterValue || (filterSave && filterSave[props.columnDef.field]) ? 'withFilter' : '' }
          >
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },

    {
      field: 'clientName', title: 'Client Name', width: '100px', cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden' },
      render: rowData => (<div>
          <Tooltip title={ rowData.payment.client.name } placement="bottom">
            <div className="cell">{ rowData.payment.client.name }</div>
          </Tooltip>
          <Tooltip title={ rowData.payment.client.contactEmail } placement="bottom">
            <div className="cell">{ rowData.payment.client.contactEmail }</div>
          </Tooltip>
        </div>
      ),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && props.columnDef.tableData.filterValue || (filterSave && filterSave[props.columnDef.field]) ? 'withFilter' : '' }
          >
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    // { field: 'clientEmail', title: 'Client Email', width: '100px', cellStyle: {fontSize:'12px', whiteSpace: 'nowrap', overflow: 'hidden'},
    //     hidden: true, hiddenByColumnsButton: false,
    //     render: rowData => (<Tooltip title={rowData.clientEmail} placement="bottom">
    //             <div className='cell' >{rowData.clientEmail}</div>
    //         </Tooltip>
    //     ),
    // },
    {
      field: 'appId',
      title: 'App Id',
      width: '100px',
      cellStyle: { fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden' },
      hidden: true,
      hiddenByColumnsButton: false,
      render: rowData => (<Tooltip title={ rowData.application?.appId ? rowData.application.appId : 'none' } placement="bottom">
          <div className="cell">{ rowData.application?.appId ? rowData.application.appId : 'none' }</div>
        </Tooltip>
      ),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && props.columnDef.tableData.filterValue || (filterSave && filterSave[props.columnDef.field]) ? 'withFilter' : '' }
          >
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'servicePlatform', title: 'Platform', type: 'numeric', width: '70px', editable: 'never', cellStyle: { overflow: 'hidden' },
      render: rowData => (<div>{ rowData.service.platform }</div>),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && props.columnDef.tableData.filterValue || (filterSave && filterSave[props.columnDef.field]) ? 'withFilter' : '' }
          >
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'service', title: 'Service Type', type: 'numeric', width: '70px',
      cellStyle: { overflow: 'hidden' },
      render: rowData => (<div>{ rowData.service.name }</div>),
      // render: rowData => (
      //     <FormControl sx={{ m: 1, width: '100%' }}>
      //     {/*<label htmlFor="grouped-select">{rowData.service.name}</label>*/}
      //     <select
      //             name='service'
      //             id='grouped-select'
      //             value={rowData.service.name}
      //             // label={rowData.service.name}
      //     >
      //
      //         <optgroup label="IOS">
      //             {serviceIos.map((item, index) => {
      //                 return (
      //                     <option value={item.name} selected={rowData.service.id === item.id}>{item.name}</option>
      //                 )
      //             })}
      //         </optgroup>
      //         <optgroup label="Android">
      //             {serviceAndroid.map((item, index) => {
      //                 return (
      //                     <option value={item.name} selected={rowData.service.id === item.id }>{item.name}</option>
      //                 )
      //             })}
      //         </optgroup>
      //     </select>
      //     </FormControl>
      // ),
      editComponent: props => (
        <FormControl sx={ { m: 1, width: '100%' } }>
          <select
            defaultValue="" id="grouped-native-select"
            name={ props.columnDef.field }
            value={ props.value }
            onChange={ (e) => onChangeValue(parseInt(e.target.value), props) }
          >
            <option label="None" value="None" />
            <optgroup label="IOS">
              { serviceIos.map((item, index) => {
                return (
                  <option value={ item.id } key={ item.id }>{ item.name }</option>
                )
              }) }
            </optgroup>
            <optgroup label="Android">
              { serviceAndroid.map((item, index) => {
                return (
                  <option value={ item.id } key={ item.id }>{ item.name }</option>
                )
              }) }
            </optgroup>
          </select>
        </FormControl>
      ),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && props.columnDef.tableData.filterValue || (filterSave && filterSave[props.columnDef.field]) ? 'withFilter' : '' }
          >
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'quantity', title: 'Q-ty', type: 'numeric', width: '50px', cellStyle: { textAlign: 'center' },
      render: rowData => (<div className="cell">{ rowData.quantity }</div>),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && props.columnDef.tableData.filterValue || (filterSave && filterSave[props.columnDef.field]) ? 'withFilter' : '' }
          >
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'perDay', title: 'Speed per day', type: 'numeric', width: '50px', cellStyle: { textAlign: 'center' },
      render: rowData => (<div className="cell">{ rowData.perDay }</div>),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && props.columnDef.tableData.filterValue || (filterSave && filterSave[props.columnDef.field]) ? 'withFilter' : '' }
          >
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'GEO', title: 'GEO(old)', width: '60px', editable: 'never', cellStyle: { textAlign: 'center' },
      emptyValue: <span className="emptyValue">Empty</span>,
      render: rowData => (
        rowData.GEO === null ? 'none' : rowData.GEO
      ),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && props.columnDef.tableData.filterValue || (filterSave && filterSave[props.columnDef.field]) ? 'withFilter' : '' }
          >
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'location', title: 'Location', width: '60px', cellStyle: { textAlign: 'center' },
      // emptyValue: <span className="emptyValue">Empty</span>,
      render: rowData => (
        rowData.location === null ? 'none' : rowData.location
      ),
      // render: rowData => (<div className='cell' >{rowData.GEO}</div> ),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && props.columnDef.tableData.filterValue || (filterSave && filterSave[props.columnDef.field]) ? 'withFilter' : '' }
          >
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'language', title: 'Lang', width: '60px',
      emptyValue: <span className="emptyValue">Empty</span>,
      hidden: true,
      hiddenByColumnsButton: false,
      render: rowData => (
        rowData.language === '' ? <span className="emptyValue">Empty</span> : rowData.language
      ),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && props.columnDef.tableData.filterValue || (filterSave && filterSave[props.columnDef.field]) ? 'withFilter' : '' }
          >
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    },
    {
      field: 'mode', title: 'Mode', width: '60px',
      emptyValue: <span className="emptyValue">Empty</span>,
      render: rowData => (
        rowData.mode === '' ? <span className="emptyValue">Empty</span> : rowData.mode
      ),
      hidden: true,
      hiddenByColumnsButton: false
    },
    {
      field: 'linkChecker', title: 'Checker', width: '60px',
      emptyValue: <span className="emptyValue">Empty</span>,
      render: rowData => (
        rowData.linkChecker === '' ? <span className="emptyValue">Empty</span> : rowData.linkChecker
      ),
      hidden: true,
      hiddenByColumnsButton: false
    },
    {
      field: 'linkChecker', title: 'Checker', width: '60px',
      emptyValue: <span className="emptyValue">Empty</span>,
      render: rowData => (
        rowData.linkChecker === '' ? <span className="emptyValue">Empty</span> : rowData.linkChecker
      ),
      hidden: true,
      hiddenByColumnsButton: false
    },
    {
      field: 'updated', title: 'Updated', width: '60px',
      render: rowData => (<DatePicker value={ moment(rowData.updated).format('DD-MMM-YYYY') } />),
      hidden: true,
      hiddenByColumnsButton: false
    },
    {
      field: 'trackId', title: 'TrackId', width: '60px',
      hidden: true,
      hiddenByColumnsButton: false,
      render: rowData => (
        rowData.application.trackId === '' ? <span className="emptyValue">Empty</span> : rowData.application.trackId
      )
    },
    {
      title: 'Status',
      field: 'status', width: '120px',
      // render: rowData => (<div className={rowData.status === "paid" ? "text-success" : "text-warning"}>{rowData.status}</div> ),
      render: rowData => (
        <select
          name="status"
          className={ rowData.status === 'paid' ? 'text-success' : 'text-warning' }
          defaultValue={ rowData.status }
        >
          { status.map((item) => {
            return (
              <option value={ item.name } key={ item.id }>{ item.name }</option>
            )
          }) }
        </select>

      ),
      editComponent: props => (

        <select
          name="status"
          // value={value}
          onChange={ (e) => onChangeValue(e.target.value, props) }
          // onChange={onChange}
          value={ props.value }
          // onChange={(e) => onChangeValue(e.target.value, props)}

        >
          { status.map((item) => {
            return (
              <option value={ item.name } key={ item.id }>{ item.name }</option>
            )
          }) }
        </select>
      ),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && props.columnDef.tableData.filterValue || (filterSave && filterSave[props.columnDef.field]) ? 'withFilter' : '' }
          >
            <select
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            >
              <option value={ '' } hidden></option>
              { status.map((item) => (
                <option value={ item.name } key={ item.id }>{ item.name }</option>
              )) }
            </select>
          </TableCell>
        )
      },

      cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden' }
      // lookup: { 'pending payment': 'pending payment', active: 'active', 'add details': 'add details', 'in work': 'in work',
      //             finished:'finished', 'report sent': 'report sent', refilling: 'refilling', refilled:'refilled', cancelled:'cancelled',
      //         archived: 'archived', paused: 'paused'
      // },
    },
    {
      field: 'provider', title: 'Provider', cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden' }, width: '100px',
      type: 'numeric',
      // lookup: serviceProvider,
      render: rowData => (
        <select
          name="provider"
          defaultChecked={ rowData.provider && rowData.provider.login }
          // onChange={(e) => onChangeValue(e.target.value, props)}

        >
          <option label="None" value="None" />
          { Object.entries(serviceProvider).map(([key, value], i) => (
            rowData.provider && rowData.provider.login === value ? (
              <option key={ key } value={ key } selected>{ value }</option>
            ) : (
              <option key={ key } value={ key }>{ value }</option>
            )
          )) }
        </select>
      ),

      editComponent: props => (
        <select
          name="provider"
          defaultChecked={ props.value }
          // value={props.value}
          onChange={ (e) => onChangeValue(e.target.value, props) }

        >
          <option label="None" value="None" />
          { Object.entries(serviceProvider).map(([key, value], i) => (
            props.rowData.provider.login === value ? (
              <option key={ key } value={ key } selected>{ value }</option>
            ) : (
              <option key={ key } value={ key }>{ value }</option>
            )
          )) }
        </select>
      ),
      // filterComponent: (props) => {
      //     return (
      //         <TableCell className={isActiveFilter && props.columnDef.tableData.filterValue || (filterSave && filterSave[props.columnDef.field]) ? 'withFilter' : ''}>
      //             <select
      //                 id={props.columnDef.field}
      //                 value={valueFilter(props)}
      //                 onChange={(e) => onChangeFilter(e, props)}
      //             >
      //                 <option value={''} hidden></option>
      //                 {Object.entries(serviceProvider).map(([key, value], i) => (
      //                     <option value={key}>{value}</option>
      //                 ))}
      //             </select>
      //         </TableCell>
      //     );
      // },
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && props.columnDef.tableData.filterValue || (filterSave && filterSave[props.columnDef.field]) ? 'withFilter' : '' }
          >
            <select
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            >
              <option value={ '' } hidden></option>
              { arrServiceProviders.map((item) => (
                <option value={ item.id } key={ item.id }>{ item.login }</option>
              )) }
            </select>
          </TableCell>
        )
      }

    },
    {
      field: 'progress', title: 'Progress', editable: 'never', width: '50px', cellStyle: { textAlign: 'center' },
      render: rowData => (<div>{ rowData.progress }/{ rowData.quantity }</div>),
      filterComponent: (props) => {
        return (
          <TableCell
            className={ isActiveFilter && props.columnDef.tableData.filterValue || (filterSave && filterSave[props.columnDef.field]) ? 'withFilter' : '' }
          >
            <TextField
              id={ props.columnDef.field }
              value={ valueFilter(props) }
              onChange={ (e) => onChangeFilter(e, props) }
            />
          </TableCell>
        )
      }
    }
  ])

  // async function f(props) {
  //     new Promise((resolve, reject) => {
  //         dispatch(updateOrderData(props.rowData.id, newData))
  //         setTimeout(() => resolve("готово!"), 1500)
  //     });
  //
  //     await new Promise((resolve, reject) => setTimeout(resolve, ));
  //     // dispatch(resetDataOrdersList());
  //     // dispatch(getOrdersList());
  // }
  const actions = [
    {
      icon: () => <span className={ activeFilter ? 'btnFilterActive' : 'btnFilter' }>Clear Filters</span>,
      tooltip: activeFilter ? 'Clear all filters' : '',
      isFreeAction: true,
      onClick: (event) => {

        tableRef.current.dataManager.columns.map(item => {
          tableRef.current.onFilterChange(item.tableData.id, '')
        })

        setFilterData('')
        setFilterSave('')
        // localStorage.removeItem('filterData')
        // localStorage.removeItem('filterValue')
        isActiveFilter(false)
        dispatch(getOrdersList(page, pageSize, ''))
      }
    }
    // {
    //     icon: 'refresh',
    //     tooltip: 'Refresh data',
    //     isFreeAction: true,
    //     onClick: (event, rowData) => {
    //         // tableRef.current && tableRef.current.onQueryChange(),
    //         console.log('rowData2222', rowData)
    //         console.log('tableRef.current', tableRef.current)
    //        setData(tableRef.dataManager.data)
    //     }
    // },
  ]

  useEffect(() => {
    // dispatch(setIsLoading(true));
    dispatch(getOrdersList(page, pageSize, filterData))
  }, [page, pageSize, filterData, updateOrder])

  useEffect(() => {
    if (!isEmpty(filterData)) {
      isActiveFilter(true)
    }
  }, [filterData])

  useEffect(() => {
    setData(orders)
    // setIsLoading(false)
  }, [orderDataList])

  return (

    <MaterialTable
      tableRef={ tableRef }
      title="Orders"
      columns={ columns }
      actions={ actions }
      data={ data }
      page={ page }
      totalCount={ total }
      isLoading={ isLoading }
      options={ {
        tableLayout: 'fixed',
        filtering: true,
        columnsButton: true,
        pageSize: pageSize,
        paginationPosition: 'both',
        pageSizeOptions: [30, 60, 90, total],
        padding: 'dense',
        sorting: true,
        search: false,
        addRowPosition: 'first',
        headerStyle: {
          // width: 26,
          // whiteSpace: 'nowrap',
          // textAlign: 'left',
          // flexDirection: 'row',
          // overflow: 'hidden',
          // textOverflow: 'ellipsis',
          // paddingLeft: 5,
          // paddingRight: 5,
          // backgroundColor: theme.palette.primary.table,
          // // fontWeight: 'bold',
          // color: theme.palette.primary.main,
          backgroundColor: '#DEF3FA',
          color: 'Black'
        },
        rowStyle: {
          // width: 10,
          // whiteSpace: 'nowrap',
          // textAlign: 'left',
          // flexDirection: 'row',
          // overflow: 'hidden',
          // textOverflow: 'ellipsis',
          // fontSize: '12px',
          paddingLeft: 5,
          paddingRight: 5
          // backgroundColor: theme.palette.primary.table,
          // color: theme.palette.primary.main,
        }
        // headerStyle: {
        //     backgroundColor: '#DEF3FA',
        //     color: 'Black',
        //     whiteSpace: 'nowrap'
        // },
      } }
      // components={{
      //     EditField: (props) => {
      //         if (props.columnDef.field === 'provider') {
      //             return  <Select
      //                 name={props.columnDef.field}
      //                 value={props.value}
      //                 onChange={(e) => onChangeValue(e.target.value, props)}
      //
      //             >
      //                 {/*{serviceProvider.find(item =>*/}
      //                 {/*        item.name*/}
      //                 {/*)}*/}
      //                 {Object.entries(serviceProvider).map(([key, value], i) => (
      //                     <MenuItem value={parseInt(key)}>{value}</MenuItem>
      //                 ))}
      //             </Select>
      //         } else if (props.columnDef.field === 'status') {
      //
      //             return  <Select
      //                 name={props.columnDef.field}
      //                 value={props.value}
      //                 onChange={(e) => onChangeValue(e.target.value, props)}
      //
      //             >
      //                 {status.map((item) => {
      //                     return (
      //                         <MenuItem value={item.name}>{item.name}</MenuItem>
      //                     )
      //                 })}
      //             </Select>
      //         } else if (props.columnDef.field === 'created'){
      //             return <DatePicker selected={created} onChange={(date) => onChangeDate(date, props)} />
      //         }
      //         // else if (props.columnDef.field === 'price') {
      //         //
      //         //     return <TextField
      //         //         className="editCell"
      //         //         style={props.columnDef.type === 'numeric' ? { width: '50px' } : {}}
      //         //         type={props.columnDef.type === 'numeric' ? 'text' : 'text'}
      //         //         placeholder={props.columnDef.title}
      //         //         value={props.value}
      //         //         onChange={(e) => onChangeValueInput(e.target.value, props)}
      //         //         // onBlur={onBlur}
      //         //         InputProps={{ autoFocus: true, style: { fontSize: 13, } }}
      //         //         onKeyDown={(e) => {
      //         //             if (e.key === "Enter") {
      //         //                 f(props)
      //         //             }
      //         //         }}
      //         //     />
      //         // }
      //         else if (props.columnDef.field === 'service') {
      //
      //             return   <FormControl sx={{ m: 1, minWidth: 120 }}>
      //                 {/*<InputLabel htmlFor="grouped-native-select">Grouping</InputLabel>*/}
      //                 <Select native defaultValue="" id="grouped-native-select" label="Grouping"
      //                         name={props.columnDef.field}
      //                         value={props.value}
      //                         onChange={(e) => onChangeValue(parseInt(e.target.value), props)}
      //                 >
      //                     <option aria-label="None" value="" />
      //                     <optgroup label="IOS">
      //                         {serviceIos.map((item, index) => {
      //                         return (
      //                         <option value={item.id}>{item.name}</option>
      //                         )
      //                     })}
      //                     </optgroup>
      //                     <optgroup label="Android">
      //                         {serviceAndroid.map((item, index) => {
      //                             return (
      //                                 <option value={item.id}>{item.name}</option>
      //                             )
      //                         })}
      //                     </optgroup>
      //                 </Select>
      //             </FormControl>
      //
      //
      //             // <Select
      //             //     name={props.columnDef.field}
      //             //     value={props.value}
      //             //     onChange={(e) => onChangeValue(e.target.value, props)}
      //             //
      //             // >
      //             //     {status.map((item) => {
      //             //         return (
      //             //             <MenuItem value={item.name}>{item.name}</MenuItem>
      //             //         )
      //             //     })}
      //             // </Select>
      //         } else if (props.columnDef.field === 'date'){
      //             return <DatePicker selected={created} onChange={(date) => onChangeDate(date, props)} />
      //         } else {
      //             return <TextField
      //                 className="editCell"
      //                 style={props.columnDef.type === 'numeric' ? { width: '50px' } : {}}
      //                 type={props.columnDef.type === 'numeric' ? 'number' : 'text'}
      //                 placeholder={props.columnDef.title}
      //                 value={props.value === undefined ? '' : props.value}
      //                 onChange={(e) => onChangeValueInput(e.target.value, props)}
      //                 // onBlur={onBlur}
      //                 InputProps={{ autoFocus: true, style: { fontSize: 13, } }}
      //                 onKeyDown={(e) => {
      //                     if (e.key === "Enter") {
      //                         f(props)
      //                     }
      //                 }}
      //             />
      //         }
      //
      //     }
      // }
      // }
      onRowsPerPageChange={ pageSize => {
        setPageSize(pageSize)
      } }
      onPageChange={ page => {
        setPage(page)
      } }
      cellEditable={ {
        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
          return new Promise((resolve, reject) => {
            // const dataUpdate = [...data];
            // dataUpdate[rowData.tableData.id][columnDef.field] = newValue
            const newData = { [columnDef.field]: newValue }

            // setData((prev) =>
            //     prev.map((item, i) => {
            //         if (rowData.tableData.id === i)
            //             item[columnDef.field] = newValue;
            //         return item;
            //     })
            // );
            const dataUpdate =
              [...data].map((item, i) => {
                if (rowData.tableData.id === item.id)
                  rowData[columnDef.field] = newValue
                return item
              })
            // setData([...dataUpdate]);
            dispatch(updateOrderData(rowData.id, newData))

            // console.log('columnDef: '+ columnDef.field);
            // dispatch(updateOrderData(rowData.id, newData))
            setTimeout(resolve, 1000)

          })
        }
      } }

      // editable={{
      //     // onRowAdd: newData =>
      //     //     new Promise((resolve, reject) => {
      //     //         setTimeout(() => {
      //     //             setData([...data, newData]);
      //     //             dispatch(updateOrderData(newData))
      //     //             resolve();
      //     //         }, 1000)
      //     //     }),
      //     // onRowUpdate: (newData, oldData) =>
      //     //     new Promise((resolve, reject) => {
      //     //         setTimeout(() => {
      //     //             const dataUpdate = [...data];
      //     //             const index = oldData.tableData.id;
      //     //             dataUpdate[index] = newData;
      //     //             setData([...dataUpdate]);
      //     //
      //     //             const providerFind = () => {
      //     //                 if(typeof newData.serviceProvider == 'string') {
      //     //                     userData.find(element => element.login === newData.serviceProvider)
      //     //                 }
      //     //             }
      //     //             // setRowId(newData.id);
      //     //             console.log("providerFind", providerFind)
      //     //             console.log("oldData.tableDataPROVIDER", newData.serviceProvider)
      //     //             dispatch(updateOrderData(newData.id, newData, providerFind))
      //     //             resolve();
      //     //         }, 1000)
      //     //     }),
      //     // onRowDelete: oldData =>
      //     //     new Promise((resolve, reject) => {
      //     //         setTimeout(() => {
      //     //             const dataDelete = [...data];
      /*    //             const index = oldData.tableData.id;*/
      /*    //             dataDelete.splice(index, 1);*/
      //     //             setData([...dataDelete]);
      //     //
      //     //             resolve();
      //     //         }, 1000)
      //     //     }),
      // }}
    />
  )
}

export default TableOrders