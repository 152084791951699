import authHeader from "../../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../../environment";
import {DATA_SUCCESS, dataSuccess} from "../../Error/messageSuccess";
import {setFetchError} from "../../Error/errorAction";
import {dataError} from "../../Error/errorData";

export const ADD_COMMENT_ORDER = 'ADD_COMMENT_ORDER';
const API_URL = environment.baseUrl;

export const addCommentOrder = (data) => {
    console.log('dataComment', data)
    return (dispatch) => {
        axios
            .post(API_URL + `/order/${data.orderId}/comment`, { comment: data.comment }, { headers: authHeader() })
            .then(res => {
                console.log(res)
                dispatch({ type: ADD_COMMENT_ORDER, payload: res})
                const messageSuccess = 'Your comment has been sent!'
                dispatch({ type: DATA_SUCCESS, payload: messageSuccess})
                setTimeout(() => {
                    dispatch(dataSuccess())
                }, 4000)
            })
            .catch((error) => {
                dispatch(setFetchError(true))
                dispatch(dataError(error.response.data))
                setTimeout(() => {
                    dispatch(setFetchError(false))
                }, 4000)
            })
    }
}