import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import moment from "moment";

const DateRangeFilter=({setFilterData, filterData, handleClose,  filterSave, setFilterSave, props})=> {
    const [Date1, setDate1] = useState();
    const [Date2, setDate2] = useState();

    console.log("Date1", Date1)
    console.log("Date2", Date2)
    console.log("filterData", filterData)
    const onChangeFilter = () => {
        const filterDate = {
            created: {
                from: moment(Date1).format('YYYY-MM-DD'),
                to: moment(Date2).endOf('day').format('YYYY-MM-DD')
        }
    }
        // isActiveFilter(true)
        setFilterData(prevState => ({
            ...prevState, ...filterDate
    }))
        handleClose()
    }

        return (
            <>
                {/*<Grid alignItems="center" container justify="center">*/}
                {/*    <Grid item xs={12} sm={6} md={4} lg={4}>*/}
                        <Paper
                            elevation={3}
                            style={{
                                margin: "10px auto",
                                textAlign: "center",
                                padding: "10px",
                            }}
                        >
                            <Typography>Filter by Date Range</Typography>
                            <TextField
                                value={Date1}
                                onChange={(e) => setDate1(e.target.value)}
                                type="date"
                                id="date"
                                label='Start Date'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{margin: "10px"}}
                            />
                            <TextField
                                value={Date2}
                                label='End Date'
                                onChange={(e) => setDate2(e.target.value)}
                                type="date"
                                id="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{margin: "10px"}}
                            />
                            <div>
                                <Button
                                    onClick={onChangeFilter}
                                    variant="contained"
                                    color="primary"
                                    sx={{marginRight: '10px'}}
                                >
                                    Filter
                                </Button>
                            </div>
                        </Paper>
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </>
        );
}

export default DateRangeFilter;