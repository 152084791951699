import { GET_ORDER } from './actions/getOrderById'
import { ADD_NEW_ORDER } from './actions/addNewOrderAction'
import { GET_ORDERS_LIST } from './actions/getDataOrdersList'
import { ADD_COMMENT_ORDER } from './actions/addOrderComment'
import { DOWNLOAD_FILE } from './actions/downloadXlsxOrders'
import { GET_PROVIDER_ORDERS } from './actions/getProviderOrders'
import { GET_PROVIDER_ORDER } from './actions/getProviderOrderHash'
import { DOWNLOAD_FILE_SINGLE_ORDER } from './actions/downloadXlsxSingleOrder'
import { UPLOAD_FILE_ORDER_REPORT } from './actions/uploadXlsxReport'
import { UPDATE_ORDER_DATA } from './actions/updateOrderData'
import { UPDATE_ORDER_REPORT } from './actions/updateOrderReport'
import { UPLOAD_FILE_ORDER_CLIENT } from './actions/uploadXlsxClient'
import { UPLOAD_FILE_ORDERS } from './actions/uploadOrders'
import { UPLOAD_FILE_PROVIDER_REPORT } from './actions/uploadProviderXlsReport'
import { DOWNLOAD_FILE_HASH_PROVIDER } from './actions/downloadXlsxFileProvider'
import { DOWNLOAD_PROVIDER_ORDERS } from './actions/downloadProviderXlsxOrders'
import { UPDATE_CHECK_DATE } from './actions/updateCheckEndDate'
import { UPDATE_ORDER } from './actions/updateOrderNew'
import { UPDATE_ORDER_EXECUTOR } from './actions/updateOrderExecutor'
import { GET_ORDERS_BY_PROVIDER } from './actions/getOrdersByProvider'
import { EXECUTOR_DOWNLOAD_ORDERS } from './actions/executorDownloadXlsxOrders'

const initStore = {
  orderData: {},
  newDataOrder: {},
  orderDataList: [],
  commentOrder: {},
  downloadFile: {},
  fileProviderOrders: {},
  fileOrdersByProvider: {},
  downloadFileSingleOrder: {},
  fileHashProviderReport: {},
  uploadFileReport: {},
  fileProviderReport: {},
  uploadFileClient: {},
  uploadFileOrders: {},
  providerOrdersData: {},
  ordersByProviderData: {},
  providerOrderData: {},
  updateOrder: {},
  updateOrderExec: {},
  updateOrderNew: {},
  updateReport: {},
  orderNewCheckDate: {}
}

export const orderReducer = (initialState = initStore, action) => {
  switch (action.type) {
    case GET_ORDER:
      return { ...initialState, orderData: action.payload }
    case ADD_NEW_ORDER:
      return { ...initialState, newDataOrder: action.payload }
    case GET_ORDERS_LIST:
      return { ...initialState, orderDataList: action.payload }
    case ADD_COMMENT_ORDER:
      return { ...initialState, commentOrder: action.payload }
    case DOWNLOAD_FILE:
      return { ...initialState, downloadFile: action.payload }
    case DOWNLOAD_PROVIDER_ORDERS:
      return { ...initialState, fileProviderOrders: action.payload }
    case EXECUTOR_DOWNLOAD_ORDERS:
      return { ...initialState, fileOrdersByProvider: action.payload }
    case DOWNLOAD_FILE_SINGLE_ORDER:
      return { ...initialState, downloadFileSingleOrder: action.payload }
    case DOWNLOAD_FILE_HASH_PROVIDER:
      return { ...initialState, fileHashProviderReport: action.payload }
    case UPLOAD_FILE_ORDER_REPORT:
      return { ...initialState, uploadFileReport: action.payload }
    case UPLOAD_FILE_PROVIDER_REPORT:
      return { ...initialState, fileProviderReport: action.payload }
    case UPLOAD_FILE_ORDER_CLIENT:
      return { ...initialState, uploadFileClient: action.payload }
    case UPLOAD_FILE_ORDERS:
      return { ...initialState, uploadFileOrders: action.payload }
    case GET_PROVIDER_ORDERS:
      return { ...initialState, providerOrdersData: action.payload }
    case GET_ORDERS_BY_PROVIDER:
      return { ...initialState, ordersByProviderData: action.payload }
    case GET_PROVIDER_ORDER:
      return { ...initialState, providerOrderData: action.payload }
    case UPDATE_ORDER_DATA:
      return { ...initialState, updateOrder: action.payload }
    case UPDATE_ORDER:
      return { ...initialState, updateOrderNew: action.payload }
    case UPDATE_ORDER_EXECUTOR:
      return { ...initialState, updateOrderExec: action.payload }
    case UPDATE_ORDER_REPORT:
      return { ...initialState, updateReport: action.payload }
    case UPDATE_CHECK_DATE:
      return { ...initialState, orderNewCheckDate: action.payload }
    default:
      return initialState
  }
}
