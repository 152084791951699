import authHeader from '../../../../../services/auth-header'
import axios from 'axios'
import environment from '../../../../../environment'
import { DATA_SUCCESS, dataSuccess } from '../../Error/messageSuccess'

export const EXECUTOR_DOWNLOAD_ORDERS = 'EXECUTOR_DOWNLOAD_ORDERS'
const API_URL = environment.baseUrl

export const downloadOrdersByProvider = (filters, date) => {

  const data = {
    filters: filters ? filters : {},
    sort: { created: 'DESC' }
  }
  console.log('dataFILE', data)
  return (dispatch) => {
    // dispatch(setIsLoading(true));
    axios
      .post(API_URL + '/providers/orders/download', data, {
        headers:
          {
            'Content-Disposition': 'attachment; filename=Orders.xlsx',
            'Content-Type': 'application/json',
            'Authorization': authHeader().Authorization
          },
        responseType: 'arraybuffer'


      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `orders_${ date }.xlsx`)
        document.body.appendChild(link)
        link.click()
        // dispatch({ type: EXECUTOR_DOWNLOAD_ORDERS, payload: res.data });
        // dispatch(setIsLoading(false));

        console.log('data:', res)
      })
      .catch((err) => {
        console.log('err', err)
        // dispatch(setError(err))
        // dispatch(setIsLoading(false));
      })
  }
}