import authHeader from "../../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../../environment";
import {setFetchError} from "../../Error/errorAction";
import {dataError} from "../../Error/errorData";
import {DATA_SUCCESS, dataSuccess} from "../../Error/messageSuccess";

export const DELETE_CMS = "DELETE_CMS";
const API_URL = environment.baseUrl;

export const deleteCmsData = (site, id) => {
    console.log('site -thunk', site)
    console.log('id -thunk', id)
    return (dispatch) => {
        // dispatch(setIsLoading(true));
        axios
            .delete(API_URL + '/cms', {
                headers:
                    {
                        'Authorization': authHeader().Authorization
                    },
                params: {
                    site: site,
                    id: id
                }
            })
            .then((res) => {
                dispatch({ type: DELETE_CMS, payload: res.data });
                // dispatch(setIsLoading(false));
                dispatch({ type: DATA_SUCCESS, payload: res.data})
                setTimeout(() => {
                    dispatch(dataSuccess())
                }, 4000)
            })
            .catch((error) => {
                dispatch(setFetchError(true))
                dispatch(dataError(error.response.data))
                setTimeout(() => {
                    dispatch(setFetchError(false))
                }, 4000)
                // dispatch(setError(err))
                // dispatch(setIsLoading(false));
            });
    };
};
//
// export const resetDataUser = () => {
//     return (dispatch) => {
//         dispatch({type: GET_USERS, payload: {}});
//     };
// };