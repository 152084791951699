import authHeader from '../../../../../services/auth-header'
import axios from 'axios'
import environment from '../../../../../environment'

export const DOWNLOAD_FILE_SINGLE_ORDER = 'DOWNLOAD_FILE_SINGLE_ORDER'
const API_URL = environment.baseUrl

export const downloadFileSingleOrder = (id, data) => {

  const queryParams = encodeURIComponent(JSON.stringify(data))
  return (dispatch) => {
    // dispatch(setIsLoading(true));
    axios
      .get(API_URL + `/order/${ id }/reviews/download?status=${ queryParams }`, {
        headers:
          {
            'Content-Disposition': 'attachment; ',
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'Authorization': authHeader().Authorization
          },
        responseType: 'arraybuffer'
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        // const fileName = res.headers["content-disposition"]
        link.setAttribute('download', `${ id }_order_reviews.xlsx`)
        document.body.appendChild(link)
        link.click()
        // dispatch({ type: DOWNLOAD_FILE_SINGLE_ORDER, payload: res.data });
        // dispatch(setIsLoading(false));

      })
      .catch((err) => {
        console.log('err', err)
        // dispatch(setError(err))
        // dispatch(setIsLoading(false));
      })
  }
}