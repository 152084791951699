import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";

export const ADD_NEW_SERVICE = 'ADD_NEW_SERVICE';
const API_URL = environment.baseUrl;

export const addNewService = (newService) => {
    console.log('newManager', newService)
    return (dispatch) => {
        axios
            .post(API_URL + '/services', newService, { headers: authHeader() })
            .then(res => {
                console.log(res)
                dispatch({ type: ADD_NEW_SERVICE, payload: res})
            })
            .catch((err) => {
                console.log("err", err)
            })
    }
}