import authHeader from "../../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../../environment";

export const ADD_NEW_MANAGER = 'ADD_NEW_MANAGER';
const API_URL = environment.baseUrl;

export const addNewManager = (newManager) => {
    console.log('newManager', newManager)
    return (dispatch) => {
        axios
            .post(API_URL + '/user/registration/manager', newManager, { headers: authHeader() })
            .then(res => {
                console.log(res)
                dispatch({ type: ADD_NEW_MANAGER, payload: res})
            })
            .catch((err) => {
                console.log("err", err)
            })
    }
}