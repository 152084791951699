import { addNewOrder } from '../store/reducers/orderReducer/actions/addNewOrderAction'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { addCommentOrder } from '../store/reducers/orderReducer/actions/addOrderComment'
import { Grid, InputLabel, Radio, Select } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { getService } from '../store/reducers/services/getService'
import { getLanguages } from '../store/reducers/services/getLanguages'
import { MenuProps } from '../../const/config'
import { getCountries } from '../store/reducers/services/getCountries'
import { countries_ios } from '../../const/countries_ios'
import { countries_android } from '../../const/countries_android'

const NewOrder = ({ handleClose }) => {
  const dispatch = useDispatch()
  // const {id} = useParams();
  const { paymentData } = useSelector(({ payment }) => payment)
  const { serviceData } = useSelector(({ service }) => service)
  // const { languagesData } = useSelector(({ service }) => service)
  // const { countriesData } = useSelector(({ service }) => service)
  const { userData } = useSelector(({ user }) => user)

  const [langCode, setLangCode] = useState('en')

  const [checked, setChecked] = React.useState(false)
  // console.log('checkedLINK', checked)

  const checkboxCheck = (event) => {
    setChecked(event.target.checked)
  }

  const [comment, setComment] = useState({
    orderId: '',
    text: ''
  })
  const [orderData, setOrderData] = useState({
    payment: paymentData.id,
    appLink: '',

    linkChecker: checked
  })

  useEffect(() => {
    const findLangByCode = (code) => {
      const findObject = ([12, 15, 16, 17, 18, 22, 23].includes(orderData.service) ? countries_ios : countries_android).find(item => item.code.includes(code))
      // if ([12,15,16,17,18,22,23].includes(orderData.service)) {
      //   const findObject = countries_ios.find(item => item.code.includes(code))
      //   return findObject && findObject.lang_code
      // } else {
      //   const findObject = countries_android.find(item => item.code.includes(code))
      //   return findObject && findObject.lang_code
      // }
      return findObject && findObject.lang_code
    }
    const lang = findLangByCode(orderData.location)
    console.log('lang', lang)
    if (lang) setLangCode(lang)
    if (lang) {
      setOrderData({
        ...orderData,
        language: lang
      })
    }
  }, [orderData.location])

  useEffect(() => {
    dispatch(getService())
    // dispatch(getLanguages())
    // dispatch(getCountries())
  }, [])

  const updateInputValue = e => {
    console.log(e.target.value)
    let applicationId

    if (e.target.name === 'language') setLangCode(e.target.value)

    if (['comment', 'location', 'appLink', 'language'].includes(e.target.name)) {

      setOrderData({
        ...orderData,
        [e.target.name]: e.target.value.toString()
      })

    } else {
      setOrderData({ ...orderData, [e.target.name]: parseInt(e.target.value) })
    }

    if (['appId', 'trackId'].includes(e.target.name)) {
      applicationId = e.target.value

      const regex = /(https?:\/\/[^\s]+)/g
      const matches = e.target.value.match(regex)
      if (matches) {
        matches.forEach((match) => {
          const androidRegex = /(?:\?|&)id=([^&#]+)/
          const appStoreRegex = /\/id(\d+)/
          const idMatch = match.match(e.target.name === 'appId' ? androidRegex : appStoreRegex)

          if (idMatch) {
            applicationId = idMatch[1]
            if (e.target.name === 'appId') {
              setOrderData({
                ...orderData,
                appId: applicationId
              })
            } else {
              setOrderData({
                ...orderData,
                trackId: Number(applicationId)
              })
            }

          } else {
            console.log('Ссылка без ID:', match)
          }
        })
      } else {
        setOrderData({
          ...orderData,
          appId: applicationId
        })
      }
    }

  }
  const submitAddNewOrder = (e) => {
    e.preventDefault()
    dispatch(addNewOrder(orderData))
    handleClose()
  }
  const updateCommentValue = e => {
    console.log(e.target.value)
    setComment({ ...comment, [e.target.name]: e.target.value.toString() })
  }
  const addNewCommentOrder = (e) => {
    // e.preventDefault();
    // dispatch(addCommentOrder(comment))
    // console.log("Comment!!!:", comment);
  }

  const [platform, setPlatform] = React.useState('IOS')

  const handleChange = (event, newPlatform) => {
    setPlatform(newPlatform)
  }

  const serviceIos = serviceData.filter(item => item.platform === 'IOS')
  const serviceAndroide = serviceData.filter(item => item.platform === 'android')

  const serviceProviders = userData.filter(user => user.role === 'executor')

  return (
    <div className="container">
      <h1>Add new Order</h1>

      <form onSubmit={ submitAddNewOrder }>
        <Grid container spacing={ 3 }>
          <Grid item xs>
            <b>First select the service:</b>
            <ToggleButtonGroup
              color="primary"
              value={ platform }
              exclusive
              onChange={ handleChange }
              name="platform"
            >
              <ToggleButton value="IOS">IOS</ToggleButton>
              <ToggleButton value="androide">Android</ToggleButton>
            </ToggleButtonGroup>

            <FormControl variant="standard" sx={ { m: 1, minWidth: 150, mb: '50px' } }>
              <InputLabel id="demo-simple-select-standard-label">Choose Service:</InputLabel>
              <Select
                labelId="chooseService"
                id="service"
                onChange={ updateInputValue }
                label="Choose Service:"
                name="service"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                { platform === 'IOS'
                  ? serviceIos.map((item, index) => {
                    return (
                      <MenuItem value={ item.id }>{ item.name }</MenuItem>
                    )
                  })
                  : serviceAndroide.map((item, index) => {
                    return (
                      <MenuItem value={ item.id }>{ item.name }</MenuItem>
                    )
                  })
                }

              </Select>
            </FormControl>
            <FormGroup className="list-item">
              <FormControlLabel
                control={ <Checkbox checked={ checked } onChange={ checkboxCheck } /> }
                label="Use Link checker"
                name="linkCheck"
              />
            </FormGroup>

          </Grid>

          <Grid item xs className="col-30">
            <div>
              { orderData.service === 12 ? (
                <TextField
                  id="standard-basic"
                  label="Track Id"
                  variant="standard"
                  name="trackId"
                  onChange={ updateInputValue }
                />
              ) : orderData.service === 11 ? (
                <TextField
                  id="standard-basic"
                  label="AppId"
                  variant="standard"
                  name="appId"
                  onChange={ updateInputValue }
                  disabled={ !orderData.service }
                />
              ) : (
                <TextField
                  id="standard-basic"
                  label="AppLink"
                  variant="standard"
                  name="appLink"
                  onChange={ updateInputValue }
                />
              ) }

            </div>
            <div>
              <TextField
                id="standard-basic" label="Quantity" variant="standard" name="quantity"
                type="number" onChange={ updateInputValue }
              />
            </div>
            <div>
              <TextField
                id="standard-basic" label="Speed" variant="standard" name="perDay" type="number"
                onChange={ updateInputValue }
              />
            </div>
            <FormControl variant="standard" component="fieldset" sx={ { m: 1, minWidth: 174.4 } }>
              <InputLabel id="location">Location</InputLabel>
              <Select
                labelId="location"
                onChange={ updateInputValue }
                label="Location"
                name="location"
                MenuProps={ MenuProps }
              >
                { ([12, 15, 16, 17, 18, 22, 23].includes(orderData.service) ? countries_ios : countries_android).map((item, index) => {
                  return (
                    <MenuItem value={ item.code }>{ item.name }</MenuItem>
                  )
                }) }
              </Select>
            </FormControl>
            <FormControl variant="standard" component="fieldset" sx={ { m: 1, minWidth: 174.4 } }>
              <InputLabel id="language">Language</InputLabel>
              <Select
                labelId="language"
                onChange={ updateInputValue }
                label="Language"
                name="language"
                MenuProps={ MenuProps }
                value={ langCode }
              >
                { ([12, 15, 16, 17, 18, 22, 23].includes(orderData.service) ? countries_ios : countries_android).map((item, index) => {
                  return (
                    <MenuItem value={ item.lang_code }>{ item.lang_name }</MenuItem>
                  )
                }) }
              </Select>
            </FormControl>
            <div>
              <TextField
                id="standard-basic" label="O.Price" variant="standard" name="price" type="text"
                onChange={ updateInputValue }
              />
            </div>
            <div>
              <TextField
                id="standard-basic" label="O.Service Cost" variant="standard" name="serviceCost"
                type="text" onChange={ updateInputValue }
              />
            </div>
          </Grid>

          <Grid item xs className="col-30">
            <FormControl variant="standard" component="fieldset" sx={ { m: 1, minWidth: 150, mb: '50px' } }>
              <InputLabel id="provider">Service Provider</InputLabel>
              <Select
                labelId="provider"
                onChange={ updateInputValue }
                label="Service Provider"
                name="provider"
              >
                { serviceProviders.map((item, index) => {
                  return (
                    <MenuItem value={ item.id }>{ item.login }</MenuItem>
                  )
                }) }
              </Select>
            </FormControl>
            <div className="notes">
              Notes for Service Provider
              <textarea name="comment" id="" cols="25" rows="4" onChange={ updateInputValue }></textarea>
              <div>
                {/*<Button variant="outlined" onClick={addNewCommentOrder}>Apply</Button>*/ }
              </div>
            </div>
          </Grid>
        </Grid>
        <Box sx={ { width: '100%', textAlign: 'center', mt: '30px' } }>
          <Button type="submit" variant="contained">Create New Order</Button>
        </Box>

      </form>
    </div>
  )
}

export default NewOrder