import React, { useEffect, useState } from 'react'
import { useParams, useLocation, Link } from 'react-router-dom'
import { getOrderById, getProviderOrder, resetDataOrder } from '../store/reducers/orderReducer/actions/getOrderById'
import { useDispatch, useSelector } from 'react-redux'
import TableOrderReport from './TableOrderReport'
import isEmpty from 'lodash/isEmpty'
import { addCommentOrder } from '../store/reducers/orderReducer/actions/addOrderComment'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Button from '@mui/material/Button'
import { downloadFileSingleOrder } from '../store/reducers/orderReducer/actions/downloadXlsxSingleOrder'
import { uploadFileOrderReport } from '../store/reducers/orderReducer/actions/uploadXlsxReport'
import Alert from '@mui/material/Alert'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { getUser } from '../store/reducers/user/actions/getUserId'
import Checkbox from '@mui/material/Checkbox'
import UploadIcon from '@mui/icons-material/Upload'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import NewPromo from '../NewPromo/NewPromo'
import Modal from '@mui/material/Modal'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import { updateManager } from '../store/reducers/user/actions/updateManager'
import { LoadingButton } from '@mui/lab'
import TextField from '@material-ui/core/TextField'
import { updateCheckDate } from '../store/reducers/orderReducer/actions/updateCheckEndDate'
import { InputLabel, Select } from '@mui/material'
import { MenuProps } from '../../const/config'
import { statusOrder } from '../../const/status'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { getService } from '../store/reducers/services/getService'
import { getLanguages } from '../store/reducers/services/getLanguages'
import { getCountries } from '../store/reducers/services/getCountries'
import { addNewOrder } from '../store/reducers/orderReducer/actions/addNewOrderAction'
import { updateOrder } from '../store/reducers/orderReducer/actions/updateOrderNew'
import { checkIosApp } from '../store/reducers/report/checkIosApp'
import { checkAndroidApp } from '../store/reducers/report/checkAndroidApp'
import { countries_ios } from '../../const/countries_ios'
import { countries_android } from '../../const/countries_android'

function SingleOrder() {
  const { id } = useParams()
  const { orderData, commentOrder } = useSelector(({ order }) => order)
  const { isLoading } = useSelector(({ service }) => service)
  // const { languagesData } = useSelector(({ service }) => service)
  // const { countriesData } = useSelector(({ service }) => service)
  const { serviceData } = useSelector(({ service }) => service)
  const { isFetchError } = useSelector(({ error }) => error)
  const { dataError } = useSelector(({ error }) => error)
  const { messageSuccess } = useSelector(({ error }) => error)
  const { uploadFileReport } = useSelector(({ order }) => order)
  const { orderNewCheckDate } = useSelector(({ order }) => order)
  const { newDataOrder } = useSelector(({ order }) => order)
  const { updateOrderNew } = useSelector(({ order }) => order)
  const { updateReport } = useSelector(({ order }) => order)

  const { deleteDataReports } = useSelector(({ report }) => report)
  const { newReport } = useSelector(({ report }) => report)

  const { userDataOne } = useSelector(({ user }) => user)
  const { userData } = useSelector(({ user }) => user)
  const { user: currentUser } = useSelector((state) => state.auth)

  const dispatch = useDispatch()

  const [selectedFile, setSelectedFile] = useState(null)
  const [commentSend, setCommentSend] = useState({
    orderId: parseInt(id),
    comment: ''
  })

  const {
    // id,
    appLink,
    created,
    updated,
    client,
    source,
    paymentType,
    quantity,
    progress,
    status,
    perDay,
    price,
    GEO,
    location,
    language,
    service,
    serviceCost,
    comment,
    payment,
    linkChecker,
    massCheckEndDate,
    reviews: reports,
    application
  } = orderData

  const checkDate = new Date(massCheckEndDate).toLocaleDateString('sv-SE')
  const serviceIos = serviceData.filter(item => item.platform === 'IOS')
  const serviceAndroid = serviceData.filter(item => item.platform === 'android')
  const [date1, setDate1] = React.useState(massCheckEndDate)
  const [orderDataUpdate, setOrderDataUpdate] = useState(null)

  const changeOrderData = () => {
    // setOrderDataUpdate({...orderDataUpdate, payment: orderData && payment?.id})
    console.log('payment', { payment: payment?.id })
    dispatch(updateOrder(id, orderDataUpdate))
    if (date1) dispatch(updateCheckDate(id, date1))
  }

  useEffect(() => {
    currentUser && dispatch(getUser(currentUser.id))
  }, [currentUser])

  useEffect(() => {
    !isEmpty(orderData) && dispatch(getOrderById(id))
    dispatch(resetDataOrder())
    dispatch(getOrderById(id))
    console.log('orderData!ID', id)
  }, [uploadFileReport, deleteDataReports, newReport, orderNewCheckDate, newDataOrder, updateOrderNew])


  useEffect(() => {
    dispatch(getOrderById(id))
  }, [updateReport])

  useEffect(() => {
    dispatch(getService())
    // dispatch(getLanguages())
    // dispatch(getCountries())
  }, [])

  useEffect(() => {
    setOrderDataUpdate({
      payment: payment?.id,
      id: parseInt(id),
      service: service?.id
    })
  }, [orderData])

  const updateCommentValue = e => {
    console.log(e.target.name, e.target.value)
    setCommentSend({ ...commentSend, [e.target.name]: e.target.value.toString() })
  }
  const AddNewCommentOrder = (e) => {
    e.preventDefault()
    dispatch(addCommentOrder(commentSend))
    console.log('Comment!!!:', commentSend)
  }

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const checkedFilter = ['Alive', 'Empty', 'NotFound', 'NotChecked', 'Removed']
  const [checkedFilterDownload, setCheckedFilterDownload] = useState(checkedFilter)
  console.log('checkedFilter', checkedFilter)
  console.log('checkedFilterDownload', checkedFilterDownload)
  const checkboxCheck = (e) => {
    // setCheckedFilterDownload(e.target.checked);
    console.log('e.target.checked', e.target.checked)
    console.log('e.target.name', e.target.name)
    if (e.target.checked) {
      const checkedResult = checkedFilterDownload.concat(e.target.name)
      setCheckedFilterDownload(checkedResult)
    } else {
      const checkedResult = checkedFilterDownload.filter((current) => current !== e.target.name)
      setCheckedFilterDownload(checkedResult)
    }
  }
  console.log('checkedFilterDownload22', checkedFilterDownload)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 250,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3
  }

  const downloadFile = () => {
    const data = {
      in: checkedFilterDownload
    }
    dispatch(downloadFileSingleOrder(id, data))
    handleClose()
  }

  const checkApp = (appId, trackId) => {
    // e.preventDefault()
    console.log('checkApp !!!!!!', appId, trackId)
    service.platform === 'android' ? dispatch(checkAndroidApp(appId)) : dispatch(checkIosApp(trackId, location))
    // dispatch(checkAndroidApp(appId))
    // dispatch(checkIosApp(appId))
  }

  const submitFormUpload = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('file', selectedFile)
    dispatch(uploadFileOrderReport(id, formData))
  }

  const clipboardClick = () => {
    navigator.clipboard.writeText(appLink)
  }
  const handleClick = (e) => {
    e.preventDefault()
  }

  const arrServiceProviders = userData.filter(user => user.role === 'executor')

  const [serviceProvider, setServiceProvider] = useState({})

  useEffect(() => {
    arrServiceProviders.map(row => serviceProvider[row.id] = row.login)
    console.log('provider', serviceProvider)
    setServiceProvider(serviceProvider)
  }, [])
  console.log('provider2', serviceProvider)
  console.log('isLoading', isLoading)

  // useEffect(() => {
  //
  // }, [uploadFileReport]);

  const managerBread = () => {
    if (userDataOne.role == 'manager') {
      return <Breadcrumbs aria-label="breadcrumb">
        <Link hover="underline" color="inherit" to={ `/payments/${ payment.id }` }>Payment</Link>
        {/*<Link hover="underline" color="inherit" to='/orders/' >Orders All</Link>*/ }
        <Typography color="text.primary">Order</Typography>
      </Breadcrumbs>

    } else {
      return <Breadcrumbs aria-label="breadcrumb">
        <Link hover="underline" color="inherit" to={ `/orders/provider/${ serviceProvider.id }` }>
          Orders
        </Link>
        <Typography color="text.primary">Order</Typography>
      </Breadcrumbs>


    }
  }
  const updateInputValue = e => {
    console.log(e.target.name, e.target.checked)

    if (['linkChecker'].includes(e.target.name)) {
      setOrderDataUpdate({ ...orderDataUpdate, [e.target.name]: e.target.checked })
    } else if (['location', 'appId', 'language', 'status'].includes(e.target.name)) {
      setOrderDataUpdate({ ...orderDataUpdate, [e.target.name]: e.target.value.toString() })
    } else {
      setOrderDataUpdate({ ...orderDataUpdate, [e.target.name]: parseInt(e.target.value) })
    }
    console.log('orderDataUpdate', orderDataUpdate)
    console.log('orderDataUpdate', orderData)
  }

  console.log('RePORTS', reports)
  const arrCount = reports && reports.length !== 0 ? reports.map(item => {
    return item.count
  }) : 0
  const reducer = (prev, cur) => prev + cur

  const reportAlive = reports && reports.filter(item => item.status === 'alive' || item.status === 'alive_ok' || item.status === 'removed')
  // const reportAlive = reports && reports.length !== 0 ? reportsAlive.map(item => {
  //     return item
  // }) : 0

  return (
    <>
      { messageSuccess &&
        <div className="errorBlock">
          <Alert severity="success">{ messageSuccess }</Alert>
        </div>
      }
      { isFetchError &&
        <div className="errorBlock">
          <Alert severity="error">{ dataError.message }</Alert>
        </div>
      }
      { !isEmpty(orderData) && (
        <Box>
          <div role="presentation" onClick={ handleClick }>
            { managerBread() }
          </div>

          <Typography variant="h5" component="div" gutterBottom sx={ { textAlign: 'center' } }>
            Order № { id }
          </Typography>
          <Box sx={ { width: '100%', display: 'flex', justifyContent: 'space-between', mb: '20px' } }>
            <Box sx={ { width: '40%', display: 'flex' } }>
              <Box sx={ { width: '100%', maxWidth: 600 } } className="custom-list-col">
                <Box sx={ { width: '100%' } }>
                  <span>App link:<span className="spanCopy" onClick={ clipboardClick }>{ appLink } <ContentCopyIcon
                    fontSize="small" className="copyLink"
                  /></span> </span>
                </Box>
                <Box sx={ { width: '100%', display: 'flex' } }>
                  <List sx={ { width: '50%' } }>
                    <ListItem className="list-item">
                      <label htmlFor="created"><span>Order Date: </span>{ created.slice(0, 10) }</label>
                    </ListItem>
                    <ListItem className="list-item">
                      <label htmlFor="update"><span>Update Date: </span>{ updated && updated.slice(0, 10) }</label>
                    </ListItem>
                    <ListItem className="list-item">
                      <label><span>Speed per day: </span></label>
                      <TextField id="standard-basic" variant="standard" name="perDay" defaultValue={ perDay } onChange={ updateInputValue } className="textField" />
                    </ListItem>
                    <ListItem className="list-item">
                      <label><span>Service Cost </span></label>
                      <TextField id="standard-basic" variant="standard" name="serviceCost" defaultValue={ serviceCost } onChange={ updateInputValue } className="textField" />
                    </ListItem>
                    <ListItem className="list-item">
                      <label><span>Price </span></label>
                      <TextField id="standard-basic" variant="standard" name="price" defaultValue={ price } onChange={ updateInputValue } className="textField" />
                    </ListItem>
                    <ListItem className="list-item">
                      {/*<label><span>AppId </span>{ application.appId }</label>*/ }
                      <TextField id="standard-basic" label="AppId" variant="standard" name="appId" defaultValue={ application ? application?.appId : appLink } onChange={ updateInputValue } />
                    </ListItem>
                  </List>
                  <List sx={ { width: '50%' } }>
                    <ListItem className="list-item">
                      <label htmlFor="service"><span>Service: </span></label>
                      <FormControl sx={ { m: 1, width: '100%' } }>
                        <select
                          defaultValue={ service.id } id="grouped-native-select"
                          onChange={ updateInputValue }
                          name="service"
                        >
                          <optgroup label="IOS">
                            { serviceIos.map((item) => {
                              return (
                                <option value={ item.id }>{ item.name }</option>
                              )
                            }) }
                          </optgroup>
                          <optgroup label="Android">
                            { serviceAndroid.map((item) => {
                              return (
                                <option value={ item.id }>{ item.name }</option>
                              )
                            }) }
                          </optgroup>
                        </select>
                      </FormControl>
                    </ListItem>
                    <ListItem className="list-item">
                      <label htmlFor="service"><span>Platform: </span>{ service.platform }</label>
                    </ListItem>
                    <ListItem className="list-item">
                      <label htmlFor="GEO"><span>GEO(old): </span>{ GEO }</label>
                    </ListItem>
                    <ListItem className="list-item">
                      <label htmlFor="location"><span>Location: </span></label>
                      <FormControl variant="standard" component="fieldset" sx={ { minWidth: 150 } }>
                        <Select
                          labelId="location"
                          onChange={ updateInputValue }
                          label="Location"
                          name="location"
                          autoWidth
                          defaultValue={ location ? location?.toUpperCase() : 'none' }
                          MenuProps={ MenuProps }
                        >
                          { (service.platform === 'IOS' ? countries_ios : countries_android).map((item, index) => {
                            return (
                              <MenuItem value={ item.code }>{ item.name }</MenuItem>
                            )
                          }) }
                        </Select>
                      </FormControl>
                    </ListItem>
                    <ListItem className="list-item">
                      <label><span>Language: </span></label>
                      <FormControl variant="standard" component="fieldset" sx={ { minWidth: 150 } }>
                        <Select
                          labelId="language"
                          onChange={ updateInputValue }
                          label="Language"
                          name="language"
                          autoWidth
                          defaultValue={ language }
                          MenuProps={ MenuProps }
                        >
                          { (service.platform === 'IOS' ? countries_ios : countries_android).map((item) => {
                            return (
                              <MenuItem value={ item.lang_code }>{ item.lang_name }</MenuItem>
                            )
                          }) }
                        </Select>
                      </FormControl>
                    </ListItem>
                    <ListItem className="list-item">
                      <label htmlFor="Progress"><span>Progress: </span>{ progress }/{ quantity }</label>
                    </ListItem>
                    <ListItem className="list-item">
                      <label><span>Track Id: </span></label>
                      <TextField id="standard-basic" variant="standard" name="trackId" defaultValue={ application?.trackId ? application?.trackId : 'none' } onChange={ updateInputValue } className="textField" />
                    </ListItem>
                  </List>
                </Box>

              </Box>
            </Box>
            <Box sx={ { width: '57%', display: 'flex', justifyContent: 'space-between' } }>
              <Box sx={ { width: '32%' } } className="custom-list-col">
                <List sx={ { width: '100%' } }>
                  <ListItem className="list-item">
                    <label><span>Status: </span></label>
                    <FormControl variant="standard" component="fieldset" sx={ { minWidth: 140 } }>
                      <Select
                        onChange={ updateInputValue }
                        name="status"
                        autoWidth
                        defaultValue={ status }
                        MenuProps={ MenuProps }
                      >
                        { statusOrder.map((item) => {
                          return (
                            <MenuItem value={ item }>{ item }</MenuItem>
                          )
                        }) }
                      </Select>
                    </FormControl>
                  </ListItem>


                </List>
                <div>
                  <label htmlFor="linkChecker" className="linkCheckerLabel">
                    <Checkbox
                      defaultChecked={ linkChecker }
                      onChange={ updateInputValue }
                      name="linkChecker"
                      inputProps={ { 'aria-label': 'linkChecker' } }
                    />
                    <span>Link Checker (24h)</span></label>
                </div>
                <ListItem className="list-item">
                  <label><span>Status App: </span>{ application ? application?.status : 'not available' }</label>
                </ListItem>
                <ListItem className="list-item">
                  <label><span>Last check: </span>{ application?.lastCheck.slice(0, 10) }</label>
                </ListItem>
                <LoadingButton
                  loading={ isLoading }
                  loadingPosition="start"
                  sx={ { width: '100%', my: '10px' } }
                  variant="outlined"
                  disabled={ !application }
                  onClick={ () => checkApp(application?.appId, application?.trackId) }
                >
                  Check App
                </LoadingButton>
                <div>
                  <TextField
                    defaultValue={ checkDate }
                    onChange={ (e) => setDate1(e.target.value) }
                    type="date"
                    id="date"
                    inputProps={ { min: '2020-01-01', max: '2026-12-31' } }
                    label="Check End Date"
                    InputLabelProps={ {
                      shrink: true
                    } }
                    style={ { margin: '10px' } }
                  />
                  <Button type="submit" sx={ { width: '100%' } } variant="outlined" onClick={ changeOrderData }>Change</Button>
                </div>
              </Box>
              <Box sx={ { width: '30%' } } className="custom-list-col">
                {/*<div className="column col-30">*/ }
                {/*    <div className="notes">*/ }
                {/*    <textarea name="text" id="" cols="27" rows="3"*/ }
                {/*              onChange={updateCommentValue}>{comment}</textarea>*/ }
                {/*    </div>*/ }
                {/*    <Button onClick={AddNewCommentOrder}>Apply</Button>*/ }
                {/*</div>*/ }

                <Typography variant="h6" component="div" gutterBottom sx={ { fontWeight: 'bold' } }>
                  Comment
                </Typography>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={ 5 }
                  placeholder="Comment for payment"
                  style={ { width: 200 } }
                  name="comment"
                  onChange={ updateCommentValue }
                >
                  { comment }
                </TextareaAutosize>
                <div>
                  <Button onClick={ AddNewCommentOrder }>Apply</Button>
                </div>

              </Box>
              <Box sx={ { width: '35%' } } className="custom-list-col">

                <Box sx={ { mb: '20px', borderBottom: '1px solid grey', pb: '30px' } }>
                  <form onSubmit={ submitFormUpload }>
                    <label className="custom-file-upload">
                      <input
                        type="file"
                        // value={selectedFile}
                        onChange={ (e) => setSelectedFile(e.target.files[0]) }
                      />
                      <div className="uploadIcon">
                        <UploadIcon /><span className="file-preview">{ selectedFile && selectedFile.name }</span>
                      </div>
                    </label>

                    <Button type="submit" sx={ { width: '100%' } } variant="outlined">Upload texts</Button>

                  </form>
                </Box>

                <Button sx={ { width: '100%', my: '10px' } } variant="outlined" onClick={ handleOpen }>Download xls report</Button>
                <Modal
                  open={ open }
                  onClose={ handleClose }
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={ style }>
                    <Typography variant="h6" component="div" gutterBottom sx={ { fontWeight: 'bold', textAlign: 'center' } }>
                      Choose status filter
                    </Typography>
                    <FormGroup className="list-item">
                      {
                        checkedFilter.map(item => (
                          <FormControlLabel
                            control={ <Checkbox checked={ checkedFilterDownload.includes(item) } onChange={ checkboxCheck } /> }
                            label={ item }
                            name={ item }
                          />
                        ))
                      }
                      <Button sx={ { width: '100%', my: '10px' } } variant="outlined" onClick={ downloadFile }>Download xls report</Button>
                    </FormGroup>
                  </Box>
                </Modal>
                <Button sx={ { width: '100%' } } variant="outlined" disabled>Send report to client</Button>


                {/*<List sx={{maxWidth: 250}}>*/ }
                {/*    <Button sx={{width: '100%'}} variant="outlined" w>All reviews</Button>*/ }
                {/*    <Button sx={{width: '100%', my: '10px'}} variant="outlined">Live reviews*/ }
                {/*        only</Button>*/ }
                {/*    <Button sx={{width: '100%', mb: '10px'}} variant="outlined">Only not posted</Button>*/ }
                {/*    <Button sx={{width: '100%'}} variant="outlined">Removed reviews</Button>*/ }
                {/*</List>*/ }

              </Box>
            </Box>
          </Box>
          <TableOrderReport reports={ orderData.reviews } id={ id } service={ service } />
        </Box>

      ) }
    </>
  )
}

export default SingleOrder