import React, {useState, useRef, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link, Redirect} from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { login } from "../actions/auth";
import {getProviderOrders} from "./store/reducers/orderReducer/actions/getProviderOrders";
import {getProviderOrder} from "./store/reducers/orderReducer/actions/getProviderOrderHash";
import {getUser} from "./store/reducers/user/actions/getUserId";
import isEmpty from "lodash/isEmpty";
import {setProviderPass} from "./store/reducers/user/actions/saveProviderPass";
import {history} from "../helpers/history";
import {clearMessage} from "../actions/message";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = (props) => {
  // const {currentUser} = props
  const form = useRef();
  const checkBtn = useRef();


  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);

  const { user: currentUser } = useSelector((state) => state.auth);
  const {userDataOne} = useSelector(({user}) => user);
  const {providerPass} = useSelector(({user}) => user);

  const dispatch = useDispatch();


  // console.log("currentUser.id!!!!!!!!!!!", currentUser.id)

  // useEffect(() => {
  //   dispatch(setProviderPass(userDataOne.providerPass));
  // }, [currentUser]);



  // console.log("userDataOne.providerPass", userDataOne.providerPass)
  console.log("userDataOne", userDataOne)
  console.log("providerPassINSTORE", providerPass)

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(username, password))
        .then(() => {
          props.history.push("/orders");
          window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };


  // useEffect(() => {
  //   console.log("currentUser1", currentUser)
  //   currentUser && dispatch(getUser(currentUser.id));
  // }, [currentUser]);
  // const userFindData = userDataOne.find(element => element.id === currentUser.id);

  console.log("userDataOne", userDataOne)
  console.log("currentUser2", currentUser)
  console.log("isManager!!!!33", props.isManager)

  // if (isLoggedIn && userDataOne.role !== 'manager') {
  //   return <Redirect to={`/orders/provider/${currentUser.id}`} />;
  // }
  if (isLoggedIn && currentUser.role === 'manager') {
    return <Redirect to="/orders" />;
  } else if(isLoggedIn && currentUser.role === 'executor'){
    return <Redirect to={`/orders/provider/${currentUser.id}`} />;
  }




  // if (!isLoggedIn) {
  //   return <Redirect to="/login" />;
  // }

  // if (currentUser.id !== 1) {
  //   return <Redirect to="/orders/provider/" />
  // } else {
  //   return <Redirect to="/orders" />;
  // }

  // console.log("currentUser.id", currentUser.id && currentUser.id)




  return (<>
        <Form onSubmit={handleLogin} ref={form} className="form-login">
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <Input
                type="text"
                className="form-control"
                name="username"
                value={username}
                onChange={onChangeUsername}
                validations={[required]}
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <Input
                type="password"
                className="form-control"
                name="password"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
            />
          </div>

          <div className="form-group">
            <button className="btn btn-primary btn-block" disabled={loading}>
              {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Login</span>
            </button>
          </div>

          {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
        {/*<div>*/}
        {/*  <Link to='/hashIn'>Go to hashPage</Link>*/}
        {/*</div>*/}
      </>

  );
};

export default Login;
