import authHeader from "../../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../../environment";
import {setFetchError} from "../../Error/errorAction";
import {dataError} from "../../Error/errorData";
import {DATA_SUCCESS, dataSuccess} from "../../Error/messageSuccess";

export const UPDATE_ORDER_REPORT= 'UPDATE_ORDER_REPORT';
const API_URL = environment.baseUrl;

export const updateOrderReport = (id, data) => {

    return (dispatch) => {
        axios
            .put(API_URL + `/reviews/${id}`, data, { headers: authHeader() })
            .then(res => {
                dispatch({ type: UPDATE_ORDER_REPORT, payload: res.data})
                dispatch({ type: DATA_SUCCESS, payload: res.data.message})
                setTimeout(() => {
                    dispatch(dataSuccess())
                }, 4000)
            })
            .catch((error) => {
                dispatch(setFetchError(true))
                dispatch(dataError(error.response.data))
                setTimeout(() => {
                    dispatch(setFetchError(false))
                }, 3000)
            })
    }
}