import axios from "axios";
import environment from "../environment";
const API_URL = environment.baseUrl;

const instance = axios.create({
    baseURL: API_URL + "/api",
    headers: {
        "Content-Type": "application/json",
    },
});

export default instance;