import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";
import {setFetchError} from "../Error/errorAction";
import {dataError} from "../Error/errorData";
import {DATA_SUCCESS, dataSuccess} from "../Error/messageSuccess";
import { useState } from 'react'
import {setIsLoading} from "../services/isLoading";

export const CHECK_ANDROID_APP= 'CHECK_ANDROID_APP';
const API_URL = environment.baseUrl;

export const checkAndroidApp = (id) => {
  console.log('idReview', id)
  // console.log('Report', data)
  return (dispatch) => {
    dispatch(setIsLoading(true))
    axios
      .get(API_URL + `/reviews/scraper/android/${id}/app`,{ headers: authHeader() })
      .then(res => {
        console.log(res)
        dispatch({ type: CHECK_ANDROID_APP, payload: res.data})
        dispatch({ type: DATA_SUCCESS, payload: res.data.message})
        setTimeout(() => {
          dispatch(dataSuccess())
        }, 4000)
        dispatch(setIsLoading(false))
      })
      .catch((error) => {
        dispatch(setIsLoading(false))
        dispatch(setFetchError(true))
        dispatch(dataError(error.response))
        setTimeout(() => {
          dispatch(setFetchError(false))
        }, 4000)
      })
  }
}