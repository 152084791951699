import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAll } from '../store/reducers/user/actions/getUserAll'
import List from '@material-ui/core/List'
import { getBusiness } from '../store/reducers/services/getBusiness'

const MainMenu = () => {
  const dispatch = useDispatch()
  const { userData } = useSelector(({ user }) => user)
  const { businessData } = useSelector(({ service }) => service)

  useEffect(async () => {
    await dispatch(getUserAll())
    await dispatch(getBusiness())
  }, [])

  const serviceProviders = userData.filter(user => user.role === 'executor')

  return (
    <div className="menu">
      <List className="menuList">
        <li>
          <NavLink to="/payments" activeClassName="active">Payments</NavLink>
        </li>
        <li>
          <NavLink exact to="/orders" activeClassName="active">Orders</NavLink>
        </li>

        <li className={ 'dropdown' || (window.location.pathname === '/orders/provider/18' && 'active') }>
          <a>Providers</a>
          <ul className="submenu">
            { serviceProviders.map((item, index) => {
              return (
                <li key={ item.id }>
                  <NavLink to={ `/orders/provider/${ item.id }` } activeClassName="active">
                    Provider { item.id }
                  </NavLink>
                </li>
              )
            }) }
          </ul>
        </li>

        <li>
          <NavLink to="/user" activeClassName="active">AdminCRM</NavLink>
        </li>
        <li className="dropdown">
          {/*<NavLink to="/sites/site" activeClassName="active"></NavLink>*/ }
          <a>Site</a>
          <ul className="submenu">
            { businessData.map((item, index) => {
              return (
                <li key={ item.id }>
                  <NavLink to={ `/sites/site/${ item.id }` } activeClassName="active">
                    { item.name }
                  </NavLink>
                </li>
              )
            }) }
          </ul>
        </li>
        <li>
          <NavLink to="/statistics" activeClassName="active">Statistics</NavLink>
        </li>
        <li>
          <NavLink to="/proxy" activeClassName="active">Proxy</NavLink>
        </li>
      </List>
    </div>
  )
}

export default MainMenu