import authHeader from "../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../environment";

export const ADD_NEW_PAYMENT_TYPE = 'ADD_NEW_PAYMENT_TYPE';
const API_URL = environment.baseUrl;

export const addNewPaymentType = (newPayment) => {
    console.log('newManager', newPayment)
    return (dispatch) => {
        axios
            .post(API_URL + '/payments/paymentType', newPayment, { headers: authHeader() })
            .then(res => {
                console.log(res)
                dispatch({ type: ADD_NEW_PAYMENT_TYPE, payload: res})
            })
            .catch((err) => {
                console.log("err", err)
            })
    }
}