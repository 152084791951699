import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import DateRangeFilter from './DataRangeFilter'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400
  // bgcolor: 'background.paper',
  // boxShadow: 24,
}

const BasicModal = ({ setFilterData, filterData, isActiveFilter, filterSave, setFilterSave, props }) => {

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div style={ { textAlign: 'center' } } className={ isActiveFilter ? 'withFilter' : '' }>
      <Button onClick={ handleOpen }>Filter by date</Button>
      <Modal
        open={ open }
        onClose={ handleClose }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ style }>
          <DateRangeFilter
            setFilterData={ setFilterData } filterData={ filterData } handleClose={ handleClose }
            filterSave={ filterSave } setFilterSave={ setFilterSave } props={ props }
          />
        </Box>
      </Modal>
    </div>
  )
}

export default BasicModal