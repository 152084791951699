export const countries_ios = [
  {
    code: 'US',
    name: 'United States',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'BR',
    name: 'Brazil',
    lang_code: 'pt',
    lang_name: 'Portuguese'
  },
  {
    code: 'RU',
    name: 'Russian Federation',
    lang_code: 'ru',
    lang_name: 'Russian'
  },
  {
    code: 'TR',
    name: 'Turkey',
    lang_code: 'tr',
    lang_name: 'Turkish'
  },
  {
    code: 'DE',
    name: 'Germany',
    lang_code: 'de',
    lang_name: 'German'
  },
  {
    code: 'PL',
    name: 'Poland',
    lang_code: 'pl',
    lang_name: 'Polish'
  },
  {
    code: 'FR',
    name: 'France',
    lang_code: 'fr',
    lang_name: 'French'
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'AG',
    name: 'Antigua and Barbuda',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'AI',
    name: 'Anguilla',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'AL',
    name: 'Albania',
    lang_code: 'sq',
    lang_name: 'Albanian'
  },
  {
    code: 'AM',
    name: 'Armenia',
    lang_code: 'hy',
    lang_name: 'Armenian'
  },
  {
    code: 'AO',
    name: 'Angola',
    lang_code: 'pt',
    lang_name: 'Portuguese'
  },
  {
    code: 'AR',
    name: 'Argentina',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'AT',
    name: 'Austria',
    lang_code: 'de',
    lang_name: 'German'
  },
  {
    code: 'AU',
    name: 'Australia',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
    lang_code: 'az',
    lang_name: 'Azerbaijani'
  },
  {
    code: 'BB',
    name: 'Barbados',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'BD',
    name: 'Bangladesh',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'BE',
    name: 'Belgium',
    lang_code: 'nl',
    lang_name: 'Dutch'
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
    lang_code: 'fr',
    lang_name: 'French'
  },
  {
    code: 'BG',
    name: 'Bulgaria',
    lang_code: 'bg',
    lang_name: 'Bulgarian'
  },
  {
    code: 'BH',
    name: 'Bahrain',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'BJ',
    name: 'Benin',
    lang_code: 'fr',
    lang_name: 'French'
  },
  {
    code: 'BM',
    name: 'Bermuda',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'BN',
    name: 'Brunei Darussalam',
    lang_code: 'ms',
    lang_name: 'Malay'
  },
  {
    code: 'BO',
    name: 'Bolivia',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'BS',
    name: 'Bahamas',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'BT',
    name: 'Bhutan',
    lang_code: 'dz',
    lang_name: 'Dzongkha'
  },
  {
    code: 'BW',
    name: 'Botswana',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'BY',
    name: 'Belarus',
    lang_code: 'be',
    lang_name: 'Belarusian'
  },
  {
    code: 'BZ',
    name: 'Belize',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'CA',
    name: 'Canada',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'CG',
    name: 'Congo',
    lang_code: 'fr',
    lang_name: 'French'
  },
  {
    code: 'CH',
    name: 'Switzerland',
    lang_code: 'de',
    lang_name: 'German'
  },
  {
    code: 'CI',
    name: 'Cote dIvoire',
    lang_code: 'fr',
    lang_name: 'French'
  },
  {
    code: 'CL',
    name: 'Chile',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'CN',
    name: 'China',
    lang_code: 'zh',
    lang_name: 'Chinese'
  },
  {
    code: 'CO',
    name: 'Colombia',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'CR',
    name: 'Costa Rica',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'CV',
    name: 'Cape Verde',
    lang_code: 'pt',
    lang_name: 'Portuguese'
  },
  {
    code: 'CY',
    name: 'Cyprus',
    lang_code: 'tr',
    lang_name: 'Turkish'
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
    lang_code: 'cs',
    lang_name: 'Czech'
  },
  {
    code: 'DK',
    name: 'Denmark',
    lang_code: 'da',
    lang_name: 'Danish'
  },
  {
    code: 'DM',
    name: 'Dominica',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'DZ',
    name: 'Algeria',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'EC',
    name: 'Ecuador',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'EE',
    name: 'Estonia',
    lang_code: 'et',
    lang_name: 'Estonian'
  },
  {
    code: 'EG',
    name: 'Egypt',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'ES',
    name: 'Spain',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'FI',
    name: 'Finland',
    lang_code: 'fi',
    lang_name: 'Finnish'
  },
  {
    code: 'FJ',
    name: 'Fiji',
    lang_code: 'fj',
    lang_name: 'Fijian'
  },
  {
    code: 'FM',
    name: 'Micronesia',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'GD',
    name: 'Grenada',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'GH',
    name: 'Ghana',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'GM',
    name: 'Gambia',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'GR',
    name: 'Greece',
    lang_code: 'el',
    lang_name: 'Greek'
  },
  {
    code: 'GT',
    name: 'Guatemala',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau',
    lang_code: 'pt',
    lang_name: 'Portuguese'
  },
  {
    code: 'GY',
    name: 'Guyana',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'HK',
    name: 'Hong Kong',
    lang_code: 'zh',
    lang_name: 'Chinese'
  },
  {
    code: 'HN',
    name: 'Honduras',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'HR',
    name: 'Croatia',
    lang_code: 'hr',
    lang_name: 'Croatian'
  },
  {
    code: 'HU',
    name: 'Hungary',
    lang_code: 'hu',
    lang_name: 'Hungarian'
  },
  {
    code: 'ID',
    name: 'Indonesia',
    lang_code: 'id',
    lang_name: 'Indonesian'
  },
  {
    code: 'IE',
    name: 'Ireland',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'IL',
    name: 'Israel',
    lang_code: 'he',
    lang_name: 'Hebrew'
  },
  {
    code: 'IN',
    name: 'India',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'IS',
    name: 'Iceland',
    lang_code: 'is',
    lang_name: 'Icelandic'
  },
  {
    code: 'IT',
    name: 'Italy',
    lang_code: 'it',
    lang_name: 'Italian'
  },
  {
    code: 'JM',
    name: 'Jamaica',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'JO',
    name: 'Jordan',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'JP',
    name: 'Japan',
    lang_code: 'ja',
    lang_name: 'Japanese'
  },
  {
    code: 'KE',
    name: 'Kenya',
    lang_code: 'sw',
    lang_name: 'Swahili'
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan',
    lang_code: 'ky',
    lang_name: 'Kirghiz'
  },
  {
    code: 'KH',
    name: 'Cambodia',
    lang_code: 'km',
    lang_name: 'Central Khmer'
  },
  {
    code: 'KN',
    name: 'Saint Kitts and Nevis',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'KR',
    name: 'South Korea',
    lang_code: 'ko',
    lang_name: 'Korean'
  },
  {
    code: 'KW',
    name: 'Kuwait',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'KY',
    name: 'Cayman Islands',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
    lang_code: 'kk',
    lang_name: 'Kazakh'
  },
  {
    code: 'LA',
    name: 'Laos',
    lang_code: 'lo',
    lang_name: 'Lao'
  },
  {
    code: 'LB',
    name: 'Lebanon',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'LC',
    name: 'Saint Lucia',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
    lang_code: 'de',
    lang_name: 'German'
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
    lang_code: 'si',
    lang_name: 'Sinhalese'
  },
  {
    code: 'LR',
    name: 'Liberia',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'LT',
    name: 'Lithuania',
    lang_code: 'lt',
    lang_name: 'Lithuanian'
  },
  {
    code: 'LU',
    name: 'Luxembourg',
    lang_code: 'fr',
    lang_name: 'French'
  },
  {
    code: 'LV',
    name: 'Latvia',
    lang_code: 'lv',
    lang_name: 'Latvian'
  },
  {
    code: 'MD',
    name: 'Moldova',
    lang_code: 'ro',
    lang_name: 'Romanian'
  },
  {
    code: 'MG',
    name: 'Madagascar',
    lang_code: 'mg',
    lang_name: 'Malagasy'
  },
  {
    code: 'MK',
    name: 'Macedonia',
    lang_code: 'mk',
    lang_name: 'Macedonian'
  },
  {
    code: 'ML',
    name: 'Mali',
    lang_code: 'fr',
    lang_name: 'French'
  },
  {
    code: 'MN',
    name: 'Mongolia',
    lang_code: 'mn',
    lang_name: 'Mongolian'
  },
  {
    code: 'MO',
    name: 'Macao',
    lang_code: 'zh',
    lang_name: 'Chinese'
  },
  {
    code: 'MR',
    name: 'Mauritania',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'MS',
    name: 'Montserrat',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'MT',
    name: 'Malta',
    lang_code: 'mt',
    lang_name: 'Maltese'
  },
  {
    code: 'MU',
    name: 'Mauritius',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'MV',
    name: 'Maldives',
    lang_code: 'dv',
    lang_name: 'Maldivian'
  },
  {
    code: 'MW',
    name: 'Malawi',
    lang_code: 'ny',
    lang_name: 'Chichewa'
  },
  {
    code: 'MX',
    name: 'Mexico',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'MY',
    name: 'Malaysia',
    lang_code: 'ms',
    lang_name: 'Malay'
  },
  {
    code: 'MZ',
    name: 'Mozambique',
    lang_code: 'pt',
    lang_name: 'Portuguese'
  },
  {
    code: 'NA',
    name: 'Namibia',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'NE',
    name: 'Niger',
    lang_code: 'fr',
    lang_name: 'French'
  },
  {
    code: 'NG',
    name: 'Nigeria',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'NI',
    name: 'Nicaragua',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'NL',
    name: 'Netherlands',
    lang_code: 'nl',
    lang_name: 'Dutch'
  },
  {
    code: 'NO',
    name: 'Norway',
    lang_code: 'nb',
    lang_name: 'Norwegian'
  },
  {
    code: 'NP',
    name: 'Nepal',
    lang_code: 'ne',
    lang_name: 'Nepali'
  },
  {
    code: 'NZ',
    name: 'New Zealand',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'OM',
    name: 'Oman',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'PA',
    name: 'Panama',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'PE',
    name: 'Peru',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'PG',
    name: 'Papua New Guinea',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'PH',
    name: 'Philippines',
    lang_code: 'fil',
    lang_name: 'Filipino'
  },
  {
    code: 'PK',
    name: 'Pakistan',
    lang_code: 'ur',
    lang_name: 'Urdu'
  },
  {
    code: 'PT',
    name: 'Portugal',
    lang_code: 'pt',
    lang_name: 'Portuguese'
  },
  {
    code: 'PW',
    name: 'Palau',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'PY',
    name: 'Paraguay',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'QA',
    name: 'Qatar',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'RO',
    name: 'Romania',
    lang_code: 'ro',
    lang_name: 'Romanian'
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'SC',
    name: 'Seychelles',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'SE',
    name: 'Sweden',
    lang_code: 'sv',
    lang_name: 'Swedish'
  },
  {
    code: 'SG',
    name: 'Singapore',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'SI',
    name: 'Slovenia',
    lang_code: 'sl',
    lang_name: 'Slovenian'
  },
  {
    code: 'SK',
    name: 'Slovakia',
    lang_code: 'sk',
    lang_name: 'Slovak'
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'SN',
    name: 'Senegal',
    lang_code: 'fr',
    lang_name: 'French'
  },
  {
    code: 'SR',
    name: 'Suriname',
    lang_code: 'nl',
    lang_name: 'Dutch'
  },
  {
    code: 'ST',
    name: 'Sao Tome and Principe',
    lang_code: 'pt',
    lang_name: 'Portuguese'
  },
  {
    code: 'SV',
    name: 'El Salvador',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'SZ',
    name: 'Swaziland',
    lang_code: 'ss',
    lang_name: 'Swati'
  },
  {
    code: 'TC',
    name: 'Turks and Caicos Islands',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'TD',
    name: 'Chad',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'TH',
    name: 'Thailand',
    lang_code: 'th',
    lang_name: 'Thai'
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
    lang_code: 'tg',
    lang_name: 'Tajik'
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
    lang_code: 'tk',
    lang_name: 'Turkmen'
  },
  {
    code: 'TN',
    name: 'Tunisia',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'TT',
    name: 'Trinidad and Tobago',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'TW',
    name: 'Taiwan',
    lang_code: 'zh',
    lang_name: 'Chinese'
  },
  {
    code: 'TZ',
    name: 'Tanzania',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'UA',
    name: 'Ukraine',
    lang_code: 'uk',
    lang_name: 'Ukrainian'
  },
  {
    code: 'UG',
    name: 'Uganda',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'UY',
    name: 'Uruguay',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
    lang_code: 'uz',
    lang_name: 'Uzbek'
  },
  {
    code: 'VC',
    name: 'Saint Vincent and the Grenadines',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'VE',
    name: 'Venezuela',
    lang_code: 'es',
    lang_name: 'Spanish'
  },
  {
    code: 'VG',
    name: 'British Virgin Islands',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'VN',
    name: 'Vietnam',
    lang_code: 'vi',
    lang_name: 'Vietnamese'
  },
  {
    code: 'YE',
    name: 'Yemen',
    lang_code: 'ar',
    lang_name: 'Arabic'
  },
  {
    code: 'ZA',
    name: 'South Africa',
    lang_code: 'en',
    lang_name: 'English'
  },
  {
    code: 'ZW',
    name: 'Zimbabwe',
    lang_code: 'en',
    lang_name: 'English'
  }
]