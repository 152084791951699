import React, {useEffect, useState} from 'react';
import TablePayments from "./TablePayments";
import {useDispatch, useSelector} from "react-redux";
import {getPaymentList, resetDataPaymentList} from "../store/reducers/payment/getDataPaymentList";
import isEmpty from "lodash/isEmpty";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import NewPayment from "../NewPayment/NewPayment";
import {downloadXlsxPayments} from "../store/reducers/payment/downloadXlsxPayments";
import Button from "@mui/material/Button";
import {getPaymentType} from "../store/reducers/payment/getPaymentType";
import Alert from "@mui/material/Alert";
import TableOrdersProvider from "../Orders/TableOrdersProvider";

const Payments = () => {
    const dispatch = useDispatch();
    const {paymentDataList} = useSelector(({payment}) => payment);
    const {paymentType} = useSelector(({payment}) => payment);
    const {updatePayment} = useSelector(({payment}) => payment);
    const {newDataPayment} = useSelector(({payment}) => payment);

    const {isFetchError} = useSelector(({error}) => error);
    const {dataError} = useSelector(({error}) => error);
    const {messageSuccess} = useSelector(({error}) => error);

    const [pageSize, setPageSize] = useState(30);
    const [page, setPage] = useState(0 );
    const [filterData, setFilterData] = useState({});
    const [filterSave, setFilterSave] = useState({});

    // const {id} = useParams();
    // console.log("paymentDataList!!!", paymentDataList)
    // console.log("paymentIDAFTERCHANGE!!!", id)


    const [open, setOpen] = React.useState(false);
    // const [data, setData] = React.useState([])

    if(localStorage.getItem('page') === null && localStorage.getItem('pageSize') === null
        // && localStorage.getItem('columns') === null
    )
    {
        window.localStorage.setItem('page', page);
        window.localStorage.setItem('pageSize', pageSize);
        // window.localStorage.setItem('columns', columnsHide);
    }


    useEffect(() => {
        setPage(JSON.parse(window.localStorage.getItem('page')));
        setPageSize(JSON.parse(window.localStorage.getItem('pageSize')));
        setFilterData(JSON.parse(window.localStorage.getItem('filterData')));
        setFilterSave(JSON.parse(window.localStorage.getItem('filterValue')));
    }, []);

    useEffect(() => {
        window.localStorage.setItem('page', page);
        window.localStorage.setItem('pageSize', pageSize);
        window.localStorage.setItem('filterData', JSON.stringify(filterData));
        window.localStorage.setItem('filterValue', JSON.stringify(filterSave));
    }, [page, pageSize, filterData, filterSave]);


    useEffect (() => {
        !isEmpty(paymentDataList) && dispatch(getPaymentList(page, pageSize, filterData));
        dispatch(resetDataPaymentList());
        dispatch(getPaymentList(page, pageSize, filterData));
        dispatch(getPaymentType());
    }, [newDataPayment]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 6,
    };
     // console.log(' idEdit ', idEdit)
    // const updatePayment = (idEdit, data) => {
    //     // e.preventDefault();
    //     dispatch(updatePaymentData(idEdit,data))
    //     console.log("updatePaymentData:", idEdit, data);
    // }

    const downloadFile = () => {
        dispatch(downloadXlsxPayments())
    }

    return (
        <div className="container ha">
            { messageSuccess &&
            <div className='errorBlock'>
                <Alert severity="success">{messageSuccess}</Alert>
            </div>
            }
            { isFetchError &&
            <div className='errorBlock'>
                <Alert severity="error">{dataError.message}</Alert>
            </div>
            }
            {!isEmpty(paymentDataList) && (
                <div>
                    <div className="column col-100 paymentBtn-block">

                            <Button variant="contained" onClick={handleOpen}>Add new Payment</Button>
                            {/*<button className='btn-mb' onClick={updatePayment}>Update Data</button>*/}
                        {/*<Link to="/payments/create"></Link>*/}
                        <Button variant="outlined" onClick={downloadFile}>Download xls report</Button>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <NewPayment handleClose={handleClose}/>
                            </Box>
                        </Modal>
                    </div>
                    <TablePayments paymentType={paymentType} page={page} pageSize={pageSize} setPage={setPage}
                                   setPageSize={setPageSize} filterData={filterData} setFilterData={setFilterData}
                                   filterSave={filterSave} setFilterSave={setFilterSave}
                                   // setIdEdit={setIdEdit}
                    />
                </div>
            )}


        </div>
    );
}

export default Payments;