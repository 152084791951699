import authHeader from "../../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../../environment";
import { DATA_SUCCESS, dataSuccess } from '../../Error/messageSuccess'
import { dataError } from '../../Error/errorData'
import { setFetchError } from '../../Error/errorAction'

export const UPDATE_ORDER = "UPDATE_ORDER";
const API_URL = environment.baseUrl;

export const updateOrder = (id, data) => {
  return (dispatch) => {
    // dispatch(setIsLoading(true));
    axios
      .put(API_URL + `/order/${id}/update`, data, { headers: authHeader() })
      .then((res) => {
        dispatch({ type: UPDATE_ORDER, payload: res.data.order });
        dispatch({ type: DATA_SUCCESS, payload: res.data.message})
        setTimeout(() => {
          dispatch(dataSuccess())
        }, 4000)
        // dispatch(setIsLoading(false));
        console.log("res updateOrder:", res.data)
      })
      .catch((err) => {
        dispatch(setFetchError(true))
        dispatch(dataError(err.response.data))
        setTimeout(() => {
          dispatch(setFetchError(false))
        }, 4000)
        // dispatch(setError(err))
        // dispatch(setIsLoading(false));
      });
  };
};

export const resetDataOrder = () => {
  return (dispatch) => {
    dispatch({type: UPDATE_ORDER, payload: {}});
  };
};