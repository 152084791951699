import React from 'react';
import TableCell from "@mui/material/TableCell";
import {updateExecutor} from "../store/reducers/user/actions/updateExecutor";
import TextField from "@mui/material/TextField";

const EditableRow = ({providerData, handleEditExecutorData, handleCancelClick}) => {
    return (
        <tr>
            <TableCell>
                <input type="text" id="login" name="login"
                       onChange={handleEditExecutorData}
                       value={providerData.login}
                />
            </TableCell>
            <TableCell>
                <TextField variant="standard"
                           type="text"
                           value={providerData.password}
                />
            </TableCell>
            <TableCell>
                <TextField variant="standard"
                           type="text"
                           value={providerData.providerPass}
                />
            </TableCell>
            <TableCell>
                <button type="submit">change</button>
                <button type="button" onClick={handleCancelClick}>cancel</button>
            </TableCell>
        </tr>
    );
};

export default EditableRow;