import authHeader from "../../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../../environment";
import {setFetchError} from "../../Error/errorAction";
import {dataError} from "../../Error/errorData";
import {DATA_SUCCESS, dataSuccess} from "../../Error/messageSuccess";
import {setIsLoading} from "../../services/isLoading";

export const UPDATE_ORDER_DATA= 'UPDATE_ORDER_DATA';
const API_URL = environment.baseUrl;

export const updateOrderData = (rowId, data, providerFind) => {
    const id = rowId
    const data2 = {
        // created: newData.created,
        // price: newData.price,
        // serviceCost: newData.serviceCost,
        // appLink: newData.appLink,
        // clientName: newData.clientName,
        // // service: newData.serviceType,
        // quantity: newData.quantity,
        // perDay: newData.speedPerDay,
        // GEO: newData.GEO,
        // status: newData.status,
        // provider:
        //     // typeof newData.serviceProvider == 'string' ? providerFind.id :
        //         parseInt(newData.serviceProvider)
    }
    console.log('UPDATE- DATA', data)
    console.log('UPDATE- id', id)
    // console.log('UPDATE- newData', newData.serviceProvider)
    // console.log('UPDATE- data', data.provider)

    return (dispatch) => {
        dispatch(setIsLoading(true));
        axios
            .put(API_URL + `/order/${id}/update`, data, { headers: authHeader() })
            .then(res => {
                console.log(res)
                dispatch({ type: UPDATE_ORDER_DATA, payload: res})
                dispatch({ type: DATA_SUCCESS, payload: res.data.message})
                setTimeout(() => {
                    dispatch(dataSuccess())
                }, 4000)
                dispatch(setIsLoading(false));
            })
            .catch((error) => {
                dispatch(setFetchError(true))
                dispatch(dataError(error.response.data))
                setTimeout(() => {
                    dispatch(setFetchError(false))
                }, 4000)
                dispatch(setIsLoading(false));
            })
    }
}