import axios from "axios";
import api from "./api";
import environment from "../environment";
import TokenService from "./token.service";

const API_URL = environment.baseUrl;

const login = (username, password) => {
  return api
    .post(API_URL + "/auth/login", {
      username,
      password,

    })

      .then((response) => {
        if (response.data.access_token) {
          TokenService.setUser(response.data);
        }

        return response.data;
      });


  // .then((response) => {
  //     if (response.data.access_token) {
  //       localStorage.setItem("user", JSON.stringify(response.data));
  //     }
  //     console.log("USER-LOCALSTORAGE:", localStorage.user)
  //     return response.data;
  //   });
};

// const logout = () => {
//   localStorage.removeItem("user");
// };

const logout = () => {
  TokenService.removeUser();
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

// export default {
//   login,
//   logout,
//   getCurrentUser,
// };

const AuthService = {
  login,
  logout,
  getCurrentUser,
};

export default AuthService;
