import authHeader from "../../../../../services/auth-header";
import axios from "axios";
import environment from "../../../../../environment";

export const UPDATE_CMS = "UPDATE_CMS";
const API_URL = environment.baseUrl;

export const updateCmsData = (site, data) => {
    console.log('siteUpd -thunk', site)
    console.log('dataUpdate -thunk', data)
    return (dispatch) => {
        // dispatch(setIsLoading(true));
        axios
            .put(API_URL + '/cms', data, {
                headers:
                    {
                        'Authorization': authHeader().Authorization
                    },
                params: {
                    site: site
                }
            })
            .then((res) => {
                dispatch({ type: UPDATE_CMS, payload: res.data });
                // dispatch(setIsLoading(false));
                console.log("dataCMS:", res.data)
            })
            .catch((err) => {
                console.log("err", err)
                // dispatch(setError(err))
                // dispatch(setIsLoading(false));
            });
    };
};
//
// export const resetDataUser = () => {
//     return (dispatch) => {
//         dispatch({type: GET_USERS, payload: {}});
//     };
// };